import { gql, useQuery } from '@apollo/client'
import { type JournalingLogQuery, type JournalingLogQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query journalingLog($input: JournalingLogQueryInput!) {
    journalingLog(input: $input) {
      date
      id
      sharedWithTherapistUserId
      journalingActivities {
        id
        name
        slug
      }
      journalingMoods {
        id
        name
        slug
      }
      score
      sharedWithTherapistUserIds
      thankfulReasons
      todayThoughts
    }
  }
`

export const useJournalingLogById = (id: string) => {
  const { data, error, loading, refetch } = useQuery<JournalingLogQuery, JournalingLogQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        id,
      },
    },
  })

  return {
    error: !!error,
    loading,
    journalingLog: data?.journalingLog || null,
    refetch,
  }
}
