import loadable from '@loadable/component'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import {
  generateJournalingScoresFileNames,
  generateRewardFileNames,
} from '~/domains/shareStickers/utils/generateStickerFileNames'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const fileNames = [...generateJournalingScoresFileNames(), ...generateRewardFileNames()]

const LazyStickersPage = loadable(() => import('~/domains/shareStickers'), {
  resolveComponent: (components) => components.ShareStickers,
})

export const StickersPage: Page = {
  exact: true,
  id: 'stickers',
  paths: ['/stickers'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'stickers',
    title: 'actions.share',
  },
  PageGuard: ({ children }) => <AssertPatient target="/">{children}</AssertPatient>,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyStickersPage fileNames={fileNames} trackEventId="share.sticker" />,
}
