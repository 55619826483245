import { media } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import styled from 'styled-components'
import { StartTherapyPathCard, type TherapyPathKey } from './components/StartTherapyPathCard'

const therapyPaths: TherapyPathKey[] = [
  'MYSELF_PSYCHOTHERAPY',
  'COUPLES_PSYCHOTHERAPY',
  'PATH_SEXOLOGY',
  'PATH_COACHING',
  'MYSELF_PSYCHIATRY',
  'PATH_NUTRITION_WEIGHT_LOSS',
  'PATH_NUTRITION_DCA',
]

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${SPACING_SM};
  align-items: stretch;
  padding-top: 16px;

  ${media.gtMd`
    grid-template-columns: 1fr 1fr;
  `}
`

export const StartTherapyPathTiles = () => (
  <Grid>
    {therapyPaths.map((path) => (
      <StartTherapyPathCard key={path} therapyPathType={path} />
    ))}
  </Grid>
)
