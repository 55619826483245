import { useCallback, useState } from 'react'
import { type FormValues } from '~/components/TherapistChurnReasonFields'
import { TherapistChurnReasonModal } from '~/components/TherapistChurnReasonModal'
import { useModals } from '~/domains/modals'
import { useStopTherapySession } from '~/domains/therapy-session/hooks/useStopTherapySession'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { useTherapistAgendaByPatientId } from '~/hooks/useTherapistAgendaByPatientId'
import { useTherapistTherapiesFetch } from '~/hooks/useTherapistTherapiesFetch'
import { useToasts } from '~/hooks/useToasts'

type Props = {
  id: string
  patient: {
    fullName: string
    id: string
  }
}

export const StopTherapySessionModal = ({ id, patient }: Props) => {
  const [loading, setLoading] = useState(false)
  const [stopTherapySession] = useStopTherapySession()
  const { addToast } = useToasts()
  const { close } = useModals()
  const { refetch: therapistTherapiesRefetch } = useTherapistTherapiesFetch()
  const { refetch: therapySessionsTherapistAgendaRefetch } = useTherapySessionsTherapistAgenda()
  const { refetch: therapistAgendaByPatientIdRefetch } = useTherapistAgendaByPatientId(patient.id)

  const modalId = `stopTherapySession:${id}` as const

  const handleClose = useCallback(async () => {
    close(modalId)
  }, [close, modalId])

  const onSubmit = useCallback(
    async ({ churnReason }: FormValues) => {
      setLoading(true)

      try {
        const response = await stopTherapySession({
          variables: {
            input: {
              churnReason,
              id,
            },
          },
        })

        setLoading(false)

        const therapySession = response.data?.stopTherapySession

        if (!therapySession) {
          addToast({ translationId: 'therapySession.stop.cannotStopTherapySession', type: 'alert' })

          return
        }

        // NOTE: because the patient status depends on various factors and it's calculated backend side it's safer to refetch instead of using "updateQuery"
        therapySessionsTherapistAgendaRefetch()
        therapistTherapiesRefetch()
        therapistAgendaByPatientIdRefetch()

        addToast({ translationId: 'therapySession.stop.therapySessionStopped', type: 'success' })

        await handleClose()
      } catch (error) {
        addToast({ translationId: 'therapySession.stop.cannotStopTherapySession', type: 'alert' })

        setLoading(false)
      }
    },
    [
      addToast,
      handleClose,
      id,
      stopTherapySession,
      therapistAgendaByPatientIdRefetch,
      therapistTherapiesRefetch,
      therapySessionsTherapistAgendaRefetch,
    ],
  )

  return (
    <TherapistChurnReasonModal id={modalId} loading={loading} onSubmit={onSubmit} patientFullName={patient.fullName} />
  )
}
