import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { RadioButton, type RadioButtonProps } from 'ui'
import { useLoadingState } from '~/hooks/useLoadingState'

type Props = RadioButtonProps & {
  name: string
  rules?: RegisterOptions
}

export const ReactHookFormRadioFieldVariant = ({ disabled, name, rules, value: inputValue, ...props }: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({ field: { onChange, value, ref }, formState: { isSubmitting, isValid } }) => (
        <RadioButton
          {...props}
          ref={ref}
          checked={value === inputValue}
          disabled={disabled || (isValid && (isSubmitting || loading))}
          name={name}
          onChange={onChange}
          value={inputValue}
        />
      )}
      rules={rules}
    />
  )
}
