import { Flex, media } from 'cdk'
import { BORDER_RADIUS_CIRCLE, BORDER_RADIUS_SM, SPACING_MD, SPACING_SM, SPACING_XS } from 'design-tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTherapistSpecializations } from '~/domains/agenda/hooks/useTherapistSpecializations'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${SPACING_XS};

  ${media.gtSm`
    grid-template-columns: 1fr 1fr;
  `}
`

type Props = { sex: number }

export const TherapistOverviewCardDetailContentTreatments = ({ sex }: Props) => {
  const { specializations } = useTherapistSpecializations()

  if (specializations.length === 0) {
    return null
  }

  return (
    <Flex $backgroundColorName="neutral-20" $borderRadius={BORDER_RADIUS_SM} $gap={SPACING_SM} $p={SPACING_MD}>
      <Text kind="h3">
        <Translation
          id={
            sex === 1 ? 'therapist.overviewCard.dealsWith.title.male' : 'therapist.overviewCard.dealsWith.title.female'
          }
        />
      </Text>
      <GridContainer className="therapist-treatments-container">
        {specializations.map(({ id, name }) => (
          <Flex key={id} $align="center" $direction="row" $gap={SPACING_XS} $grow={1} $shrink={1}>
            <Flex
              $backgroundColorName="darker"
              $borderRadius={BORDER_RADIUS_CIRCLE}
              $maxHeight={4}
              $maxWidth={4}
              $minHeight={4}
              $minWidth={4}
            />
            <Flex $grow={1} $shrink={1}>
              <Text kind="paragraph">{name}</Text>
            </Flex>
          </Flex>
        ))}
      </GridContainer>
    </Flex>
  )
}
