import { pipe } from 'fp-ts/function'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getSessionStorageQueryParams } from '~/utils/getSessionStorageQueryParams'
import { setSessionStorage } from '~/utils/sessionStorage/setSessionStorage'
import { getDefaultSessionStorage } from '../useSessionStorageParams'

export const useQueryParamInSessionStorageEffect = () => {
  const { asPath } = useRouter()

  useEffect(() => {
    if (getSessionStorageQueryParams()) {
      return
    }

    const value = pipe(asPath, getDefaultSessionStorage, JSON.stringify)

    setSessionStorage('query-params', value)
  }, [asPath])
}
