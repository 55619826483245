import { type ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useUserFormFlowResponseCompleted } from '~/domains/formFlow/hooks/useUserFormFlowResponseCompleted'
import { getRoute } from '~/utils/getRoute'

type Props = {
  children: ReactNode
}

export const AssertAtLeastOneFormFlow = ({ children }: Props) => {
  const { loading, userFormFlowResponseCompletedId } = useUserFormFlowResponseCompleted()

  if (loading) {
    return <CenteredLoader />
  }

  if (!userFormFlowResponseCompletedId) {
    return <Redirect to={getRoute('/start')} />
  }

  return <>{children}</>
}
