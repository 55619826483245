import { Flex } from 'cdk'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { FieldMarketingEmails } from '~/domains/react-hook-form/fields/FieldMarketingEmails'
import { FieldProfilation, FieldProfilationVariant } from '~/domains/react-hook-form/fields/FieldProfilation'
import {
  FieldTermsAndConditions,
  FieldTermsAndConditionsVariant,
} from '~/domains/react-hook-form/fields/FieldTermsAndConditions'

export const SignupTermsAndConditions = () => {
  const { isVariant } = useFeatureFlagsByFingerprint()

  return (
    <Flex $gap={16}>
      <FieldMarketingEmails />

      {isVariant('ff_restyle_survey') ? (
        <>
          <FieldTermsAndConditionsVariant />
          <FieldProfilationVariant />
        </>
      ) : (
        <>
          <FieldTermsAndConditions />
          <FieldProfilation />
        </>
      )}
    </Flex>
  )
}
