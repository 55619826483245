import { FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { forwardRef, type HTMLProps } from 'react'
import styled from 'styled-components'
import { CardBox } from 'ui-deprecated'
import { useTooltipContext } from './TooltipContext'

// Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
const TooltipContentContainer = styled(CardBox)`
  z-index: 10;
  width: fit-content;
  max-width: 324px;
`

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ children, ...props }, propRef) => {
    const { strategy, y, x, getFloatingProps, isMounted, kind, refs, styles } = useTooltipContext()
    const ref = useMergeRefs([refs.setFloating, propRef])

    if (!isMounted) {
      return null
    }

    return (
      <FloatingPortal>
        <TooltipContentContainer
          ref={ref}
          borderRadius={kind === 'card' ? 16 : 8}
          borderSize={kind === 'card' ? 1 : 0}
          boxShadow={true}
          p={kind === 'card' ? 24 : 8}
          {...getFloatingProps({
            ...props,
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 16,
              right: 16,
              ...styles,
              ...props.style,
            },
          })}
        >
          {children}
        </TooltipContentContainer>
      </FloatingPortal>
    )
  },
)
