import { addMonths, getTime, startOfDay } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { useCallback } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal, type ModalId, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useProductUserFeedbackComplete } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackComplete'
import { AskForReviewRouteBad } from '~/domains/reviews/routes/AskForReviewRouteBad'
import { AskForReviewRouteGood } from '~/domains/reviews/routes/AskForReviewRouteGood'
import { AskForReviewRouteIntro } from '~/domains/reviews/routes/AskForReviewRouteIntro'
import { getReviewRoute } from '~/domains/reviews/utils/getReviewRoute'
import { useToasts } from '~/hooks/useToasts'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

const modalId: ModalId = 'askForReview'

type Props = {
  productUserFeedbackId: string
}

export const AskForReviewModal = ({ productUserFeedbackId }: Props) => {
  const { close } = useModals()
  const { addToast } = useToasts()

  const { productUserFeedbackComplete } = useProductUserFeedbackComplete(productUserFeedbackId)

  const onClose = useCallback(() => {
    close(modalId)
  }, [close])

  const onConfirm = useCallback(() => {
    setLocalStorage('review-asked-at', pipe(nowInMilliseconds(), String))

    productUserFeedbackComplete().catch(noop)
  }, [productUserFeedbackComplete])

  const onDismiss = useCallback(() => {
    onClose()

    const reviewAskedCount = pipe('review-asked-count', getLocalStorage, Number)

    setLocalStorage('review-ask-at', pipe(nowInMilliseconds(), addMonths(1), startOfDay, getTime, String))
    setLocalStorage('review-asked-count', reviewAskedCount + 1)
  }, [onClose])

  const onCloseWithToast = useCallback(() => {
    addToast({
      translationId: 'reviews.askReviewModal.bad.toast',
      type: 'success',
    })

    onConfirm()

    onClose()
  }, [addToast, onClose, onConfirm])

  return (
    <Modal id={modalId}>
      <MemoryRouter>
        <Switch>
          <Route exact path={getReviewRoute('/')}>
            <ModalCloseButton id={modalId} onClose={onDismiss} />

            <AskForReviewRouteIntro
              onConfirm={onConfirm}
              onDismiss={onDismiss}
              productUserFeedbackId={productUserFeedbackId}
            />
          </Route>
          <Route exact path={getReviewRoute('/bad')}>
            <ModalCloseButton id={modalId} onClose={onDismiss} />

            <AskForReviewRouteBad onClose={onCloseWithToast} productUserFeedbackId={productUserFeedbackId} />
          </Route>
          <Route exact path={getReviewRoute('/good')}>
            <ModalCloseButton id={modalId} />

            <AskForReviewRouteGood />
          </Route>
        </Switch>
      </MemoryRouter>
    </Modal>
  )
}
