import { Flex, PositionAbsolute, PositionRelative, Pressable } from 'cdk'
import { useBreakpoints } from 'hooks'
import { useCallback } from 'react'
import { Text } from 'ui'
import { TertiaryButtonIcon } from '~/components/TertiaryButton/TertiaryButtonIcon'
import { TimelineItem, VerticalDash } from '~/components/Timeline'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { MenuExternalLink } from '~/domains/navigation/NewNavigation'
import { useCopyToClipboard } from '~/hooks/useCopyToClipboard'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'
import { useTranslate } from '~/i18n/hooks/useTranslation'
import { buildReferralSharedLink } from './utils/buildReferralSharedLink'

export const ReferralPageView = () => {
  const { referralCode } = useCurrentUser()

  const { addToast } = useToasts()

  const [, copy] = useCopyToClipboard()

  const { isSmallOnly } = useBreakpoints()

  const handleCopyLink = useCallback(async () => {
    if (referralCode) {
      const result = await copy(buildReferralSharedLink(referralCode))

      addToast(
        result
          ? { translationId: 'referralCode.share.copied', type: 'success' }
          : { translationId: 'generic.error', type: 'alert' },
      )
    }
  }, [addToast, copy, referralCode])

  const translate = useTranslate()

  const handleShareLink = useCallback(async () => {
    try {
      if (referralCode) {
        navigator.share({
          text: translate('referralCode.shared.body'),
          url: buildReferralSharedLink(referralCode),
        })
      }
    } catch (error) {
      addToast({ translationId: 'generic.error', type: 'alert' })
    }
  }, [addToast, referralCode, translate])

  return (
    <Flex $maxWidth={640} $mdPt={0} $pt={24} $px={24}>
      <Flex $pb={32}>
        <Text fontWeight="600" kind="h1">
          <Translation id="referralCode.share.modal.title" />
        </Text>
        <Flex $pt={8}>
          <Text fontWeight="400" kind="h3">
            <Translation id="referralCode.share.modal.body" />
          </Text>
        </Flex>
        <Flex $pt={16}>
          <Text fontWeight="600" kind="h3">
            <Translation id="referralCode.share.modal.subtitle" />
          </Text>
        </Flex>
        <Flex $pt={8}>
          <PositionRelative>
            <PositionAbsolute $bottom="0" $top="0">
              <Flex $pt={16} />

              <VerticalDash />
            </PositionAbsolute>

            <Flex>
              <TimelineItem circleOuterColorName="primary-40">
                <TranslationMarkdown colorName="black" id="referralCode.share.modal.bulletPoint.1" kind="paragraph" />
              </TimelineItem>
              <TimelineItem circleOuterColorName="primary-40">
                <TranslationMarkdown colorName="black" id="referralCode.share.modal.bulletPoint.2" kind="paragraph" />
              </TimelineItem>
            </Flex>
          </PositionRelative>

          <TimelineItem circleOuterColorName="primary-40">
            <TranslationMarkdown colorName="black" id="referralCode.share.modal.bulletPoint.3" kind="paragraph" />
          </TimelineItem>
        </Flex>

        <Flex $pt={24}>
          <Text fontWeight="600" kind="caption">
            <Translation id="referralCode.share.modal.disclaimer" />
          </Text>

          <Flex $pt={24}>
            <MenuExternalLink to="https://www.serenis.it/terms">
              <Text colorName="primary" fontWeight="600" kind="caption" textDecoration="underline">
                <Translation id="referralCode.share.modal.terms" />
              </Text>
            </MenuExternalLink>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        $align="center"
        $backgroundColorName="neutral-30"
        $direction="row"
        $justify="space-between"
        $px={24}
        $py={24}
      >
        <Text fontWeight="600" kind="paragraph">
          <Translation id="referralCode.share.modal.footer" />
        </Text>
        <Flex $align="center" $direction="row">
          <Pressable onClick={handleCopyLink}>
            <TertiaryButtonIcon borderColor="primary-40" colorName="primary" iconName="copy" />
          </Pressable>
          {isSmallOnly && (
            <Flex $pl={8}>
              <Pressable onClick={handleShareLink}>
                <TertiaryButtonIcon borderColor="primary-40" colorName="primary" iconName="send-diagonal" />
              </Pressable>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
