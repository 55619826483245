import { gql, useQuery } from '@apollo/client'
import { type PartnerFindLatestQuery } from '~/types/graphql'

const QUERY = gql`
  query partnerFindLatest {
    partnerFindLatest {
      email
      firstName
      id
      lastName
      notificationsEnabledAt
    }
  }
`

export const usePartnerFindLatest = () => {
  const { data, error, loading } = useQuery<PartnerFindLatestQuery>(QUERY)

  return {
    error: !!error,
    partner: data?.partnerFindLatest || null,
    loading,
  }
}
