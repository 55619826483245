import { Flex, MaxWidth1260px } from 'cdk'
import { Icon } from 'icons'
import { type ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { APP_BAR_HEIGHT } from '~/components/AppBar/constants'
import { TertiaryButtonIconLeft } from '~/components/TertiaryButton/TertiaryButtonIconLeft'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { Translation } from '~/components/Translation'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useStepNavigation } from '../useStepNavigation'

type StepAppBarProps = {
  title?: ReactNode
  onExit?: () => void
}

export const StepAppBar = ({ onExit, title }: StepAppBarProps) => {
  const { isVisibleBackButton } = useStepNavigation()
  const history = useHistory()
  const rootHistory = useRootHistory()

  return (
    <Flex
      $backgroundColorName="lighter"
      $basis={APP_BAR_HEIGHT}
      $elevationName="xxs"
      $justify="center"
      $px={16}
      $zIndex={10}
      as="header"
    >
      <MaxWidth1260px $align="center" $direction="row" $justify="space-between">
        <Flex $basis={60}>
          {isVisibleBackButton && (
            <TertiaryButtonIconLeft iconName="arrow-left" onClick={history.goBack}>
              <Text fontWeight="600" kind="paragraph">
                <Translation id="actions.back" />
              </Text>
            </TertiaryButtonIconLeft>
          )}
        </Flex>
        <Flex $align="center">{title}</Flex>
        <Flex $align="flex-end" $basis={60} data-test-id="appbar-close-button">
          <TertiaryButtonIconOnly onClick={onExit ? onExit : rootHistory.goBack}>
            <Icon name="xmark" size={24} />
          </TertiaryButtonIconOnly>
        </Flex>
      </MaxWidth1260px>
    </Flex>
  )
}
