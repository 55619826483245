import { COLOR_BLACK, COLOR_TRANSPARENT, OPACITY_48 } from 'design-tokens'
import { type ComponentProps } from 'react'
import type ReactModal from 'react-modal'

type StyleProps = ComponentProps<typeof ReactModal>['style']

const styleCommon: StyleProps = {
  content: {
    background: COLOR_TRANSPARENT,
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
  },
  overlay: {
    backgroundColor: `rgb(from ${COLOR_BLACK} r g b / ${OPACITY_48})`,
    bottom: 0,
    left: 0,
    position: 'fixed',
    overflow: 'hidden',
    right: 0,
    top: 0,
    userSelect: 'none',
    zIndex: 110,
  },
}

export const styleDesktop: StyleProps = {
  content: {
    ...styleCommon.content,
    bottom: 'auto',
    display: 'flex',
    left: '50%',
    maxHeight: '80vh',
    minWidth: '400px',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: styleCommon.overlay,
}

export const styleMobile: StyleProps = {
  content: {
    ...styleCommon.content,
    bottom: 0,
    left: 0,
    right: 0,
    top: '20%',
  },
  overlay: styleCommon.overlay,
}
