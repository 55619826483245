import { Flex } from 'cdk'
import { BORDER_RADIUS_4XS, SPACING_2XS, SPACING_3XS, SPACING_MD, SPACING_XS } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'

type Props = {
  leftIndicator: TranslationId
  rightIndicator: TranslationId
  rangeValue?: number
  rangeValues?: number[]
}

export const RangeIndicator = ({
  leftIndicator,
  rightIndicator,
  rangeValue = 0,
  rangeValues = [-2, -1, 0, 1, 2],
}: Props) => (
  <Flex $gap={SPACING_XS} $grow={1} $pt={SPACING_3XS} $shrink={1}>
    <Flex $direction="row" $gap={SPACING_MD} $justify="space-between">
      <Text fontWeight="500" kind="caption">
        <Translation id={leftIndicator} />
      </Text>
      <Text fontWeight="500" kind="caption">
        <Translation id={rightIndicator} />
      </Text>
    </Flex>
    <Flex $direction="row" $gap={SPACING_XS}>
      {rangeValues.map((value, index) => (
        <Flex
          key={index}
          $backgroundColorName={value === rangeValue ? 'primary' : 'neutral-30'}
          $borderRadius={BORDER_RADIUS_4XS}
          $grow={1}
          $maxHeight={SPACING_2XS}
          $minHeight={SPACING_2XS}
        />
      ))}
    </Flex>
  </Flex>
)
