import { gql, useQuery } from '@apollo/client'
import { type AvailableChangeTherapistFreeByPatientQuery } from '~/types/graphql'

const QUERY = gql`
  query availableChangeTherapistFreeByPatient {
    availableChangeTherapistFreeByPatient
  }
`

export const useAvailableChangeTherapistsFree = () => {
  const { data, loading, error } = useQuery<AvailableChangeTherapistFreeByPatientQuery>(QUERY)

  return {
    availableChangeTherapistsFree: data?.availableChangeTherapistFreeByPatient,
    loading,
    error: !!error,
  }
}
