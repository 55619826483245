import { memo } from 'react'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { MenuNativeAppVersion } from '~/domains/appNative/components/MenuNativeAppVersion'
import { MenuLanguageSwitcher } from '~/i18n/components/MenuLanguageSwitcher'
import { useReviewCanShare } from '../hooks/useReviewCanShare'
import { MenuHeader } from './items/MenuHeader'
import { MenuItemAccount } from './items/MenuItemAccount'
import { MenuItemConvention } from './items/MenuItemConvention'
import { MenuItemEmergency } from './items/MenuItemEmergency'
import { MenuItemFAQ } from './items/MenuItemFAQ'
import { MenuItemInvoices } from './items/MenuItemInvoices'
import { MenuItemLogout } from './items/MenuItemLogout'
import { MenuItemNotifications } from './items/MenuItemNotifications'
import { MenuItemPayments } from './items/MenuItemPayments'
import { MenuItemPrivacyPolicy } from './items/MenuItemPrivacyPolicy'
import { MenuItemReview } from './items/MenuItemReview'
import { MenuItemTermsAndConditions } from './items/MenuItemTermsAndConditions'
import { MenuItemWriteUs } from './items/MenuItemWriteUs'

export const PatientTabMenu = memo(({ handleAutoClose }: { handleAutoClose?: () => void }) => {
  const { data: status } = useReviewCanShare()

  return (
    <>
      <MenuHeader />

      <OverflowAuto p={16}>
        <Flex pb={24}>
          <Text colorName="primary" fontWeight="600" kind="paragraph">
            <Translation id="menu.menu" />
          </Text>
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemAccount />
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemPayments />
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemInvoices />
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemNotifications />
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemConvention />
        </Flex>

        <Flex py={24}>
          <Text colorName="primary" fontWeight="600" kind="paragraph">
            <Translation id="menu.help" />
          </Text>
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemFAQ />
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemWriteUs />
        </Flex>

        <Flex pb={24}>
          <MenuItemEmergency />
        </Flex>

        {status === 'CAN_SHARE' && (
          <>
            <Flex py={24}>
              <Text colorName="primary" fontWeight="600" kind="paragraph">
                <Translation id="menu.youOkay" />
              </Text>
            </Flex>

            <Flex pb={24}>
              <MenuItemReview />
            </Flex>
          </>
        )}

        <Flex py={24}>
          <Text colorName="primary" fontWeight="600" kind="paragraph">
            <Translation id="menu.legal" />
          </Text>
        </Flex>

        <Flex pb={24}>
          <MenuItemTermsAndConditions />
        </Flex>

        <Flex pb={24}>
          <MenuItemPrivacyPolicy />
        </Flex>

        <Flex justify="flex-end" pb={24} pt={40}>
          <MenuItemLogout />
        </Flex>

        <Flex pb={24}>
          <MenuLanguageSwitcher />
        </Flex>

        <MenuNativeAppVersion />
      </OverflowAuto>
    </>
  )
})

PatientTabMenu.displayName = 'PatientTabMenu'
