import { createContext, type ReactNode, useContext } from 'react'
import { useHistory, type useLocation } from 'react-router-dom'

type Context = ReturnType<typeof useHistory>

type Props = {
  children: ReactNode
}

const RootHistoryContext = createContext<Context | null>(null)

export const RootHistoryProvider = ({ children }: Props) => {
  const history = useHistory()

  return <RootHistoryContext.Provider value={history}>{children}</RootHistoryContext.Provider>
}

export const useRootHistory = () => {
  const history = useContext(RootHistoryContext)

  if (!history) {
    throw new Error('The `useRootHistory` should be wrapped with `RootHistoryProvider`.')
  }

  return history
}

export const useRootLocation = () => {
  const history = useRootHistory()

  return history.location as ReturnType<typeof useLocation<unknown>>
}
