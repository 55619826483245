import { type ReactNode } from 'react'
import { useUserRedirectEffect } from '~/hooks/useUserRedirectEffect'
import { getRoute } from '~/utils/getRoute'
import { useFeatureFlagsByUserId } from '../hooks/useFeatureFlagsByUserId'
import { type FeatureName } from '../types'

type Props = {
  children: ReactNode
  name: FeatureName
}

export const AssertFeatureFlagVariant1Redirect = ({ children, name }: Props) => {
  const { isVariant1 } = useFeatureFlagsByUserId()
  const isFeatureFlagEnabled = isVariant1(name)

  useUserRedirectEffect(!isFeatureFlagEnabled, getRoute('/'))

  if (!isFeatureFlagEnabled) {
    return null
  }

  return <>{children}</>
}
