import { Flex, Form } from 'cdk'
import { SPACING_LG, SPACING_XS } from 'design-tokens'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Card, Text } from 'ui'
import { provinces } from 'validation'
import { CenteredLoader } from '~/components/CenteredLoader'
import { type SelectOption } from '~/components/SelectNew/types'
import { Translation } from '~/components/Translation'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { StepBottomBarButton } from '~/domains/navigation/step/StepBottomBarButton'
import { StepOverflowAuto } from '~/domains/navigation/step/StepOverflowAuto'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import {
  FieldCity,
  FieldName,
  FieldPostalCode,
  FieldProvince,
  FieldStreet,
  FieldTaxCode,
} from '~/domains/react-hook-form/fields'
import { FieldCountry } from '~/domains/react-hook-form/fields/FieldCountry'
import { FieldForeignRegion } from '~/domains/react-hook-form/fields/FieldForeignRegion'
import { FieldPostalCodeForeign } from '~/domains/react-hook-form/fields/FieldPostalCodeForeign'
import { FieldStsEnabled, FieldStsEnabledVariant } from '~/domains/react-hook-form/fields/FieldStsEnabled'
import { FieldTaxCodeForeign } from '~/domains/react-hook-form/fields/FieldTaxCodeForeign'
import { useToasts } from '~/hooks/useToasts'
import { useUpsertCustomer } from '~/hooks/useUpsertCustomer'
import { useUserCustomer } from '~/hooks/useUserCustomer'
import { useCountries } from '~/i18n/hooks/useCountries'
import { type UserCustomerQuery } from '~/types/graphql'
import { GoRootButton } from '../components/GoRootButton'
import { getReservationRoutes } from '../utils/getReservationRoutes'

type FormValues = {
  city: string
  country: SelectOption<string>
  foreignRegion: string
  foreignPostalCode: string
  name: string
  postalCode: string
  province?: SelectOption<string>
  street: string
  stsEnabled: boolean
  taxCode: string
  taxCodeForeign: string
}

type LocationState = Partial<{
  stsEnabledDefaultValue: boolean
}>

type Props = {
  customer?: NonNullable<UserCustomerQuery['user']>['customer']
}

const BillingForm = ({ customer }: Props) => {
  const { isVariant1 } = useFeatureFlagsByUserId()
  const isNewCheckbox = isVariant1('ff_new_checkbox')

  const [upsertCustomer] = useUpsertCustomer()

  const history = useHistory()

  const { addToast } = useToasts()
  const { countries, italian } = useCountries()

  const { state } = useLocation<LocationState>()

  const countryCode = italian.find(({ name }) => name === (customer?.address?.country ?? 'Italia'))?.code
  const countryName = countries.find(({ code }) => code === countryCode)?.name
  const province = provinces.find(({ code }) => code === customer?.address?.province)

  const form = useReactHookForm<FormValues>({
    defaultValues: {
      city: customer?.address?.city || '',
      country:
        countryCode && countryName ? { label: countryName, value: countryCode } : { label: 'Italia', value: 'IT' },
      foreignRegion: customer?.address?.province ?? '',
      foreignPostalCode: customer?.address?.postalCode ?? '',
      name: customer?.address?.name || '',
      postalCode: customer?.address?.postalCode || '',
      province: province ? { label: province.name, value: province.code } : undefined,
      street: customer?.address?.street || '',
      stsEnabled: Boolean(customer?.stsEnabledAt || state?.stsEnabledDefaultValue),
      taxCode: customer?.taxCode || '',
      taxCodeForeign: customer?.taxCode || '',
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        if (form.formState.isDirty) {
          const country = italian.find(({ code }) => values.country.value === code)?.name ?? 'Italia'

          const { data } = await upsertCustomer({
            variables: {
              input: {
                address: {
                  city: values.city.trim(),
                  country,
                  name: values.name.trim(),
                  postalCode:
                    (values.country.value === 'IT' ? values.postalCode.trim() : values.foreignPostalCode?.trim()) || '',
                  province: (values.country.value === 'IT' ? values.province?.value : values.foreignRegion) || '',
                  street: values.street.trim(),
                },
                customer: {
                  invoiceEnabled: true,
                  stsEnabled: values.country.value === 'IT' ? values.stsEnabled : false,
                  taxCode:
                    values.country.value === 'IT'
                      ? values.taxCode.trim().toUpperCase()
                      : values.taxCodeForeign.trim().toUpperCase(),
                },
              },
            },
          })

          if (!data?.upsertCustomer.ok) {
            addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })

            return
          }

          addToast({ translationId: 'payments.billing.updated', type: 'success' })
        }

        history.push(getReservationRoutes('/insert-payment-method'))
      } catch (e) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, form.formState.isDirty, italian, history, upsertCustomer],
  )

  const {
    formState: { isSubmitting },
    watch,
  } = form

  const selectedCountry = watch('country')
  const isItaly = useMemo(() => selectedCountry.value === 'IT', [selectedCountry.value])

  return (
    <ReactHookFormProvider {...form}>
      <Form $grow={1} $shrink={1} onSubmit={form.handleSubmit(onSubmit)}>
        <StepOverflowAuto>
          <Flex $gap={SPACING_LG}>
            <Flex $gap={SPACING_XS}>
              <Text fontWeight="600" kind="h1">
                <Translation id="bundle.purchase.billing.title" />
              </Text>
              <Flex $pt={SPACING_XS}>
                <Card backgroundColorName="primary-10" size="sm">
                  <Text kind="paragraph">
                    <Translation id="bundle.purchase.billing.infoBox" />
                  </Text>
                </Card>
              </Flex>
            </Flex>

            <FieldName validateOnSubmit={false} />
            <FieldCountry />
            {isItaly ? <FieldProvince /> : <FieldForeignRegion />}
            <FieldCity />
            <FieldStreet />
            {isItaly ? <FieldPostalCode /> : <FieldPostalCodeForeign />}
            {isItaly ? <FieldTaxCode /> : <FieldTaxCodeForeign />}
            {isItaly && (isNewCheckbox ? <FieldStsEnabledVariant /> : <FieldStsEnabled />)}
          </Flex>
        </StepOverflowAuto>
        <StepBottomBarButton>
          <Flex $direction="row" $justify="space-between">
            <GoRootButton />
            <Button disabled={!form.formState.isValid} isLoading={isSubmitting} kind="primary" type="submit">
              <Translation id="actions.confirm" />
            </Button>
          </Flex>
        </StepBottomBarButton>
      </Form>
    </ReactHookFormProvider>
  )
}

export const StepBilling = () => {
  const { customer, loading } = useUserCustomer()

  if (loading) {
    return <CenteredLoader />
  }

  return <BillingForm customer={customer} />
}
