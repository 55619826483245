import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyShareSentenceOfTheDayPage = loadable(() => import('./View'), {
  resolveComponent: (components) => components.ShareSentenceOfTheDayView,
})

export const ShareSentenceOfTheDayPage: Page = {
  exact: true,
  id: 'share.sentence',
  paths: ['/share/sentence'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'sentences.share',
    title: 'seo.sentences.share.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyShareSentenceOfTheDayPage />,
}
