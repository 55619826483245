import { type ReactNode } from 'react'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useUserRedirectEffect } from '~/hooks/useUserRedirectEffect'
import { type Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  target?: Route
}

export const AssertPatientOrTherapist = ({ children, target }: Props) => {
  const isPatient = useUserActingAsGroup() === 'patient'

  const isTherapist = useUserActingAsGroup() === 'therapist'

  const isAuthorized = isPatient || isTherapist

  useUserRedirectEffect(!isAuthorized, target)

  if (!isAuthorized) {
    return null
  }

  return <>{children}</>
}
