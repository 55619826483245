import { gql, useMutation } from '@apollo/client'
import { type MarkAsNoShowMutation, type MarkAsNoShowMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation markAsNoShow($input: NoShowInput!) {
    markAsNoShow(input: $input) {
      ok
    }
  }
`

export const useMarkAsNoShow = () => useMutation<MarkAsNoShowMutation, MarkAsNoShowMutationVariables>(MUTATION)
