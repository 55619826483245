import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { type UserProductReviewQuery, type UserProductReviewQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query userProductReview($userId: String!) {
    userProductReview(userId: $userId) {
      id
      body
    }
  }
`

type Param = {
  productReviewId: string
}

export const useUserProductReview = () => {
  const { productReviewId } = useParams<Param>()

  const { data, error, loading } = useQuery<UserProductReviewQuery, UserProductReviewQueryVariables>(QUERY, {
    variables: {
      userId: productReviewId,
    },
  })

  return {
    error: !!error,
    loading,
    productReview: data?.userProductReview,
  }
}
