import { gql, useQuery } from '@apollo/client'
import { type ReviewCanShareQuery, type ReviewCanShareQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query reviewCanShare {
    reviewCanShare {
      status
    }
  }
`

export const useReviewCanShare = () => {
  const { data, error, loading } = useQuery<ReviewCanShareQuery, ReviewCanShareQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    data: data?.reviewCanShare.status || null,
    error: !!error,
    loading,
  }
}
