import { createContext, type ReactNode, useContext, useMemo, useState } from 'react'

type Context = {
  setUnread: (value: Context['unread']) => void
  unread: number
}

const ChatUnreadCountContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
}

export const ChatUnreadCountProvider = ({ children }: Props) => {
  const [unread, setUnread] = useState(0)

  const value = useMemo(
    () => ({
      setUnread,
      unread,
    }),
    [setUnread, unread],
  )

  return <ChatUnreadCountContext.Provider value={value}>{children}</ChatUnreadCountContext.Provider>
}

export const useChatUnreadCount = () => {
  const state = useContext(ChatUnreadCountContext)

  if (!state) {
    throw new Error('The `useChatUnreadCount` should be wrapped with `ChatUnreadCountProvider`.')
  }

  return state
}
