import { gql, useMutation } from '@apollo/client'
import { type CreateUserDeviceMutation, type CreateUserDeviceMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation createUserDevice($input: CreateUserDeviceInput!) {
    createUserDevice(input: $input) {
      id
    }
  }
`

export const useCreateUserDevice = () =>
  useMutation<CreateUserDeviceMutation, CreateUserDeviceMutationVariables>(MUTATION)
