import { startOfDay, subYears } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { type CalendarDay } from 'ui-deprecated'

type Param = {
  startAt: Date
}

export const useTherapistAgenda = () => {
  const { state } = useLocation<Param>()

  const initialDate = useMemo(() => {
    const date = new Date(state?.startAt || nowInMilliseconds())

    return date
  }, [state])

  const [selectedDate, setSelectedDate] = useState<Date>(initialDate)

  const onSelectDay = useCallback(({ date }: CalendarDay) => {
    setSelectedDate(date)
  }, [])

  return {
    disabledBeforeDate: pipe(nowInMilliseconds(), subYears(1), startOfDay),
    onSelectDay,
    selectedDate,
  }
}
