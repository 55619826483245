import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Suggestion, Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { getRoute, type Route } from '~/utils/getRoute'
import { useShowAvailabilitiesInsertionReminder } from '../hooks/useShowAvailabilitiesInsertionReminder'

export const AvailabilitiesInsertionReminderBanner = () => {
  const show = useShowAvailabilitiesInsertionReminder()
  const { pathname } = useLocation()

  const showLink = useMemo(
    () => (['/availabilities/calendar', '/availabilities/weekly'] as Route[]).every((route) => pathname !== route),
    [pathname],
  )

  if (!show) {
    return null
  }

  return (
    <Suggestion kind="info">
      <Text as="p" kind="caption">
        <Translation id="availabilities.insertionReminder.banner.label" />
      </Text>
      {showLink && (
        <Flex pt={8}>
          <Link to={getRoute('/availabilities/calendar')}>
            <Text colorName="primary" fontWeight="600" kind="caption" textDecoration="underline">
              <Translation id="availabilities.insertionReminder.banner.goToCalendar" />
            </Text>
          </Link>
        </Flex>
      )}
    </Suggestion>
  )
}
