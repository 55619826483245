import { type LocalStorageKey } from './types'

export function removeLocalStorage(key: LocalStorageKey): boolean {
  try {
    window.localStorage.removeItem(`srns-${key}`)

    return true
  } catch (error) {
    return false
  }
}
