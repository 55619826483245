import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { Text } from 'ui'
import { type TranslationId } from '~/i18n/types'
import { Translation } from './Translation'

type Props = {
  startAt: Date
  repeatEveryWeek: number
}

export const RecurrencyTime = ({ startAt, repeatEveryWeek }: Props) => {
  const therapySessionDay = pipe(startAt, format(FormatDateEnum.WEEK_DAY))

  const typeTranslationId: Extract<TranslationId, `therapySession.generic.${string}`> =
    repeatEveryWeek > 1
      ? 'therapySession.generic.multipleRecurrencyDetail'
      : 'therapySession.generic.singleRecurrencyDetail'

  return (
    <Text fontWeight="400" kind="paragraph">
      <Translation
        id={typeTranslationId}
        values={{
          therapySessionDay,
          repeatEveryWeek,
        }}
      />
    </Text>
  )
}
