import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { JournalingProvider } from '../journaling/hooks/useJournalingContext'

const LazyJournalingTipsOfLovePageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.JournalingTipsOfLovePageView,
})

export const JournalingTipsOfLovePage: Page = {
  exact: true,
  id: null,
  paths: ['/journaling/tips-of-love'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: null,
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: JournalingProvider,
  PageView: () => <LazyJournalingTipsOfLovePageView />,
}
