import { useCallback } from 'react'
import { Avatar, Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { ReactHookFormProvider, ReactHookFormRadioField, useReactHookForm } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { type FormValues } from '../types/formValues'

type Props = {
  handleRedirect: (therapistId: string) => void
}

export const ChooseTherapistBeforeBookingModalContent = ({ handleRedirect }: Props) => {
  const { therapists, therapies } = usePatientTherapies()
  const {
    errors: { required },
  } = useFormTranslations()

  const form = useReactHookForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = useCallback(
    ({ therapistId }: FormValues) => {
      if (!therapistId) {
        return
      }

      const therapyId = therapies.find((therapy) => therapy.therapist?.id === therapistId)?.id

      if (!therapyId) {
        return
      }

      handleRedirect(therapyId)
    },
    [handleRedirect, therapies],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
        <OverflowAuto>
          <Flex pt={24}>
            {therapists.map(({ id, fullName }, index) => (
              <Flex key={id} pt={index === 0 ? 0 : 8}>
                <Flex as="label" borderColor="purple04" borderRadius={16} borderSize={1} id={id} p={16}>
                  <ReactHookFormRadioField name="therapistId" rules={{ required }} value={id}>
                    <Flex direction="row">
                      <Avatar name={fullName} size="xs" />

                      <Flex pl={8}>
                        <Text fontWeight="600" kind="paragraph">
                          {fullName}
                        </Text>
                      </Flex>
                    </Flex>
                  </ReactHookFormRadioField>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </OverflowAuto>

        <Flex pt={16}>
          <Button disabled={!form.formState.isValid} kind="primary" type="submit">
            <Translation id="actions.proceed" />
          </Button>
        </Flex>
      </Form>
    </ReactHookFormProvider>
  )
}
