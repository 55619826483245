import { gql, useQuery } from '@apollo/client'
import { isUserTherapist } from '~/domains/therapist/types'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { type FindTherapistsProfilesByIdsQuery, type QueryFindTherapistsProfilesByIdsArgs } from '~/types/graphql'

const QUERY = gql`
  query findTherapistsProfilesByIds($therapistsUserIds: [String!]!) {
    findTherapistsProfilesByIds(therapistsUserIds: $therapistsUserIds) {
      id
      firstName
      fullName
      pathologies {
        pathology {
          id
          name
          nameEn
        }
      }
      therapist {
        about
        aboutCoach
        age
        availableHoursPerWeek
        id
        numberOfPatients
        registerRegistrationRegion
        registerRegistrationYearPsychologist
        sex
        userId
        professionTypes {
          professionType {
            id
            value
          }
        }
        profileImage {
          s
          m
          l
        }
        professionalExperiences {
          id
          name
          type
        }
        therapistCertifications {
          certification {
            description
            icon
            id
            name
            slug
          }
        }
        therapistLanguages {
          language {
            id
            code
            name
          }
        }
        therapistProfessionalStyles {
          score
          type
        }
        therapistStyles {
          style {
            id
            name
          }
        }
        therapistWorkplaces {
          workplace {
            id
            name
          }
        }
        therapySchool {
          name
          orientation
        }
        workRegion
      }
    }
  }
`

export const useSuggestedTherapistsProfiles = (ids: string[]) => {
  const { data, error, loading } = useQuery<FindTherapistsProfilesByIdsQuery, QueryFindTherapistsProfilesByIdsArgs>(
    QUERY,
    {
      variables: {
        therapistsUserIds: ids,
      },
      fetchPolicy: 'cache-first',
    },
  )

  const { language } = useLanguage()

  const suggestedTherapistsProfiles = (data?.findTherapistsProfilesByIds ?? [])
    .map(({ pathologies, ...user }) => ({
      ...user,
      pathologies: pathologies.map(({ pathology: { id, name, nameEn } }) => ({
        pathology: {
          id,
          name: language === 'it' ? name : (nameEn ?? name),
        },
      })),
    }))
    .filter(isUserTherapist)

  return {
    suggestedTherapistsProfiles,
    error,
    loading,
  }
}
