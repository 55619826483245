import { gql, useMutation } from '@apollo/client'
import {
  type SubmitClinicalTestAnswersMutation,
  type SubmitClinicalTestAnswersMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation submitClinicalTestAnswers($input: SubmitClinicalTestAnswersInput!) {
    submitClinicalTestAnswers(input: $input) {
      ok
    }
  }
`

export const useSubmitClinicalTestAnswers = () =>
  useMutation<SubmitClinicalTestAnswersMutation, SubmitClinicalTestAnswersMutationVariables>(MUTATION)
