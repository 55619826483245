import { Flex } from 'cdk'
import { type TextProps } from 'ui'
import { StartTherapyPathTitle } from './components/StartTherapyPathTitle'
import { StartTherapyPathTiles } from './Tiles'

type Props = {
  textAlign?: TextProps['textAlign']
}

export const StartTherapyPath = ({ textAlign = 'left' }: Props) => (
  <Flex>
    <StartTherapyPathTitle textAlign={textAlign} />

    <StartTherapyPathTiles />
  </Flex>
)
