import { createContext, type ReactNode, useCallback, useContext, useState } from 'react'

type Context = {
  hideAppBar: () => void
  hideBackButton: () => void
  hideNotificationsInbox: () => void
  hideTabNavigation: () => void
  isVisibleAppBar: boolean
  isVisibleBackButton: boolean
  isVisibleNotificationsInbox: boolean
  isVisibleTabNavigation: boolean
  showAppBar: () => void
  showBackButton: () => void
  showNotificationsInbox: () => void
  showTabNavigation: () => void
  title: string | null
  updateTitle: (title: string | null) => void
}

const NavigationContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
}

export const NavigationProvider = ({ children }: Props) => {
  const [isVisibleAppBar, setIsVisibleAppBar] = useState(true)
  const [isVisibleNotificationsInbox, setIsVisibleNotificationsInbox] = useState(false)
  const [isVisibleTabNavigation, setIsVisibleTabNavigation] = useState(false)
  const [isVisibleBackButton, setIsVisibleBackButton] = useState(false)
  const [title, setTitle] = useState<string | null>(null)

  const hideAppBar = useCallback(() => {
    setIsVisibleAppBar(false)
  }, [])

  const hideBackButton = useCallback(() => {
    setIsVisibleBackButton(false)
  }, [])

  const hideNotificationsInbox = useCallback(() => {
    setIsVisibleNotificationsInbox(false)
  }, [])

  const hideTabNavigation = useCallback(() => {
    setIsVisibleTabNavigation(false)
  }, [])

  const showAppBar = useCallback(() => {
    setIsVisibleAppBar(true)
  }, [])

  const showBackButton = useCallback(() => {
    setIsVisibleBackButton(true)
  }, [])

  const showNotificationsInbox = useCallback(() => {
    setIsVisibleNotificationsInbox(true)
  }, [])

  const showTabNavigation = useCallback(() => {
    setIsVisibleTabNavigation(true)
  }, [])

  const updateTitle = useCallback((title: string | null) => {
    setTitle(title)
  }, [])

  return (
    <NavigationContext.Provider
      value={{
        hideAppBar,
        hideBackButton,
        hideNotificationsInbox,
        hideTabNavigation,
        isVisibleAppBar,
        isVisibleBackButton,
        isVisibleNotificationsInbox,
        isVisibleTabNavigation,
        showAppBar,
        showBackButton,
        showNotificationsInbox,
        showTabNavigation,
        title,
        updateTitle,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigation = () => {
  const state = useContext(NavigationContext)

  if (!state) {
    throw new Error('The `useNavigation` should be wrapped with `NavigationProvider`.')
  }

  return state
}
