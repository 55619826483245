import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { JournalingProvider } from '../journaling/hooks/useJournalingContext'

const LazyJournalingLogPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.JournalingLogPageView,
})

export const JournalingLogPage: Page = {
  exact: true,
  id: 'journaling.log',
  paths: ['/journaling/logs/:journalingLogId'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'journaling.log',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => (
    <JournalingProvider>
      <PatientTherapiesProvider>{children}</PatientTherapiesProvider>
    </JournalingProvider>
  ),
  PageView: () => <LazyJournalingLogPageView />,
}
