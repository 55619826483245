import { type ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getRoute, type Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  target?: Route
}

export const AssertPatientTypeCouples = ({ children, target = '/' }: Props) => {
  const { loading, therapies } = usePatientTherapies()

  if (loading) {
    return <CenteredLoader />
  }

  const hasCoupleTherapy = therapies.some(
    ({ ongoing, therapyPath }) => ongoing && therapyPath?.type === 'COUPLES_PSYCHOTHERAPY',
  )

  if (!hasCoupleTherapy) {
    return <Redirect to={getRoute(target)} />
  }

  return <>{children}</>
}
