import { gql, useQuery } from '@apollo/client'
import {
  type AvailableTimeSlotsFirstBookingQuery,
  type AvailableTimeSlotsFirstBookingQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query availableTimeSlotsFirstBooking($userIds: [String!]!) {
    availableTimeSlotsFirstBooking(userIds: $userIds) {
      id
      endAt
      startAt
      userId
    }
  }
`

type Param = {
  userIds: string[]
}

export const useAvailableTimeSlotsFirstBooking = ({ userIds }: Param) => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    AvailableTimeSlotsFirstBookingQuery,
    AvailableTimeSlotsFirstBookingQueryVariables
  >(QUERY, {
    variables: {
      userIds,
    },
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    items: data?.availableTimeSlotsFirstBooking || [],
    loading,
    refetch,
    updateQuery,
  }
}
