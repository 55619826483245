import countries, { type GetNameOptions } from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import itLocale from 'i18n-iso-countries/langs/it.json'
import { useMemo } from 'react'
import { useLanguage } from './useLanguage'

countries.registerLocale(enLocale)
countries.registerLocale(itLocale)

const options: GetNameOptions = {
  select: 'official',
}

export const useCountries = () => {
  const { language } = useLanguage()

  const values = useMemo(
    () =>
      Object.entries(countries.getNames(language, options)).map(([code, name]) => ({
        code,
        name,
      })),
    [language],
  )

  const italian = useMemo(
    () =>
      Object.entries(countries.getNames('it', options)).map(([code, name]) => ({
        code,
        name,
      })),
    [],
  )

  return {
    italian,
    countries: values,
  }
}
