import { BORDER_RADIUS_CIRCLE, BORDER_WIDTH_1, COLOR_PRIMARY_10, COLOR_PRIMARY_40 } from 'design-tokens'
import { Icon, type IconName, type IconProps } from 'icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Card, Divider, Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/FloatingUI/Tooltip'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { type TranslationId } from '~/i18n/types'
import { getRoute } from '~/utils/getRoute'
import { useTherapiesGroups } from '../hooks/useTherapiesGroup'
import { type TherapiesFilter } from '../utils/getGroupFilterTranslation'

const RecapIconContainer = styled(Flex)`
  width: 32px;
  height: 32px;
  border: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_PRIMARY_10};
`

const RecapIcon = ({ name }: Pick<IconProps, 'name'>) => (
  <RecapIconContainer align="center" justify="center">
    <Icon colorName="primary" name={name} size={20} />
  </RecapIconContainer>
)

type RecapItemProps = {
  amountOfPatients: number
  filterName: Extract<TherapiesFilter, 'noAppointment' | 'noClinicalDiary' | 'noDiagnosis'>
  filterNameTranslationId: Extract<TranslationId, `patientsManagement.filtersRecap.${RecapItemProps['filterName']}`>
  iconName: IconName
  linkTranslationId: Extract<
    TranslationId,
    'patientsManagement.filtersRecap.manage' | 'patientsManagement.filtersRecap.fillIt'
  >
}

const RecapItem = ({
  filterName,
  filterNameTranslationId,
  iconName,
  linkTranslationId,
  amountOfPatients,
}: RecapItemProps) => (
  <Flex align="center" direction="row">
    <RecapIcon name={iconName} />
    <Flex grow={1} pl={8} shrink={1}>
      <Text kind="caption">
        <TranslationMarkdown id={filterNameTranslationId} kind="caption" values={{ value: `${amountOfPatients}` }} />
      </Text>
    </Flex>
    {amountOfPatients ? (
      <Link to={{ pathname: getRoute('/patients'), search: `filter=${filterName}` }}>
        <Text colorName="primary" fontWeight="600" kind="caption" textDecoration="underline">
          <Translation id={linkTranslationId} />
        </Text>
      </Link>
    ) : (
      <Flex pl={8}>
        <Icon colorName="green-60" name="double-check" size={24} />
      </Flex>
    )}
  </Flex>
)

export const PatientsFiltersRecap = () => {
  const { noAppointment, noClinicalDiary, noDiagnosis } = useTherapiesGroups()

  return (
    <Card>
      <Flex direction="row" justify="space-between" pb={8}>
        <Text fontWeight="600" kind="paragraph">
          <Translation id="patientsManagement.filtersRecap.title" />
        </Text>
        <Flex pl={8}>
          <Tooltip>
            <TooltipTrigger>
              <Icon colorName="primary" name="info-circle" size={24} />
            </TooltipTrigger>
            <TooltipContent>
              <Text fontWeight="600" kind="paragraph">
                <Translation id="patientsManagement.filtersRecap.help.title" />
              </Text>
              <Flex direction="row" pt={16}>
                <Flex pr={16}>
                  <RecapIcon name="user" />
                </Flex>
                <Flex grow={1} shrink={1}>
                  <TranslationMarkdown id="patientsManagement.filtersRecap.help.noAppointment" kind="paragraph" />
                </Flex>
              </Flex>
              <Flex direction="row" pt={16}>
                <Flex pr={16}>
                  <RecapIcon name="page-plus" />
                </Flex>
                <Flex grow={1} shrink={1}>
                  <TranslationMarkdown id="patientsManagement.filtersRecap.help.noClinicalDiary" kind="paragraph" />
                </Flex>
              </Flex>
              <Flex direction="row" pt={16}>
                <Flex pr={16}>
                  <RecapIcon name="multiple-pages-plus" />
                </Flex>
                <Flex grow={1} shrink={1}>
                  <TranslationMarkdown id="patientsManagement.filtersRecap.help.noDiagnosis" kind="paragraph" />
                </Flex>
              </Flex>
            </TooltipContent>
          </Tooltip>
        </Flex>
      </Flex>
      <RecapItem
        amountOfPatients={noAppointment.length}
        filterName="noAppointment"
        filterNameTranslationId="patientsManagement.filtersRecap.noAppointment"
        iconName="user"
        linkTranslationId="patientsManagement.filtersRecap.manage"
      />
      <Flex py={8}>
        <Divider />
      </Flex>
      <RecapItem
        amountOfPatients={noClinicalDiary.length}
        filterName="noClinicalDiary"
        filterNameTranslationId="patientsManagement.filtersRecap.noClinicalDiary"
        iconName="page-plus"
        linkTranslationId="patientsManagement.filtersRecap.fillIt"
      />
      <Flex py={8}>
        <Divider />
      </Flex>
      <RecapItem
        amountOfPatients={noDiagnosis.length}
        filterName="noDiagnosis"
        filterNameTranslationId="patientsManagement.filtersRecap.noDiagnosis"
        iconName="multiple-pages-plus"
        linkTranslationId="patientsManagement.filtersRecap.fillIt"
      />
    </Card>
  )
}
