import { useCallback, useState } from 'react'
import { Text } from 'ui'
import { type AutoComplete, PositionAbsolute, PositionRelative, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormTextField } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { type TranslationId } from '~/i18n/types'

type Props = {
  autoComplete: Extract<AutoComplete, 'new-password' | 'current-password'>
  helperText?: string
  label: string
  name: 'newPassword' | 'oldPassword' | 'password'
}

export const FieldPassword = ({ autoComplete, helperText, label, name }: Props) => {
  const [type, setType] = useState<'text' | 'password'>('password')

  const {
    errors: { required },
    placeholders,
  } = useFormTranslations()

  const { isPassword } = useFormValidation()

  const onTogglePasswordType = useCallback(() => {
    setType(type === 'text' ? 'password' : 'text')
  }, [type])

  const togglePasswordTranslationId: TranslationId =
    type === 'password' ? 'actions.showPassword' : 'actions.hidePassword'

  return (
    <PositionRelative>
      <ReactHookFormTextField
        autoComplete={autoComplete}
        helperText={helperText}
        label={label}
        name={name}
        placeholder={placeholders.password}
        rules={{ required, validate: isPassword }}
        type={type}
      />

      <PositionAbsolute align="flex-end" right="0px" top="30px">
        <Pressable
          autoWidth
          colorName="purple08"
          colorNameHover="purple10"
          justify="center"
          onClick={onTogglePasswordType}
          tabIndex={-1}
        >
          <Text fontWeight="600" kind="caption">
            <Translation id={togglePasswordTranslationId} />
          </Text>
        </Pressable>
      </PositionAbsolute>
    </PositionRelative>
  )
}
