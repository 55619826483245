import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import {
  type ProductUserFeedbackCompleteInput,
  type ProductUserFeedbackCompleteMutation,
  type ProductUserFeedbackCompleteMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation productUserFeedbackComplete($input: ProductUserFeedbackCompleteInput!) {
    productUserFeedbackComplete(input: $input) {
      id
    }
  }
`

export const useProductUserFeedbackComplete = (id: ProductUserFeedbackCompleteInput['id']) => {
  const [mutation] = useMutation<ProductUserFeedbackCompleteMutation, ProductUserFeedbackCompleteMutationVariables>(
    MUTATION,
  )

  const productUserFeedbackComplete = useCallback(
    () =>
      mutation({
        variables: {
          input: {
            id,
          },
        },
      }),
    [id, mutation],
  )

  return {
    productUserFeedbackComplete,
  }
}
