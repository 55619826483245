import { differenceInYears, setYear } from 'date-fns'

export const useTherapistYearOfExperience = ({ registrationYear }: { registrationYear: number | null }): number => {
  if (!registrationYear) {
    return 0
  }

  const now = new Date()
  return differenceInYears(now, setYear(now, registrationYear))
}
