import { useCallback, useEffect, useMemo } from 'react'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useModals } from '~/domains/modals'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { DeleteRecurrencyButton } from './components/DeleteRecurrencyButton'
import { DeleteBatchRecurrencyModal } from './DeleteBatchRecurrencyModal'
import { type FormValues } from './types/formValues'

type Props = Pick<TherapistAgendaTherapySession, 'id' | 'patient'>

export const DeleteRecurrency = ({ id, patient }: Props) => {
  const { open } = useModals()

  const onClick = useCallback(() => {
    open(`deleteBatchRecurrency:${id}`)
  }, [id, open])

  const formDefaultValues = useMemo(
    () => ({
      message: '',
    }),
    [],
  )

  const form = useReactHookForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  // Note: we need to reset form when default values change because it means we are updating a new therapy session; without this the the fields will contain previous session data
  useEffect(() => {
    form.reset(formDefaultValues)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefaultValues])

  return (
    <ReactHookFormProvider {...form}>
      <DeleteRecurrencyButton onClick={onClick} />
      <DeleteBatchRecurrencyModal id={id} patient={patient} />
    </ReactHookFormProvider>
  )
}
