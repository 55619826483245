import { subHours } from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { COLOR_PRIMARY_40 } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Link, Suggestion, Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useModals } from '~/domains/modals'
import { TriggerPaymentModal } from '~/domains/triggerPayment'
import { useTriggerPayment } from '~/domains/triggerPayment/useTriggerPayment'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { type TranslationId } from '~/i18n/types'
import { getRoute } from '~/utils/getRoute'
import { type PatientAgendaTherapySession } from '../types'

type Props = Pick<PatientAgendaTherapySession, 'id' | 'startAt' | 'status'>

const FlexWithBorderLeft = styled(Flex).attrs({
  grow: 1,
  pt: 0,
  lgPt: 0,
  px: 0,
  lgPx: 0,
})`
  border-left: 1px solid ${COLOR_PRIMARY_40};
`

export const PaymentMethodWarningSuggestion = ({ id, startAt, status }: Props) => {
  const history = useHistory()
  const { open, close } = useModals()
  const [loading, setLoading] = useState(false)
  const [loopCompleted, setLoopCompleted] = useState(false)
  const [triggerPayment] = useTriggerPayment()

  const [success, setSuccess] = useState<boolean | null>(null)

  const { refetch: refetchPatientAgenda } = usePatientAgenda()

  const lastDayEdit = useMemo(() => pipe(startAt, subHours(24)), [startAt])

  const onClose = useCallback(async () => {
    await refetchPatientAgenda()
    close(`triggerPayment:${id}`)
    setSuccess(null)
  }, [close, id, refetchPatientAgenda])

  const onLoopCompleted = useCallback(() => {
    setLoopCompleted(true)
  }, [setLoopCompleted])

  const therapySessionDay = useMemo(
    () => ({
      date: pipe(startAt, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME)),
    }),
    [startAt],
  )

  const lastDayEditDate = useMemo(
    () => ({
      date: pipe(lastDayEdit, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME)),
      hours: pipe(lastDayEdit, format(FormatDateEnum.HOURS)),
    }),
    [lastDayEdit],
  )

  const triggerPaymentCallback = useCallback(async () => {
    try {
      setLoading(true)
      setLoopCompleted(false)
      const response = await triggerPayment({
        variables: {
          input: {
            therapySessionId: id,
          },
        },
      })

      const ok = response.data?.triggerPayment.ok

      setSuccess(Boolean(ok))
    } catch (error) {
      setSuccess(false)
    } finally {
      setLoading(false)
    }
  }, [id, triggerPayment])

  const handleTriggerPayment = useCallback(() => {
    open(`triggerPayment:${id}`)
    triggerPaymentCallback()
  }, [id, open, triggerPaymentCallback])

  const handleUpdatePaymentMethod = useCallback(() => {
    history.push(getRoute('/settings/payments'))
  }, [history])

  const titleTranslationId: TranslationId = [
    'PAYMENT_FAILED_FIRST_ATTEMPT',
    'PAYMENT_FAILED_FIRST_ATTEMPT_NO_PAYMENT_METHOD',
  ].includes(status)
    ? 'patientAgenda.therapySessionCard.paymentFailed.title'
    : 'patientAgenda.therapySessionCard.paymentMethodMissing.title'

  const descriptionTranslationId: TranslationId = [
    'PAYMENT_FAILED_FIRST_ATTEMPT',
    'PAYMENT_FAILED_FIRST_ATTEMPT_NO_PAYMENT_METHOD',
  ].includes(status)
    ? 'patientAgenda.therapySessionCard.paymentFailed.firstAttempt.body.variant'
    : 'patientAgenda.therapySessionCard.paymentMethodMissing.body.variant'

  return (
    <Suggestion kind="warning">
      <Flex mdAlign="center" mdDirection="row" mdJustify="space-between">
        <Flex align="flex-start">
          <Text fontWeight="600" kind="paragraph">
            <Translation id={titleTranslationId} />
          </Text>
          <Flex pt={8}>
            <TranslationMarkdown
              id={descriptionTranslationId}
              kind="paragraph"
              values={{
                lastDayEdit: lastDayEditDate.date,
                lastHourEdit: lastDayEditDate.hours,
                therapySessionDay: therapySessionDay.date,
              }}
            />
          </Flex>
        </Flex>

        <Flex mdPl={16} mdPt={0} pt={8}>
          {(status === 'NO_PAYMENT_METHOD' || status === 'SCHEDULED') && (
            <Link onClick={handleUpdatePaymentMethod}>
              <Text fontWeight="600" kind="caption" textDecoration="underline">
                <Translation id="patientAgenda.therapySessionCard.paymentMethodMissing.cta" />
              </Text>
            </Link>
          )}

          {status === 'PAYMENT_FAILED_FIRST_ATTEMPT' && (
            <Flex direction="row" pt={16}>
              <Flex pr={16}>
                <Link onClick={handleTriggerPayment}>
                  <Text fontWeight="600" kind="caption" textDecoration="underline">
                    <Translation id="patientAgenda.therapySessionCard.paymentFailed.retry" />
                  </Text>
                </Link>
              </Flex>

              <FlexWithBorderLeft>
                <Flex pl={16}>
                  <Link onClick={handleUpdatePaymentMethod}>
                    <Text fontWeight="600" kind="caption" textDecoration="underline">
                      <Translation id="patientAgenda.therapySessionCard.paymentFailed.firstAttempt.cta" />
                    </Text>
                  </Link>
                </Flex>
              </FlexWithBorderLeft>
            </Flex>
          )}
        </Flex>
      </Flex>

      <TriggerPaymentModal
        loading={loading}
        loopCompleted={loopCompleted}
        onChangePaymentMethod={handleUpdatePaymentMethod}
        onClose={onClose}
        onLoopCompleted={onLoopCompleted}
        success={success}
        therapySessionId={id}
        triggerPaymentCallback={triggerPaymentCallback}
      />
    </Suggestion>
  )
}
