import { assertNever } from 'functions'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { type TranslationId } from '~/i18n/types'

export const getCurrentStatusTranslationId = (status: TherapistAgendaTherapySession['status']): TranslationId => {
  switch (status) {
    case 'CANCELLED':
      return 'therapySession.patient.cancelled.statusValues'
    case 'NO_SHOW':
      return 'therapySession.patient.noShow.statusValues'
    case 'CONFIRMED':
    case 'PAID':
      return 'therapySession.patient.confirmed.statusValues'
    case 'ENDED':
      return 'therapySession.patient.ended.statusValues'
    case 'ONGOING':
      return 'therapySession.patient.ongoing.statusValues'
    case 'NO_PAYMENT_METHOD':
      return 'therapySession.patient.noPaymentMethod.statusValue'
    case 'PAYMENT_FAILED':
      return 'therapySession.patient.paymentFailed.statusValue'
    case 'PAYMENT_FAILED_FIRST_ATTEMPT':
    case 'PAYMENT_FAILED_FIRST_ATTEMPT_NO_PAYMENT_METHOD':
      return 'therapySession.patient.paymentFailedFirstAttempt.statusValue'
    case 'TO_BE_CONFIRMED':
    case 'SCHEDULED':
      return 'therapySession.patient.scheduled.statusValue'
    default:
      return assertNever(status)
  }
}
