import { type FormFlowForWhomType } from '~/types/graphql'

export const formFlowPrefilledConceptIdToTherapyPathType: Record<string, FormFlowForWhomType> = {
  fcp_l3mjf8iwhig1pxa2: 'COUPLES',
  fcp_shb8r10sn17jcg4c: 'COUPLES', // SKIP
  fcp_nxyasbxbd28bzi1j: 'PSYCHIATRY',
  fcp_d96b49iwat7eqym9: 'PSYCHIATRY', // SKIP
  fcp_zn8srvnk0o2kcxpm: 'MYSELF',
  fcp_01nupy985ryhjkfz: 'MYSELF', // SKIP
  fcp_pcte7g1kzqtybxj2: 'PATH_ASSERTIVE_COMMUNICATION',
  fcp_sl6nvy2dkkfcwg6u: 'PATH_COACHING',
  fcp_75n7jtbqpox2d2s9: 'PATH_COACHING', // SKIP
  fcp_xesyg9r30gs282du: 'PATH_SEXOLOGY',
  fcp_yi5jfjn5vwespxw3: 'PATH_SEXOLOGY', // SKIP
  fcp_sc50mrhiak6jhcvs: 'PATH_SLEEP',
  fcp_1pplin5t1u43vud6: 'UNDERAGE',
  fcp_x9jv6djz1mr1ddbr: 'PATH_NUTRITION_WEIGHT_LOSS',
  fcp_wxjsctsmgsudhsu7: 'PATH_NUTRITION_DCA',
}
