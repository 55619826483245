import { COLOR_ACCENT, COLOR_PRIMARY_30, COLOR_PRIMARY_40, COLOR_PRIMARY_50 } from 'design-tokens'
import { useMemo } from 'react'
import { type TherapyTherapyPathType } from '~/types/graphql'

const colors: Record<
  TherapyTherapyPathType,
  {
    stopColor1: string
    stopColor2: string
    stopColor3: string
    stopColor4: string
    stopColor5: string
    stopColor6: string
    stopColor7: string
    stopColor8: string
    stopColor9: string
    stopColor10: string
    stopColor11: string
    stopColor12: string
    stopColor13: string
    stopColor14: string
    stopColor15: string
    stopColor16: string
  } | null
> = {
  COUPLES_PSYCHOTHERAPY: {
    stopColor1: '#0056D2',
    stopColor2: COLOR_PRIMARY_50,
    stopColor3: COLOR_PRIMARY_50,
    stopColor4: COLOR_PRIMARY_40,
    stopColor5: COLOR_PRIMARY_50,
    stopColor6: COLOR_PRIMARY_50,
    stopColor7: COLOR_PRIMARY_50,
    stopColor8: COLOR_PRIMARY_50,
    stopColor9: COLOR_PRIMARY_30,
    stopColor10: COLOR_PRIMARY_30,
    stopColor11: COLOR_PRIMARY_30,
    stopColor12: COLOR_PRIMARY_30,
    stopColor13: '#2D0D7A',
    stopColor14: '#2D0D7A',
    stopColor15: '#2D0D7A',
    stopColor16: '#2D0D7A',
  },
  MYSELF_PSYCHIATRY: null,
  MYSELF_PSYCHOTHERAPY: {
    stopColor1: COLOR_PRIMARY_50,
    stopColor2: COLOR_PRIMARY_50,
    stopColor3: COLOR_PRIMARY_50,
    stopColor4: COLOR_PRIMARY_40,
    stopColor5: COLOR_PRIMARY_50,
    stopColor6: COLOR_PRIMARY_50,
    stopColor7: COLOR_PRIMARY_50,
    stopColor8: COLOR_PRIMARY_50,
    stopColor9: COLOR_PRIMARY_30,
    stopColor10: COLOR_PRIMARY_30,
    stopColor11: COLOR_PRIMARY_30,
    stopColor12: COLOR_PRIMARY_30,
    stopColor13: COLOR_ACCENT,
    stopColor14: COLOR_ACCENT,
    stopColor15: COLOR_ACCENT,
    stopColor16: COLOR_ACCENT,
  },
  PATH_ASSERTIVE_COMMUNICATION: null,
  PATH_COACHING: {
    stopColor1: '#FD80A8',
    stopColor2: '#FD80A8',
    stopColor3: '#FD80A8',
    stopColor4: '#FD80A8',
    stopColor5: '#FD80A8',
    stopColor6: '#FD80A8',
    stopColor7: '#FD80A8',
    stopColor8: '#FD80A8',
    stopColor9: '#FD80A8',
    stopColor10: '#FD80A8',
    stopColor11: '#FD80A8',
    stopColor12: '#FD80A8',
    stopColor13: '#FCCE42',
    stopColor14: '#FCCE42',
    stopColor15: '#FCCE42',
    stopColor16: '#FCCE42',
  },
  PATH_SEXOLOGY: {
    stopColor1: COLOR_PRIMARY_50,
    stopColor2: COLOR_PRIMARY_50,
    stopColor3: COLOR_PRIMARY_50,
    stopColor4: COLOR_PRIMARY_40,
    stopColor5: COLOR_PRIMARY_50,
    stopColor6: COLOR_PRIMARY_50,
    stopColor7: COLOR_PRIMARY_50,
    stopColor8: COLOR_PRIMARY_50,
    stopColor9: COLOR_PRIMARY_30,
    stopColor10: COLOR_PRIMARY_30,
    stopColor11: COLOR_PRIMARY_30,
    stopColor12: COLOR_PRIMARY_30,
    stopColor13: '#F95A6D',
    stopColor14: '#F95A6D',
    stopColor15: '#F95A6D',
    stopColor16: '#F95A6D',
  },
  PATH_SLEEP: null,
  UNDERAGE_PSYCHOTHERAPY: {
    stopColor1: COLOR_PRIMARY_50,
    stopColor2: COLOR_PRIMARY_50,
    stopColor3: COLOR_PRIMARY_50,
    stopColor4: COLOR_PRIMARY_40,
    stopColor5: COLOR_PRIMARY_50,
    stopColor6: COLOR_PRIMARY_50,
    stopColor7: COLOR_PRIMARY_50,
    stopColor8: COLOR_PRIMARY_50,
    stopColor9: COLOR_PRIMARY_30,
    stopColor10: COLOR_PRIMARY_30,
    stopColor11: COLOR_PRIMARY_30,
    stopColor12: COLOR_PRIMARY_30,
    stopColor13: COLOR_ACCENT,
    stopColor14: COLOR_ACCENT,
    stopColor15: COLOR_ACCENT,
    stopColor16: COLOR_ACCENT,
  },
  PATH_NUTRITION_WEIGHT_LOSS: {
    stopColor1: COLOR_PRIMARY_50,
    stopColor2: COLOR_PRIMARY_50,
    stopColor3: COLOR_PRIMARY_50,
    stopColor4: COLOR_PRIMARY_40,
    stopColor5: COLOR_PRIMARY_50,
    stopColor6: COLOR_PRIMARY_50,
    stopColor7: COLOR_PRIMARY_50,
    stopColor8: COLOR_PRIMARY_50,
    stopColor9: COLOR_PRIMARY_30,
    stopColor10: COLOR_PRIMARY_30,
    stopColor11: COLOR_PRIMARY_30,
    stopColor12: COLOR_PRIMARY_30,
    stopColor13: '#13B1A2',
    stopColor14: '#13B1A2',
    stopColor15: '#13B1A2',
    stopColor16: '#13B1A2',
  },
  PATH_NUTRITION_DCA: {
    stopColor1: COLOR_PRIMARY_50,
    stopColor2: COLOR_PRIMARY_50,
    stopColor3: COLOR_PRIMARY_50,
    stopColor4: COLOR_PRIMARY_40,
    stopColor5: COLOR_PRIMARY_50,
    stopColor6: COLOR_PRIMARY_50,
    stopColor7: COLOR_PRIMARY_50,
    stopColor8: COLOR_PRIMARY_50,
    stopColor9: COLOR_PRIMARY_30,
    stopColor10: COLOR_PRIMARY_30,
    stopColor11: COLOR_PRIMARY_30,
    stopColor12: COLOR_PRIMARY_30,
    stopColor13: '#6EEEC1',
    stopColor14: '#6EEEC1',
    stopColor15: '#6EEEC1',
    stopColor16: '#6EEEC1',
  },
}

type BubblesBackgroundProps = {
  therapyPathType: TherapyTherapyPathType | null
}

export const TherapyPathTypeBackgroundStatic = ({ therapyPathType }: BubblesBackgroundProps) => {
  const stopColors = useMemo(
    () => (therapyPathType && colors[therapyPathType] ? colors[therapyPathType] : null),
    [therapyPathType],
  )

  if (!stopColors) {
    return null
  }

  return (
    <svg fill="none" height="100%" viewBox="0 0 210 170" width="100%" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#static_a)">
        <path
          clipRule="evenodd"
          d="M63.458 63.101c44.043-.418 89.671 18.216 103.31 60.096 13.661 41.949-12.642 83.308-48.027 109.658C81.709 260.432 32.538 277.868-5.19 251.25c-38.963-27.489-45.14-81.352-28.795-126.147 14.589-39.985 54.882-61.598 97.443-62.002Z"
          fill="url(#static_b)"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="static_b"
          x1="-42.443"
          x2="190.885"
          y1="264.463"
          y2="162.378"
        >
          <stop stopColor={stopColors.stopColor1} />
          <stop offset=".067" stopColor={stopColors.stopColor2} />
          <stop offset=".133" stopColor={stopColors.stopColor3} />
          <stop offset=".2" stopColor={stopColors.stopColor4} />
          <stop offset=".267" stopColor={stopColors.stopColor5} />
          <stop offset=".333" stopColor={stopColors.stopColor6} />
          <stop offset=".4" stopColor={stopColors.stopColor7} />
          <stop offset=".467" stopColor={stopColors.stopColor8} />
          <stop offset=".533" stopColor={stopColors.stopColor9} />
          <stop offset=".6" stopColor={stopColors.stopColor10} />
          <stop offset=".667" stopColor={stopColors.stopColor11} />
          <stop offset=".733" stopColor={stopColors.stopColor12} />
          <stop offset=".8" stopColor={stopColors.stopColor13} />
          <stop offset=".867" stopColor={stopColors.stopColor14} />
          <stop offset=".933" stopColor={stopColors.stopColor15} />
          <stop offset="1" stopColor={stopColors.stopColor16} />
        </linearGradient>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="329.045"
          id="static_a"
          width="340.561"
          x="-106.282"
          y="-.744"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_1801_2234" stdDeviation="31.919" />
        </filter>
      </defs>
    </svg>
  )
}

export const TherapyPathTypeBackgroundStaticHeader = ({ therapyPathType }: BubblesBackgroundProps) => {
  const stopColors = useMemo(
    () => (therapyPathType && colors[therapyPathType] ? colors[therapyPathType] : null),
    [therapyPathType],
  )

  if (!stopColors) {
    return null
  }

  return (
    <svg fill="none" height="100%" viewBox="0 0 327 96" width="100%" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#staticHeader_a)">
        <path
          clipRule="evenodd"
          d="M162.46-52.606c44.043-.418 89.671 18.215 103.31 60.096 13.661 41.949-12.642 83.308-48.027 109.658-37.032 27.577-86.204 45.013-123.931 18.395-38.963-27.489-45.14-81.352-28.795-126.147 14.589-39.985 54.882-61.598 97.443-62.002Z"
          fill="url(#staticHeader_b)"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="staticHeader_b"
          x1="56.559"
          x2="289.887"
          y1="148.756"
          y2="46.671"
        >
          <stop stopColor={stopColors.stopColor1} />
          <stop offset=".067" stopColor={stopColors.stopColor2} />
          <stop offset=".133" stopColor={stopColors.stopColor3} />
          <stop offset=".2" stopColor={stopColors.stopColor4} />
          <stop offset=".267" stopColor={stopColors.stopColor5} />
          <stop offset=".333" stopColor={stopColors.stopColor6} />
          <stop offset=".4" stopColor={stopColors.stopColor7} />
          <stop offset=".467" stopColor={stopColors.stopColor8} />
          <stop offset=".533" stopColor={stopColors.stopColor9} />
          <stop offset=".6" stopColor={stopColors.stopColor10} />
          <stop offset=".667" stopColor={stopColors.stopColor11} />
          <stop offset=".733" stopColor={stopColors.stopColor12} />
          <stop offset=".8" stopColor={stopColors.stopColor13} />
          <stop offset=".867" stopColor={stopColors.stopColor14} />
          <stop offset=".933" stopColor={stopColors.stopColor15} />
          <stop offset="1" stopColor={stopColors.stopColor16} />
        </linearGradient>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="329.045"
          id="staticHeader_a"
          width="340.563"
          x="-7.28"
          y="-116.451"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_1792_6248" stdDeviation="31.919" />
        </filter>
      </defs>
    </svg>
  )
}
