import { COLOR_DARKER, COLOR_GREY_60 } from 'design-tokens'
import { type TextAreaStyleProps } from '../types'

export const getColorFromProps = ({ disabled }: Pick<TextAreaStyleProps, 'disabled'>) => {
  if (disabled) {
    return COLOR_GREY_60
  }

  return COLOR_DARKER
}
