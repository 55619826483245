import { assertNever } from 'functions'
import { type TranslationId } from '~/i18n/types'
import { type TherapySchoolOrientation } from '~/types/graphql'

export const getOrientationTranslationId = (orientation: TherapySchoolOrientation): TranslationId => {
  switch (orientation) {
    case 'BREVE_STRATEGICA':
      return 'therapist.orientation.briefStrategic'
    case 'COGNITIVO_COMPORTAMENTALE':
      return 'therapist.orientation.cognitiveBehavioural'
    case 'PSICODINAMICO':
      return 'therapist.orientation.psychoanalytic'
    case 'SISTEMICO_RELAZIONALE':
      return 'therapist.orientation.systemicRelational'
    default:
      return assertNever(orientation)
  }
}
