import { type PropsWithChildren } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useGetTherapySessionLive } from '~/domains/therapy-session/hooks/useGetTherapySessionLive'
import { getRoute } from '~/utils/getRoute'

type Props = PropsWithChildren

type Param = {
  therapySessionId: string
}

export const TherapySessionLivePageGuard = ({ children }: Props) => {
  const { therapySessionId } = useParams<Param>()
  const { isAuthenticated } = useAuthState()
  const { error, item, loading } = useGetTherapySessionLive()

  if (!isAuthenticated) {
    return <Redirect to={getRoute(`/therapy-session/${therapySessionId}/live/external`)} />
  }

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !item || item.provider !== 'HOUSE') {
    return <Redirect to={getRoute('/')} />
  }

  return <>{children}</>
}
