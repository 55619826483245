import { Flex } from 'cdk'
import { useBreakpoints } from 'hooks'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { BottomBar } from './NewNavigation/components/BottomBar'
import { SideBar } from './NewNavigation/components/SideBar'
import { TabNavigationMenuProvider } from './NewNavigation/hooks/useTabNavigationMenu'

type Props = {
  direction: 'horizontal' | 'vertical'
}

export const Navigation = ({ direction }: Props) => {
  const { isAuthenticated } = useAuthState()
  const { isMedium, isSmallOnly } = useBreakpoints()

  if (!isAuthenticated) {
    return null
  }

  return (
    <Flex $zIndex={2}>
      <TabNavigationMenuProvider>
        {isSmallOnly && direction === 'horizontal' && <BottomBar />}
        {isMedium && direction === 'vertical' && <SideBar />}
      </TabNavigationMenuProvider>
    </Flex>
  )
}
