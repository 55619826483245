import { isValidElement, type PropsWithChildren, type ReactNode } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { ScrollRestoreProvider } from '~/hooks/useScrollRestore'
import { PageHead } from '~/routes/PageHead'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { PageTrackEffect } from '~/routes/PageTrackEffect'
import { StepAppBar } from './StepAppBar/StepAppBar'
import { StepAppBarEffect } from './StepAppBar/StepAppBarEffect'
import { StepProgressBar } from './StepProgressBar'
import { type Step } from './types'
import { StepNavigationProvider } from './useStepNavigation'

type Props = PropsWithChildren<{
  steps: Step<string>[]
  initialIndex?: number
  onNavigateToSubmit?: () => void
  title?: ReactNode
  onExit?: () => void
  withProgressBar?: boolean
}>

export const StepsRouter = ({
  children,
  initialIndex = 0,
  onExit,
  steps,
  onNavigateToSubmit,
  title,
  withProgressBar = false,
}: Props) => {
  const initialEntries = steps.flatMap((step) => step.paths)

  const navigationEntries = steps.map(({ paths, options }) => ({
    path: paths[0],
    ...options,
  }))

  return (
    <MemoryRouter initialEntries={initialEntries} initialIndex={initialIndex}>
      <StepNavigationProvider entries={navigationEntries} initialIndex={initialIndex}>
        <StepAppBar onExit={onExit} title={title} />

        {withProgressBar && <StepProgressBar />}

        <ScrollRestoreProvider background="white" grow={1} pb={0} shrink={1}>
          {children}

          <Switch>
            {steps.map(({ exact, id, paths, StepGuard, StepLoaded, StepProvider, StepView, options }) => (
              <Route key={id} exact={exact} path={paths}>
                <StepGuard>
                  <PageHead id={options.seoKey} />

                  <StepProvider>
                    <StepLoaded>
                      <PageTrackEffect id={id} />
                      <PageScrollEffect />
                    </StepLoaded>

                    {isValidElement(StepView) && StepView}
                    {!isValidElement(StepView) && typeof StepView === 'function' && (
                      <StepView onNavigateToSubmit={onNavigateToSubmit} />
                    )}
                  </StepProvider>
                </StepGuard>

                <StepAppBarEffect isVisibleBackButton={options.isVisibleBackButton} />
              </Route>
            ))}
          </Switch>
        </ScrollRestoreProvider>
      </StepNavigationProvider>
    </MemoryRouter>
  )
}
