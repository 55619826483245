import { Button, LinkButton } from 'ui-deprecated'
import { RouterLinkButton } from '~/components/RouterLinkButton'
import { Translation } from '~/components/Translation'
import { useTherapySession } from '~/hooks/useTherapySession'
import { getGoogleMeetUrlWithEmail } from '~/utils/getGoogleMeetUrlWithEmail'
import { type Route } from '~/utils/getRoute'

type Props = {
  isVeryNextTherapySession?: boolean
}

export const ConnectToLiveButton = ({ isVeryNextTherapySession = false }: Props) => {
  const { patient, provider, status, url } = useTherapySession()

  return url && isVeryNextTherapySession ? (
    <>
      {provider === 'HOUSE' ? (
        <RouterLinkButton
          disabled={!isVeryNextTherapySession}
          kind="primary"
          liveAnimation={status === 'ONGOING'}
          to={url as Route}
          translationId="therapySession.generic.ongoing.goToTherapySession"
        />
      ) : (
        <LinkButton
          disabled={!isVeryNextTherapySession}
          href={getGoogleMeetUrlWithEmail(url, patient?.email ?? '')}
          id="therapy-session-link"
          kind="primary"
          target="_blank"
        >
          <Translation id="therapySession.generic.ongoing.goToTherapySession" />
        </LinkButton>
      )}
    </>
  ) : (
    <Button disabled kind="primary">
      <Translation id="therapySession.generic.ongoing.goToTherapySession" />
    </Button>
  )
}
