import { Flex } from 'ui-deprecated'
import { DateTime } from '~/components/DateTime'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'

type Props = Pick<TherapistAgendaTherapySession, 'endAt' | 'startAt'>

export const TherapySessionDateInfo = ({ endAt, startAt }: Props) => (
  <Flex pt={8}>
    <DateTime endAt={endAt} startAt={startAt} />
  </Flex>
)
