import { Link as ReactRouterLink } from 'react-router-dom'
import { Link, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'
import { type Route } from '~/utils/getRoute'

type Props = {
  to: Extract<Route, '/settings/billing'>
  translationId: Extract<TranslationId, 'home.patient.serviceInfo.billing.action'>
}

export const ServiceInfoAction = ({ to, translationId }: Props) => (
  <Link as={ReactRouterLink} to={to}>
    <Text kind="paragraph" textDecoration="underline">
      <Translation id={translationId} />
    </Text>
  </Link>
)
