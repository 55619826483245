import { type Interval, setHours, startOfHour } from 'date-fns/fp'
import { type FormatDateLanguage, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { useBreakpoints } from 'hooks'
import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { type DayLayoutAlgorithm, type Formats, type View, Views } from 'react-big-calendar'
import { isCalendarEventWithinRange } from '~/domains/availabilities/calendar/utils/isCalendarEventWithinRange'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { type CalendarEvent, type CalendarRangeInterval } from '../types'
import { getCalendarDateRange } from '../utils/getCalendarDateRange'
import { groupCalendarEventsByDay } from '../utils/groupCalendarEventsByDay'
import { isCalendarEventOverlapping } from '../utils/isCalendarEventOverlapping'
import { isCalendarEventPast } from '../utils/isCalendarEventPast'
import { mapCalendarAvailabilityToCalendarEvent } from '../utils/mapCalendarAvailabilityToCalendarEvent'
import { parseEndAndStartFromCalendar } from '../utils/parseEndAndStartFromCalendar'
import { useAvailabilitiesByUserId } from './useAvailabilitiesByUserId'

type Props = {
  children: ReactNode
  onError?: () => void
  overrideDefaultView?: typeof Views.DAY | typeof Views.WEEK
}

export type TherapistCalendarParam = {
  dateRange: Interval
  events: {
    available: CalendarEvent[]
    busy: CalendarEvent[]
    slots: Date[][]
  }
  loading: boolean
  isEvent: (range: CalendarRangeInterval) => boolean
  onRangeChange: (range: Date[] | CalendarRangeInterval, view?: View) => void
  onSelecting: (range: CalendarRangeInterval) => boolean | undefined
  options: {
    culture: FormatDateLanguage
    dayLayoutAlgorithm: DayLayoutAlgorithm
    defaultView: View
    formats?: Formats
    max: Date
    min: Date
    longPressThreshold: number
    selectable: 'ignoreEvents'
    step: 15
    timeslots: 4
    views: Extract<View, 'day' | 'week'>[]
  }
  refetch: ReturnType<typeof useAvailabilitiesByUserId>['refetch']
  updateQuery: ReturnType<typeof useAvailabilitiesByUserId>['updateQuery']
}

const defaultDateRange = getCalendarDateRange(nowInMilliseconds(), 'week')
const max = pipe(nowInMilliseconds(), setHours(23), startOfHour)
const min = pipe(nowInMilliseconds(), setHours(6), startOfHour)

const TherapistCalendarContext = createContext<TherapistCalendarParam | null>(null)

export const TherapistCalendarProvider = ({ children, onError = noop, overrideDefaultView }: Props) => {
  const [dateRange, setDateRange] = useState<Interval>(defaultDateRange)

  const { isSmallOnly } = useBreakpoints()
  const { language } = useLanguage()

  const { error, items, loading, refetch, updateQuery } = useAvailabilitiesByUserId(dateRange)

  useEffect(() => {
    if (error) {
      onError()
    }
  }, [error, onError])

  const onRangeChange = useCallback((range: Date[] | CalendarRangeInterval, view?: View) => {
    if (Array.isArray(range)) {
      setDateRange(getCalendarDateRange(range[0], view || 'week'))

      return
    }

    setDateRange(getCalendarDateRange(parseEndAndStartFromCalendar(range).start, view || 'day'))
  }, [])

  const value = useMemo(() => {
    const available = items.filter((item) => item.available).map(mapCalendarAvailabilityToCalendarEvent)
    const busy = items.filter((item) => !item.available).map(mapCalendarAvailabilityToCalendarEvent)

    const defaultView = overrideDefaultView ? overrideDefaultView : isSmallOnly ? Views.DAY : Views.WEEK

    return {
      dateRange,
      events: {
        available,
        busy,
        slots: groupCalendarEventsByDay(busy),
      },
      isEvent: (range: CalendarRangeInterval) =>
        busy
          .concat(available.filter(({ resource }) => resource.type !== 'WEEKLY'))
          .some(isCalendarEventWithinRange(range)),
      loading,
      onRangeChange,
      onSelecting: (range: CalendarRangeInterval) =>
        !busy.some(isCalendarEventOverlapping(range)) && !isCalendarEventPast(range),
      options: {
        culture: language,
        dayLayoutAlgorithm: 'no-overlap' as const,
        defaultView,
        formats: {
          timeGutterFormat: 'HH',
        },
        max,
        min,
        longPressThreshold: 20,
        selectable: 'ignoreEvents' as const,
        step: 15 as const,
        timeslots: 4 as const,
        views: [Views.DAY, Views.WEEK],
      },
      refetch,
      updateQuery,
    }
  }, [dateRange, isSmallOnly, items, language, loading, onRangeChange, overrideDefaultView, refetch, updateQuery])

  return <TherapistCalendarContext.Provider value={value}>{children}</TherapistCalendarContext.Provider>
}

export const useTherapistCalendar = () => {
  const context = useContext(TherapistCalendarContext)

  if (!context) {
    throw new Error('The `useTherapistCalendar` should be wrapped with `TherapistCalendarProvider`.')
  }

  return context
}
