import { gql, useMutation } from '@apollo/client'
import { type JournalingLogUpsertMutation, type JournalingLogUpsertMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation journalingLogUpsert($input: JournalingLogUpsertInput!) {
    journalingLogUpsert(input: $input) {
      id
    }
  }
`

export const useJournalingLogUpsert = () =>
  useMutation<JournalingLogUpsertMutation, JournalingLogUpsertMutationVariables>(MUTATION)
