import { useMergeRefs } from '@floating-ui/react'
import { forwardRef, type HTMLProps, type ReactNode } from 'react'
import { Pressable } from 'ui-deprecated'
import { usePopoverContext } from './PopoverContext'

type Props = {
  children: ReactNode
}

export const PopoverTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & Props>(
  ({ children, ...props }, propRef) => {
    const { getReferenceProps, refs } = usePopoverContext()
    const ref = useMergeRefs([refs.setReference, propRef])
    const referenceProps = getReferenceProps(props)

    return (
      <Pressable ref={ref} autoWidth {...referenceProps}>
        {children}
      </Pressable>
    )
  },
)
