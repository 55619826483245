import { type ReactNode } from 'react'
import { Flex, Pressable } from 'ui-deprecated'

type Props = {
  children: ReactNode
  disabled?: boolean
  onClick: () => void
}

export const MenuAction = ({ children, disabled = false, onClick }: Props) => (
  <Pressable colorNameHover="purple08" disabled={disabled} onClick={onClick}>
    <Flex align="center" direction="row" grow={1} pr={8} shrink={1}>
      {children}
    </Flex>
  </Pressable>
)
