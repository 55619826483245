import { type PropsWithChildren } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { getRoute } from '~/utils/getRoute'

type Props = PropsWithChildren

type Param = {
  therapySessionId: string
}

export const TherapySessionLiveExternalPageGuard = ({ children }: Props) => {
  const { therapySessionId } = useParams<Param>()
  const { isAuthenticated } = useAuthState()

  if (isAuthenticated) {
    return <Redirect to={getRoute(`/therapy-session/${therapySessionId}/live`)} />
  }

  return <>{children}</>
}
