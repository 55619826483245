import { Icon } from 'icons'
import { Modal, ModalContent, ModalTrigger, Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { DeleteForTherapistEmergencyModal } from './components/DeleteForTherapistEmergencyModal'

type Props = Pick<TherapistAgendaTherapySession, 'free' | 'id' | 'startAt' | 'patient' | 'status' | 'typeCost'>

export const DeleteForTherapistEmergency = ({ free, id, startAt, patient, status, typeCost }: Props) => (
  <Modal>
    <ModalTrigger>
      <Pressable autoWidth>
        <Flex align="center" direction="row" justify="flex-start">
          <Flex basis="28px" pr={8}>
            <Icon colorName="black" name="xmark" size={20} />
          </Flex>

          <Text fontWeight="400" kind="caption">
            <Translation id="actions.deleteForEmergency" />
          </Text>
        </Flex>
      </Pressable>
    </ModalTrigger>

    <ModalContent>
      <DeleteForTherapistEmergencyModal
        free={free}
        id={id}
        patient={patient}
        startAt={startAt}
        status={status}
        typeCost={typeCost}
      />
    </ModalContent>
  </Modal>
)
