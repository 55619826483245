import { gql, useQuery } from '@apollo/client'
import { type GetPatientAgendaQuery } from '~/types/graphql'

const QUERY = gql`
  query getPatientAgenda {
    getPatientAgenda {
      lastTherapySession {
        bonus
        cost
        duration
        endAt
        id
        eligibleForTechnicalFeedback
        parentTherapySessionId
        patientId
        startAt
        therapistId
        therapyPathType
      }
      nextTherapySessions {
        bonus
        cost
        costJustify
        duration
        endAt
        id
        free
        patientId
        parentTherapySessionId
        recurrency
        repeatEveryWeek
        startAt
        status
        therapistId
        therapyBundleId
        therapyPathType
        url
        provider
      }
    }
  }
`

export const usePatientAgenda = () => {
  const { data, error, loading, refetch, startPolling, stopPolling, updateQuery } = useQuery<GetPatientAgendaQuery>(
    QUERY,
    {
      fetchPolicy: 'cache-first',
    },
  )

  return {
    agenda: data?.getPatientAgenda,
    error: !!error,
    loading,
    refetch,
    startPolling,
    stopPolling,
    updateQuery,
  }
}
