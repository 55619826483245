import { gql, useMutation } from '@apollo/client'
import { type CheckVerificationCodeMutation, type CheckVerificationCodeMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation checkVerificationCode($input: CheckVerificationCodeInput!) {
    checkVerificationCode(input: $input) {
      ok
    }
  }
`

export const useCheckVerificationCode = () =>
  useMutation<CheckVerificationCodeMutation, CheckVerificationCodeMutationVariables>(MUTATION)
