import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatientOrTherapist } from '~/domains/auth/components/AssertPatientOrTherapist'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyTestTherapistRestyle = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const TestTherapistRestylePage: Page = {
  exact: true,
  id: null,
  paths: ['/test-therapist-restyle'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: null,
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatientOrTherapist target="/">{children}</AssertPatientOrTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyTestTherapistRestyle />,
}
