import type * as Sentry from '@sentry/nextjs'
import { type Logger, type LogLevel, logToConsole } from '@stream-io/video-react-sdk'

const logLevelMapping = new Map<LogLevel, Sentry.SeverityLevel>()

logLevelMapping.set('debug', 'debug')
logLevelMapping.set('info', 'info')
logLevelMapping.set('warn', 'warning')
logLevelMapping.set('error', 'error')

export const videocallToSentryLogger: Logger = logToConsole
