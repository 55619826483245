import { Pressable } from 'cdk'
import { useCallback } from 'react'
import { Modal, ModalContent, ModalTrigger } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { TherapyPathCard } from '~/components/TherapyPathCard'
import { ChangeTherapistModal } from '~/domains/changeTherapist/components/ChangeTherapistModal'
import { useModals } from '~/domains/modals'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { StartTherapyPathModalContent } from './StartTherapyPathModalContent'
import { TherapyAlreadyOngoingModalContent } from './TherapyAlreadyOngoingModalContent'

export type TherapyPathKey = Extract<
  TherapyTherapyPathType,
  | 'MYSELF_PSYCHOTHERAPY'
  | 'COUPLES_PSYCHOTHERAPY'
  | 'PATH_COACHING'
  | 'PATH_SEXOLOGY'
  | 'MYSELF_PSYCHIATRY'
  | 'PATH_NUTRITION_DCA'
  | 'PATH_NUTRITION_WEIGHT_LOSS'
>

type Props = {
  therapyPathType: TherapyPathKey
}

export const StartTherapyPathCard = ({ therapyPathType }: Props) => {
  const { therapies, loading } = usePatientTherapies()
  const { open } = useModals()

  const isNutritionTherapyPath = ['PATH_NUTRITION_DCA', 'PATH_NUTRITION_WEIGHT_LOSS'].includes(therapyPathType)

  const ongoingTherapy = therapies
    .filter((therapy) => therapy.ongoing)
    .find(({ therapyPath }) =>
      isNutritionTherapyPath
        ? therapyPath.type === 'PATH_NUTRITION_WEIGHT_LOSS' || therapyPath.type === 'PATH_NUTRITION_DCA'
        : therapyPath.type === therapyPathType,
    )

  const onChangeTherapist = useCallback(() => {
    if (!ongoingTherapy || therapyPathType === 'MYSELF_PSYCHIATRY') {
      return
    }

    open(`changeTherapist:${ongoingTherapy.id}`)
  }, [ongoingTherapy, open, therapyPathType])

  if (loading) {
    return <CenteredLoader />
  }

  return (
    <>
      <Modal>
        <ModalTrigger data-test-id={`cta-start-therapy-path-${therapyPathType.toLowerCase()}`}>
          <Pressable>
            <TherapyPathCard therapyPathType={therapyPathType} />
          </Pressable>
        </ModalTrigger>
        <ModalContent>
          {ongoingTherapy ? (
            <TherapyAlreadyOngoingModalContent
              onChangeTherapist={onChangeTherapist}
              therapistFullName={ongoingTherapy?.therapist?.fullName}
              therapistId={ongoingTherapy?.therapist?.id}
              therapyPathType={ongoingTherapy?.therapyPath?.type}
            />
          ) : (
            <StartTherapyPathModalContent therapyPathType={therapyPathType} />
          )}
        </ModalContent>
      </Modal>

      {!!ongoingTherapy?.id && <ChangeTherapistModal therapyId={ongoingTherapy.id} />}
    </>
  )
}
