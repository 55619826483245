import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { type TherapySessionLiveQuery, type TherapySessionLiveQueryVariables } from '~/types/graphql'

const GET_THERAPY_SESSION_LIVE = gql`
  query therapySessionLive($id: String!) {
    therapySessionLive(id: $id) {
      duration
      endAt
      endAtSlot
      id
      provider
      partner {
        id
        firstName
        fullName
      }
      patient {
        id
        firstName
        fullName
      }
      startAt
      streamToken
      therapyPathType
      therapist {
        id
        firstName
        fullName
        profileImage {
          s
          m
          l
        }
      }
    }
  }
`

type Param = {
  therapySessionId: string
}

export const useGetTherapySessionLive = () => {
  const { therapySessionId } = useParams<Param>()

  const { data, error, loading } = useQuery<TherapySessionLiveQuery, TherapySessionLiveQueryVariables>(
    GET_THERAPY_SESSION_LIVE,
    {
      fetchPolicy: 'cache-first',
      variables: {
        id: therapySessionId,
      },
    },
  )

  return {
    error: !!error,
    item: data?.therapySessionLive,
    loading,
  }
}
