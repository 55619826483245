import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { type BookAndAssignMutation, type BookAndAssignMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation bookAndAssign($input: BookAndAssignInput!) {
    bookAndAssign(input: $input) {
      ok
    }
  }
`

type Param = {
  startAt: Date
  therapistId: string
}

export const useBookAndAssign = () => {
  const [bookAndAssign] = useMutation<BookAndAssignMutation, BookAndAssignMutationVariables>(MUTATION)
  const { id: patientId } = useCurrentUser()

  return useCallback(
    ({ startAt, therapistId }: Param) =>
      bookAndAssign({
        variables: {
          input: {
            patientId,
            startAt,
            therapistId,
          },
        },
      }),
    [bookAndAssign, patientId],
  )
}
