import { createContext, type ReactNode, useCallback, useContext, useState } from 'react'

export type ThirdPartyApp = 'instagram'

type Context = {
  isInstalled: (app: ThirdPartyApp) => boolean
  setIsInstalled: (app: ThirdPartyApp, value: boolean) => void
}

type State = Partial<Record<ThirdPartyApp, boolean>>

const ThirdPartyAppContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
}

export const ThirdPartyAppProvider = ({ children }: Props) => {
  const [state, setState] = useState<State>({})

  const handleSetIsInstalled = useCallback((app: ThirdPartyApp, value: boolean) => {
    setState((state) => ({
      ...state,
      [app]: value,
    }))
  }, [])

  const handleIsInstalled = useCallback((app: ThirdPartyApp) => !!state[app], [state])

  return (
    <ThirdPartyAppContext.Provider value={{ isInstalled: handleIsInstalled, setIsInstalled: handleSetIsInstalled }}>
      {children}
    </ThirdPartyAppContext.Provider>
  )
}

export const useThirdPartyApp = () => {
  const context = useContext(ThirdPartyAppContext)

  if (!context) {
    throw new Error('The `useThirdPartyApp` should be wrapped with `ThirdPartyAppProvider`.')
  }

  return context
}
