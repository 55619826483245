import {
  COLOR_LIGHTER,
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_80,
  COLOR_PRIMARY,
  COLOR_PRIMARY_10,
  COLOR_RED_60,
} from 'design-tokens'
import { type Theme } from 'react-select'
import { type SelectSize } from '~/components/SelectNew/types'

export const theme =
  (size: SelectSize) =>
  (theme: Theme): Theme => ({
    borderRadius: 8,
    colors: {
      ...theme.colors,
      danger: COLOR_RED_60,
      neutral0: COLOR_LIGHTER,
      neutral20: COLOR_NEUTRAL_40,
      neutral50: COLOR_NEUTRAL_80,
      primary: COLOR_PRIMARY,
      primary25: COLOR_PRIMARY_10,
    },
    spacing: {
      baseUnit: 8,
      controlHeight: size === 'medium' ? 60 : 48,
      menuGutter: 8,
    },
  })
