import { Flex, type FlexProps } from 'cdk'
import { type ColorName } from 'design-tokens'
import { Icon, type IconProps } from 'icons'
import styled from 'styled-components'

type Props = {
  borderColor?: ColorName
  colorName?: ColorName
  iconName: IconProps['name']
  backgroundColor?: ColorName
}

const ArrowWrapper = styled(Flex).attrs<FlexProps>(({ $backgroundColorName }) => ({
  $align: 'center',
  $justify: 'center',
  $backgroundColorName,
}))`
  width: 40px;
  height: 40px;
`

export const TertiaryButtonIcon = ({
  backgroundColor = 'transparent',
  borderColor = 'primary-30',
  colorName,
  iconName,
}: Props) => (
  <ArrowWrapper
    $backgroundColorName={backgroundColor}
    $borderColorName={borderColor}
    $borderRadius={40}
    $borderSize={1}
    className="arrow"
  >
    <Icon colorName={colorName} name={iconName} size={24} />
  </ArrowWrapper>
)
