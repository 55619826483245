import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import NextLink from 'next/link'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Translation } from '~/components/Translation'
import { useIsSaasTherapist } from '~/domains/auth/hooks/useIsSaasTherapist'
import { getEnv } from '~/utils/getEnv'
import { getRoute } from '~/utils/getRoute'
import { type NavigationProps } from '../../types'
import { FlexWithTransition } from '../common/FlexWithTransition'
import { NavLinkContainer } from '../common/NavLinkContainer'
import { TextSize18 } from '../common/TextSize18'

type Props = {
  onLinkClick?: () => void
} & NavigationProps

const GestoIcon = () => (
  <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4014 9.80056c1.4787-2.48286 3.186-3.85487 3.6751-4.21576h7.0029c-1.507 1.55382-4.1125 4.8541-5.1421 9.9129-.6706-1.4458-1.6931-2.6441-2.6841-3.5662-1.1094-1.0322-2.2174-1.7545-2.8518-2.13094Z"
      fill="#083400"
      stroke="#083400"
      strokeWidth="1.16959"
    />
    <path
      d="M9.60497 10.0225H2.31344c-.32225 0-.43345.4675-.15432.6422 1.85182 1.1551 5.27178 3.867 5.27405 8.1743 0-3.7195.9781-6.6357 2.17406-8.8165h-.00226Z"
      fill="#CAFACB"
    />
    <path
      d="M9.60717 10.0225c-1.19596 2.1808-2.17406 5.097-2.17406 8.8165V18.8708c0 .3586.26551.6513.59457.6513h7.49122c.3291 0 .5946-.2905.5946-.6513v-.0545c-.0204-5.501-6.49271-8.7848-6.51086-8.7938h.00453Z"
      fill="#69F16C"
    />
  </svg>
)

export const LinkGesto = ({ extended, onLinkClick, isMobile = false }: Props) => {
  const gestoAppUrl = getEnv('GESTO_APP_URL')
  const isSaasTherapist = useIsSaasTherapist()
  const history = useHistory()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!isSaasTherapist) {
        event.preventDefault()
        onLinkClick?.()
        history.push(getRoute('/gesto/onboarding/internal'))
      } else {
        onLinkClick?.()
      }
    },
    [isSaasTherapist, onLinkClick, history],
  )

  return (
    <Flex as={NextLink} href={`${gestoAppUrl}/dashboard`} onClick={handleClick} target="_blank">
      <NavLinkContainer $direction="row" $gap={SPACING_SM}>
        <Flex>
          <GestoIcon />
        </Flex>
        {!isMobile && (
          <FlexWithTransition $opacity={extended ? 1 : 0}>
            <TextSize18 colorName="grey-110" fontWeight="500" kind="h3">
              <Translation id="menu.gesto" />
            </TextSize18>
          </FlexWithTransition>
        )}
      </NavLinkContainer>
    </Flex>
  )
}
