import { useCallback } from 'react'
import { useCreatePaymentMethod } from '~/domains/payments/hooks/useCreatePaymentMethod'
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods'
import { useDataLayer } from '~/hooks/useDataLayer'
import { useToasts } from '~/hooks/useToasts'
import { useUpsertCustomer } from '~/hooks/useUpsertCustomer'

export const useAddPaymentMethodByPaymentElement = () => {
  const [upsertCustomer] = useUpsertCustomer()
  const [createPaymentMethod] = useCreatePaymentMethod()
  const { addToast } = useToasts()
  const { items, update } = usePaymentMethods()
  const dataLayer = useDataLayer()

  return useCallback(
    async (paymentMethodId: string) => {
      try {
        const { data: createCustomerData } = await upsertCustomer({
          variables: {
            input: {
              customer: { paymentMethodId },
            },
          },
        })

        if (!createCustomerData?.upsertCustomer?.ok) {
          addToast({ translationId: 'payments.unableToChangePaymentMethod', type: 'alert' })

          return
        }

        try {
          await createPaymentMethod({
            variables: {
              input: {
                defaultPaymentMethod: true,
                paymentMethodId,
              },
            },
          })

          addToast({ translationId: 'payments.paymentMethodAdded', type: 'success' })

          if (!items.length) {
            dataLayer.add({ event: 'USER_PAYMENT_ACCOUNT_CREATED' }, 'user-payment-account-created')
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}

        await update()
      } catch (error) {
        addToast({ translationId: 'payments.unableToChangePaymentMethod', type: 'alert' })
      }
    },
    [addToast, upsertCustomer, createPaymentMethod, dataLayer, items, update],
  )
}
