import loadable from '@loadable/component'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyForgotPasswordPage = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const ForgotPasswordPage: Page = {
  exact: true,
  id: 'auth.forgot-password',
  paths: ['/forgot-password'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'forgotPassword',
    title: null,
  },
  PageGuard: PageNoop,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyForgotPasswordPage />,
}
