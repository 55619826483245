import { Flex, Form, PositionAbsolute } from 'cdk'
import { useCallback, useMemo, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { Text } from 'ui'
import { BubblesBackground } from '~/components/BubblesBackground'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { StepNoop } from '~/domains/navigation/step/StepNoop'
import { StepsRouter } from '~/domains/navigation/step/StepsRouter'
import { type Step } from '~/domains/navigation/step/types'
import { ReactHookFormProvider } from '~/domains/react-hook-form'
import { useToasts } from '~/hooks/useToasts'
import { type ClinicalTestCode } from '~/types/graphql'
import { ClinicalTestNameTranslation } from '../components/ClinicalTestNameTranslation'
import { useClinicalTestAnswer } from '../hooks/useClinicalTestAnswer'
import { useSubmitClinicalTestAnswers } from '../hooks/useSubmitClinicalTestAnswers'
import { ClinicalTestEndStep } from './ClinicalTestEndStep'
import { ClinicalTestIntroStep } from './ClinicalTestIntroStep'
import { ClinicalTestQuestionStep } from './ClinicalTestQuestionStep'
import { ExitConfirmationModal } from './ExitConfirmationModal'

const introStep = (clinicalTestAnswerId: string): Step<string> => ({
  exact: true,
  id: 'clinicalTest.step.intro',
  paths: ['/intro'],
  options: {
    isVisibleBackButton: false,
    seoKey: 'clinicalTest',
    isInfoStep: true,
  },
  StepGuard: StepNoop,
  StepLoaded: StepNoop,
  StepProvider: StepNoop,
  StepView: <ClinicalTestIntroStep clinicalTestAnswerId={clinicalTestAnswerId} />,
})

const lastStep = (clinicalTestAnswerId: string): Step<string> => ({
  exact: true,
  id: 'clinicalTest.step.end',
  paths: ['/end'],
  options: {
    isVisibleBackButton: false,
    seoKey: 'clinicalTest',
    isInfoStep: true,
  },
  StepGuard: StepNoop,
  StepLoaded: StepNoop,
  StepProvider: StepNoop,
  StepView: <ClinicalTestEndStep clinicalTestAnswerId={clinicalTestAnswerId} />,
})

const buildQuestionStep = (questionId: string, onSubmit: VoidFunction, isSubmitStep: boolean): Step<string> => ({
  exact: true,
  id: `clinicalTest.step.question.${questionId}`,
  paths: [`/${questionId}`],
  options: {
    isVisibleBackButton: false,
    seoKey: 'clinicalTest',
    isInfoStep: false,
    isSubmitStep,
  },
  StepGuard: StepNoop,
  StepLoaded: StepNoop,
  StepProvider: StepNoop,
  StepView: <ClinicalTestQuestionStep onSubmit={onSubmit} questionId={questionId} />,
})

type FormValues = {
  [key: string]: string
}

type TitleProps = {
  code: ClinicalTestCode
}

const Title = ({ code }: TitleProps) => (
  <Flex $align="center">
    <Text kind="h3" textAlign="center">
      <Translation id="seo.clinicalTest.title" />
    </Text>
    <Text colorName="neutral-80" kind="caption" textAlign="center">
      <ClinicalTestNameTranslation code={code} />
    </Text>
  </Flex>
)

export const ClinicalTestFormFlow = () => {
  const history = useHistory()
  const { clinicalTestAnswerId } = useParams<{ clinicalTestAnswerId: string }>()
  const { loading, clinicalTestAnswer } = useClinicalTestAnswer(clinicalTestAnswerId)
  const [submitClinicalTestAnswers, { called }] = useSubmitClinicalTestAnswers()
  const { addToast } = useToasts()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const form = useForm<FormValues>()

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (answers) => {
      try {
        await submitClinicalTestAnswers({
          variables: {
            input: {
              answers: Object.entries(answers).map(([questionId, value]) => ({ answer: parseInt(value), questionId })),
              clinicalTestAnswerId,
            },
          },
        })
      } catch (error) {
        addToast({
          translationId: 'generic.errorOccurred.text',
          type: 'alert',
        })

        return
      }
    },
    [addToast, clinicalTestAnswerId, submitClinicalTestAnswers],
  )

  const steps = useMemo(
    () =>
      clinicalTestAnswer
        ? [
            introStep(clinicalTestAnswerId),
            ...clinicalTestAnswer.questions.map(({ id }, index) =>
              buildQuestionStep(id, form.handleSubmit(handleSubmit), index === clinicalTestAnswer.questions.length - 1),
            ),
            lastStep(clinicalTestAnswerId),
          ]
        : [],
    [clinicalTestAnswer, clinicalTestAnswerId, form, handleSubmit],
  )

  if (!clinicalTestAnswer || loading) {
    return <CenteredLoader />
  }

  return (
    <>
      <ReactHookFormProvider {...form}>
        <Form $grow={1} $shrink={1}>
          <StepsRouter
            initialIndex={0}
            onExit={() => {
              if (called) {
                history.goBack()
              }

              setIsModalOpen(true)
            }}
            steps={steps}
            title={<Title code={clinicalTestAnswer.code} />}
            withProgressBar={true}
          >
            <PositionAbsolute $bottom={0} $left={0} $right={0} $top={0} $zIndex={0}>
              <BubblesBackground therapyPathType="PATH_SEXOLOGY" />
            </PositionAbsolute>
          </StepsRouter>
        </Form>
      </ReactHookFormProvider>

      <ExitConfirmationModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  )
}
