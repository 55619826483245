import { COLOR_GREY_60, COLOR_PRIMARY_30, COLOR_PRIMARY_50, COLOR_RED_80 } from 'design-tokens'
import { type TextAreaStyleProps } from '../types'

export const getBorderColorFromProps =
  (focused: boolean) =>
  ({ disabled, invalid }: Omit<TextAreaStyleProps, 'minHeight'>) => {
    if (disabled) {
      return COLOR_GREY_60
    }

    if (invalid) {
      return COLOR_RED_80
    }

    if (focused) {
      return COLOR_PRIMARY_50
    }

    return COLOR_PRIMARY_30
  }
