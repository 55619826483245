import { setHours, setMinutes } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { ReactHookFormProvider, useReactHookFormContext } from '~/domains/react-hook-form'
import { useTherapistRescheduleSubmit } from '../hooks/useTherapistRescheduleSubmit'
import { type FormValues } from '../types/formValues'

type Props = {
  onClose?: () => void
  id: string
  patientFullName?: string
  patientId?: string
  selectedDay: Date
}

export const ModalRescheduleTherapySessionUnder24 = ({ onClose = noop, id, patientId, selectedDay }: Props) => {
  const modalId = `rescheduleTherapySessionUnder24:${id}` as const
  const { close } = useModals()
  const form = useReactHookFormContext<FormValues>()

  const onSuccess = useCallback(async () => {
    close(modalId)

    onClose()
  }, [close, modalId, onClose])

  const [rescheduleSubmit, { loading }] = useTherapistRescheduleSubmit({
    id,
    onSuccess,
    patientId,
  })

  const handleClose = useCallback(async () => {
    close(modalId)

    onClose()
  }, [close, onClose, modalId])

  const onSubmit = useCallback(
    async ({ startAtHours, startAtMinutes, message }: FormValues): Promise<void> => {
      const startAt = pipe(selectedDay, setHours(startAtHours), setMinutes(startAtMinutes))

      await rescheduleSubmit({ message, startAt })
    },
    [rescheduleSubmit, selectedDay],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Modal id={modalId}>
        <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
          <ModalCloseButton id={modalId} />

          <OverflowAuto lgPt={40} mdPt={32} pt={24} px={8}>
            <Text fontWeight="600" kind="h2">
              <Translation id="availabilities.calendar.rescheduleTherapySessionUnder24.title" />
            </Text>

            <Flex pt={16}>
              <Text fontWeight="400" kind="paragraph">
                <Translation id="availabilities.calendar.rescheduleTherapySessionUnder24.description" />
              </Text>
            </Flex>
          </OverflowAuto>

          <Flex pt={16} px={8}>
            <Flex pt={16}>
              <Button kind="primary" loading={loading} type="submit">
                <Translation id="actions.gotIt" />
              </Button>
              <Flex pt={8}>
                <Button disabled={loading} kind="secondary" onClick={handleClose}>
                  <Translation id="actions.cancel" />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      </Modal>
    </ReactHookFormProvider>
  )
}
