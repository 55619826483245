import loadable from '@loadable/component'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { Icon } from 'icons'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { CardBoxNoStroke } from '~/components/CardBoxNoStroke'
import { TertiaryButtonIcon } from '~/components/TertiaryButton/TertiaryButtonIcon'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useModals } from '~/domains/modals'
import { useJournalingLogCanShare } from '~/routes/journalingLog/hooks/useJournalingLogCanShare'
import { type JournalingLogsQuery } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { ShareJournalingLogModal } from './ShareJournalingLogModal'

const Fallback = styled.div`
  width: 160px;
  height: 160px;
`

const LazyJournalingLogScoreIllustrationAnimated = loadable(
  () => import('~/routes/journalingTipsOfLove/components/JournalingLogScoreIllustrationAnimated/index.lazy'),
  {
    resolveComponent: (components) => components.JournalingLogScoreIllustrationAnimated,
    fallback: <Fallback />,
  },
)

type Props = Pick<NonNullable<JournalingLogsQuery['journalingLogs']>[number], 'date' | 'id' | 'score'> & {
  sharedWithTherapistUserId?: string | null
  sharedWithTherapistUserIds?: string[] | null
}

export const JournalingLogCard = ({
  date,
  id,
  sharedWithTherapistUserId,
  sharedWithTherapistUserIds,
  score,
}: Props) => {
  const history = useHistory()
  const [canShare, setCanShare] = useState(false)

  const { open } = useModals()

  const group = useUserActingAsGroup()
  const { journalingLogCanShare } = useJournalingLogCanShare()

  const handleGoToDetailClick = useCallback(() => {
    history.push(getRoute(group === 'patient' ? `/journaling/logs/${id}` : `/journaling/logs/${id}/shared`))
  }, [group, history, id])

  const handleShareClick = useCallback(async () => {
    open(`shareJournalingLog:${id}`)
  }, [id, open])

  useEffect(() => {
    const run = async () => {
      const { data } = await journalingLogCanShare()

      const isSharingDisabled = data?.journalingLogCanShare.some((tp) =>
        ['CANNOT_SHARE', 'NO_THERAPIST', 'NOT_BOOKED', 'BOOKED_FIRST'].includes(tp.status),
      )

      if (isSharingDisabled) {
        return
      }

      setCanShare(true)
    }

    run().catch(noop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardBoxNoStroke data-test-id="journaling-log-card">
      <Flex>
        {(sharedWithTherapistUserId || !!sharedWithTherapistUserIds?.length) && group === 'patient' && (
          <Flex align="center" direction="row">
            <Icon colorName="green-60" name="link" size={24} />
            <Flex pl={8}>
              <Text colorName="green-60" fontWeight="600" kind="caption">
                <Translation id="journaling.shared" />
              </Text>
            </Flex>
          </Flex>
        )}

        <Flex align="center" direction="row" justify="space-between">
          <Flex pt={8} shrink={1}>
            <Text fontWeight="600" kind="caption">
              {pipe(date, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME))}
            </Text>
          </Flex>

          <LazyJournalingLogScoreIllustrationAnimated score={score} size={160} />

          <Flex direction="row">
            {canShare && (
              <Flex pr={24}>
                <Pressable autoWidth onClick={handleShareClick}>
                  <TertiaryButtonIcon colorName="primary" iconName="send-diagonal" />
                </Pressable>
              </Flex>
            )}

            <Pressable autoWidth onClick={handleGoToDetailClick}>
              <TertiaryButtonIcon colorName="primary" iconName="arrow-right" />
            </Pressable>
          </Flex>
        </Flex>
      </Flex>

      {group === 'patient' && (
        <ShareJournalingLogModal
          journalingLogId={id}
          score={score}
          sharedWithTherapistUserIds={sharedWithTherapistUserIds}
        />
      )}
    </CardBoxNoStroke>
  )
}
