import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { slugify } from 'strings'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { useUserFormFlowResponseCompleted } from '~/domains/formFlow/hooks/useUserFormFlowResponseCompleted'
import { useModals } from '~/domains/modals'
import { ModalTitle } from '~/domains/modals/components/ModalTitle'
import { ReactHookFormRadioField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { type TranslationId } from '~/i18n/types'
import { getRoute } from '~/utils/getRoute'
import { useChangeTherapist } from '../hooks/useChangeTherapist'
import { getChangeTherapistModalRoutes } from '../utils/routes'
import { type ChangeTherapistReasonValue, type FormValues } from './ChangeTherapistModal'

type Option = {
  id: TranslationId
  value: ChangeTherapistReasonValue
}

const values: Option[] = [
  { id: 'changeTherapist.reason.variant.badTimeSlots', value: 'BAD_TIME_SLOTS' },
  { id: 'changeTherapist.reason.variant.needToKnowMultipleTherapists', value: 'NEED_TO_KNOW_MULTIPLE_THERAPISTS' },
  { id: 'changeTherapist.reason.variant.therapyOrientation', value: 'THERAPY_ORIENTATION' },
  { id: 'changeTherapist.reason.variant.changedGoals', value: 'CHANGED_GOALS' },
  { id: 'changeTherapist.reason.variant.therapistAge', value: 'THERAPIST_AGE' },
  { id: 'changeTherapist.reason.variant.therapistGender', value: 'THERAPIST_GENDER' },
  { id: 'changeTherapist.reason.variant.badProfilePicture', value: 'BAD_PROFILE_PICTURE' },
  { id: 'changeTherapist.reason.variant.other.step.1', value: 'OTHER_MANUAL' },
]

const ChangeTherapistReasonVariantAutoReasons = () => {
  const {
    errors: { required },
  } = useFormTranslations()

  const { formState } = useReactHookFormContext<FormValues>()

  const hasError = !!formState.errors.changeTherapistReason?.message

  return (
    <Flex justify="center">
      {hasError && (
        <Flex pt={16}>
          <Text colorName="red-80" kind="caption">
            {required}
          </Text>
        </Flex>
      )}

      {values.map(({ id, value }) => (
        <Flex key={id} as="label" justify="center" pt={16}>
          <ReactHookFormRadioField name="changeTherapistReason" rules={{ required }} value={value}>
            <Flex data-test-id={`change-therapist-reason-${slugify(value)}`} grow={1} justify="center">
              <Text kind="paragraph">
                <Translation id={id} />
              </Text>
            </Flex>
          </ReactHookFormRadioField>
        </Flex>
      ))}
    </Flex>
  )
}

type Props = {
  therapyId: string
}

export const ChangeTherapistReasonAuto = ({ therapyId }: Props) => {
  useTrackEventView('changeTherapist.modal.auto')

  const { loading, startLoading, stopLoading } = useLoadingState()
  const history = useHistory()
  const rootHistory = useRootHistory()
  const { close } = useModals()
  const { addToast } = useToasts()
  const trackClick = useTrackEvent('CLICK')

  const [changeTherapist] = useChangeTherapist()

  const form = useReactHookFormContext<FormValues>()

  const { userFormFlowResponseCompletedId, loading: loadingFormFlowCompleted } = useUserFormFlowResponseCompleted()

  const onSubmit = useCallback(
    async ({ changeTherapistReason }: FormValues) => {
      if (changeTherapistReason === 'OTHER_MANUAL') {
        history.push(getChangeTherapistModalRoutes('/changeTherapistReasonManual'))

        return
      }

      startLoading('local')

      try {
        /**
         * @description Update to accept only `therapyId` as input
         * @link https://linear.app/serenis/issue/PRD-2769/update-changetherapist-input-to-accept-only-therapyid
         */
        await changeTherapist({
          variables: {
            input: {
              changeTherapistReason,
              therapyId,
            },
          },
        })

        if (userFormFlowResponseCompletedId && changeTherapistReason === 'CHANGED_GOALS') {
          rootHistory.push(getRoute(`/change-therapist/${therapyId}`))

          return
        }

        if (userFormFlowResponseCompletedId) {
          rootHistory.push(getRoute(`/change-therapist/${therapyId}/preferences`))

          return
        }

        rootHistory.push(getRoute('/start'))
      } catch {
        addToast({ type: 'alert', translationId: 'generic.error' })
      } finally {
        stopLoading()

        trackClick({ name: 'clicks.support.i-want-to-change-therapist' })

        close(`changeTherapist:${therapyId}`)
      }
    },
    [
      addToast,
      changeTherapist,
      close,
      history,
      rootHistory,
      startLoading,
      stopLoading,
      therapyId,
      trackClick,
      userFormFlowResponseCompletedId,
    ],
  )

  if (loadingFormFlowCompleted) {
    return <CenteredLoader />
  }

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <OverflowAuto>
        <ModalTitle id="changeTherapist.modal.variant.step.1.title" />

        <TranslationMarkdown id="changeTherapist.modal.variant.step.1.description" kind="paragraph" />

        <Flex pt={16}>
          <ChangeTherapistReasonVariantAutoReasons />
        </Flex>
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" loading={loading} type="submit">
          <Translation id="actions.proceed" />
        </Button>
      </Flex>
    </Form>
  )
}
