import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ReactHookFormRadioField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { getTherapistChurnReasonRoute, type TherapistChurnReasonRoute } from '../utils/getTherapistChurnReasonRoute'

type Props = {
  loading: boolean
  patientFullName: string
}

type FormValues = {
  action: Extract<TherapistChurnReasonRoute, '/proceed' | '/stop'>
}

export const TherapistChurnReasonModalIntro = ({ loading, patientFullName }: Props) => {
  const {
    errors: { required },
  } = useFormTranslations()

  const form = useReactHookFormContext<FormValues>()

  const history = useHistory()

  const onSubmit = useCallback(
    ({ action }: FormValues) => {
      history.push(getTherapistChurnReasonRoute(action))
    },
    [history],
  )

  const value = form.watch('action')

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <Text kind="paragraph">1 / 2</Text>
      <Flex py={8}>
        <Text fontWeight="600" kind="h2">
          <Translation id="therapistChurnReasonModal.intro.title" />
        </Text>
      </Flex>
      <Flex pb={24}>
        <Text colorName="black" kind="paragraph">
          <Translation id="therapistChurnReasonModal.intro.subtitle" />
        </Text>
      </Flex>

      <OverflowAuto>
        <Flex
          as="label"
          borderColor={value === '/proceed' ? 'purple08' : 'purple04'}
          borderRadius={16}
          borderSize={1}
          p={16}
        >
          <ReactHookFormRadioField name="action" rules={{ required }} value="/proceed">
            <Flex pt={1}>
              <Text colorName="black" fontWeight="600" kind="paragraph">
                <Translation id="therapistChurnReasonModal.intro.options.proceed.title" />
              </Text>
              <TranslationMarkdown
                colorName="black"
                id="therapistChurnReasonModal.intro.options.proceed.description"
                kind="paragraph"
                values={{ patientFullName }}
              />
            </Flex>
          </ReactHookFormRadioField>
        </Flex>
        <Flex pt={24}>
          <Flex
            as="label"
            borderColor={value === '/stop' ? 'purple08' : 'purple04'}
            borderRadius={16}
            borderSize={1}
            p={16}
          >
            <ReactHookFormRadioField name="action" rules={{ required }} value="/stop">
              <Flex pt={1}>
                <Text colorName="black" fontWeight="600" kind="paragraph">
                  <Translation id="therapistChurnReasonModal.intro.options.stop.title" />
                </Text>
                <TranslationMarkdown
                  colorName="black"
                  id="therapistChurnReasonModal.intro.options.stop.description"
                  kind="paragraph"
                  values={{ patientFullName }}
                />
              </Flex>
            </ReactHookFormRadioField>
          </Flex>
        </Flex>
      </OverflowAuto>

      <Flex pt={16}>
        <Button disabled={!form.formState.isValid || !value} kind="primary" loading={loading} type="submit">
          <Translation id="actions.proceed" />
        </Button>
      </Flex>
    </Form>
  )
}
