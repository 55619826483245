import { Flex, type FlexProps } from 'cdk'
import styled from 'styled-components'
import { EmptySlot, Slot } from './Slot'

const FlexRowWithGapWrap = styled(Flex).attrs({
  $direction: 'row',
  $wrap: 'wrap',
  $shrink: 1,
  $grow: 1,
  $gap: '8px 24px',
})`
  /* stylelint-disable-next-line no-empty-source */
`

export const SlotsByDay = ({
  onTimeSlotSelected,
  slots,
  therapistId,
  py = 0,
}: {
  onTimeSlotSelected: (timeSlot: Date, therapistId: string) => void
  slots: (Date | 'PLACEHOLDER')[]
  therapistId: string
  py?: FlexProps['$py']
}) => (
  <FlexRowWithGapWrap $py={py}>
    {slots.map((slot, index) => {
      if (slot === 'PLACEHOLDER' || therapistId === null) {
        return (
          <Flex key={index} $basis="calc((100% - 48px) / 3);">
            <EmptySlot />
          </Flex>
        )
      }

      return (
        <Flex key={`${index}-${slot.getTime()}`} $basis="calc((100% - 48px) / 3);">
          <Slot onSelected={onTimeSlotSelected} slot={slot} therapistId={therapistId} />
        </Flex>
      )
    })}
  </FlexRowWithGapWrap>
)
