import { COLOR_PRIMARY_40 } from 'design-tokens'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { getRoute } from '~/utils/getRoute'

type Props = Pick<TherapistAgendaTherapySession, 'id' | 'patient'>

const FlexWithBorderLeft = styled(Flex).attrs({
  grow: 1,
  pt: 0,
  lgPt: 0,
  px: 0,
  lgPx: 0,
})`
  border-left: 1px solid ${COLOR_PRIMARY_40};
`

export const TherapySessionPatientProfileLinks = ({ patient, id }: Props) => (
  <Flex direction="row">
    <Link to={getRoute(`/chat/${patient.id}`)}>
      <Flex pr={16}>
        <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
          <Translation id="menu.chat" />
        </Text>
      </Flex>
    </Link>

    <FlexWithBorderLeft>
      <Link to={getRoute(`/chat/${patient.id}/diary/${id}`)}>
        <Flex pl={16}>
          <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
            <Translation id="patientDetail.clinicalDiary" />
          </Text>
        </Flex>
      </Link>
    </FlexWithBorderLeft>
  </Flex>
)
