import { Flex, PositionSticky } from 'cdk'
import { format, FormatDateEnum } from 'dates'
import { COLOR_DARKER, COLOR_PRIMARY } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { Icon } from 'icons'
import { type PropsWithChildren, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Text } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { ClinicalTestAnswerChipForPatient } from '../components/ClinicalTestAnswerChip'
import { ClinicalTestNameTranslation } from '../components/ClinicalTestNameTranslation'
import { ClinicalTestQuestionTranslation } from '../components/ClinicalTestQuestionTranslation'
import { useClinicalTestAnswer } from '../hooks/useClinicalTestAnswer'

const RecapAnswersSvg = () => (
  <svg fill="none" viewBox="0 0 120 120" width={105} xmlns="http://www.w3.org/2000/svg">
    <rect
      fill="url(#a)"
      height="69.81"
      rx="12.39"
      transform="rotate(-45 10.88 70.48)"
      width="69.81"
      x="10.88"
      y="70.48"
    />
    <path
      d="M60.23 80.2c-17.78 0-33.5-1.63-42.98-4.14-5.97-1.58-9.47-3.5-9.47-5.58s3.5-4 9.47-5.58c9.48-2.51 25.2-4.15 42.98-4.15"
      stroke={COLOR_DARKER}
      strokeOpacity=".96"
      strokeWidth="2.47"
    />
    <path
      d="M59.16 98a1.24 1.24 0 0 1 0-1.75l7.87-7.86a1.24 1.24 0 1 1 1.74 1.74l-6.99 7 7 6.98a1.24 1.24 0 1 1-1.75 1.75L59.16 98Zm.88-2.11c14.38 0 27.36-2.02 36.69-5.26 4.67-1.62 8.36-3.52 10.86-5.56 2.51-2.06 3.67-4.13 3.67-6.12h2.48c0 3.03-1.78 5.73-4.58 8.03-2.8 2.3-6.8 4.32-11.62 5.99-9.66 3.34-22.92 5.39-37.5 5.39v-2.47Zm51.23-16.94c0-2-1.17-4.07-3.68-6.12-2.5-2.05-6.19-3.95-10.86-5.57C87.4 64.03 74.43 62 60.03 62v-2.47c14.6 0 27.85 2.05 37.5 5.4 4.83 1.67 8.82 3.68 11.63 5.99 2.8 2.29 4.58 5 4.58 8.03h-2.47Z"
      fill={COLOR_DARKER}
      fillOpacity=".96"
    />
    <path
      d="M59.16 43.88a1.24 1.24 0 0 1 0-1.75l7.87-7.86a1.24 1.24 0 1 1 1.74 1.75L61.78 43l7 6.99a1.24 1.24 0 1 1-1.75 1.74l-7.87-7.86Zm.88 35.07c14.38 0 27.35-2.07 36.68-5.37 4.67-1.66 8.36-3.6 10.86-5.7 2.51-2.1 3.69-4.23 3.69-6.28h2.47c0 3.07-1.77 5.83-4.57 8.18-2.8 2.35-6.8 4.42-11.62 6.13-9.66 3.42-22.92 5.51-37.51 5.51v-2.47Zm51.23-17.35c0-2.06-1.18-4.2-3.69-6.3-2.5-2.09-6.19-4.03-10.86-5.68-9.33-3.31-22.3-5.38-36.68-5.38v-2.47c14.59 0 27.85 2.1 37.5 5.51 4.83 1.72 8.82 3.78 11.63 6.13 2.8 2.35 4.57 5.1 4.57 8.19h-2.48Z"
      fill={COLOR_DARKER}
      fillOpacity=".96"
    />
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="a" x1="45.78" x2="45.78" y1="70.48" y2="140.29">
        <stop stopColor="#F6BFFF" />
        <stop offset="1" stopColor={COLOR_PRIMARY} />
      </linearGradient>
    </defs>
  </svg>
)

type ClinicalTestAnswerItemProps = PropsWithChildren<{
  questionId: string
  answer: number
}>

const ClinicalTestAnswerItem = ({ answer, children, questionId }: ClinicalTestAnswerItemProps) => (
  <Flex $align="flex-start" $gap={4}>
    {children}
    <Text fontWeight="600">
      <ClinicalTestQuestionTranslation questionId={questionId} />
    </Text>
    <ClinicalTestAnswerChipForPatient answer={answer} />
  </Flex>
)

type ClinicalTestAnswersListProps = {
  clinicalTestAnswerId: string
}

const ClinicalTestAnswersList = ({ clinicalTestAnswerId }: ClinicalTestAnswersListProps) => {
  const history = useHistory()
  const { loading: clinicalTestLoading, clinicalTestAnswer } = useClinicalTestAnswer(clinicalTestAnswerId)

  const handleBack = useCallback(() => {
    history.goBack()
  }, [history])

  if (clinicalTestLoading) {
    return <CenteredLoader />
  }

  if (!clinicalTestAnswer) {
    return <GenericErrorContent />
  }

  const { answers, code, createdAt } = clinicalTestAnswer

  return (
    <Flex $gap={16}>
      <PositionSticky
        $align="center"
        $backgroundColorName="lighter"
        $direction="row"
        $elevationName="xxs"
        $gap={8}
        $justify="center"
        $px={16}
        $py={8}
        $top={0}
      >
        <Flex $align="flex-start" $basis={50} $grow={1} $shrink={1}>
          <Button isGhost kind="primary" onClick={handleBack}>
            <Icon name="arrow-left" size={24} />
          </Button>
        </Flex>
        <Flex $grow={1} $shrink={1}>
          <Text fontWeight="600" textAlign="center">
            <ClinicalTestNameTranslation code={code} />
          </Text>
          <Text colorName="neutral-80" kind="caption" textAlign="center">
            {pipe(createdAt, format(FormatDateEnum.DAY_MONTH_YEAR))}
          </Text>
        </Flex>
        <Flex $align="flex-start" $basis={50} $grow={1} $shrink={1} />
      </PositionSticky>
      <Flex $gap={24} $pb={24} $px={16}>
        <RecapAnswersSvg />
        <Flex $gap={16}>
          <Text kind="h3">
            <Translation id="clinicalTests.results.readAnswers" />
          </Text>
          <Flex $gap={24}>
            {answers.map(({ answer, questionId }, index, array) => (
              <ClinicalTestAnswerItem key={index} answer={answer} questionId={questionId}>
                <Text colorName="neutral-80" kind="caption">
                  <Translation id="generic.outOf" values={{ value: index + 1, max: array.length }} />
                </Text>
              </ClinicalTestAnswerItem>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const PatientDetailClinicalTestForPatient = () => {
  const { clinicalTestAnswerId } = useParams<{ clinicalTestAnswerId?: string }>()

  if (!clinicalTestAnswerId) {
    return <CenteredLoader />
  }

  return <ClinicalTestAnswersList clinicalTestAnswerId={clinicalTestAnswerId} />
}
