import { gql, useQuery } from '@apollo/client'
import { noop } from 'functions'
import { createContext, type JSXElementConstructor, type ReactElement, type ReactNode, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { isUserTherapist, type UserMaybeTherapist } from '~/domains/therapist/types'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { type FindTherapistProfileByIdQuery, type FindTherapistProfileByIdQueryVariables } from '~/types/graphql'
import { TherapistProvider } from './useTherapist'

type Param = {
  therapistId: string
}

type Props = {
  children: ReactNode
  id?: string
  loadingElement?: ReactElement<any, string | JSXElementConstructor<any>>
  onError?: () => void
}

const QUERY = gql`
  query findTherapistProfileById($id: String!) {
    findTherapistProfileById(id: $id) {
      id
      firstName
      fullName
      pathologies {
        pathology {
          id
          name
          nameEn
        }
      }
      therapist {
        about
        aboutCoach
        age
        availableHoursPerWeek
        id
        numberOfPatients
        registerRegistrationRegion
        registerRegistrationYearPsychologist
        sex
        userId
        professionTypes {
          professionType {
            id
            value
          }
        }
        profileImage {
          s
          m
          l
        }
        professionalExperiences {
          id
          name
          type
        }
        therapistCertifications {
          certification {
            description
            icon
            id
            name
            slug
          }
        }
        therapistLanguages {
          language {
            id
            code
            name
          }
        }
        therapistProfessionalStyles {
          score
          type
        }
        therapistStyles {
          style {
            id
            name
          }
        }
        therapistWorkplaces {
          workplace {
            id
            name
          }
        }
        therapySchool {
          name
          orientation
        }
        workRegion
      }
    }
  }
`

export const useTherapistFetch = (id: string) => {
  const { data, error, loading, refetch } = useQuery<
    FindTherapistProfileByIdQuery,
    FindTherapistProfileByIdQueryVariables
  >(QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
  })

  return { data, error, loading, refetch }
}

const TherapistFetchContext = createContext<UserMaybeTherapist | null>(null)

export const TherapistFetchProvider = ({ children, id = '', loadingElement, onError = noop }: Props) => {
  const { therapistId } = useParams<Param>()
  const { data, error, loading } = useTherapistFetch(id || therapistId)
  const { language } = useLanguage()

  const user = data?.findTherapistProfileById

  useEffect(() => {
    if (error || (user && !isUserTherapist(user))) {
      onError()
    }
  }, [error, onError, user])

  if (loading || !user || !isUserTherapist(user)) {
    return loadingElement || <CenteredLoader />
  }

  const userTherapist = {
    ...user,
    pathologies: user.pathologies.map(({ pathology: { id, name, nameEn } }) => ({
      pathology: {
        id,
        name: language === 'it' ? name : (nameEn ?? name),
      },
    })),
  }

  return (
    <TherapistFetchContext.Provider value={userTherapist}>
      <TherapistProvider therapist={userTherapist}>{children}</TherapistProvider>
    </TherapistFetchContext.Provider>
  )
}
