import { useCallback, useState } from 'react'
import { useDeleteTherapySession } from '~/domains/therapy-session/hooks/useDeleteTherapySession'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'
import { type FormValues } from '../types/formValues'

export const useDeleteTherapySessionOnSubmit = (therapistId: string | null) => {
  const [deleteTherapySession] = useDeleteTherapySession()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const { refetch: refetchPatientAgenda } = usePatientAgenda()
  const history = useRootHistory()

  const onSubmit = useCallback(
    async (id: string, { churnReason, deletedReason, message }: FormValues) => {
      setLoading(true)

      try {
        const response = await deleteTherapySession({
          variables: {
            input: {
              churnReason: deletedReason === 'CHURN' ? churnReason : null,
              deletedReason,
              id,
              message,
            },
          },
        })

        setLoading(false)

        const therapySession = response.data?.deleteTherapySession

        if (!therapySession) {
          addToast({ translationId: 'therapySession.delete.cannotDeleteTherapySession', type: 'alert' })

          return
        }

        addToast({ translationId: 'therapySession.delete.therapySessionDeleted', type: 'success' })

        refetchPatientAgenda()

        if (therapistId) {
          history.push(getRoute(`/chat/${therapistId}`))

          return
        }

        history.push(getRoute('/chat'))
      } catch (error) {
        addToast({ translationId: 'therapySession.delete.cannotDeleteTherapySession', type: 'alert' })

        setLoading(false)
      }
    },
    [addToast, deleteTherapySession, history, refetchPatientAgenda, therapistId],
  )

  return { loading, onSubmit }
}
