import { toAuthenticatedUserGroups } from 'acl'
import { type CognitoUser } from '../types/cognitoUser'
import { type IdTokenPayload } from '../types/idTokenPayload'

export const toIdTokenPayload = (user: CognitoUser): IdTokenPayload => ({
  ...user.signInUserSession.idToken.payload,
  groups: toAuthenticatedUserGroups(user.signInUserSession.idToken.payload['cognito:groups']),
  token_use: 'id',
  user_id: user.signInUserSession.idToken.payload['custom:user_id'],
  username: user.signInUserSession.idToken.payload['cognito:username'],
})
