import { Flex } from 'cdk'
import styled from 'styled-components'

export const TherapistOverviewCardWrapperCustomStyle = styled(Flex)`
  --srns-typography-caption-font-size: 16px;
  --srns-typography-paragraph-font-size: 18px;
  --srns-typography-paragraph-line-height: 24px;
  --srns-typography-h2-font-size: 24px;
  --srns-typography-h2-line-height: 32px;
`
