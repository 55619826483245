import { gql, useMutation } from '@apollo/client'
import {
  type JournalingSettingUpsertActivitiesMutation,
  type JournalingSettingUpsertActivitiesMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation journalingSettingUpsertActivities($input: JournalingSettingUpsertActivitiesInput!) {
    journalingSettingUpsertActivities(input: $input) {
      ok
    }
  }
`

export const useJournalingSettingUpsertActivities = () =>
  useMutation<JournalingSettingUpsertActivitiesMutation, JournalingSettingUpsertActivitiesMutationVariables>(MUTATION)
