import { gql, useLazyQuery } from '@apollo/client'
import { type GetTherapySessionIcsQuery, type GetTherapySessionIcsQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getTherapySessionIcs($id: String!) {
    therapySessionIcs(id: $id) {
      sessionId
      icsAsBase64
      fileName
    }
  }
`

export const useLazyGetTherapySessionIcs = () => {
  const [fetchGetTherapySessionIcs] = useLazyQuery<GetTherapySessionIcsQuery, GetTherapySessionIcsQueryVariables>(QUERY)

  return fetchGetTherapySessionIcs
}
