import { gql, useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import { useTherapyIdLocationParam } from '~/domains/changeTherapist/hooks/useTherapyIdLocationParam'
import { useRootLocation } from '~/hooks/useRootHistory'
import { useIntl } from '~/i18n/hooks/useIntl'
import { type FormFlowResponseQuery, type FormFlowResponseQueryVariables } from '~/types/graphql'
import { type Route } from '~/utils/getRoute'
import { getFormFlowTypeFromPathname } from '../utils/getFormFlowTypeFromPathname'
import { usePrefilledConceptIdOrName } from '../utils/usePrefilledConceptIdOrName'

const QUERY = gql`
  query formFlowResponse(
    $prefilledConceptIdOrName: String
    $timeZone: String
    $type: FormFlowResponseType!
    $therapyId: String
  ) {
    formFlowResponse(
      prefilledConceptIdOrName: $prefilledConceptIdOrName
      timeZone: $timeZone
      type: $type
      therapyId: $therapyId
    ) {
      answers {
        flowId
        step {
          id
          name
          title
        }
        valueChoice {
          references {
            type
            value
          }
          name
          title
          fillable
        }
        valueText
      }
      completedAt
      id
      userId
    }
  }
`

export const useFormFlowResponse = (fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
  const prefilledConceptIdOrName = usePrefilledConceptIdOrName()
  const { timeZone } = useIntl()

  const { pathname } = useRootLocation()

  const type = getFormFlowTypeFromPathname(pathname as Route)

  const therapyId = useTherapyIdLocationParam()

  const { data, error, loading, refetch, updateQuery } = useQuery<
    FormFlowResponseQuery,
    FormFlowResponseQueryVariables
  >(QUERY, {
    fetchPolicy,
    variables: { prefilledConceptIdOrName, timeZone, type, therapyId },
  })

  return {
    error: !!error,
    formFlowResponse: data?.formFlowResponse,
    loading,
    refetch,
    updateQuery,
  }
}
