import { type IFlagsmith } from 'flagsmith/types'
import { createContext, memo, type ReactElement, useEffect } from 'react'
import { flagsmithByFingerprint } from '~/clients/flagsmith'
import { useFeatureFlagsInit } from '~/domains/featureFlags/hooks/useFeatureFlagsInit'
import { useSessionStorageParams } from '~/hooks/useSessionStorageParams'
import { upsertAnonymousFingerprint } from '~/utils/upsertAnonymousFingerprint'

type Props = {
  children: ReactElement
}

type Context = IFlagsmith

export const FeatureFlagsByFingerprintContext = createContext<Context>(flagsmithByFingerprint)

/**
 * Warning about mounting both `FeatureFlagsByFingerprint` and `FeatureFlagsByUserId`
 *
 * Set `cacheFlags` to `false` in `src/clients/flagsmith.ts`
 */
export const FeatureFlagsByFingerprint = memo(({ children }: Props) => {
  const { init, initialized } = useFeatureFlagsInit(flagsmithByFingerprint)

  const fingerprintId = upsertAnonymousFingerprint()

  const sessionStorage = useSessionStorageParams()

  useEffect(() => {
    init(fingerprintId, {
      ...sessionStorage,
      fingerprintId,
    })
  }, [fingerprintId, init, sessionStorage])

  useEffect(
    () => () => {
      flagsmithByFingerprint.logout()
    },
    [],
  )

  if (!initialized) {
    return null
  }

  return (
    <FeatureFlagsByFingerprintContext.Provider value={flagsmithByFingerprint}>
      {children}
    </FeatureFlagsByFingerprintContext.Provider>
  )
})
