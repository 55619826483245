import { gql, useQuery } from '@apollo/client'
import { type PaymentMethodsQuery, type PaymentMethodsQueryVariables } from '~/types/graphql'

const PAYMENT_METHODS = gql`
  query paymentMethods {
    paymentMethods {
      brand
      defaultPaymentMethod
      exp_month
      exp_year
      fingerprint
      id
      last4
      type
      verified_email
    }
  }
`

export const usePaymentMethods = () => {
  const { data, error, loading, refetch } = useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PAYMENT_METHODS)

  return {
    error: !!error,
    items: data?.paymentMethods || [],
    loading,
    update: refetch,
  }
}
