import { type NavigationLink } from '../types'

export const patientNavigationLinks: (flag: boolean) => NavigationLink[] = (isFoodJournalEnabled) => [
  {
    id: 'home',
    dataTestId: 'tab-item-home',
    route: '/',
    translation: 'menu.home',
    iconName: 'srns-home',
    iconNameActive: 'srns-home-solid',
    pathMatches: [
      '/',
      '/therapy-session/:therapyId/schedule',
      '/therapy-session/:therapySessionId/edit',
      '/therapy-session/booking/:therapy',
    ],
    exact: true,
  },
  {
    id: 'therapies',
    dataTestId: 'tab-item-therapies',
    route: '/therapies',
    translation: 'menu.therapies',
    iconName: 'srns-therapy',
    iconNameActive: 'srns-therapy-solid',
    pathMatches: ['/therapies'],
    exact: false,
  },
  {
    id: 'chat',
    dataTestId: 'tab-item-chat',
    route: '/chat',
    translation: 'menu.chat',
    iconName: 'srns-chat',
    iconNameActive: 'srns-chat-solid',
    pathMatches: ['/chat'],
    exact: false,
  },
  ...(isFoodJournalEnabled
    ? [
        {
          id: 'diaries',
          dataTestId: 'tab-item-diaries',
          route: '/diaries',
          translation: 'menu.diaries',
          iconName: 'srns-journaling',
          iconNameActive: 'srns-journaling-solid',
          pathMatches: ['/diaries', '/journaling', '/food-journal'],
          exact: false,
        } as NavigationLink,
      ]
    : [
        {
          id: 'journaling',
          dataTestId: 'tab-item-journaling',
          route: '/journaling',
          translation: 'menu.journaling',
          iconName: 'srns-journaling',
          iconNameActive: 'srns-journaling-solid',
          pathMatches: ['/journaling'],
          exact: false,
        } as NavigationLink,
      ]),
]
