import { NavigationHideTabNavigationEffect } from '~/domains/navigation/components/NavigationHideTabNavigationEffect'
import { NavigationShowTabNavigationEffect } from '~/domains/navigation/components/NavigationShowTabNavigationEffect'
import { type Page } from '~/routes/types'

type Props = Pick<Page['options'], 'isVisibleTabNavigation'>

export const PageTabNavigationEffect = ({ isVisibleTabNavigation }: Props) => (
  <>
    {isVisibleTabNavigation && <NavigationShowTabNavigationEffect />}
    {!isVisibleTabNavigation && <NavigationHideTabNavigationEffect />}
  </>
)
