import { Flex } from 'cdk'
import { differenceInYears, setYear } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { SPACING_MD } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { Icon, type IconName } from 'icons'
import { type ReactElement, useMemo } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTherapist } from '~/hooks/useTherapist'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { shouldShowYearsOfExperience } from '~/utils/shouldShowYearsOfExperience'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: ${SPACING_MD};
  padding-top: ${SPACING_MD};

  .one-column-layout & {
    grid-template-columns: 1fr;
  }
`

type ItemProps = {
  children: ReactElement<typeof Translation> | string | number
  icon: IconName
  title: Extract<TranslationId, `therapist.presentationCard.${string}.title`>
}

const Item = ({ children, icon, title }: ItemProps) => (
  <Flex $direction="row">
    <Flex>
      <Icon colorName="grey-80" name={icon} size={24} />
    </Flex>
    <Flex $grow={1} $px={16} $shrink={1}>
      <Text colorName="grey-80" fontWeight="600" kind="paragraph">
        <Translation id={title} />
      </Text>
      <Flex $pt={4}>
        <Text colorName="black" kind="paragraph">
          {children}
        </Text>
      </Flex>
    </Flex>
  </Flex>
)

type Props = {
  therapyPathType: TherapyTherapyPathType
}

export const TherapistWhatYouShouldKnow = ({ therapyPathType }: Props) => {
  const {
    therapist: {
      numberOfPatients,
      registerRegistrationYearPsychologist,
      therapistLanguages,
      therapistStyles,
      therapistWorkplaces,
      therapistProfessionalStyles,
      workRegion,
    },
  } = useTherapist()

  const treatmentFormality = useMemo(() => {
    const score = therapistProfessionalStyles?.find(({ type }) => type === 'FORMALITY')?.score

    if (typeof score === 'undefined' || score === 0) {
      return null
    }

    return score < 0 ? 'FORMAL' : 'INFORMAL'
  }, [therapistProfessionalStyles])

  const isTherapyCoaching = therapyPathType === 'PATH_COACHING'

  const yearsOfExperience = useMemo(() => {
    if (!registerRegistrationYearPsychologist) {
      return 0
    }

    return pipe(
      nowInMilliseconds(),
      differenceInYears(pipe(nowInMilliseconds(), setYear(registerRegistrationYearPsychologist))),
    )
  }, [registerRegistrationYearPsychologist])

  const { isNutritionist, isPsychiatrist } = useTherapistProfessionTypeValue()

  const showNumberOfPatients = !isPsychiatrist && !isTherapyCoaching && numberOfPatients >= 10

  const showYearsOfExperience = useMemo(() => shouldShowYearsOfExperience(yearsOfExperience), [yearsOfExperience])

  const languages = useMemo(() => {
    if (!therapistLanguages?.length) {
      return ''
    }

    const italianLanguageIndex = therapistLanguages.findIndex(({ language }) => language?.code === 'it')

    // NOTE: therapist speaks only Italian, do not show anything
    if (therapistLanguages.length === 1 && italianLanguageIndex !== -1) {
      return ''
    }

    const italianLaguage = therapistLanguages.filter(({ language }) => language?.code === 'it')
    const languagesWithoutItalian = therapistLanguages.filter(({ language }) => language?.code !== 'it')

    return [...italianLaguage, ...languagesWithoutItalian]
      .map(({ language }) => language?.name)
      .filter(Boolean)
      .join(', ')
  }, [therapistLanguages])

  const styles = useMemo(() => {
    if (!therapistStyles?.length) {
      return ''
    }

    return therapistStyles.map(({ style }) => style.name).join(', ')
  }, [therapistStyles])

  const workplaces = useMemo(() => {
    if (!therapistWorkplaces?.length) {
      return ''
    }

    return therapistWorkplaces.map(({ workplace }) => workplace.name).join(', ')
  }, [therapistWorkplaces])

  const followedOnSerenisBodyTranslationId: Extract<
    TranslationId,
    `therapist.presentationCard.followedOnSerenis.body.${'singular' | 'plural'}`
  > =
    numberOfPatients === 1
      ? 'therapist.presentationCard.followedOnSerenis.body.singular'
      : 'therapist.presentationCard.followedOnSerenis.body.plural'

  const treatmentFormalityBodyTranslationId: Extract<
    TranslationId,
    `therapist.presentationCard.treatmentFormality.body.${'formal' | 'informal'}`
  > =
    treatmentFormality === 'INFORMAL'
      ? 'therapist.presentationCard.treatmentFormality.body.informal'
      : 'therapist.presentationCard.treatmentFormality.body.formal'

  return (
    <>
      <Text fontWeight="600" kind="paragraph">
        <Translation id="therapist.presentationCard.whatYouShouldKnow.title" />
      </Text>
      <Grid>
        {showYearsOfExperience && (
          <Item icon="calendar" title="therapist.presentationCard.yearsOfExperience.title">
            {yearsOfExperience}
          </Item>
        )}
        {!!workplaces && (
          <Item icon="building" title="therapist.presentationCard.workedIn.title">
            {workplaces}
          </Item>
        )}
        {showNumberOfPatients && (
          <Item icon="community" title="therapist.presentationCard.followedOnSerenis.title">
            <Translation id={followedOnSerenisBodyTranslationId} values={{ amount: String(numberOfPatients) }} />
          </Item>
        )}
        {workRegion && (
          <Item icon="home-simple-door" title="therapist.presentationCard.workRegion.title">
            {workRegion}
          </Item>
        )}
        {!isTherapyCoaching && !!languages && (
          <Item icon="chat-bubble-empty" title="therapist.presentationCard.languages.title">
            {languages}
          </Item>
        )}
        {!!styles && !isNutritionist && (
          <Item icon="donate" title="therapist.presentationCard.styleWithAdjectives.title">
            {styles}
          </Item>
        )}
        {treatmentFormality && !isNutritionist && (
          <Item icon="chat-lines" title="therapist.presentationCard.treatmentFormality.title">
            <Translation id={treatmentFormalityBodyTranslationId} />
          </Item>
        )}
      </Grid>
    </>
  )
}
