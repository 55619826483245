import loadable from '@loadable/component'
import { CenteredLoader } from '~/components/CenteredLoader'
import { AssertPatientOrTherapist } from '~/domains/auth/components/AssertPatientOrTherapist'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { TherapistTherapiesProvider } from '~/domains/therapies/TherapistTherapiesProvider'
import { useGetTherapySessionLive } from '~/domains/therapy-session/hooks/useGetTherapySessionLive'
import { TherapySessionVideocallProvider } from '~/domains/therapy-session/videocall/VideocallProvider'
import { TherapySessionLiveProvider } from '~/domains/therapy-session/videocall/VideocallProvider/useTherapySessionLive'
import { VideocallSidePanelProvider } from '~/domains/therapy-session/videocall/VideocallSidePanel/useVideocallSidePanel'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { TherapySessionLivePageGuard } from './Guard'

const LazyTherapySessionLivePageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.TherapySessionLivePageView,
})

export const TherapySessionLivePage: Page = {
  exact: true,
  id: 'therapy-sessions.live',
  paths: ['/therapy-session/:therapySessionId/live'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'therapy-session.live',
    title: null,
  },
  PageGuard: ({ children }) => (
    <TherapySessionLivePageGuard>
      <AssertPatientOrTherapist>{children}</AssertPatientOrTherapist>
    </TherapySessionLivePageGuard>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => {
    const { item } = useGetTherapySessionLive()
    const group = useUserActingAsGroup()

    if (!item?.patient || !item?.therapist) {
      return <CenteredLoader />
    }

    if (group === 'therapist') {
      return (
        <TherapistTherapiesProvider>
          <TherapySessionLiveProvider therapySessionLive={item}>
            <TherapySessionVideocallProvider>
              <VideocallSidePanelProvider>{children}</VideocallSidePanelProvider>
            </TherapySessionVideocallProvider>
          </TherapySessionLiveProvider>
        </TherapistTherapiesProvider>
      )
    }

    return (
      <TherapySessionLiveProvider therapySessionLive={item}>
        <TherapySessionVideocallProvider>
          <VideocallSidePanelProvider>{children}</VideocallSidePanelProvider>
        </TherapySessionVideocallProvider>
      </TherapySessionLiveProvider>
    )
  },
  PageView: () => <LazyTherapySessionLivePageView />,
}
