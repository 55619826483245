import { pipe } from 'fp-ts/function'
import { createContext, type ReactNode, useContext } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { getEnv } from '~/utils/getEnv'
import { useTherapyPathByIdQuery } from './useTherapyPathByIdQuery'

type Props = {
  children: ReactNode
}

type Context = ReturnType<typeof useTherapyPathByIdQuery>

type TherapyPath = NonNullable<Context['therapyPath']>

const defaultTherapyPath: TherapyPath = {
  active: true,
  cost: pipe('SERENIS_THERAPY_SESSION_COST_ONLINE_MYSELF', getEnv, Number),
  costFirst: 0,
  duration: pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_MYSELF', getEnv, Number),
  id: 'tpp_utqytx1nedovxt6p',
  type: 'MYSELF_PSYCHOTHERAPY',
}

const TherapyPathByIdContext = createContext<Context>({
  loading: true,
  therapyPath: defaultTherapyPath,
})

export const TherapyPathByIdProvider = ({ children }: Props) => {
  const { loading, therapyPath } = useTherapyPathByIdQuery()

  if (loading) {
    return <CenteredLoader />
  }

  return <TherapyPathByIdContext.Provider value={{ loading, therapyPath }}>{children}</TherapyPathByIdContext.Provider>
}

export const useTherapyPathById = () => {
  const context = useContext(TherapyPathByIdContext)

  return {
    loading: context.loading,
    therapyPath: context.therapyPath ?? defaultTherapyPath,
  }
}
