import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import {
  type ProductUserFeedbackCreateInput,
  type ProductUserFeedbackCreateMutation,
  type ProductUserFeedbackCreateMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation productUserFeedbackCreate($input: ProductUserFeedbackCreateInput!) {
    productUserFeedbackCreate(input: $input) {
      id
    }
  }
`

export const useProductUserFeedbackCreate = (
  type: ProductUserFeedbackCreateInput['type'],
  extra: ProductUserFeedbackCreateInput['extra'],
) => {
  const [mutation] = useMutation<ProductUserFeedbackCreateMutation, ProductUserFeedbackCreateMutationVariables>(
    MUTATION,
  )

  const productUserFeedbackCreate = useCallback(
    () =>
      mutation({
        variables: {
          input: {
            extra,
            type,
          },
        },
      }),
    [extra, mutation, type],
  )

  return {
    productUserFeedbackCreate,
  }
}
