import { Icon, type IconName, type IconSize } from 'icons'
import { useMemo } from 'react'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { type TherapyTherapyPathType } from '~/types/graphql'

type StartTherapyPathIconProps = {
  size: IconSize
  therapyPathType: TherapyTherapyPathType | null
}

const iconByTherapyPathIcon: Record<
  NonNullable<StartTherapyPathIconProps['therapyPathType']>,
  Extract<IconName, `path-${string}`>
> = {
  COUPLES_PSYCHOTHERAPY: 'path-couples',
  MYSELF_PSYCHIATRY: 'path-psychiatry',
  MYSELF_PSYCHOTHERAPY: 'path-myself',
  PATH_COACHING: 'path-coaching',
  PATH_NUTRITION_DCA: 'path-nutrition-dca',
  PATH_NUTRITION_WEIGHT_LOSS: 'path-nutrition-weight-loss',
  PATH_SEXOLOGY: 'path-sexology',
  PATH_ASSERTIVE_COMMUNICATION: 'path-myself',
  PATH_SLEEP: 'path-myself',
  UNDERAGE_PSYCHOTHERAPY: 'path-myself',
}

const iconByTherapyPathIconVariant: Record<
  NonNullable<StartTherapyPathIconProps['therapyPathType']>,
  Extract<IconName, `path-${string}-solid`>
> = {
  COUPLES_PSYCHOTHERAPY: 'path-couples-solid',
  MYSELF_PSYCHIATRY: 'path-psychiatry-solid',
  MYSELF_PSYCHOTHERAPY: 'path-myself-solid',
  PATH_COACHING: 'path-coaching-solid',
  PATH_NUTRITION_DCA: 'path-nutrition-dca-solid',
  PATH_NUTRITION_WEIGHT_LOSS: 'path-nutrition-weight-loss-solid',
  PATH_SEXOLOGY: 'path-sexology-solid',
  PATH_ASSERTIVE_COMMUNICATION: 'path-myself-solid',
  PATH_SLEEP: 'path-myself-solid',
  UNDERAGE_PSYCHOTHERAPY: 'path-myself-solid',
}

export const StartTherapyPathIcon = ({ size, therapyPathType }: StartTherapyPathIconProps) => {
  const { isVariant1 } = useFeatureFlagsByFingerprint()

  const iconName = useMemo(() => {
    if (!therapyPathType) {
      return null
    }

    return isVariant1('ff_restyle_survey')
      ? iconByTherapyPathIconVariant[therapyPathType]
      : iconByTherapyPathIcon[therapyPathType]
  }, [isVariant1, therapyPathType])

  if (!iconName) {
    return null
  }

  if (iconName) {
    return <Icon name={iconName} size={size} />
  }
}
