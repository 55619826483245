import { gql, useQuery } from '@apollo/client'
import { type JournalingLogTodayQuery, type JournalingLogTodayQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query journalingLogToday {
    journalingLogToday {
      date
      id
      journalingActivities {
        id
        name
        slug
      }
      journalingMoods {
        id
        name
        slug
      }
      score
      sharedWithTherapistUserId
      sharedWithTherapistUserIds
      thankfulReasons
      todayThoughts
    }
  }
`

export const useJournalingLogToday = () => {
  const { data, error, loading, refetch } = useQuery<JournalingLogTodayQuery, JournalingLogTodayQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    loading,
    journalingLogToday: data?.journalingLogToday || null,
    refetch,
  }
}
