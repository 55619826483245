import { Flex } from 'cdk'
import { colorSerenisLight, SPACING_3XL, SPACING_XL, SPACING_XS } from 'design-tokens'
import ReactConfetti from 'react-confetti'
import { Text } from 'ui'
import { APP_BAR_HEIGHT } from '~/components/AppBar/constants'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useRootHistory } from '~/hooks/useRootHistory'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { ThankYouPageActions } from './components/ThankYouPageActions'
import { ThankYouPageDescription } from './components/ThankYouPageDescription'
import { TherapistCard } from './components/TherapistCard'

export const ThankYouPageView = () => {
  const { agenda, loading } = usePatientAgenda()
  const rootHistory = useRootHistory()

  const { bookedTherapistId } = useReservationFlow()

  const therapySession = agenda?.nextTherapySessions?.find((session) => session.therapistId === bookedTherapistId)

  const { free, startAt, endAt, id, therapyPathType } = therapySession || {}

  if (loading) {
    return null
  }

  if (!therapySession || !startAt || !endAt || !id) {
    rootHistory.push('/')
    return null
  }

  return (
    <>
      <PageScrollEffect />

      <ReactConfetti
        colors={[
          colorSerenisLight.COLOR_PRIMARY_10_VALUE,
          colorSerenisLight.COLOR_PRIMARY_20_VALUE,
          colorSerenisLight.COLOR_PRIMARY_30_VALUE,
          colorSerenisLight.COLOR_PRIMARY_40_VALUE,
          colorSerenisLight.COLOR_PRIMARY_50_VALUE,
          colorSerenisLight.COLOR_PRIMARY_60_VALUE,
          colorSerenisLight.COLOR_PRIMARY_70_VALUE,
          colorSerenisLight.COLOR_PRIMARY_80_VALUE,
        ]}
        gravity={0.12}
        height={window.innerHeight - APP_BAR_HEIGHT}
        numberOfPieces={200}
        recycle={false}
      />

      <PageRoute id="reservation.thank-you-page">
        <Flex $gap={SPACING_XL} $grow={1} $maxWidth={360} $overflow="hidden" $p={16} $pt={SPACING_3XL}>
          <Flex $gap={SPACING_XS}>
            <Text kind="h1">
              <Translation id="thankYouPage.therapist-card.title" />
            </Text>
            <Text kind="paragraph">
              <Translation id="thankYouPage.therapist-card.subtitle" />
            </Text>
          </Flex>
          <TherapistFetchProvider id={bookedTherapistId}>
            <TherapistCard endAt={endAt} free={free} startAt={startAt} therapyPathType={therapyPathType} />
            <ThankYouPageDescription />
            <ThankYouPageActions therapyPathType={therapyPathType} therapySessionId={id} />
          </TherapistFetchProvider>
        </Flex>
      </PageRoute>
    </>
  )
}
