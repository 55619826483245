import { memo } from 'react'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { MenuNativeAppVersion } from '~/domains/appNative/components/MenuNativeAppVersion'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { MenuLanguageSwitcher } from '~/i18n/components/MenuLanguageSwitcher'
import { MenuHeader } from './items/MenuHeader'
import { MenuItemAccount } from './items/MenuItemAccount'
import { MenuItemCommunications } from './items/MenuItemCommunications'
import { MenuItemFAQ } from './items/MenuItemFAQ'
import { MenuItemHistoricalTherapySessions } from './items/MenuItemHistoricalTherapySessions'
import { MenuItemLogout } from './items/MenuItemLogout'
import { MenuItemNotifications } from './items/MenuItemNotifications'
import { MenuItemProfile } from './items/MenuItemProfile'
import { MenuItemReview } from './items/MenuItemReview'
import { MenuItemWriteUs } from './items/MenuItemWriteUs'

export const TherapistTabMenu = memo(({ handleAutoClose }: { handleAutoClose?: () => void }) => {
  const isNativeApp = useIsNativeApp()

  return (
    <>
      <MenuHeader />

      <OverflowAuto p={16}>
        <Flex pb={24}>
          <Text colorName="primary" fontWeight="600" kind="paragraph">
            <Translation id="menu.menu" />
          </Text>
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemAccount />
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemHistoricalTherapySessions />
        </Flex>

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemCommunications />
        </Flex>

        {isNativeApp && (
          <Flex pb={24}>
            <MenuItemNotifications />
          </Flex>
        )}

        <Flex onClick={handleAutoClose} pb={24}>
          <MenuItemProfile />
        </Flex>

        <Flex py={24}>
          <Text colorName="primary" fontWeight="600" kind="paragraph">
            <Translation id="menu.youOkay" />
          </Text>
        </Flex>

        <Flex pb={24}>
          <MenuItemReview />
        </Flex>

        <Flex py={24}>
          <Text colorName="primary" fontWeight="600" kind="paragraph">
            <Translation id="menu.help" />
          </Text>
        </Flex>

        <Flex pb={24}>
          <MenuItemFAQ />
        </Flex>

        <Flex pb={24}>
          <MenuItemWriteUs />
        </Flex>

        <Flex justify="flex-end" pb={24} pt={40}>
          <MenuItemLogout />
        </Flex>

        <Flex pb={24}>
          <MenuLanguageSwitcher />
        </Flex>

        <MenuNativeAppVersion />
      </OverflowAuto>
    </>
  )
})
