import loadable from '@loadable/component'
import { Flex } from 'cdk'
import { noop } from 'functions'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button, type ButtonProps } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId, type TranslationValues } from '~/i18n/types'
import { getRoute, type Route } from '~/utils/getRoute'

const LazyLiveAnimation = loadable(() => import('./LiveAnimation.lazy'), {
  resolveComponent: (components) => components.LiveAnimation,
})

const Wrapper = styled(Flex)`
  overflow: hidden;
  width: 16px;
  height: 16px;
  margin-top: -1px;
`

type Props = {
  liveAnimation?: boolean
  onClick?: () => void
  replace?: boolean
  to: Route
  translationId: TranslationId
  translationValues?: TranslationValues
} & Omit<ButtonProps, 'children'>

export const RouterLinkButton = ({
  disabled = false,
  kind,
  liveAnimation = false,
  isLoading,
  onClick = noop,
  replace = false,
  to,
  translationId,
  translationValues,
  ...props
}: Props) => {
  if (liveAnimation) {
    return (
      <Link onClick={onClick} replace={replace} to={getRoute(to)}>
        <Button {...props} as="span" disabled={disabled} isLoading={isLoading} kind={kind} role="button">
          <Flex $align="center" $direction="row">
            <Wrapper $align="center" $justify="center">
              <LazyLiveAnimation />
            </Wrapper>
            <Flex $pl={8}>
              <Translation id={translationId} values={translationValues} />
            </Flex>
          </Flex>
        </Button>
      </Link>
    )
  }

  return (
    <Link onClick={onClick} replace={replace} to={getRoute(to)}>
      <Button {...props} as="span" disabled={disabled} isLoading={isLoading} kind={kind} role="button">
        <Translation id={translationId} values={translationValues} />
      </Button>
    </Link>
  )
}
