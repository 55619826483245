import { Flex, MountOn } from 'cdk'
import { Icon } from 'icons'
import { useMemo } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useConventionCodeName } from '~/domains/convention/hooks/useConventionCodeName'
import { useIsUtmSource } from '~/hooks/useIsUtmSource'
import { usePartnershipQueryParam } from '~/hooks/usePartnershipQueryParam'
import { type TranslationId, type TranslationValues } from '~/i18n/types'
import { useSignupIntent } from '../hooks/useSignupIntent'
import { type Intent } from '../types/intent'
import { TrustpilotWidget } from './TrustpilotWidget'

const titleByIntent: Record<Intent, TranslationId> = {
  call: 'signup.call.title',
  dca: 'signup.dca.title',
  journaling: 'signup.journaling.title',
  home: 'signup.home.title',
  nutritionist: 'signup.nutritionist.title',
  coaching: 'signup.title',
  psychiatry: 'signup.title.psychiatry',
  sexology: 'signup.title',
  therapyMyself: 'signup.title',
  therapyCouple: 'signup.title',
}

const itemsByIntent: Record<Intent, TranslationId[] | null> = {
  call: null,
  dca: null,
  journaling: null,
  home: null,
  nutritionist: [
    'signup.hero.variant.item1.nutrition',
    'signup.hero.variant.item2.nutrition',
    'signup.hero.variant.item3.nutrition',
  ],
  coaching: ['signup.hero.item1', 'signup.hero.item2', 'signup.hero.item3.coaching'],
  psychiatry: [
    'signup.hero.variant.item1.psychiatry',
    'signup.hero.variant.item2.psychiatry',
    'signup.hero.variant.item3.psychiatry',
  ],
  sexology: ['signup.hero.item1', 'signup.hero.item2', 'signup.hero.item3.sexology'],
  therapyMyself: ['signup.hero.item1', 'signup.hero.item2', 'signup.hero.item3'],
  therapyCouple: ['signup.hero.item1', 'signup.hero.item2.therapyCouple', 'signup.hero.item3'],
}

const FlexWithoutMargin = styled(Flex)`
  margin: 0;
`

type Props = {
  translationId: TranslationId
  translationValues: TranslationValues
}

const ListItem = ({ translationId, translationValues }: Props) => (
  <Flex $align="center" $direction="row" $gap={8} $grow={1} $shrink={0}>
    <Flex $shrink={0}>
      <Icon colorName="white" name="check" size={20} />
    </Flex>

    <Flex $grow={1} $mdShrink={0} $shrink={1}>
      <TranslationMarkdown colorName="lighter" id={translationId} kind="public-h5" values={translationValues} />
    </Flex>
  </Flex>
)

export const SignupHeroContent = () => {
  const intent = useSignupIntent()

  const isWellhubUtmSource = useIsUtmSource('wellhub-srns')

  const conventionCodeName = useConventionCodeName()

  const partnership = usePartnershipQueryParam()

  const titleTranslationId = titleByIntent[intent] ?? titleByIntent.therapyMyself

  const items = useMemo((): TranslationId[] => {
    if (!!conventionCodeName || !!partnership) {
      return [
        'signup.firstSessionFree.ul.convention.1',
        'signup.firstSessionFree.ul.convention.2',
        'signup.firstSessionFree.ul.convention.3',
      ]
    }

    if (isWellhubUtmSource) {
      return ['signup.hero.item1', 'signup.hero.item3']
    }

    return itemsByIntent[intent] ?? itemsByIntent.therapyMyself ?? []
  }, [conventionCodeName, intent, isWellhubUtmSource, partnership])

  return (
    <Flex $lgBasis="50%">
      <Flex $gap={16} $grow={1} $lgJustify="space-between" $lgPx={64} $lgPy={112} $px={16} $py={24} $shrink={1}>
        <Flex $gap={16} $lgGap={32}>
          <FlexWithoutMargin $justify="flex-start">
            <Text colorName="lighter" fontWeight="600" kind="public-h3">
              <Translation id={titleTranslationId} />
            </Text>
          </FlexWithoutMargin>

          <Flex
            $align="flex-start"
            $direction="row"
            $gap={16}
            $grow={1}
            $lgAlign="center"
            $lgGap={24}
            $lgPb={0}
            $pb={intent === 'psychiatry' ? 16 : 0}
            $shrink={1}
          >
            <Flex $align="flex-start" $gap={4} $grow={1} $lgGap={16} $shrink={1}>
              {items.map((translationId) => (
                <ListItem
                  key={translationId}
                  translationId={translationId}
                  translationValues={{ convention: conventionCodeName || partnership || '' }}
                />
              ))}
            </Flex>
          </Flex>

          <MountOn mediaQuery="gt-md">
            <Flex>
              <TranslationMarkdown colorName="lighter" id="generic.signup.notASubscription" kind="public-h5" />
            </Flex>
          </MountOn>
        </Flex>

        <MountOn mediaQuery="gt-md">
          <Flex $pt={24}>
            <TrustpilotWidget />
          </Flex>
        </MountOn>
      </Flex>
    </Flex>
  )
}
