import { PositionRelative } from 'cdk'
import { type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { APP_BAR_HEIGHT } from '../AppBar/constants'

const FlowWrapperContainer = styled(PositionRelative)`
  height: calc(100vh - 144px);
`

export const FlowWrapper = ({ children }: PropsWithChildren) => (
  <FlowWrapperContainer $grow={1} $maxHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`} $overflow="hidden" $shrink={1}>
    {children}
  </FlowWrapperContainer>
)

FlowWrapper.displayName = 'FormFlowWrapper'
