import { Icon } from 'icons'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useTherapistSpecializations } from '~/domains/agenda/hooks/useTherapistSpecializations'
import { useShowMoreButton } from '~/hooks/useShowMoreButton'
import { useTherapist } from '~/hooks/useTherapist'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { Grid } from './TherapistWhatYouShouldKnow'

type ItemProps = { active: boolean; name: string }

const Item = ({ active, name }: ItemProps) => (
  <Flex align="center" direction="row">
    <Flex>
      <Icon colorName="grey-80" name="book-stack" size={24} />
    </Flex>
    <Flex grow={1} pl={8} shrink={1}>
      <Text fontWeight={active ? '600' : '400'} kind="caption">
        {name}
      </Text>
    </Flex>
  </Flex>
)

type Props = {
  therapyPathType: TherapyTherapyPathType
}

export const TherapistWhatDoes = ({ therapyPathType }: Props) => {
  const { specializations } = useTherapistSpecializations()
  const { pathologies } = useTherapist()

  const isTherapyCouples = therapyPathType === 'COUPLES_PSYCHOTHERAPY'
  const isTherapyUnderage = therapyPathType === 'UNDERAGE_PSYCHOTHERAPY'

  const couplesSpecialization = useTranslation('therapist.presentationCard.specializations.couples.title')
  const underageSpecialization = useTranslation('therapist.presentationCard.specializations.underage.title')

  const pathlogiesToShow = useMemo(
    () => [
      ...specializations.map((specialization) => ({ ...specialization, active: true })),
      ...pathologies
        .flatMap(({ pathology }) => ({
          ...{ ...pathology, active: false },
        }))
        .filter(({ name }) => !specializations.find((specialization) => specialization.name === name)),
    ],
    [specializations, pathologies],
  )

  const { areAllItemsVisible, visibleItems, showAllItems } = useShowMoreButton(
    pathlogiesToShow,
    specializations.length || 6,
  )

  return (
    <>
      <Text fontWeight="600" kind="paragraph">
        <Translation id="therapist.presentationCard.whatDoes.title" />
      </Text>
      <Flex pt={16}>
        <Grid>
          {isTherapyCouples && <Item active={true} name={couplesSpecialization} />}
          {isTherapyUnderage && <Item active={true} name={underageSpecialization} />}
          {visibleItems.map(({ active, id, name }, index) => (
            <Flex key={id} pt={index === 0 ? 0 : 4}>
              <Item active={active} name={name} />
            </Flex>
          ))}
        </Grid>
        {!areAllItemsVisible && (
          <Flex align="flex-start" pt={16}>
            <Button kind="secondary" onClick={showAllItems} size="small">
              <Translation id="actions.learnMore" />
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  )
}
