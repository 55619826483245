import { assertNever } from 'functions'
import { type ComponentProps, useMemo } from 'react'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { TherapySessionProvider } from '../../hooks/useTherapySession'
import { CardBoxNoStroke } from '../CardBoxNoStroke'
import { BookedWithoutPaymentMethodContent } from './contents/BookedWithoutPaymentMethodContent'
import { BookedWithPaymentMethodContent } from './contents/BookedWithPaymentMethodContent'
import { ConfirmedAppointmentContent } from './contents/ConfirmedAppointmentContent'
import { FirstPaymentAttemptFailedContent } from './contents/FirstPaymentAttemptFailedContent'
import { MissingPaymentCancelledAppointmentContent } from './contents/MissingPaymentCancelledAppointmentContent'

type TherapySessionCardPatientProps = ComponentProps<typeof TherapySessionProvider>['therapySession'] & {
  isVeryNextTherapySession?: boolean
}

export const TherapySessionCardPatient = (therapySession: TherapySessionCardPatientProps) => {
  const content = useMemo(() => {
    switch (therapySession.status) {
      case 'CANCELLED':
      case 'ENDED':
      case 'NO_SHOW':
      case undefined:
        return null

      case 'CONFIRMED':
      case 'SCHEDULED':
      case 'TO_BE_CONFIRMED': {
        if (therapySession.cost) {
          return <BookedWithPaymentMethodContent isVeryNextTherapySession={therapySession.isVeryNextTherapySession} />
        }

        return (
          <ConfirmedAppointmentContent
            bonus={!!therapySession.bonus}
            isVeryNextTherapySession={therapySession.isVeryNextTherapySession}
          />
        )
      }

      case 'NO_PAYMENT_METHOD':
        return <BookedWithoutPaymentMethodContent isVeryNextTherapySession={therapySession.isVeryNextTherapySession} />

      case 'ONGOING':
      case 'PAID':
        return (
          <ConfirmedAppointmentContent
            bonus={!!therapySession.bonus}
            isVeryNextTherapySession={therapySession.isVeryNextTherapySession}
          />
        )

      case 'PAYMENT_FAILED':
        return <MissingPaymentCancelledAppointmentContent />

      case 'PAYMENT_FAILED_FIRST_ATTEMPT':
        return <FirstPaymentAttemptFailedContent isVeryNextTherapySession={therapySession.isVeryNextTherapySession} />

      case 'PAYMENT_FAILED_FIRST_ATTEMPT_NO_PAYMENT_METHOD':
        return <MissingPaymentCancelledAppointmentContent />

      default:
        assertNever(therapySession.status)
    }
  }, [therapySession])

  if (!therapySession.therapistId) {
    return null
  }

  if (!content) {
    return null
  }

  return (
    <TherapistFetchProvider id={therapySession.therapistId}>
      <TherapySessionProvider therapySession={therapySession}>
        <CardBoxNoStroke data-test-id="patient-next-session-card">{content}</CardBoxNoStroke>
      </TherapySessionProvider>
    </TherapistFetchProvider>
  )
}
