import { Flex, PositionSticky, Pressable } from 'cdk'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { Icon } from 'icons'
import { type PropsWithChildren, useCallback, useMemo, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Text } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { usePatientFindById } from '~/hooks/usePatientFindById'
import { ClinicalTestAnswerChipForTherapist } from '../components/ClinicalTestAnswerChip'
import { ClinicalTestNameTranslation } from '../components/ClinicalTestNameTranslation'
import { ClinicalTestQuestionTranslation } from '../components/ClinicalTestQuestionTranslation'
import { ClinicalTestResultTranslation } from '../components/ClinicalTestResultTranslation'
import { useClinicalTestAnswer } from '../hooks/useClinicalTestAnswer'
import { ClinicalTestScoresScale } from './ClinicalTestScoresScale'

type ClinicalTestAnswerItemProps = PropsWithChildren<{
  questionId: string
  answer: number
}>

const ClinicalTestAnswerItem = ({ answer, children, questionId }: ClinicalTestAnswerItemProps) => (
  <Flex $align="flex-start" $gap={4}>
    {children}
    <Text fontWeight="600">
      <ClinicalTestQuestionTranslation questionId={questionId} />
    </Text>
    <ClinicalTestAnswerChipForTherapist answer={answer} />
  </Flex>
)

type ClinicalTestAnswersListProps = {
  clinicalTestAnswerId: string
  patientId: string
}

const ClinicalTestAnswersList = ({ clinicalTestAnswerId, patientId }: ClinicalTestAnswersListProps) => {
  const history = useHistory()
  const { loading: clinicalTestLoading, clinicalTestAnswer } = useClinicalTestAnswer(clinicalTestAnswerId)
  const { loading: patientLoading, patient } = usePatientFindById(patientId)

  const scoresScaleRef = useRef<HTMLDivElement>(null)

  const sumOfAnswers = useMemo(
    () => clinicalTestAnswer?.answers.reduce((a, b) => a + (b.answer ?? 0), 0) ?? 0,
    [clinicalTestAnswer?.answers],
  )

  const handleBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleGoToScoresScale = useCallback(() => {
    scoresScaleRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  if (clinicalTestLoading || patientLoading) {
    return <CenteredLoader />
  }

  if (!clinicalTestAnswer || !patient) {
    return <GenericErrorContent />
  }

  const { answers, code, createdAt } = clinicalTestAnswer

  return (
    <Flex $gap={16}>
      <PositionSticky
        $align="center"
        $backgroundColorName="lighter"
        $direction="row"
        $elevationName="xxs"
        $gap={8}
        $justify="center"
        $px={16}
        $py={8}
        $top={0}
      >
        <Flex $align="flex-start" $basis={50} $grow={1} $shrink={1}>
          <Button isGhost kind="primary" onClick={handleBack}>
            <Icon name="arrow-left" size={24} />
          </Button>
        </Flex>
        <Flex $grow={1} $shrink={1}>
          <Text fontWeight="600" textAlign="center">
            <ClinicalTestNameTranslation code={code} />
          </Text>
          <Text colorName="neutral-80" kind="caption" textAlign="center">
            {pipe(createdAt, format(FormatDateEnum.DAY_MONTH_YEAR))}
          </Text>
        </Flex>
        <Flex $align="flex-end" $basis={50} $grow={1} $shrink={1} />
      </PositionSticky>
      <Flex $gap={32} $px={16}>
        <Flex $gap={16}>
          <Text kind="h3">
            <Translation id="clinicalTests.results.title" />
          </Text>
          <Flex
            $align="flex-start"
            $borderRadius={8}
            $direction="row"
            $elevationName="xxs"
            $justify="space-between"
            $p={12}
          >
            <Flex>
              <Text colorName="neutral-80">
                <Translation id="clinicalTests.results.points" values={{ value: `${sumOfAnswers}` }} />
              </Text>
              <Text kind="h3">
                <ClinicalTestResultTranslation value={sumOfAnswers} />
              </Text>
            </Flex>
            <Pressable onClick={handleGoToScoresScale}>
              <Icon colorName="primary" name="info-circle" size={16} />
            </Pressable>
          </Flex>
        </Flex>
        <Flex $gap={16}>
          <Text kind="h3">
            <Translation id="clinicalTests.results.body" values={{ patientFirstName: patient.firstName }} />
          </Text>
          <Flex $gap={24}>
            {answers.map(({ answer, questionId }, index, array) => (
              <ClinicalTestAnswerItem key={index} answer={answer} questionId={questionId}>
                <Text colorName="neutral-80" kind="caption">
                  <Translation id="generic.outOf" values={{ value: index + 1, max: array.length }} />
                </Text>
              </ClinicalTestAnswerItem>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex $p={16}>
        <Flex ref={scoresScaleRef} $backgroundColorName="neutral-20" $borderRadius={8} $p={12}>
          <Text fontWeight="600">
            <Translation id="clinicalTests.results.scale.title" />
          </Text>
          <ClinicalTestScoresScale code={code} />
        </Flex>
      </Flex>
    </Flex>
  )
}

type PatientDetailClinicalTestProps = {
  patientId: string
}

export const PatientDetailClinicalTestForTherapist = ({ patientId }: PatientDetailClinicalTestProps) => {
  const { clinicalTestAnswerId } = useParams<{ clinicalTestAnswerId?: string }>()

  if (!clinicalTestAnswerId) {
    return <CenteredLoader />
  }

  return <ClinicalTestAnswersList clinicalTestAnswerId={clinicalTestAnswerId} patientId={patientId} />
}
