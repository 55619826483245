import { Icon } from 'icons'
import { Modal, ModalContent, ModalTrigger, Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { MarkAsNoShowTherapySessionModal } from './MarkAsNoShowTherapySessionModal'

type Props = Pick<TherapistAgendaTherapySession, 'id' | 'startAt' | 'patient'>

export const MarkAsNoShowTherapySession = ({ id, startAt, patient }: Props) => (
  <Modal>
    <ModalTrigger>
      <Pressable autoWidth>
        <Flex align="center" direction="row">
          <Flex basis="28px" pr={8}>
            <Icon colorName="red-60" name="user-xmark" size={20} />
          </Flex>

          <Text colorName="red-60" kind="caption" textAlign="center">
            <Translation id="actions.markAsNoShow" />
          </Text>
        </Flex>
      </Pressable>
    </ModalTrigger>

    <ModalContent>
      <MarkAsNoShowTherapySessionModal id={id} patient={patient} startAt={startAt} />
    </ModalContent>
  </Modal>
)
