import { Flex, Form, MaxWidth400px } from 'cdk'
import { SPACING_4XL, SPACING_XL } from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { type PropsWithChildren, useCallback } from 'react'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type ReservationFormValues } from '~/domains/reservation/types'
import { ScrollRestoreProvider } from '~/hooks/useScrollRestore'
import { TherapistOverviewCard } from '../../../therapist/TherapistOverviewCard'
import { AvailabilityDayDropdownProvider } from '../../hooks/useAvailabilityDayDropdown'
import { useReservationFlow } from '../../hooks/useReservationFlow'

const Container = ({ children, isMobile }: { isMobile: boolean } & PropsWithChildren) =>
  isMobile ? (
    <MaxWidth400px $grow={1} $justify="center">
      {children}
    </MaxWidth400px>
  ) : (
    <Flex $direction="row" $gap={SPACING_XL} $justify="space-evenly" $maxWidth={1440} $p={SPACING_4XL}>
      {children}
      <Flex $grow={1}>CALENDAR HERE</Flex>
    </Flex>
  )

export const BookBySuggestedTherapistsVariant = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  const { onDayAndTimeSlotSelected } = useReservationFlow()

  const { isSmallOnly } = useBreakpoints()

  const onSubmit = useCallback(
    ({ selectedTimeSlot }: ReservationFormValues) => {
      if (!selectedTimeSlot) {
        return
      }

      onDayAndTimeSlotSelected(selectedTimeSlot)
    },
    [onDayAndTimeSlotSelected],
  )

  return (
    <ScrollRestoreProvider pb={0}>
      <Form $backgroundColorName="lighter" $grow={1} id="reservationFlow" onSubmit={form.handleSubmit(onSubmit)}>
        <AvailabilityDayDropdownProvider>
          <Container isMobile={isSmallOnly}>
            <TherapistOverviewCard />
          </Container>
        </AvailabilityDayDropdownProvider>
      </Form>
    </ScrollRestoreProvider>
  )
}
