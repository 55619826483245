import { useEffect } from 'react'
import { useTranslate } from '~/i18n/hooks/useTranslation'
import { type TranslationId, type TranslationValues } from '~/i18n/types'
import { useNavigation } from '../hooks/useNavigation'

type Props = {
  title: TranslationId | null
  values?: TranslationValues
}

const useNavigationUpdateAppBarTitleEffect = ({ title, values }: Props) => {
  const { updateTitle } = useNavigation()
  const translate = useTranslate()

  useEffect(() => {
    if (!title) {
      updateTitle(null)

      return
    }

    updateTitle(translate(title, values))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])
}

export const NavigationUpdateAppBarTitleEffect = ({ title, values }: Props) => {
  useNavigationUpdateAppBarTitleEffect({ title, values })

  return null
}
