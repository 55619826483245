import { type ComponentProps, forwardRef, type Ref } from 'react'
import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { TextFieldNew } from 'ui-deprecated'
import { useLoadingState } from '~/hooks/useLoadingState'

type TextFieldProps = ComponentProps<typeof TextFieldNew>

type Props = Omit<TextFieldProps, 'value'> & {
  rules?: RegisterOptions
  validateOnSubmit?: boolean
  warning?: boolean
}

export const ReactHookFormTextFieldNew = forwardRef(
  ({ disabled, name, rules, validateOnSubmit = true, warning, ...props }: Props, ref: Ref<HTMLInputElement>) => {
    const { control } = useFormContext()
    const { loading } = useLoadingState()

    return (
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error, invalid },
          formState: { isSubmitting, isSubmitted, isValid },
        }) => (
          <TextFieldNew
            {...props}
            ref={ref}
            disabled={disabled || ((isSubmitting || loading) && isValid)}
            errorMessage={error?.message}
            invalid={(invalid && !validateOnSubmit) || (invalid && isSubmitted && validateOnSubmit)}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            warning={warning}
          />
        )}
        rules={rules}
      />
    )
  },
)
