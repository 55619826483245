import {
  COLOR_DARKER,
  COLOR_GREY_40,
  COLOR_PRIMARY,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_30,
  COLOR_PRIMARY_40,
  COLOR_RED_80,
  TYPOGRAPHY_CAPTION_FONT_SIZE,
} from 'design-tokens'
import { type GroupBase, type StylesConfig } from 'react-select'
import { type SelectOption, type SelectSize } from '~/components/SelectNew/types'

export const styles = (
  hasError: boolean,
  size: SelectSize,
): StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> => ({
  control: (base, { hasValue, menuIsOpen }) => ({
    ...base,
    boxShadow: 'none',
    ...(hasValue &&
      !menuIsOpen && {
        borderColor: COLOR_GREY_40,
      }),
    ...(hasError && {
      borderColor: COLOR_RED_80,
      ':focus': {
        borderColor: COLOR_RED_80,
      },
    }),
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: size === 'medium' ? 16 : 8,
    pointerEvents: 'none',
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 0,
    paddingRight: 8,
  }),
  indicatorSeparator: (base, { isMulti, hasValue, selectProps: { menuIsOpen } }) => ({
    ...base,
    backgroundColor: COLOR_PRIMARY_40,
    margin: 0,
    ...(menuIsOpen && {
      backgroundColor: COLOR_PRIMARY,
    }),
    ...((!isMulti || !hasValue) && {
      display: 'none',
    }),
  }),
  input: (base) => ({
    ...base,
    fontSize: TYPOGRAPHY_CAPTION_FONT_SIZE,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 200 }), // Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
  multiValue: (base) => ({
    ...base,
    backgroundColor: COLOR_PRIMARY_10,
    fontSize: TYPOGRAPHY_CAPTION_FONT_SIZE,
    lineHeight: '16px',
    paddingBottom: 4,
    paddingTop: 4,
    borderRadius: 12,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: COLOR_DARKER,
    fontSize: 12,
    padding: 0,
    paddingLeft: 8,
  }),
  multiValueRemove: (base) => ({
    ...base,
    ':hover': {
      backgroundColor: COLOR_PRIMARY_10,
    },
    paddingLeft: 12,
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    fontSize: TYPOGRAPHY_CAPTION_FONT_SIZE,
    ...(isFocused &&
      !isSelected && {
        color: COLOR_PRIMARY,
      }),
    ':active': {
      backgroundColor: COLOR_PRIMARY_30,
      color: COLOR_PRIMARY,
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: TYPOGRAPHY_CAPTION_FONT_SIZE,
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: TYPOGRAPHY_CAPTION_FONT_SIZE,
  }),
})
