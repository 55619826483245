import { createContext, type PropsWithChildren, useContext } from 'react'
import { useTherapistTherapiesFetch } from '~/hooks/useTherapistTherapiesFetch'
import { type TherapistTherapiesQuery } from '~/types/graphql'

type ContextType = {
  error: boolean
  loading: boolean
  therapies: TherapistTherapiesQuery['therapies']
}

const TherapistTherapiesContext = createContext<ContextType | null>(null)

export const useTherapistTherapies = () => {
  const context = useContext(TherapistTherapiesContext)

  if (!context) {
    throw new Error('useTherapistTherapies hook must be wrapped in <TherapistTherapiesProvider />')
  }

  return context
}

export const TherapistTherapiesProvider = ({ children }: PropsWithChildren) => {
  const { error, loading, therapies } = useTherapistTherapiesFetch()

  return (
    <TherapistTherapiesContext.Provider value={{ error, loading, therapies }}>
      {children}
    </TherapistTherapiesContext.Provider>
  )
}
