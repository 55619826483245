import loadable from '@loadable/component'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { AssertPatientOrAnonymous } from '~/domains/auth/components/AssertPatientOrAnonymous'
import { FormFlowsProvider } from '~/domains/formFlow/hooks/useFormFlows'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { getRoute } from '~/utils/getRoute'

const LazyFormFlow = loadable(() => import('~/domains/formFlow'), {
  resolveComponent: (components) => components.FormFlow,
})

export const StartPage: Page = {
  exact: true,
  id: 'start.form',
  paths: ['/start', '/start/p/:prefilledConceptIdOrName'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'start',
    title: null,
  },
  PageGuard: ({ children }) => <AssertPatientOrAnonymous target="/">{children}</AssertPatientOrAnonymous>,
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => {
    const history = useHistory()

    const onError = useCallback(() => {
      history.replace(getRoute('/'))
    }, [history])

    return (
      <FormFlowsProvider onError={onError} type="COMPLETE">
        {children}
      </FormFlowsProvider>
    )
  },
  PageView: () => <LazyFormFlow />,
}
