import { type ReactNode } from 'react'
import { Link } from 'ui'
import { Flex } from 'ui-deprecated'

type Props = {
  children: ReactNode
  to: string
}

export const MenuExternalLink = ({ children, to }: Props) => (
  <Link colorName="black" colorNameHover="primary" href={to} rel="noreferrer" target="_blank">
    <Flex align="center" direction="row">
      <Flex align="center" direction="row" grow={1} pr={8} shrink={1}>
        {children}
      </Flex>
    </Flex>
  </Link>
)
