import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, Form, PageLayout } from 'ui-deprecated'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { usePhoneVerificationContext } from '~/domains/phoneNumbers/hooks/usePhoneVerificationContext'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { FieldPhoneNumberNew } from '~/domains/react-hook-form/fields/FieldPhoneNumberNew'
import { TextFieldNewHelperText } from '~/domains/react-hook-form/fields/TextFieldNewHelperText'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { Head } from '../components/Head'
import { type ReservationFormValues } from '../types'
import { getReservationRoutes } from '../utils/getReservationRoutes'

export const SendVerificationCode = () => {
  const history = useHistory()

  const form = useReactHookFormContext<ReservationFormValues>()

  const { sendVerificationCode } = usePhoneVerificationContext()

  const onSubmit = useCallback(
    async ({ phoneNumber }: ReservationFormValues) => {
      if (!phoneNumber) {
        return
      }

      const { ok } = await sendVerificationCode(phoneNumber, false)

      if (!ok) {
        return
      }

      history.push(getReservationRoutes('/check-verification-code'))
    },
    [history, sendVerificationCode],
  )

  const { helperTexts } = useFormTranslations()

  return (
    <>
      <PageScrollEffect />

      <PageRoute id="reservation.send-verification-code">
        <PageLayout maxWidth="640px" px={24}>
          <Head translationId="reservation.sendVerificationCode" />

          <Flex pb={32}>
            <Form grow={1} id="reservationFlow" onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
              <Flex>
                <Text fontWeight="600" kind="h2">
                  <Translation id="reservation.sendVerification.title" />
                </Text>
              </Flex>

              <TranslationMarkdown colorName="black" id="reservation.sendVerification.body" kind="paragraph" pt={16} />

              <Flex pt={24}>
                <FieldPhoneNumberNew />
                <TextFieldNewHelperText helperText={helperTexts.mobilePhone} />
              </Flex>
            </Form>
          </Flex>
        </PageLayout>
      </PageRoute>
    </>
  )
}
