import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { Checkbox, type CheckboxProps } from 'ui'
import { useLoadingState } from '~/hooks/useLoadingState'

type Props = CheckboxProps & {
  name: string
  rules?: RegisterOptions
}

export const ReactHookFormCheckboxFieldVariant = ({ disabled, name, rules, ...props }: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({ field: { onChange, value, ref }, formState: { isSubmitting, isValid } }) => (
        <Checkbox
          {...props}
          ref={ref}
          checked={value}
          disabled={(isValid && (isSubmitting || loading)) || disabled}
          id={name}
          name={name}
          onChange={onChange}
          required={!!rules?.required}
          value={name}
        />
      )}
      rules={rules}
    />
  )
}
