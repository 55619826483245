import { type ReactText } from 'react'
import { Text } from 'ui'

type Props = {
  children: ReactText
}

export const AppBarTitle = ({ children }: Props) => (
  <Text data-test-id="appbar-title" fontWeight="600" kind="paragraph" textAlign="center">
    {children}
  </Text>
)
