import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type TranslationId } from '~/i18n/types'
import { isBeforeCancelationPolicyLimit } from '~/utils/dates/isBeforeCancelationPolicyLimit'
import { isTherapySessionSuccessfullyPaid } from '~/utils/dates/isTherapySessionSuccessfullyPaid'
import { type FormValues } from '../types/formValues'
import { getDeleteForTherapistEmergencyRoute } from '../utils/getDeleteForTherapistEmergencyRoute'

type Props = Pick<TherapistAgendaTherapySession, 'patient' | 'startAt' | 'status' | 'typeCost'>

export const StepPaymentWarning = ({ patient, startAt, status, typeCost }: Props) => {
  const history = useHistory()
  const form = useReactHookFormContext<FormValues>()

  const therapistEmergencyReason = form.watch('therapistEmergencyReason')

  const titleTranslationId = useMemo((): TranslationId => {
    if (isTherapySessionSuccessfullyPaid({ status, typeCost }) && isBeforeCancelationPolicyLimit(startAt)) {
      return 'therapySession.deleteForTherapistEmergency.step.warningPayment.paid.beforeCancelationPolicyLimit.title'
    }

    if (
      isTherapySessionSuccessfullyPaid({ status, typeCost }) &&
      !isBeforeCancelationPolicyLimit(startAt) &&
      (therapistEmergencyReason === 'FAMILY_ISSUE' || therapistEmergencyReason === 'HEALTH_ISSUE')
    ) {
      return 'therapySession.deleteForTherapistEmergency.step.warningPayment.paid.underCancelationPolicyLimit.therapistEmergency.title'
    }

    if (
      typeCost === 'FREE' &&
      !isBeforeCancelationPolicyLimit(startAt) &&
      (therapistEmergencyReason === 'FAMILY_ISSUE' || therapistEmergencyReason === 'HEALTH_ISSUE')
    ) {
      return 'therapySession.deleteForTherapistEmergency.step.warningPayment.free.underCancelationPolicyLimit.therapistEmergency.title'
    }

    if (
      typeCost === 'FREE' &&
      !isBeforeCancelationPolicyLimit(startAt) &&
      therapistEmergencyReason === 'PATIENT_REQUEST'
    ) {
      return 'therapySession.deleteForTherapistEmergency.step.warningPayment.free.underCancelationPolicyLimit.patientRequest.title'
    }

    return 'therapySession.deleteForTherapistEmergency.step.warningPayment.paid.underCancelationPolicyLimit.patientRequest.title'
  }, [status, startAt, therapistEmergencyReason, typeCost])

  const paragraphTranslationId = useMemo((): TranslationId => {
    if (isTherapySessionSuccessfullyPaid({ status, typeCost }) && isBeforeCancelationPolicyLimit(startAt)) {
      return 'therapySession.deleteForTherapistEmergency.step.warningPayment.paid.beforeCancelationPolicyLimit.paragraph'
    }

    if (
      isTherapySessionSuccessfullyPaid({ status, typeCost }) &&
      !isBeforeCancelationPolicyLimit(startAt) &&
      (therapistEmergencyReason === 'FAMILY_ISSUE' || therapistEmergencyReason === 'HEALTH_ISSUE')
    ) {
      return 'therapySession.deleteForTherapistEmergency.step.warningPayment.paid.underCancelationPolicyLimit.therapistEmergency.paragraph'
    }

    if (
      typeCost === 'FREE' &&
      !isBeforeCancelationPolicyLimit(startAt) &&
      (therapistEmergencyReason === 'FAMILY_ISSUE' || therapistEmergencyReason === 'HEALTH_ISSUE')
    ) {
      return 'therapySession.deleteForTherapistEmergency.step.warningPayment.free.underCancelationPolicyLimit.therapistEmergency.paragraph'
    }

    if (
      typeCost === 'FREE' &&
      !isBeforeCancelationPolicyLimit(startAt) &&
      therapistEmergencyReason === 'PATIENT_REQUEST'
    ) {
      return 'therapySession.deleteForTherapistEmergency.step.warningPayment.free.underCancelationPolicyLimit.patientRequest.paragraph'
    }

    return 'therapySession.deleteForTherapistEmergency.step.warningPayment.paid.underCancelationPolicyLimit.patientRequest.paragraph'
  }, [status, startAt, therapistEmergencyReason, typeCost])

  const goNext = useCallback(() => {
    if (isTherapySessionSuccessfullyPaid({ status, typeCost }) && isBeforeCancelationPolicyLimit(startAt)) {
      history.push(getDeleteForTherapistEmergencyRoute('/reason'))

      return
    }

    history.push(getDeleteForTherapistEmergencyRoute('/message'))
  }, [history, startAt, status, typeCost])

  return (
    <>
      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation id={titleTranslationId} />
        </Text>

        <TranslationMarkdown
          colorName="black"
          id={paragraphTranslationId}
          kind="paragraph"
          pt={16}
          values={{
            firstName: patient.firstName,
          }}
        />
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="danger" onClick={goNext} type="button">
          <Translation id="therapySession.deleteForTherapistEmergency.step.warning.confirm" />
        </Button>
      </Flex>
    </>
  )
}
