import { noop } from 'functions'
import { Icon } from 'icons'
import { useCallback } from 'react'
import { Flex } from 'ui-deprecated'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { type ModalId, useModals } from '../hooks/useModals'

type Props = {
  id: ModalId
  onClose?: () => void
}

export const ModalCloseButton = ({ id, onClose = noop }: Props) => {
  const { dismiss } = useModals()

  const onClick = useCallback(() => {
    dismiss(id)

    onClose()
  }, [dismiss, id, onClose])

  return (
    <Flex align="flex-end" mdPb={8}>
      <TertiaryButtonIconOnly background="white" onClick={onClick} tabIndex={-1}>
        <Icon name="xmark" size={24} />
      </TertiaryButtonIconOnly>
    </Flex>
  )
}
