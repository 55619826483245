import { Flex, Pressable } from 'cdk'
import { SPACING_4XS, SPACING_SM } from 'design-tokens'
import { Icon } from 'icons'
import { memo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTabNavigationMenu } from '~/domains/navigation/NewNavigation'
import { getRoutes } from '~/utils/getRoutes'
import { type NavigationProps } from '../../types'
import { FlexWithTransition } from '../common/FlexWithTransition'
import { NavLinkContainer } from '../common/NavLinkContainer'
import { TextSize18 } from '../common/TextSize18'

/**
 * TODO (amin-khayam)
 * - remove this component when the new page /settings is ready.
 * - add this link to patientNavigationLinks and therapistNavigationLinks
 */

export const LinkMenu = memo(({ extended, isMobile = false }: NavigationProps) => {
  const { pathname } = useLocation()

  const active = !!matchPath(pathname, {
    path: getRoutes(
      '/historical-therapy-sessions',
      '/invoices',
      '/notifications',
      '/notifications/journaling',
      '/plan',
      '/settings/account',
      '/settings/billing',
      '/settings/payments',
    ),
  })

  const { open, toggleMenu } = useTabNavigationMenu()

  return (
    <Flex data-test-id="tab-item-settings">
      <Pressable onClick={toggleMenu}>
        <NavLinkContainer
          $direction={isMobile ? 'column' : 'row'}
          $gap={isMobile ? SPACING_4XS : SPACING_SM}
          $isMobile={isMobile}
        >
          <Flex>
            <Icon
              colorName={active || open ? 'primary-50' : 'grey-110'}
              name={active || open ? 'srns-menu-solid' : 'srns-menu'}
              size={24}
            />
          </Flex>
          {!isMobile ? (
            <FlexWithTransition $opacity={extended ? 1 : 0}>
              <TextSize18
                colorName={active || open ? 'primary-50' : 'grey-110'}
                fontWeight={active || open ? '600' : '500'}
                kind="h3"
              >
                <Translation id="menu.menu" />
              </TextSize18>
            </FlexWithTransition>
          ) : (
            <Text colorName={active ? 'primary-50' : 'grey-110'} kind="footnote">
              <Translation id="menu.menu" />
            </Text>
          )}
        </NavLinkContainer>
      </Pressable>
    </Flex>
  )
})
