import { type ComponentProps } from 'react'
import { ReactHookFormTextField } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'

type Props = Pick<ComponentProps<typeof ReactHookFormTextField>, 'helperText' | 'readOnly'>

export const FieldEmail = ({ helperText, readOnly }: Props) => {
  const { isEmail } = useFormValidation()
  const {
    errors: { required },
    labels,
    placeholders,
  } = useFormTranslations()

  return (
    <ReactHookFormTextField
      autoComplete="email"
      helperText={helperText}
      label={labels.email}
      name="email"
      placeholder={placeholders.email}
      readOnly={readOnly}
      rules={{
        required,
        validate: isEmail,
      }}
      type="email"
    />
  )
}
