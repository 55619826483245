import { type ComponentProps } from 'react'
import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { CheckboxField } from 'ui-deprecated'
import { useLoadingState } from '~/hooks/useLoadingState'

type CheckboxProps = ComponentProps<typeof CheckboxField>

type Props = CheckboxProps & {
  rules?: RegisterOptions
}

export const ReactHookFormRadioField = ({ disabled, name, rules, value: inputValue, ...props }: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({
        field: { onChange, value, ref },
        fieldState: { invalid },
        formState: { isSubmitting, isSubmitted, isValid },
      }) => (
        <CheckboxField
          {...props}
          ref={ref}
          checked={value === inputValue}
          disabled={disabled || (isValid && (isSubmitting || loading))}
          invalid={invalid && isSubmitted}
          name={name}
          onChange={onChange}
          type="radio"
          value={inputValue}
        />
      )}
      rules={rules}
    />
  )
}
