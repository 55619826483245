import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { useUserFormFlowResponseCompleted } from '~/domains/formFlow/hooks/useUserFormFlowResponseCompleted'
import { useModals } from '~/domains/modals'
import { ModalTitle } from '~/domains/modals/components/ModalTitle'
import { ReactHookFormTextArea, useReactHookFormContext } from '~/domains/react-hook-form'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { getRoute } from '~/utils/getRoute'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { useChangeTherapist } from '../hooks/useChangeTherapist'
import { type FormValues } from './ChangeTherapistModal'

type Props = {
  therapyId: string
}

export const ChangeTherapistReasonOpen = ({ therapyId }: Props) => {
  useTrackEventView('changeTherapist.modal.open')

  const { close } = useModals()
  const { loading, startLoading, stopLoading } = useLoadingState()
  const trackClick = useTrackEvent('CLICK')
  const history = useHistory()
  const rootHistory = useRootHistory()

  const [changeTherapist] = useChangeTherapist()

  const { userFormFlowResponseCompletedId, loading: loadingFormFlowCompleted } = useUserFormFlowResponseCompleted()

  const form = useReactHookFormContext<FormValues>()

  const { addToast } = useToasts()

  const changeTherapistPlaceHolder = useTranslation('changeTherapist.modal.variant.step.3.placeholder')

  const onSubmit = useCallback(
    async ({ changeTherapistReason, changeTherapistMessage }: FormValues) => {
      // just for type check
      if (changeTherapistReason === 'OTHER_MANUAL') {
        return
      }

      if (changeTherapistMessage) {
        setLocalStorage('change-therapist-custom-message', changeTherapistMessage)
      }

      startLoading('local')

      try {
        /**
         * @description Update to accept only `therapyId` as input
         * @link https://linear.app/serenis/issue/PRD-2769/update-changetherapist-input-to-accept-only-therapyid
         */
        await changeTherapist({
          variables: {
            input: {
              changeTherapistReason,
              therapyId,
            },
          },
        })

        if (userFormFlowResponseCompletedId) {
          rootHistory.push(getRoute(`/change-therapist/${therapyId}/preferences`))

          return
        }

        rootHistory.push(getRoute('/start'))
      } catch {
        addToast({ type: 'alert', translationId: 'generic.error' })
      } finally {
        stopLoading()

        trackClick({ name: 'clicks.support.i-want-to-change-therapist' })

        close(`changeTherapist:${therapyId}`)
      }
    },
    [
      addToast,
      changeTherapist,
      close,
      rootHistory,
      startLoading,
      stopLoading,
      therapyId,
      trackClick,
      userFormFlowResponseCompletedId,
    ],
  )

  const onBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  if (loadingFormFlowCompleted) {
    return <CenteredLoader />
  }

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <OverflowAuto>
        <ModalTitle id="changeTherapist.modal.variant.step.3.title" />

        <TranslationMarkdown id="changeTherapist.modal.variant.step.3.description" kind="paragraph" />

        <Flex pt={16}>
          <ReactHookFormTextArea
            max={500}
            minHeight="200px"
            name="changeTherapistMessage"
            placeholder={changeTherapistPlaceHolder}
            rules={{ max: 500 }}
          />
        </Flex>
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" loading={loading} type="submit">
          <Translation id="actions.proceed" />
        </Button>
        <Flex pt={8}>
          <Button kind="secondary" onClick={onBackClick}>
            <Translation id="actions.back" />
          </Button>
        </Flex>
      </Flex>
    </Form>
  )
}
