import { addSeconds, isBefore, isEqual, setHours, setMinutes } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { ReactHookFormTextArea, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { type TherapySession, type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapySessionCancellationPolicyInSeconds } from '~/utils/getTherapySessionCancellationPolicyInSeconds'
import { useTherapistRescheduleSubmit } from '../hooks/useTherapistRescheduleSubmit'
import { type FormValues } from '../types/formValues'

const max = 600

type Props = {
  id: TherapySession['id']
  initialStartAt: Date
  onClose?: () => void
  patientFullName?: string
  patientId?: string
  selectedDay: Date
  therapyPathType: TherapyTherapyPathType | null
}

export const ModalRescheduleTherapySessionConfirm = ({
  onClose = noop,
  id,
  initialStartAt,
  patientFullName,
  patientId,
  selectedDay,
  therapyPathType,
}: Props) => {
  const modalId = `rescheduleTherapySessionConfirm:${id}` as const
  const form = useReactHookFormContext<FormValues>()
  const { close, dismiss, open } = useModals()

  const handleCancel = useCallback(() => {
    dismiss(modalId)

    onClose()
  }, [dismiss, onClose, modalId])

  const handleClose = useCallback(async () => {
    close(modalId)

    onClose()
  }, [close, onClose, modalId])

  const [rescheduleSubmit, { loading }] = useTherapistRescheduleSubmit({
    id,
    patientId,
    onSuccess: handleClose,
  })

  const {
    errors: { required },
    placeholders: { rescheduleTherapistSessionMessage },
  } = useFormTranslations()

  const onSubmit = useCallback(
    async ({ startAtHours, startAtMinutes, message }: FormValues): Promise<void> => {
      const startAt = pipe(selectedDay, setHours(startAtHours), setMinutes(startAtMinutes))

      if (
        pipe(
          startAt,
          isBefore(
            pipe(nowInMilliseconds(), addSeconds(getTherapySessionCancellationPolicyInSeconds(therapyPathType))),
          ),
        )
      ) {
        close(modalId)

        open(`rescheduleTherapySessionUnder24:${id}`)

        return
      }

      if (isEqual(startAt, initialStartAt)) {
        handleClose()

        return
      }

      await rescheduleSubmit({
        message,
        startAt,
      })
    },
    [close, handleClose, id, initialStartAt, modalId, open, rescheduleSubmit, selectedDay, therapyPathType],
  )

  return (
    <Modal id={modalId}>
      <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
        <ModalCloseButton id={modalId} onClose={handleCancel} />

        <OverflowAuto>
          <Text fontWeight="600" kind="h3">
            <Translation id="availabilities.calendar.rescheduleTherapySessionConfirm.title" />
          </Text>
          <Flex pt={4}>
            <Text fontWeight="400" kind="paragraph">
              <Translation
                id={`availabilities.calendar.rescheduleTherapySessionConfirm.${
                  patientFullName ? '1' : '2'
                }.description`}
                values={{ patientFullName: patientFullName || '' }}
              />
            </Text>
          </Flex>
          <Flex pt={24}>
            <ReactHookFormTextArea
              max={max}
              minHeight="200px"
              name="message"
              placeholder={rescheduleTherapistSessionMessage}
              rules={{ max, required }}
            />
          </Flex>
        </OverflowAuto>

        <Flex pt={16}>
          <Button disabled={!form.formState.isValid} kind="primary" loading={loading} type="submit">
            <Translation id="actions.confirm" />
          </Button>
        </Flex>
      </Form>
    </Modal>
  )
}
