import { Flex, Pressable } from 'cdk'
import { useMemo } from 'react'
import { useTherapyPathById } from '~/domains/reservation/hooks/useTherapyPathById'
import { useTherapist } from '~/hooks/useTherapist'
import { TherapistChipOnlineTherapy } from './TherapistChipOnlineTherapy'
import { TherapistChipSexology } from './TherapistChipSexology'

type Props = { onClick: () => void }

export const TherapistBadgeCertifications = ({ onClick }: Props) => {
  const {
    therapist: { therapistCertifications },
  } = useTherapist()
  const { therapyPath } = useTherapyPathById()

  const certifications = useMemo(
    () =>
      /**
       * @description Values are hardcoded intentionally
       */
      ({
        onlineTherapy: therapistCertifications?.some(({ certification: { id } }) => id === 'crt_ubmnjbow31vu45ns'),
        sexology: therapyPath.type === 'PATH_SEXOLOGY', // NOTE: sexologist are not tied to the certification, it is a profession type
      }),
    [therapistCertifications, therapyPath],
  )

  if (!certifications) {
    return null
  }

  return (
    <>
      {certifications.onlineTherapy && (
        <Flex $pt={16}>
          <Pressable data-test-id="button-open-profile-modal-from-certification" onClick={onClick}>
            <TherapistChipOnlineTherapy />
          </Pressable>
        </Flex>
      )}
      {certifications.sexology && (
        <Flex $pt={16}>
          <Pressable data-test-id="button-open-profile-modal-from-certification" onClick={onClick}>
            <TherapistChipSexology />
          </Pressable>
        </Flex>
      )}
    </>
  )
}
