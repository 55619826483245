import { gql, useQuery } from '@apollo/client'
import { type JournalingActivitiesQuery, type JournalingActivitiesQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query journalingActivities {
    journalingActivities {
      id
      name
      slug
    }
  }
`

export const useJournalingActivities = () => {
  const { data, error, loading } = useQuery<JournalingActivitiesQuery, JournalingActivitiesQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    loading,
    journalingActivities: data?.journalingActivities || [],
  }
}
