import loadable from '@loadable/component'
import { AssertUserTherapy } from '~/domains/agenda/components/AssertUserTherapy'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyTherapySessionBooking = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})
export const TherapySessionBookingPage: Page = {
  exact: true,
  id: 'therapy-sessions.booking',
  paths: ['/therapy-session/booking/:therapyId'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'therapy-session.booking',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient>
        <PatientTherapiesProvider>
          <AssertUserTherapy>{children}</AssertUserTherapy>
        </PatientTherapiesProvider>
      </AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyTherapySessionBooking />,
}
