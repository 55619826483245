import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

type Context = {
  closeMenu: () => void
  open: boolean
  openMenu: () => void
  toggleMenu: () => void
}

export const TabNavigationMenuContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
}

export const TabNavigationMenuProvider = ({ children }: Props) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)

  const closeMenu = useCallback(() => {
    setOpen(false)
  }, [])

  const openMenu = useCallback(() => {
    setOpen(true)
  }, [])

  const toggleMenu = useCallback(() => {
    setOpen((state) => !state)
  }, [])

  useEffect(() => {
    if (!open) {
      return
    }

    closeMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const value = useMemo(
    () => ({
      closeMenu,
      open,
      openMenu,
      toggleMenu,
    }),
    [closeMenu, open, openMenu, toggleMenu],
  )

  return <TabNavigationMenuContext.Provider value={value}>{children}</TabNavigationMenuContext.Provider>
}

export const useTabNavigationMenu = () => {
  const state = useContext(TabNavigationMenuContext)

  if (!state) {
    throw new Error('The `useTabNavigationMenu` should be wrapped with `TabNavigationMenuProvider`.')
  }

  return state
}
