import { Flex, type FlexProps, media } from 'cdk'
import { parseISO } from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { SPACING_MD_VALUE, SPACING_XL_VALUE } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { capitalize } from 'strings'
import styled from 'styled-components'
import { Text } from 'ui'

const Container = styled(Flex)`
  position: sticky;
  top: 0;

  ${media.sm`
    padding-top: ${SPACING_MD_VALUE};
    margin: 0 -${SPACING_XL_VALUE};
    background: white;
    z-index: 1000;
  `}

  ${media.gtSm`
    border: 0
  `}
`

type Props = {
  day: string
  pt?: FlexProps['$pt']
}

export const AvailabilityDayHeader = ({ day, pt = 0 }: Props) => (
  <Container
    $borderColorName="primary-10"
    $borderSize={2}
    $mdPb={0}
    $mdPl={0}
    $mdPr={8}
    $mdPt={pt}
    $pb={16}
    $pl={32}
    $pr={0}
  >
    <Text data-test-id="availability-day-header" fontWeight="600" kind="paragraph">
      {`${pipe(day, parseISO, format(FormatDateEnum.WEEK_DAY), capitalize)} ${pipe(
        day,
        parseISO,
        format(FormatDateEnum.DATE_MONTH_NAME),
        capitalize,
      )}`}
    </Text>
  </Container>
)

export const AvailabilityDayHeaderOnMultipleLines = ({ day, pt = 0 }: Props) => (
  <Container
    $borderColorName="primary-10"
    $borderSize={2}
    $mdPb={0}
    $mdPl={0}
    $mdPr={8}
    $mdPt={pt}
    $pb={16}
    $pl={32}
    $pr={0}
  >
    <Text data-test-id="availability-day-header" fontWeight="600" kind="paragraph">
      <Flex>{pipe(day, parseISO, format(FormatDateEnum.WEEK_DAY), capitalize)}</Flex>
      <Flex $pt={4}>{pipe(day, parseISO, format(FormatDateEnum.DATE_MONTH_NAME), capitalize)}</Flex>
    </Text>
  </Container>
)
