import { Flex } from 'cdk'
import { Link } from 'react-router-dom-v5-compat'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { CustomMessageWrapper } from '~/domains/chat/components/CustomMessage/CustomMessageWrapper'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { useClinicalTestAnswerIsCompleted, useClinicalTestNameTranslation } from '~/domains/clinicalTests'
import { type ClinicalTestCode } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'

type TitleForTherapistProps = {
  clinicalTestCode: ClinicalTestCode
}

const TitleForTherapist = ({ clinicalTestCode }: TitleForTherapistProps) => {
  const clinicalTestName = useClinicalTestNameTranslation(clinicalTestCode)

  return <Translation id="clinicalTests.chat.therapistSendToPatient.therapist.title" values={{ clinicalTestName }} />
}

type ButtonDoTestProps = {
  clinicalTestAnswerId: string
}

const ButtonDoTest = ({ clinicalTestAnswerId }: ButtonDoTestProps) => {
  const { isCompleted } = useClinicalTestAnswerIsCompleted(clinicalTestAnswerId)
  const trackClick = useTrackEventClick()

  if (isCompleted) {
    return null
  }

  return (
    <Link to={getRoute(`/clinical-test/${clinicalTestAnswerId}`)}>
      <Button
        kind="primary"
        onClick={() => {
          trackClick('clinical-test.chat.patient.start', { clinicalTestAnswerId })
        }}
        size="sm"
      >
        <Translation id="clinicalTests.doTest" />
      </Button>
    </Link>
  )
}

export const MessageClinicalTestTherapistSendToPatient = () => {
  const isPatient = useUserActingAsGroup() === 'patient'
  const { message } = useChatMessage<'SYSTEM_CLINICAL_TEST_THERAPIST_SEND_TO_PATIENT'>()

  if (!message.srns?.payload) {
    return null
  }

  const { clinicalTestCode, clinicalTestAnswerId, patientFirstName, therapistFullName } = message.srns.payload

  if (isPatient) {
    return (
      <CustomMessageWrapper
        background="blue-20"
        borderColor="blue-40"
        testId="system-message-clinical-test-therapist-send-to-patient-patient"
      >
        <Flex $align="flex-start" $gap={12}>
          <Flex>
            <Text as="p" fontWeight="600" kind="paragraph">
              <Translation
                id="clinicalTests.chat.therapistSendToPatient.patient.title"
                values={{ therapistFullName }}
              />
            </Text>
            <Text as="p" kind="paragraph">
              <Translation id="clinicalTests.chat.therapistSendToPatient.patient.body" />
            </Text>
          </Flex>
          <ButtonDoTest clinicalTestAnswerId={clinicalTestAnswerId} />
        </Flex>
      </CustomMessageWrapper>
    )
  }

  return (
    <CustomMessageWrapper testId="system-message-clinical-test-therapist-send-to-patient-therapist">
      <Text as="p" fontWeight="600" kind="paragraph">
        <TitleForTherapist clinicalTestCode={clinicalTestCode} />
      </Text>
      <Text as="p" kind="paragraph">
        <Translation id="clinicalTests.chat.therapistSendToPatient.therapist.body" values={{ patientFirstName }} />
      </Text>
    </CustomMessageWrapper>
  )
}
