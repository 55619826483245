import { Flex, MountOn, Space } from 'cdk'
import { Link, Text, type TextProps } from 'ui'
import { Translation } from '~/components/Translation'

const url = 'https://help.serenis.it/it/articles/8238708-come-funzionano-i-percorsi'

type Props = {
  textAlign: TextProps['textAlign']
}

export const StartTherapyPathFaq = ({ textAlign }: Props) => (
  <>
    <MountOn mediaQuery="gt-sm">
      <Flex $pt={16}>
        <Text kind="paragraph" textAlign={textAlign}>
          <Translation id="therapies.startTherapyPath.description" />
          <Space />
          <Link href={url} target="_blank">
            <Text fontWeight="600" kind="caption" textDecoration="underline">
              <Translation id="therapies.startTherapyPath.goToFaq" />
            </Text>
          </Link>
        </Text>
      </Flex>
    </MountOn>

    <MountOn mediaQuery="sm">
      <Flex $gap={8} $pt={16}>
        <Text kind="paragraph">
          <Translation id="therapies.startTherapyPath.description.1" />
        </Text>
        <Text kind="paragraph">
          <Translation id="therapies.startTherapyPath.description.2" />
          <Space />
          <Link href={url} target="_blank">
            <Text fontWeight="600" kind="caption" textDecoration="underline">
              <Translation id="therapies.startTherapyPath.goToFaq" />
            </Text>
          </Link>
        </Text>
      </Flex>
    </MountOn>
  </>
)
