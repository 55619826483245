import { type ComponentProps } from 'react'
import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'

type Props = Pick<ComponentProps<typeof ReactHookFormTextFieldNew>, 'helperText' | 'readOnly'>

export const FieldEmailTextFieldNew = ({ helperText, readOnly }: Props) => {
  const { isEmail } = useFormValidation()
  const {
    errors: { required },
    labels,
  } = useFormTranslations()

  return (
    <ReactHookFormTextFieldNew
      autoComplete="email"
      helperText={helperText}
      name="email"
      placeholder={labels.email}
      readOnly={readOnly}
      rules={{
        required,
        validate: isEmail,
      }}
      type="email"
    />
  )
}
