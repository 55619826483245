import { isSameDay, isSunday } from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, Form, PageLayout, Pressable } from 'ui-deprecated'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods'
import { useOtpCodeRetriever } from '~/domains/phoneNumbers/hooks/useOtpCodeRetriever'
import { usePhoneVerificationContext } from '~/domains/phoneNumbers/hooks/usePhoneVerificationContext'
import { formatPhoneNumber } from '~/domains/phoneNumbers/utils/formatPhoneNumber'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { FieldPhoneVerificationCodeNew } from '~/domains/react-hook-form/fields/FieldPhoneVerificationCodeNew'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useRootLocation } from '~/hooks/useRootHistory'
import { type TranslationId } from '~/i18n/types'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { getRoute } from '~/utils/getRoute'
import { Head } from '../components/Head'
import { hardcodedUnavailabilities } from '../constants'
import { useTherapyPathById } from '../hooks/useTherapyPathById'
import { useUserHasAttributionScoreAlertQuery } from '../hooks/useUserHasAttributionScoreAlertQuery'
import { type ReservationFormValues } from '../types'
import { getReservationRoutes } from '../utils/getReservationRoutes'

export const CheckVerificationCode = () => {
  const { checkVerificationCode, countdown, loading, sendVerificationCode, sending } = usePhoneVerificationContext()
  const form = useReactHookFormContext<ReservationFormValues>()
  const history = useHistory()
  const { fetchUserHasAttributionScoreAlert } = useUserHasAttributionScoreAlertQuery()
  const phoneNumber = form.getValues()['phoneNumber']
  const phoneNumberFormatted = formatPhoneNumber(phoneNumber ?? '')

  const trackEventClick = useTrackEventClick()

  const { id: userId } = useCurrentUser()

  const { pathname } = useRootLocation()

  const { therapyPath } = useTherapyPathById()

  const isPsychiatryPath = therapyPath.type === 'MYSELF_PSYCHIATRY'
  const isUnderagePath = therapyPath.type === 'UNDERAGE_PSYCHOTHERAPY'
  const isSexologyPath = therapyPath.type === 'PATH_SEXOLOGY'

  const isChangeTherapistBooking = !!matchPath(pathname, {
    path: getRoute('/change-therapist/:therapyId/booking'),
  })

  const { isVariant1 } = useFeatureFlagsByUserId()

  const ffSkipCreditCardBookingVariant1 = isVariant1('ff_skip_credit_card_booking')
  const ffSCreditCardLeadScoringVariant1 = isVariant1('ff_credit_card_lead_scoring')

  const { items, loading: itemsLoading } = usePaymentMethods()

  const onSubmit = useCallback(
    async ({ phoneVerificationCode, selectedTimeSlot }: ReservationFormValues) => {
      if (!phoneVerificationCode || !selectedTimeSlot || form.formState.isSubmitting || itemsLoading) {
        return
      }

      const { ok } = await checkVerificationCode(phoneVerificationCode, false)

      if (!ok) {
        return
      }

      if (ffSkipCreditCardBookingVariant1 || isChangeTherapistBooking) {
        history.push(getReservationRoutes('/submit'))

        return
      }

      if (items.length) {
        history.push(getReservationRoutes('/submit'))

        return
      }

      if (isPsychiatryPath || isUnderagePath || isSexologyPath) {
        history.push(getReservationRoutes('/insert-billing-information'))

        return
      }

      const isHardcodedUnavailability = hardcodedUnavailabilities.some((unavailableTimeSlot) =>
        isSameDay(selectedTimeSlot, unavailableTimeSlot),
      )

      const isFestive = pipe(selectedTimeSlot, isSunday)

      if (isHardcodedUnavailability || isFestive) {
        trackEventClick('reservation.hardcoded-unavailability-selected', {
          timeslot: pipe(selectedTimeSlot, format(FormatDateEnum.ATOM)),
          userId,
        })

        history.push(getReservationRoutes('/insert-billing-information'))

        return
      }

      try {
        const userHasAttributionScoreAlertResponse = await fetchUserHasAttributionScoreAlert()

        if (userHasAttributionScoreAlertResponse?.data?.userHasAttributionScoreAlert) {
          if (ffSCreditCardLeadScoringVariant1) {
            history.push(getReservationRoutes('/submit'))

            return
          }

          history.push(getReservationRoutes('/insert-billing-information'))

          return
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}

      history.push(getReservationRoutes('/submit'))
    },
    [
      checkVerificationCode,
      fetchUserHasAttributionScoreAlert,
      ffSCreditCardLeadScoringVariant1,
      ffSkipCreditCardBookingVariant1,
      form,
      history,
      isChangeTherapistBooking,
      isPsychiatryPath,
      isSexologyPath,
      isUnderagePath,
      items,
      itemsLoading,
      trackEventClick,
      userId,
    ],
  )

  const handleRetry = useCallback(async () => {
    if (!phoneNumber) {
      return
    }

    await sendVerificationCode(phoneNumber)
  }, [phoneNumber, sendVerificationCode])

  useOtpCodeRetriever({
    onOk: checkVerificationCode,
  })

  const countdownTranslationId: Extract<TranslationId, `reservation.checkVerification.waitBeforeRetry.${string}`> =
    countdown === 1
      ? 'reservation.checkVerification.waitBeforeRetry.singular'
      : 'reservation.checkVerification.waitBeforeRetry.plural'

  return (
    <>
      <PageScrollEffect />

      <PageRoute id="reservation.check-verification-code">
        <PageLayout maxWidth="640px" px={24}>
          <Head translationId="reservation.checkVerificationCode" />

          <Flex pb={32}>
            <Form grow={1} id="reservationFlow" onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
              <Text fontWeight="600" kind="h2">
                <Translation id="reservation.checkVerification.title" />
              </Text>

              <TranslationMarkdown
                colorName="black"
                id="reservation.checkVerification.body"
                kind="paragraph"
                pt={16}
                values={{ phoneNumber: phoneNumberFormatted }}
              />

              <Flex pt={24}>
                <FieldPhoneVerificationCodeNew />
              </Flex>

              <Flex align="center" direction="row" pt={16} wrap="wrap">
                <Flex pr={8} pt={8}>
                  <Text colorName="black" kind="paragraph">
                    <Translation id="reservation.checkVerification.shouldRetry" />
                  </Text>
                </Flex>

                <Flex pt={8}>
                  {sending && (
                    <Text colorName="grey-80" kind="paragraph">
                      <Translation id={countdownTranslationId} values={{ countdown }} />
                    </Text>
                  )}

                  {!sending && (
                    <Pressable autoWidth colorName="purple08" disabled={loading || sending} onClick={handleRetry}>
                      <Text fontWeight="600" kind="paragraph" textDecoration="underline">
                        <Translation id="reservation.checkVerification.resendCode" />
                      </Text>
                    </Pressable>
                  )}
                </Flex>
              </Flex>
            </Form>
          </Flex>
        </PageLayout>
      </PageRoute>
    </>
  )
}
