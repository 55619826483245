import { Flex, Form, MountOn } from 'cdk'
import { useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { ReactHookFormProvider, ReactHookFormTextFieldNew, useReactHookForm } from '~/domains/react-hook-form'
import { FieldPhoneNumberNew } from '~/domains/react-hook-form/fields'
import { FieldEmailWithConfirmation } from '~/domains/react-hook-form/fields/FieldEmailWithConfirmation'
import { FieldPasswordTextFieldNew } from '~/domains/react-hook-form/fields/FieldPasswordTextFieldNew'
import { TextFieldNewHelperText } from '~/domains/react-hook-form/fields/TextFieldNewHelperText'
import { ReferralCode } from '~/domains/referral/components/ReferralCode'
import { useReferralCodeName } from '~/domains/referral/hooks/useReferralCodeName'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useToasts } from '~/hooks/useToasts'
import { type CreatePatientInput } from '~/types/graphql'
import { useSignUp } from '../hooks/useSignUp'
import { useSignupIntent } from '../hooks/useSignupIntent'
import { FloatingButton } from './FloatingButton'
import { SignupFormCTAs } from './SignupFormCTAs'
import { SignupTermsAndConditions } from './SignupTermsAndConditions'
import { TrustpilotWidgetFloating } from './TrustpilotWidgetFloating'

export const SignupForm = () => {
  const { addToast } = useToasts()
  const [errorCount, setErrorCount] = useState<number>(0)

  const { showDefaultLauncher } = useIntercom()

  const referralCode = useReferralCodeName()

  const form = useReactHookForm<CreatePatientInput>()

  const { startLoading, stopLoading } = useLoadingState()

  const intent = useSignupIntent()

  const signUp = useSignUp(intent)

  const trackFailure = useTrackEvent('FAILURE')

  const {
    errors: { required },
    helperTexts,
    labels,
    placeholders,
  } = useFormTranslations()

  const onSubmit = useCallback(
    async (values: CreatePatientInput) => {
      startLoading('local')
      try {
        await signUp({ ...values, referralCode })
      } catch (error) {
        setErrorCount((i) => i + 1)

        /**
         * @description: The third error triggers Intercom visibility. State is updated at next cycle
         */
        if (errorCount > 1) {
          showDefaultLauncher()
        }

        trackFailure({ name: 'failures.auth.signup' })

        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      } finally {
        stopLoading()
      }
    },
    [startLoading, signUp, referralCode, errorCount, trackFailure, addToast, showDefaultLauncher, stopLoading],
  )

  const { ref, inView } = useInView({
    threshold: 0.5,
  })

  return (
    <ReactHookFormProvider {...form}>
      <Form $grow={1} onSubmit={form.handleSubmit(onSubmit)}>
        {referralCode && (
          <Flex $pb={16}>
            <ReferralCode status="REQUESTED" />
          </Flex>
        )}
        <Flex $grow={1} $justify="space-between">
          <Flex>
            <ReactHookFormTextFieldNew
              autoComplete="given-name"
              name="firstName"
              placeholder={placeholders.firstName}
              rules={{ required }}
              size="small"
              type="text"
            />

            <Flex $pt={16}>
              <ReactHookFormTextFieldNew
                autoComplete="family-name"
                name="lastName"
                placeholder={placeholders.lastName}
                rules={{ required }}
                size="small"
                type="text"
              />
              <TextFieldNewHelperText helperText={helperTexts.surname} />
            </Flex>

            <Flex $pt={16}>
              <FieldEmailWithConfirmation />
            </Flex>

            {intent === 'call' && (
              <Flex $pt={16}>
                <FieldPhoneNumberNew size="small" />
              </Flex>
            )}

            <Flex $pt={16}>
              <FieldPasswordTextFieldNew
                autoComplete="current-password"
                label={labels.password}
                name="password"
                size="small"
              />
              <TextFieldNewHelperText helperText={helperTexts.password} />
            </Flex>

            <Flex $pt={32}>
              <Text colorName="neutral-60" fontWeight="600" kind="paragraph">
                <Translation id="forms.labels.consent" />
              </Text>
            </Flex>

            <Flex $pt={24}>
              <SignupTermsAndConditions />
            </Flex>
          </Flex>

          <Flex ref={ref} $pt={32}>
            <MountOn mediaQuery="lt-lg">
              <TrustpilotWidgetFloating />
            </MountOn>

            <SignupFormCTAs />
          </Flex>
        </Flex>

        <FloatingButton isVisible={!inView} />
      </Form>
    </ReactHookFormProvider>
  )
}
