import { useMemo } from 'react'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { getFormFlowRoute } from '~/domains/formFlow/utils/getFormFlowRoute'
import { usePartnershipByUtmSource } from '~/hooks/usePartnership'
import { type FormFlowsQuery } from '~/types/graphql'
import { usePrefilledForWhomType } from '../hooks/usePrefilledForWhomType'

type Props = {
  formFlows: FormFlowsQuery['formFlows']
  initialEntries?: string[]
}

export const useMemoryRouterInitialOptions = ({ formFlows, initialEntries }: Props) => {
  const { isVariant1 } = useFeatureFlagsByFingerprint()
  const partnership = usePartnershipByUtmSource()
  const prefilledForWhomType = usePrefilledForWhomType()
  const isRestyleSurvey = isVariant1('ff_restyle_survey')
  const introduction = formFlows.find(({ type }) => type === 'INTRODUCTION')

  return useMemo(() => {
    if (isRestyleSurvey) {
      const prefilledWelcomeOrIntroduction =
        prefilledForWhomType === null && partnership === null
          ? [getFormFlowRoute(`/${introduction?.id}/${introduction?.steps[0].id}`)]
          : [getFormFlowRoute('/welcome')]

      return {
        initialEntries: initialEntries ?? prefilledWelcomeOrIntroduction,
        initialIndex: 0,
      }
    }

    return {
      initialEntries: [getFormFlowRoute('/welcome')],
      initialIndex: 0,
    }
  }, [initialEntries, introduction?.id, introduction?.steps, isRestyleSurvey, partnership, prefilledForWhomType])
}
