import { gql, useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import { type TherapistAgendaByPatientIdQuery, type TherapistAgendaByPatientIdQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query therapistAgendaByPatientId($patientId: String!) {
    therapistAgendaByPatientId(patientId: $patientId) {
      bonus
      churn
      duration
      endAt
      endAtSlot
      free
      id
      notes
      parentTherapySessionId
      patient {
        fullName
        firstName
        email
        phoneNumber
        id
      }
      provider
      recurrency
      repeatEveryWeek
      startAt
      status
      therapist {
        fullName
        id
      }
      therapyPathType
      typeCost
      url
    }
  }
`

export const useTherapistAgendaByPatientId = (
  patientId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
) => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    TherapistAgendaByPatientIdQuery,
    TherapistAgendaByPatientIdQueryVariables
  >(QUERY, {
    variables: {
      patientId,
    },
    fetchPolicy,
  })

  const items = data?.therapistAgendaByPatientId || []

  return {
    error: !!error,
    events: items.map(({ startAt }) => [startAt]),
    items,
    loading,
    refetch,
    updateQuery,
  }
}
