import { gql, useMutation } from '@apollo/client'
import { type DeleteTherapySessionMutation, type DeleteTherapySessionMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation deleteTherapySession($input: DeleteTherapySessionInput!) {
    deleteTherapySession(input: $input) {
      id
    }
  }
`

export const useDeleteTherapySession = () =>
  useMutation<DeleteTherapySessionMutation, DeleteTherapySessionMutationVariables>(MUTATION)
