import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyProfileStylesPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.ProfileStylesPageView,
})

export const ProfileStylesPage: Page = {
  exact: true,
  id: 'settings.styles',
  paths: ['/profile/styles'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'settings.styles',
    title: 'settings.styles.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertTherapist target="/">{children}</AssertTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyProfileStylesPageView />,
}
