import { range } from 'arrays'
import { Flex } from 'cdk'
import { type IconProps } from 'icons'
import { useCallback, useState } from 'react'
import { RatingStar } from '~/components/RatingStar'

type Props = {
  initialRating?: number
  onRate?: (value: number) => void
  size?: IconProps['size']
}

const ratings = range(5, (value) => value + 1)

export const Rating = ({ initialRating = 0, onRate, size = 56 }: Props) => {
  const [rating, setRating] = useState(initialRating)

  const disabled = !onRate

  const onClick = useCallback(
    (value: number) => {
      if (disabled) {
        return
      }

      setRating(value)

      onRate(value)
    },
    [disabled, onRate],
  )

  return (
    <Flex $align="center" $direction="row">
      {ratings.map((value) => (
        <Flex key={value} $pl={value === 1 ? 0 : 8}>
          <RatingStar disabled={disabled} onRate={onClick} selected={value <= rating} size={size} value={value} />
        </Flex>
      ))}
    </Flex>
  )
}
