import { gql, useMutation } from '@apollo/client'
import { type UpsertCustomerMutation, type UpsertCustomerMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation upsertCustomer($input: UpsertCustomerInput!) {
    upsertCustomer(input: $input) {
      ok
    }
  }
`

export const useUpsertCustomer = () => useMutation<UpsertCustomerMutation, UpsertCustomerMutationVariables>(MUTATION)
