import * as Sentry from '@sentry/nextjs'
import { getSessionStorage } from '~/utils/sessionStorage/getSessionStorage'

export const usePartnershipQueryParam = () => {
  const queryParam = getSessionStorage('query-params')

  if (!queryParam) {
    return null
  }

  try {
    const firstLandingPage = JSON.parse(queryParam).firstLandingPage

    if (!firstLandingPage) {
      return null
    }

    const [, partnership] = firstLandingPage.match(/partnership\/([^/]+)$/) || []

    return partnership || null
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'library',
        library: 'url-parser',
      },
    })

    return null
  }
}
