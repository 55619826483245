import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyNotificationsJournalingPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.NotificationsJournalingPageView,
})

export const NotificationsJournalingPage: Page = {
  exact: true,
  id: 'notifications.journaling',
  paths: ['/notifications/journaling'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'notifications.journaling',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyNotificationsJournalingPageView />,
}
