import { Route } from 'react-router-dom'
import { FlowMemoryRouterTransition } from '~/components/Flow/FlowMemoryRouterTransition'
import { FlowWrapper } from '~/components/Flow/FlowWrapper'
import { ChangeTherapistLeaveModal } from '~/domains/changeTherapist/components/ChangeTherapistLeaveModal'
import { ThankYouPageView } from '~/domains/reservation/pages/ThankYouPage'
import { ScrollRestoreProvider } from '~/hooks/useScrollRestore'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { ReservationFlowProgressBar } from '../components/ReservationFlowProgressBar'
import { ReservationFooter } from '../components/ReservationFooter'
import { ReservationImpressionTracker } from '../components/ReservationImpressionTracker'
import { getReservationRoutes } from '../utils/getReservationRoutes'
import { BookBySuggestedTherapist } from './BookBySuggestedTherapists'
import { CheckVerificationCode } from './CheckVerificationCode'
import { NoMatchedTherapists } from './NoMatchedTherapists'
import { SendVerificationCode } from './SendVerificationCode'
import { SkipBooking } from './SkipBooking'
import { SkipBookingCoaching } from './SkipBookingCoaching'
import { SkipBookingPsychiatry } from './SkipBookingPsychiatry'
import { StepBilling } from './StepBilling'
import { StepPaymentAdd } from './StepPaymentAdd'
import { SubmitBooking } from './SubmitBooking'
import { TherapyAlreadyOngoing } from './TherapyAlreadyOngoing'

type Props = {
  therapyPathType?: TherapyTherapyPathType
  therapistFullName?: string
  therapistId?: string
  therapyId?: string
}

export const ReservationRoutes = ({ therapyPathType, therapistFullName, therapistId, therapyId }: Props) => (
  <FlowWrapper>
    <ReservationFlowProgressBar />
    <ChangeTherapistLeaveModal />
    <ScrollRestoreProvider background="white" pb={0}>
      <FlowMemoryRouterTransition>
        <Route exact path={getReservationRoutes('/therapy-already-ongoing')}>
          <TherapyAlreadyOngoing
            therapistFullName={therapistFullName}
            therapistId={therapistId}
            therapyId={therapyId}
            therapyPathType={therapyPathType}
          />
        </Route>

        <Route exact path={getReservationRoutes('/book-by-suggested-therapist')}>
          <ReservationImpressionTracker />
          <BookBySuggestedTherapist />
        </Route>

        <Route exact path={getReservationRoutes('/send-verification-code')}>
          <SendVerificationCode />
        </Route>

        <Route exact path={getReservationRoutes('/check-verification-code')}>
          <CheckVerificationCode />
        </Route>

        <Route path={getReservationRoutes('/submit')}>
          <SubmitBooking />
        </Route>

        <Route exact path={getReservationRoutes('/insert-billing-information')}>
          <StepBilling />
        </Route>

        <Route exact path={getReservationRoutes('/insert-payment-method')}>
          <StepPaymentAdd />
        </Route>

        <Route exact path={getReservationRoutes('/skip-booking')}>
          <SkipBooking />
        </Route>

        <Route exact path={getReservationRoutes('/skip-booking/coaching')}>
          <SkipBookingCoaching />
        </Route>

        <Route exact path={getReservationRoutes('/skip-booking/psychiatry')}>
          <SkipBookingPsychiatry />
        </Route>

        <Route exact path={getReservationRoutes('/no-matched-therapists')}>
          <ReservationImpressionTracker />
          <NoMatchedTherapists />
        </Route>

        <Route exact path={getReservationRoutes('/thank-you-page')}>
          <ThankYouPageView />
        </Route>
      </FlowMemoryRouterTransition>
    </ScrollRestoreProvider>
    <ReservationFooter />
  </FlowWrapper>
)
