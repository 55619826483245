import { useCurrentUserNullable } from '~/hooks/useCurrentUser'

export const useCurrentUserProfessionTypeValue = () => {
  const { professionTypeValues } = useCurrentUserNullable()

  return {
    isCoach: !!professionTypeValues?.includes('COACH'),
    isNutritionist:
      !!professionTypeValues?.includes('NUTRITIONIST_WEIGHT_LOSS') ||
      !!professionTypeValues?.includes('NUTRITIONIST_DCA'),
    isPsychiatrist: !!professionTypeValues?.includes('PSYCHIATRIST'),
    isPsychotherapist: !!professionTypeValues?.includes('PSYCHOTHERAPIST'),
    isSexologist: !!professionTypeValues?.includes('SEXOLOGIST'),
  }
}
