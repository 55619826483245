import { type AuthenticatedUserGroup, authenticatedUserHasGroup } from 'acl'
import { pipe } from 'fp-ts/function'
import { useAuthState } from '../components/AuthStateProvider'

export const useUserActingAsGroup = (): Extract<AuthenticatedUserGroup, 'therapist' | 'patient' | 'saas-therapist'> => {
  const { accessTokenPayload } = useAuthState()

  // Note: default to patient if token is missing is actual behaviour
  if (!accessTokenPayload) {
    return 'patient'
  }

  if (pipe(accessTokenPayload, authenticatedUserHasGroup(['therapist']))) {
    return 'therapist'
  }

  if (pipe(accessTokenPayload, authenticatedUserHasGroup(['saas-therapist']))) {
    return 'saas-therapist'
  }

  return 'patient'
}
