import {
  registerDecorator,
  type ValidationOptions,
  ValidatorConstraint,
  type ValidatorConstraintInterface,
} from 'class-validator'

type TimeZone = {
  code: string
  name: string
}

export const timeZones: TimeZone[] = [
  { code: 'Africa/Abidjan', name: 'Africa/Abidjan GMT+0:00' },
  { code: 'Africa/Accra', name: 'Africa/Accra GMT+0:00' },
  { code: 'Africa/Addis_Ababa', name: 'Africa/Addis_Ababa GMT+3:00' },
  { code: 'Africa/Algiers', name: 'Africa/Algiers GMT+1:00' },
  { code: 'Africa/Asmara', name: 'Africa/Asmara GMT+3:00' },
  { code: 'Africa/Asmera', name: 'Africa/Asmera GMT+3:00' },
  { code: 'Africa/Bamako', name: 'Africa/Bamako GMT+0:00' },
  { code: 'Africa/Bangui', name: 'Africa/Bangui GMT+1:00' },
  { code: 'Africa/Banjul', name: 'Africa/Banjul GMT+0:00' },
  { code: 'Africa/Bissau', name: 'Africa/Bissau GMT+0:00' },
  { code: 'Africa/Blantyre', name: 'Africa/Blantyre GMT+2:00' },
  { code: 'Africa/Brazzaville', name: 'Africa/Brazzaville GMT+1:00' },
  { code: 'Africa/Bujumbura', name: 'Africa/Bujumbura GMT+2:00' },
  { code: 'Africa/Cairo', name: 'Africa/Cairo GMT+2:00' },
  { code: 'Africa/Casablanca', name: 'Africa/Casablanca GMT+0:00' },
  { code: 'Africa/Ceuta', name: 'Africa/Ceuta GMT+1:00' },
  { code: 'Africa/Conakry', name: 'Africa/Conakry GMT+0:00' },
  { code: 'Africa/Dakar', name: 'Africa/Dakar GMT+0:00' },
  { code: 'Africa/Dar_es_Salaam', name: 'Africa/Dar_es_Salaam GMT+3:00' },
  { code: 'Africa/Djibouti', name: 'Africa/Djibouti GMT+3:00' },
  { code: 'Africa/Douala', name: 'Africa/Douala GMT+1:00' },
  { code: 'Africa/El_Aaiun', name: 'Africa/El_Aaiun GMT+0:00' },
  { code: 'Africa/Freetown', name: 'Africa/Freetown GMT+0:00' },
  { code: 'Africa/Gaborone', name: 'Africa/Gaborone GMT+2:00' },
  { code: 'Africa/Harare', name: 'Africa/Harare GMT+2:00' },
  { code: 'Africa/Johannesburg', name: 'Africa/Johannesburg GMT+2:00' },
  { code: 'Africa/Juba', name: 'Africa/Juba GMT+3:00' },
  { code: 'Africa/Kampala', name: 'Africa/Kampala GMT+3:00' },
  { code: 'Africa/Khartoum', name: 'Africa/Khartoum GMT+2:00' },
  { code: 'Africa/Kigali', name: 'Africa/Kigali GMT+2:00' },
  { code: 'Africa/Kinshasa', name: 'Africa/Kinshasa GMT+1:00' },
  { code: 'Africa/Lagos', name: 'Africa/Lagos GMT+1:00' },
  { code: 'Africa/Libreville', name: 'Africa/Libreville GMT+1:00' },
  { code: 'Africa/Lome', name: 'Africa/Lome GMT+0:00' },
  { code: 'Africa/Luanda', name: 'Africa/Luanda GMT+1:00' },
  { code: 'Africa/Lubumbashi', name: 'Africa/Lubumbashi GMT+2:00' },
  { code: 'Africa/Lusaka', name: 'Africa/Lusaka GMT+2:00' },
  { code: 'Africa/Malabo', name: 'Africa/Malabo GMT+1:00' },
  { code: 'Africa/Maputo', name: 'Africa/Maputo GMT+2:00' },
  { code: 'Africa/Maseru', name: 'Africa/Maseru GMT+2:00' },
  { code: 'Africa/Mbabane', name: 'Africa/Mbabane GMT+2:00' },
  { code: 'Africa/Mogadishu', name: 'Africa/Mogadishu GMT+3:00' },
  { code: 'Africa/Monrovia', name: 'Africa/Monrovia GMT+0:00' },
  { code: 'Africa/Nairobi', name: 'Africa/Nairobi GMT+3:00' },
  { code: 'Africa/Ndjamena', name: 'Africa/Ndjamena GMT+1:00' },
  { code: 'Africa/Niamey', name: 'Africa/Niamey GMT+1:00' },
  { code: 'Africa/Nouakchott', name: 'Africa/Nouakchott GMT+0:00' },
  { code: 'Africa/Ouagadougou', name: 'Africa/Ouagadougou GMT+0:00' },
  { code: 'Africa/Porto-Novo', name: 'Africa/Porto-Novo GMT+1:00' },
  { code: 'Africa/Sao_Tome', name: 'Africa/Sao_Tome GMT+0:00' },
  { code: 'Africa/Timbuktu', name: 'Africa/Timbuktu GMT+0:00' },
  { code: 'Africa/Tripoli', name: 'Africa/Tripoli GMT+2:00' },
  { code: 'Africa/Tunis', name: 'Africa/Tunis GMT+1:00' },
  { code: 'Africa/Windhoek', name: 'Africa/Windhoek GMT+2:00' },
  { code: 'America/Adak', name: 'America/Adak GMT-10:00' },
  { code: 'America/Anchorage', name: 'America/Anchorage GMT-9:00' },
  { code: 'America/Anguilla', name: 'America/Anguilla GMT-4:00' },
  { code: 'America/Antigua', name: 'America/Antigua GMT-4:00' },
  { code: 'America/Araguaina', name: 'America/Araguaina GMT-3:00' },
  { code: 'America/Argentina/Buenos_Aires', name: 'America/Argentina/Buenos_Aires GMT-3:00' },
  { code: 'America/Argentina/Catamarca', name: 'America/Argentina/Catamarca GMT-3:00' },
  { code: 'America/Argentina/ComodRivadavia', name: 'America/Argentina/ComodRivadavia GMT-3:00' },
  { code: 'America/Argentina/Cordoba', name: 'America/Argentina/Cordoba GMT-3:00' },
  { code: 'America/Argentina/Jujuy', name: 'America/Argentina/Jujuy GMT-3:00' },
  { code: 'America/Argentina/La_Rioja', name: 'America/Argentina/La_Rioja GMT-3:00' },
  { code: 'America/Argentina/Mendoza', name: 'America/Argentina/Mendoza GMT-3:00' },
  { code: 'America/Argentina/Rio_Gallegos', name: 'America/Argentina/Rio_Gallegos GMT-3:00' },
  { code: 'America/Argentina/Salta', name: 'America/Argentina/Salta GMT-3:00' },
  { code: 'America/Argentina/San_Juan', name: 'America/Argentina/San_Juan GMT-3:00' },
  { code: 'America/Argentina/San_Luis', name: 'America/Argentina/San_Luis GMT-3:00' },
  { code: 'America/Argentina/Tucuman', name: 'America/Argentina/Tucuman GMT-3:00' },
  { code: 'America/Argentina/Ushuaia', name: 'America/Argentina/Ushuaia GMT-3:00' },
  { code: 'America/Aruba', name: 'America/Aruba GMT-4:00' },
  { code: 'America/Asuncion', name: 'America/Asuncion GMT-4:00' },
  { code: 'America/Atikokan', name: 'America/Atikokan GMT-5:00' },
  { code: 'America/Atka', name: 'America/Atka GMT-10:00' },
  { code: 'America/Bahia', name: 'America/Bahia GMT-3:00' },
  { code: 'America/Bahia_Banderas', name: 'America/Bahia_Banderas GMT-6:00' },
  { code: 'America/Barbados', name: 'America/Barbados GMT-4:00' },
  { code: 'America/Belem', name: 'America/Belem GMT-3:00' },
  { code: 'America/Belize', name: 'America/Belize GMT-6:00' },
  { code: 'America/Blanc-Sablon', name: 'America/Blanc-Sablon GMT-4:00' },
  { code: 'America/Boa_Vista', name: 'America/Boa_Vista GMT-4:00' },
  { code: 'America/Bogota', name: 'America/Bogota GMT-5:00' },
  { code: 'America/Boise', name: 'America/Boise GMT-7:00' },
  { code: 'America/Buenos_Aires', name: 'America/Buenos_Aires GMT-3:00' },
  { code: 'America/Cambridge_Bay', name: 'America/Cambridge_Bay GMT-7:00' },
  { code: 'America/Campo_Grande', name: 'America/Campo_Grande GMT-4:00' },
  { code: 'America/Cancun', name: 'America/Cancun GMT-5:00' },
  { code: 'America/Caracas', name: 'America/Caracas GMT-4:00' },
  { code: 'America/Catamarca', name: 'America/Catamarca GMT-3:00' },
  { code: 'America/Cayenne', name: 'America/Cayenne GMT-3:00' },
  { code: 'America/Cayman', name: 'America/Cayman GMT-5:00' },
  { code: 'America/Chicago', name: 'America/Chicago GMT-6:00' },
  { code: 'America/Chihuahua', name: 'America/Chihuahua GMT-7:00' },
  { code: 'America/Coral_Harbour', name: 'America/Coral_Harbour GMT-5:00' },
  { code: 'America/Cordoba', name: 'America/Cordoba GMT-3:00' },
  { code: 'America/Costa_Rica', name: 'America/Costa_Rica GMT-6:00' },
  { code: 'America/Creston', name: 'America/Creston GMT-7:00' },
  { code: 'America/Cuiaba', name: 'America/Cuiaba GMT-4:00' },
  { code: 'America/Curacao', name: 'America/Curacao GMT-4:00' },
  { code: 'America/Danmarkshavn', name: 'America/Danmarkshavn GMT+0:00' },
  { code: 'America/Dawson', name: 'America/Dawson GMT-8:00' },
  { code: 'America/Dawson_Creek', name: 'America/Dawson_Creek GMT-7:00' },
  { code: 'America/Denver', name: 'America/Denver GMT-7:00' },
  { code: 'America/Detroit', name: 'America/Detroit GMT-5:00' },
  { code: 'America/Dominica', name: 'America/Dominica GMT-4:00' },
  { code: 'America/Edmonton', name: 'America/Edmonton GMT-7:00' },
  { code: 'America/Eirunepe', name: 'America/Eirunepe GMT-5:00' },
  { code: 'America/El_Salvador', name: 'America/El_Salvador GMT-6:00' },
  { code: 'America/Ensenada', name: 'America/Ensenada GMT-8:00' },
  { code: 'America/Fort_Nelson', name: 'America/Fort_Nelson GMT-7:00' },
  { code: 'America/Fort_Wayne', name: 'America/Fort_Wayne GMT-5:00' },
  { code: 'America/Fortaleza', name: 'America/Fortaleza GMT-3:00' },
  { code: 'America/Glace_Bay', name: 'America/Glace_Bay GMT-4:00' },
  { code: 'America/Godthab', name: 'America/Godthab GMT-3:00' },
  { code: 'America/Goose_Bay', name: 'America/Goose_Bay GMT-4:00' },
  { code: 'America/Grand_Turk', name: 'America/Grand_Turk GMT-5:00' },
  { code: 'America/Grenada', name: 'America/Grenada GMT-4:00' },
  { code: 'America/Guadeloupe', name: 'America/Guadeloupe GMT-4:00' },
  { code: 'America/Guatemala', name: 'America/Guatemala GMT-6:00' },
  { code: 'America/Guayaquil', name: 'America/Guayaquil GMT-5:00' },
  { code: 'America/Guyana', name: 'America/Guyana GMT-4:00' },
  { code: 'America/Halifax', name: 'America/Halifax GMT-4:00' },
  { code: 'America/Havana', name: 'America/Havana GMT-5:00' },
  { code: 'America/Hermosillo', name: 'America/Hermosillo GMT-7:00' },
  { code: 'America/Indiana/Indianapolis', name: 'America/Indiana/Indianapolis GMT-5:00' },
  { code: 'America/Indiana/Knox', name: 'America/Indiana/Knox GMT-6:00' },
  { code: 'America/Indiana/Marengo', name: 'America/Indiana/Marengo GMT-5:00' },
  { code: 'America/Indiana/Petersburg', name: 'America/Indiana/Petersburg GMT-5:00' },
  { code: 'America/Indiana/Tell_City', name: 'America/Indiana/Tell_City GMT-6:00' },
  { code: 'America/Indiana/Vevay', name: 'America/Indiana/Vevay GMT-5:00' },
  { code: 'America/Indiana/Vincennes', name: 'America/Indiana/Vincennes GMT-5:00' },
  { code: 'America/Indiana/Winamac', name: 'America/Indiana/Winamac GMT-5:00' },
  { code: 'America/Indianapolis', name: 'America/Indianapolis GMT-5:00' },
  { code: 'America/Inuvik', name: 'America/Inuvik GMT-7:00' },
  { code: 'America/Iqaluit', name: 'America/Iqaluit GMT-5:00' },
  { code: 'America/Jamaica', name: 'America/Jamaica GMT-5:00' },
  { code: 'America/Jujuy', name: 'America/Jujuy GMT-3:00' },
  { code: 'America/Juneau', name: 'America/Juneau GMT-9:00' },
  { code: 'America/Kentucky/Louisville', name: 'America/Kentucky/Louisville GMT-5:00' },
  { code: 'America/Kentucky/Monticello', name: 'America/Kentucky/Monticello GMT-5:00' },
  { code: 'America/Knox_IN', name: 'America/Knox_IN GMT-6:00' },
  { code: 'America/Kralendijk', name: 'America/Kralendijk GMT-4:00' },
  { code: 'America/La_Paz', name: 'America/La_Paz GMT-4:00' },
  { code: 'America/Lima', name: 'America/Lima GMT-5:00' },
  { code: 'America/Los_Angeles', name: 'America/Los_Angeles GMT-8:00' },
  { code: 'America/Louisville', name: 'America/Louisville GMT-5:00' },
  { code: 'America/Lower_Princes', name: 'America/Lower_Princes GMT-4:00' },
  { code: 'America/Maceio', name: 'America/Maceio GMT-3:00' },
  { code: 'America/Managua', name: 'America/Managua GMT-6:00' },
  { code: 'America/Manaus', name: 'America/Manaus GMT-4:00' },
  { code: 'America/Marigot', name: 'America/Marigot GMT-4:00' },
  { code: 'America/Martinique', name: 'America/Martinique GMT-4:00' },
  { code: 'America/Matamoros', name: 'America/Matamoros GMT-6:00' },
  { code: 'America/Mazatlan', name: 'America/Mazatlan GMT-7:00' },
  { code: 'America/Mendoza', name: 'America/Mendoza GMT-3:00' },
  { code: 'America/Menominee', name: 'America/Menominee GMT-6:00' },
  { code: 'America/Merida', name: 'America/Merida GMT-6:00' },
  { code: 'America/Metlakatla', name: 'America/Metlakatla GMT-9:00' },
  { code: 'America/Mexico_City', name: 'America/Mexico_City GMT-6:00' },
  { code: 'America/Miquelon', name: 'America/Miquelon GMT-3:00' },
  { code: 'America/Moncton', name: 'America/Moncton GMT-4:00' },
  { code: 'America/Monterrey', name: 'America/Monterrey GMT-6:00' },
  { code: 'America/Montevideo', name: 'America/Montevideo GMT-3:00' },
  { code: 'America/Montreal', name: 'America/Montreal GMT-5:00' },
  { code: 'America/Montserrat', name: 'America/Montserrat GMT-4:00' },
  { code: 'America/Nassau', name: 'America/Nassau GMT-5:00' },
  { code: 'America/New_York', name: 'America/New_York GMT-5:00' },
  { code: 'America/Nipigon', name: 'America/Nipigon GMT-5:00' },
  { code: 'America/Nome', name: 'America/Nome GMT-9:00' },
  { code: 'America/Noronha', name: 'America/Noronha GMT-2:00' },
  { code: 'America/North_Dakota/Beulah', name: 'America/North_Dakota/Beulah GMT-6:00' },
  { code: 'America/North_Dakota/Center', name: 'America/North_Dakota/Center GMT-6:00' },
  { code: 'America/North_Dakota/New_Salem', name: 'America/North_Dakota/New_Salem GMT-6:00' },
  { code: 'America/Ojinaga', name: 'America/Ojinaga GMT-7:00' },
  { code: 'America/Panama', name: 'America/Panama GMT-5:00' },
  { code: 'America/Pangnirtung', name: 'America/Pangnirtung GMT-5:00' },
  { code: 'America/Paramaribo', name: 'America/Paramaribo GMT-3:00' },
  { code: 'America/Phoenix', name: 'America/Phoenix GMT-7:00' },
  { code: 'America/Port-au-Prince', name: 'America/Port-au-Prince GMT-5:00' },
  { code: 'America/Port_of_Spain', name: 'America/Port_of_Spain GMT-4:00' },
  { code: 'America/Porto_Acre', name: 'America/Porto_Acre GMT-5:00' },
  { code: 'America/Porto_Velho', name: 'America/Porto_Velho GMT-4:00' },
  { code: 'America/Puerto_Rico', name: 'America/Puerto_Rico GMT-4:00' },
  { code: 'America/Punta_Arenas', name: 'America/Punta_Arenas GMT-3:00' },
  { code: 'America/Rainy_River', name: 'America/Rainy_River GMT-6:00' },
  { code: 'America/Rankin_Inlet', name: 'America/Rankin_Inlet GMT-6:00' },
  { code: 'America/Recife', name: 'America/Recife GMT-3:00' },
  { code: 'America/Regina', name: 'America/Regina GMT-6:00' },
  { code: 'America/Resolute', name: 'America/Resolute GMT-6:00' },
  { code: 'America/Rio_Branco', name: 'America/Rio_Branco GMT-5:00' },
  { code: 'America/Rosario', name: 'America/Rosario GMT-3:00' },
  { code: 'America/Santa_Isabel', name: 'America/Santa_Isabel GMT-8:00' },
  { code: 'America/Santarem', name: 'America/Santarem GMT-3:00' },
  { code: 'America/Santiago', name: 'America/Santiago GMT-4:00' },
  { code: 'America/Santo_Domingo', name: 'America/Santo_Domingo GMT-4:00' },
  { code: 'America/Sao_Paulo', name: 'America/Sao_Paulo GMT-3:00' },
  { code: 'America/Scoresbysund', name: 'America/Scoresbysund GMT-1:00' },
  { code: 'America/Shiprock', name: 'America/Shiprock GMT-7:00' },
  { code: 'America/Sitka', name: 'America/Sitka GMT-9:00' },
  { code: 'America/St_Barthelemy', name: 'America/St_Barthelemy GMT-4:00' },
  { code: 'America/St_Johns', name: 'America/St_Johns GMT-4:30' },
  { code: 'America/St_Kitts', name: 'America/St_Kitts GMT-4:00' },
  { code: 'America/St_Lucia', name: 'America/St_Lucia GMT-4:00' },
  { code: 'America/St_Thomas', name: 'America/St_Thomas GMT-4:00' },
  { code: 'America/St_Vincent', name: 'America/St_Vincent GMT-4:00' },
  { code: 'America/Swift_Current', name: 'America/Swift_Current GMT-6:00' },
  { code: 'America/Tegucigalpa', name: 'America/Tegucigalpa GMT-6:00' },
  { code: 'America/Thule', name: 'America/Thule GMT-4:00' },
  { code: 'America/Thunder_Bay', name: 'America/Thunder_Bay GMT-5:00' },
  { code: 'America/Tijuana', name: 'America/Tijuana GMT-8:00' },
  { code: 'America/Toronto', name: 'America/Toronto GMT-5:00' },
  { code: 'America/Tortola', name: 'America/Tortola GMT-4:00' },
  { code: 'America/Vancouver', name: 'America/Vancouver GMT-8:00' },
  { code: 'America/Virgin', name: 'America/Virgin GMT-4:00' },
  { code: 'America/Whitehorse', name: 'America/Whitehorse GMT-8:00' },
  { code: 'America/Winnipeg', name: 'America/Winnipeg GMT-6:00' },
  { code: 'America/Yakutat', name: 'America/Yakutat GMT-9:00' },
  { code: 'America/Yellowknife', name: 'America/Yellowknife GMT-7:00' },
  { code: 'Antarctica/Casey', name: 'Antarctica/Casey GMT+8:00' },
  { code: 'Antarctica/Davis', name: 'Antarctica/Davis GMT+7:00' },
  { code: 'Antarctica/DumontDUrville', name: 'Antarctica/DumontDUrville GMT+10:00' },
  { code: 'Antarctica/Macquarie', name: 'Antarctica/Macquarie GMT+11:00' },
  { code: 'Antarctica/Mawson', name: 'Antarctica/Mawson GMT+5:00' },
  { code: 'Antarctica/McMurdo', name: 'Antarctica/McMurdo GMT+12:00' },
  { code: 'Antarctica/Palmer', name: 'Antarctica/Palmer GMT-3:00' },
  { code: 'Antarctica/Rothera', name: 'Antarctica/Rothera GMT-3:00' },
  { code: 'Antarctica/South_Pole', name: 'Antarctica/South_Pole GMT+12:00' },
  { code: 'Antarctica/Syowa', name: 'Antarctica/Syowa GMT+3:00' },
  { code: 'Antarctica/Troll', name: 'Antarctica/Troll GMT+0:00' },
  { code: 'Antarctica/Vostok', name: 'Antarctica/Vostok GMT+6:00' },
  { code: 'Arctic/Longyearbyen', name: 'Arctic/Longyearbyen GMT+1:00' },
  { code: 'Asia/Aden', name: 'Asia/Aden GMT+3:00' },
  { code: 'Asia/Almaty', name: 'Asia/Almaty GMT+6:00' },
  { code: 'Asia/Amman', name: 'Asia/Amman GMT+2:00' },
  { code: 'Asia/Anadyr', name: 'Asia/Anadyr GMT+12:00' },
  { code: 'Asia/Aqtau', name: 'Asia/Aqtau GMT+5:00' },
  { code: 'Asia/Aqtobe', name: 'Asia/Aqtobe GMT+5:00' },
  { code: 'Asia/Ashgabat', name: 'Asia/Ashgabat GMT+5:00' },
  { code: 'Asia/Ashkhabad', name: 'Asia/Ashkhabad GMT+5:00' },
  { code: 'Asia/Atyrau', name: 'Asia/Atyrau GMT+5:00' },
  { code: 'Asia/Baghdad', name: 'Asia/Baghdad GMT+3:00' },
  { code: 'Asia/Bahrain', name: 'Asia/Bahrain GMT+3:00' },
  { code: 'Asia/Baku', name: 'Asia/Baku GMT+4:00' },
  { code: 'Asia/Bangkok', name: 'Asia/Bangkok GMT+7:00' },
  { code: 'Asia/Barnaul', name: 'Asia/Barnaul GMT+7:00' },
  { code: 'Asia/Beirut', name: 'Asia/Beirut GMT+2:00' },
  { code: 'Asia/Bishkek', name: 'Asia/Bishkek GMT+6:00' },
  { code: 'Asia/Brunei', name: 'Asia/Brunei GMT+8:00' },
  { code: 'Asia/Calcutta', name: 'Asia/Calcutta GMT+5:30' },
  { code: 'Asia/Chita', name: 'Asia/Chita GMT+9:00' },
  { code: 'Asia/Choibalsan', name: 'Asia/Choibalsan GMT+8:00' },
  { code: 'Asia/Chongqing', name: 'Asia/Chongqing GMT+8:00' },
  { code: 'Asia/Chungking', name: 'Asia/Chungking GMT+8:00' },
  { code: 'Asia/Colombo', name: 'Asia/Colombo GMT+5:30' },
  { code: 'Asia/Dacca', name: 'Asia/Dacca GMT+6:00' },
  { code: 'Asia/Damascus', name: 'Asia/Damascus GMT+2:00' },
  { code: 'Asia/Dhaka', name: 'Asia/Dhaka GMT+6:00' },
  { code: 'Asia/Dili', name: 'Asia/Dili GMT+9:00' },
  { code: 'Asia/Dubai', name: 'Asia/Dubai GMT+4:00' },
  { code: 'Asia/Dushanbe', name: 'Asia/Dushanbe GMT+5:00' },
  { code: 'Asia/Famagusta', name: 'Asia/Famagusta GMT+2:00' },
  { code: 'Asia/Gaza', name: 'Asia/Gaza GMT+2:00' },
  { code: 'Asia/Harbin', name: 'Asia/Harbin GMT+8:00' },
  { code: 'Asia/Hebron', name: 'Asia/Hebron GMT+2:00' },
  { code: 'Asia/Ho_Chi_Minh', name: 'Asia/Ho_Chi_Minh GMT+7:00' },
  { code: 'Asia/Hong_Kong', name: 'Asia/Hong_Kong GMT+8:00' },
  { code: 'Asia/Hovd', name: 'Asia/Hovd GMT+7:00' },
  { code: 'Asia/Irkutsk', name: 'Asia/Irkutsk GMT+8:00' },
  { code: 'Asia/Istanbul', name: 'Asia/Istanbul GMT+3:00' },
  { code: 'Asia/Jakarta', name: 'Asia/Jakarta GMT+7:00' },
  { code: 'Asia/Jayapura', name: 'Asia/Jayapura GMT+9:00' },
  { code: 'Asia/Jerusalem', name: 'Asia/Jerusalem GMT+2:00' },
  { code: 'Asia/Kabul', name: 'Asia/Kabul GMT+4:30' },
  { code: 'Asia/Kamchatka', name: 'Asia/Kamchatka GMT+12:00' },
  { code: 'Asia/Karachi', name: 'Asia/Karachi GMT+5:00' },
  { code: 'Asia/Kashgar', name: 'Asia/Kashgar GMT+6:00' },
  { code: 'Asia/Kathmandu', name: 'Asia/Kathmandu GMT+5:45' },
  { code: 'Asia/Katmandu', name: 'Asia/Katmandu GMT+5:45' },
  { code: 'Asia/Khandyga', name: 'Asia/Khandyga GMT+9:00' },
  { code: 'Asia/Kolkata', name: 'Asia/Kolkata GMT+5:30' },
  { code: 'Asia/Krasnoyarsk', name: 'Asia/Krasnoyarsk GMT+7:00' },
  { code: 'Asia/Kuala_Lumpur', name: 'Asia/Kuala_Lumpur GMT+8:00' },
  { code: 'Asia/Kuching', name: 'Asia/Kuching GMT+8:00' },
  { code: 'Asia/Kuwait', name: 'Asia/Kuwait GMT+3:00' },
  { code: 'Asia/Macao', name: 'Asia/Macao GMT+8:00' },
  { code: 'Asia/Macau', name: 'Asia/Macau GMT+8:00' },
  { code: 'Asia/Magadan', name: 'Asia/Magadan GMT+11:00' },
  { code: 'Asia/Makassar', name: 'Asia/Makassar GMT+8:00' },
  { code: 'Asia/Manila', name: 'Asia/Manila GMT+8:00' },
  { code: 'Asia/Muscat', name: 'Asia/Muscat GMT+4:00' },
  { code: 'Asia/Nicosia', name: 'Asia/Nicosia GMT+2:00' },
  { code: 'Asia/Novokuznetsk', name: 'Asia/Novokuznetsk GMT+7:00' },
  { code: 'Asia/Novosibirsk', name: 'Asia/Novosibirsk GMT+7:00' },
  { code: 'Asia/Omsk', name: 'Asia/Omsk GMT+6:00' },
  { code: 'Asia/Oral', name: 'Asia/Oral GMT+5:00' },
  { code: 'Asia/Phnom_Penh', name: 'Asia/Phnom_Penh GMT+7:00' },
  { code: 'Asia/Pontianak', name: 'Asia/Pontianak GMT+7:00' },
  { code: 'Asia/Pyongyang', name: 'Asia/Pyongyang GMT+9:00' },
  { code: 'Asia/Qatar', name: 'Asia/Qatar GMT+3:00' },
  { code: 'Asia/Qostanay', name: 'Asia/Qostanay GMT+6:00' },
  { code: 'Asia/Qyzylorda', name: 'Asia/Qyzylorda GMT+5:00' },
  { code: 'Asia/Rangoon', name: 'Asia/Rangoon GMT+6:30' },
  { code: 'Asia/Riyadh', name: 'Asia/Riyadh GMT+3:00' },
  { code: 'Asia/Saigon', name: 'Asia/Saigon GMT+7:00' },
  { code: 'Asia/Sakhalin', name: 'Asia/Sakhalin GMT+11:00' },
  { code: 'Asia/Samarkand', name: 'Asia/Samarkand GMT+5:00' },
  { code: 'Asia/Seoul', name: 'Asia/Seoul GMT+9:00' },
  { code: 'Asia/Shanghai', name: 'Asia/Shanghai GMT+8:00' },
  { code: 'Asia/Singapore', name: 'Asia/Singapore GMT+8:00' },
  { code: 'Asia/Srednekolymsk', name: 'Asia/Srednekolymsk GMT+11:00' },
  { code: 'Asia/Taipei', name: 'Asia/Taipei GMT+8:00' },
  { code: 'Asia/Tashkent', name: 'Asia/Tashkent GMT+5:00' },
  { code: 'Asia/Tbilisi', name: 'Asia/Tbilisi GMT+4:00' },
  { code: 'Asia/Tehran', name: 'Asia/Tehran GMT+3:30' },
  { code: 'Asia/Tel_Aviv', name: 'Asia/Tel_Aviv GMT+2:00' },
  { code: 'Asia/Thimbu', name: 'Asia/Thimbu GMT+6:00' },
  { code: 'Asia/Thimphu', name: 'Asia/Thimphu GMT+6:00' },
  { code: 'Asia/Tokyo', name: 'Asia/Tokyo GMT+9:00' },
  { code: 'Asia/Tomsk', name: 'Asia/Tomsk GMT+7:00' },
  { code: 'Asia/Ujung_Pandang', name: 'Asia/Ujung_Pandang GMT+8:00' },
  { code: 'Asia/Ulaanbaatar', name: 'Asia/Ulaanbaatar GMT+8:00' },
  { code: 'Asia/Ulan_Bator', name: 'Asia/Ulan_Bator GMT+8:00' },
  { code: 'Asia/Urumqi', name: 'Asia/Urumqi GMT+6:00' },
  { code: 'Asia/Ust-Nera', name: 'Asia/Ust-Nera GMT+10:00' },
  { code: 'Asia/Vientiane', name: 'Asia/Vientiane GMT+7:00' },
  { code: 'Asia/Vladivostok', name: 'Asia/Vladivostok GMT+10:00' },
  { code: 'Asia/Yakutsk', name: 'Asia/Yakutsk GMT+9:00' },
  { code: 'Asia/Yangon', name: 'Asia/Yangon GMT+6:30' },
  { code: 'Asia/Yekaterinburg', name: 'Asia/Yekaterinburg GMT+5:00' },
  { code: 'Asia/Yerevan', name: 'Asia/Yerevan GMT+4:00' },
  { code: 'Atlantic/Azores', name: 'Atlantic/Azores GMT-1:00' },
  { code: 'Atlantic/Bermuda', name: 'Atlantic/Bermuda GMT-4:00' },
  { code: 'Atlantic/Canary', name: 'Atlantic/Canary GMT+0:00' },
  { code: 'Atlantic/Cape_Verde', name: 'Atlantic/Cape_Verde GMT-1:00' },
  { code: 'Atlantic/Faeroe', name: 'Atlantic/Faeroe GMT+0:00' },
  { code: 'Atlantic/Faroe', name: 'Atlantic/Faroe GMT+0:00' },
  { code: 'Atlantic/Jan_Mayen', name: 'Atlantic/Jan_Mayen GMT+1:00' },
  { code: 'Atlantic/Madeira', name: 'Atlantic/Madeira GMT+0:00' },
  { code: 'Atlantic/Reykjavik', name: 'Atlantic/Reykjavik GMT+0:00' },
  { code: 'Atlantic/South_Georgia', name: 'Atlantic/South_Georgia GMT-2:00' },
  { code: 'Atlantic/St_Helena', name: 'Atlantic/St_Helena GMT+0:00' },
  { code: 'Atlantic/Stanley', name: 'Atlantic/Stanley GMT-3:00' },
  { code: 'Australia/ACT', name: 'Australia/ACT GMT+10:00' },
  { code: 'Australia/Adelaide', name: 'Australia/Adelaide GMT+9:30' },
  { code: 'Australia/Brisbane', name: 'Australia/Brisbane GMT+10:00' },
  { code: 'Australia/Broken_Hill', name: 'Australia/Broken_Hill GMT+9:30' },
  { code: 'Australia/Canberra', name: 'Australia/Canberra GMT+10:00' },
  { code: 'Australia/Currie', name: 'Australia/Currie GMT+10:00' },
  { code: 'Australia/Darwin', name: 'Australia/Darwin GMT+9:30' },
  { code: 'Australia/Eucla', name: 'Australia/Eucla GMT+8:45' },
  { code: 'Australia/Hobart', name: 'Australia/Hobart GMT+10:00' },
  { code: 'Australia/LHI', name: 'Australia/LHI GMT+10:30' },
  { code: 'Australia/Lindeman', name: 'Australia/Lindeman GMT+10:00' },
  { code: 'Australia/Lord_Howe', name: 'Australia/Lord_Howe GMT+10:30' },
  { code: 'Australia/Melbourne', name: 'Australia/Melbourne GMT+10:00' },
  { code: 'Australia/NSW', name: 'Australia/NSW GMT+10:00' },
  { code: 'Australia/North', name: 'Australia/North GMT+9:30' },
  { code: 'Australia/Perth', name: 'Australia/Perth GMT+8:00' },
  { code: 'Australia/Queensland', name: 'Australia/Queensland GMT+10:00' },
  { code: 'Australia/South', name: 'Australia/South GMT+9:30' },
  { code: 'Australia/Sydney', name: 'Australia/Sydney GMT+10:00' },
  { code: 'Australia/Tasmania', name: 'Australia/Tasmania GMT+10:00' },
  { code: 'Australia/Victoria', name: 'Australia/Victoria GMT+10:00' },
  { code: 'Australia/West', name: 'Australia/West GMT+8:00' },
  { code: 'Australia/Yancowinna', name: 'Australia/Yancowinna GMT+9:30' },
  { code: 'Brazil/Acre', name: 'Brazil/Acre GMT-5:00' },
  { code: 'Brazil/DeNoronha', name: 'Brazil/DeNoronha GMT-2:00' },
  { code: 'Brazil/East', name: 'Brazil/East GMT-3:00' },
  { code: 'Brazil/West', name: 'Brazil/West GMT-4:00' },
  { code: 'CET', name: 'CET GMT+1:00' },
  { code: 'CST6CDT', name: 'CST6CDT GMT-6:00' },
  { code: 'Canada/Atlantic', name: 'Canada/Atlantic GMT-4:00' },
  { code: 'Canada/Central', name: 'Canada/Central GMT-6:00' },
  { code: 'Canada/Eastern', name: 'Canada/Eastern GMT-5:00' },
  { code: 'Canada/Mountain', name: 'Canada/Mountain GMT-7:00' },
  { code: 'Canada/Newfoundland', name: 'Canada/Newfoundland GMT-4:30' },
  { code: 'Canada/Pacific', name: 'Canada/Pacific GMT-8:00' },
  { code: 'Canada/Saskatchewan', name: 'Canada/Saskatchewan GMT-6:00' },
  { code: 'Canada/Yukon', name: 'Canada/Yukon GMT-8:00' },
  { code: 'Chile/Continental', name: 'Chile/Continental GMT-4:00' },
  { code: 'Chile/EasterIsland', name: 'Chile/EasterIsland GMT-6:00' },
  { code: 'Cuba', name: 'Cuba GMT-5:00' },
  { code: 'EET', name: 'EET GMT+2:00' },
  { code: 'EST5EDT', name: 'EST5EDT GMT-5:00' },
  { code: 'Egypt', name: 'Egypt GMT+2:00' },
  { code: 'Eire', name: 'Eire GMT+0:00' },
  { code: 'Etc/GMT', name: 'Etc/GMT GMT+0:00' },
  { code: 'Etc/GMT+0', name: 'Etc/GMT+0 GMT+0:00' },
  { code: 'Etc/GMT+1', name: 'Etc/GMT+1 GMT-1:00' },
  { code: 'Etc/GMT+10', name: 'Etc/GMT+10 GMT-10:00' },
  { code: 'Etc/GMT+11', name: 'Etc/GMT+11 GMT-11:00' },
  { code: 'Etc/GMT+12', name: 'Etc/GMT+12 GMT-12:00' },
  { code: 'Etc/GMT+2', name: 'Etc/GMT+2 GMT-2:00' },
  { code: 'Etc/GMT+3', name: 'Etc/GMT+3 GMT-3:00' },
  { code: 'Etc/GMT+4', name: 'Etc/GMT+4 GMT-4:00' },
  { code: 'Etc/GMT+5', name: 'Etc/GMT+5 GMT-5:00' },
  { code: 'Etc/GMT+6', name: 'Etc/GMT+6 GMT-6:00' },
  { code: 'Etc/GMT+7', name: 'Etc/GMT+7 GMT-7:00' },
  { code: 'Etc/GMT+8', name: 'Etc/GMT+8 GMT-8:00' },
  { code: 'Etc/GMT+9', name: 'Etc/GMT+9 GMT-9:00' },
  { code: 'Etc/GMT-0', name: 'Etc/GMT-0 GMT+0:00' },
  { code: 'Etc/GMT-1', name: 'Etc/GMT-1 GMT+1:00' },
  { code: 'Etc/GMT-10', name: 'Etc/GMT-10 GMT+10:00' },
  { code: 'Etc/GMT-11', name: 'Etc/GMT-11 GMT+11:00' },
  { code: 'Etc/GMT-12', name: 'Etc/GMT-12 GMT+12:00' },
  { code: 'Etc/GMT-13', name: 'Etc/GMT-13 GMT+13:00' },
  { code: 'Etc/GMT-14', name: 'Etc/GMT-14 GMT+14:00' },
  { code: 'Etc/GMT-2', name: 'Etc/GMT-2 GMT+2:00' },
  { code: 'Etc/GMT-3', name: 'Etc/GMT-3 GMT+3:00' },
  { code: 'Etc/GMT-4', name: 'Etc/GMT-4 GMT+4:00' },
  { code: 'Etc/GMT-5', name: 'Etc/GMT-5 GMT+5:00' },
  { code: 'Etc/GMT-6', name: 'Etc/GMT-6 GMT+6:00' },
  { code: 'Etc/GMT-7', name: 'Etc/GMT-7 GMT+7:00' },
  { code: 'Etc/GMT-8', name: 'Etc/GMT-8 GMT+8:00' },
  { code: 'Etc/GMT-9', name: 'Etc/GMT-9 GMT+9:00' },
  { code: 'Etc/GMT0', name: 'Etc/GMT0 GMT+0:00' },
  { code: 'Etc/Greenwich', name: 'Etc/Greenwich GMT+0:00' },
  { code: 'Etc/UCT', name: 'Etc/UCT GMT+0:00' },
  { code: 'Etc/UTC', name: 'Etc/UTC GMT+0:00' },
  { code: 'Etc/Universal', name: 'Etc/Universal GMT+0:00' },
  { code: 'Etc/Zulu', name: 'Etc/Zulu GMT+0:00' },
  { code: 'Europe/Amsterdam', name: 'Europe/Amsterdam GMT+1:00' },
  { code: 'Europe/Andorra', name: 'Europe/Andorra GMT+1:00' },
  { code: 'Europe/Astrakhan', name: 'Europe/Astrakhan GMT+4:00' },
  { code: 'Europe/Athens', name: 'Europe/Athens GMT+2:00' },
  { code: 'Europe/Belfast', name: 'Europe/Belfast GMT+0:00' },
  { code: 'Europe/Belgrade', name: 'Europe/Belgrade GMT+1:00' },
  { code: 'Europe/Berlin', name: 'Europe/Berlin GMT+1:00' },
  { code: 'Europe/Bratislava', name: 'Europe/Bratislava GMT+1:00' },
  { code: 'Europe/Brussels', name: 'Europe/Brussels GMT+1:00' },
  { code: 'Europe/Bucharest', name: 'Europe/Bucharest GMT+2:00' },
  { code: 'Europe/Budapest', name: 'Europe/Budapest GMT+1:00' },
  { code: 'Europe/Busingen', name: 'Europe/Busingen GMT+1:00' },
  { code: 'Europe/Chisinau', name: 'Europe/Chisinau GMT+2:00' },
  { code: 'Europe/Copenhagen', name: 'Europe/Copenhagen GMT+1:00' },
  { code: 'Europe/Dublin', name: 'Europe/Dublin GMT+0:00' },
  { code: 'Europe/Gibraltar', name: 'Europe/Gibraltar GMT+1:00' },
  { code: 'Europe/Guernsey', name: 'Europe/Guernsey GMT+0:00' },
  { code: 'Europe/Helsinki', name: 'Europe/Helsinki GMT+2:00' },
  { code: 'Europe/Isle_of_Man', name: 'Europe/Isle_of_Man GMT+0:00' },
  { code: 'Europe/Istanbul', name: 'Europe/Istanbul GMT+3:00' },
  { code: 'Europe/Jersey', name: 'Europe/Jersey GMT+0:00' },
  { code: 'Europe/Kaliningrad', name: 'Europe/Kaliningrad GMT+2:00' },
  { code: 'Europe/Kiev', name: 'Europe/Kiev GMT+2:00' },
  { code: 'Europe/Kirov', name: 'Europe/Kirov GMT+3:00' },
  { code: 'Europe/Lisbon', name: 'Europe/Lisbon GMT+0:00' },
  { code: 'Europe/Ljubljana', name: 'Europe/Ljubljana GMT+1:00' },
  { code: 'Europe/London', name: 'Europe/London GMT+0:00' },
  { code: 'Europe/Luxembourg', name: 'Europe/Luxembourg GMT+1:00' },
  { code: 'Europe/Madrid', name: 'Europe/Madrid GMT+1:00' },
  { code: 'Europe/Malta', name: 'Europe/Malta GMT+1:00' },
  { code: 'Europe/Mariehamn', name: 'Europe/Mariehamn GMT+2:00' },
  { code: 'Europe/Minsk', name: 'Europe/Minsk GMT+3:00' },
  { code: 'Europe/Monaco', name: 'Europe/Monaco GMT+1:00' },
  { code: 'Europe/Moscow', name: 'Europe/Moscow GMT+3:00' },
  { code: 'Europe/Nicosia', name: 'Europe/Nicosia GMT+2:00' },
  { code: 'Europe/Oslo', name: 'Europe/Oslo GMT+1:00' },
  { code: 'Europe/Paris', name: 'Europe/Paris GMT+1:00' },
  { code: 'Europe/Podgorica', name: 'Europe/Podgorica GMT+1:00' },
  { code: 'Europe/Prague', name: 'Europe/Prague GMT+1:00' },
  { code: 'Europe/Riga', name: 'Europe/Riga GMT+2:00' },
  { code: 'Europe/Rome', name: 'Europe/Rome GMT+1:00' },
  { code: 'Europe/Samara', name: 'Europe/Samara GMT+4:00' },
  { code: 'Europe/San_Marino', name: 'Europe/San_Marino GMT+1:00' },
  { code: 'Europe/Sarajevo', name: 'Europe/Sarajevo GMT+1:00' },
  { code: 'Europe/Saratov', name: 'Europe/Saratov GMT+4:00' },
  { code: 'Europe/Simferopol', name: 'Europe/Simferopol GMT+3:00' },
  { code: 'Europe/Skopje', name: 'Europe/Skopje GMT+1:00' },
  { code: 'Europe/Sofia', name: 'Europe/Sofia GMT+2:00' },
  { code: 'Europe/Stockholm', name: 'Europe/Stockholm GMT+1:00' },
  { code: 'Europe/Tallinn', name: 'Europe/Tallinn GMT+2:00' },
  { code: 'Europe/Tirane', name: 'Europe/Tirane GMT+1:00' },
  { code: 'Europe/Tiraspol', name: 'Europe/Tiraspol GMT+2:00' },
  { code: 'Europe/Ulyanovsk', name: 'Europe/Ulyanovsk GMT+4:00' },
  { code: 'Europe/Uzhgorod', name: 'Europe/Uzhgorod GMT+2:00' },
  { code: 'Europe/Vaduz', name: 'Europe/Vaduz GMT+1:00' },
  { code: 'Europe/Vatican', name: 'Europe/Vatican GMT+1:00' },
  { code: 'Europe/Vienna', name: 'Europe/Vienna GMT+1:00' },
  { code: 'Europe/Vilnius', name: 'Europe/Vilnius GMT+2:00' },
  { code: 'Europe/Volgograd', name: 'Europe/Volgograd GMT+4:00' },
  { code: 'Europe/Warsaw', name: 'Europe/Warsaw GMT+1:00' },
  { code: 'Europe/Zagreb', name: 'Europe/Zagreb GMT+1:00' },
  { code: 'Europe/Zaporozhye', name: 'Europe/Zaporozhye GMT+2:00' },
  { code: 'Europe/Zurich', name: 'Europe/Zurich GMT+1:00' },
  { code: 'GB', name: 'GB GMT+0:00' },
  { code: 'GB-Eire', name: 'GB-Eire GMT+0:00' },
  { code: 'GMT', name: 'GMT GMT+0:00' },
  { code: 'GMT0', name: 'GMT0 GMT+0:00' },
  { code: 'Greenwich', name: 'Greenwich GMT+0:00' },
  { code: 'Hongkong', name: 'Hongkong GMT+8:00' },
  { code: 'Iceland', name: 'Iceland GMT+0:00' },
  { code: 'Indian/Antananarivo', name: 'Indian/Antananarivo GMT+3:00' },
  { code: 'Indian/Chagos', name: 'Indian/Chagos GMT+6:00' },
  { code: 'Indian/Christmas', name: 'Indian/Christmas GMT+7:00' },
  { code: 'Indian/Cocos', name: 'Indian/Cocos GMT+6:30' },
  { code: 'Indian/Comoro', name: 'Indian/Comoro GMT+3:00' },
  { code: 'Indian/Kerguelen', name: 'Indian/Kerguelen GMT+5:00' },
  { code: 'Indian/Mahe', name: 'Indian/Mahe GMT+4:00' },
  { code: 'Indian/Maldives', name: 'Indian/Maldives GMT+5:00' },
  { code: 'Indian/Mauritius', name: 'Indian/Mauritius GMT+4:00' },
  { code: 'Indian/Mayotte', name: 'Indian/Mayotte GMT+3:00' },
  { code: 'Indian/Reunion', name: 'Indian/Reunion GMT+4:00' },
  { code: 'Iran', name: 'Iran GMT+3:30' },
  { code: 'Israel', name: 'Israel GMT+2:00' },
  { code: 'Jamaica', name: 'Jamaica GMT-5:00' },
  { code: 'Japan', name: 'Japan GMT+9:00' },
  { code: 'Kwajalein', name: 'Kwajalein GMT+12:00' },
  { code: 'Libya', name: 'Libya GMT+2:00' },
  { code: 'MET', name: 'MET GMT+1:00' },
  { code: 'MST7MDT', name: 'MST7MDT GMT-7:00' },
  { code: 'Mexico/BajaNorte', name: 'Mexico/BajaNorte GMT-8:00' },
  { code: 'Mexico/BajaSur', name: 'Mexico/BajaSur GMT-7:00' },
  { code: 'Mexico/General', name: 'Mexico/General GMT-6:00' },
  { code: 'NZ', name: 'NZ GMT+12:00' },
  { code: 'NZ-CHAT', name: 'NZ-CHAT GMT+12:45' },
  { code: 'Navajo', name: 'Navajo GMT-7:00' },
  { code: 'PRC', name: 'PRC GMT+8:00' },
  { code: 'PST8PDT', name: 'PST8PDT GMT-8:00' },
  { code: 'Pacific/Apia', name: 'Pacific/Apia GMT+13:00' },
  { code: 'Pacific/Auckland', name: 'Pacific/Auckland GMT+12:00' },
  { code: 'Pacific/Bougainville', name: 'Pacific/Bougainville GMT+11:00' },
  { code: 'Pacific/Chatham', name: 'Pacific/Chatham GMT+12:45' },
  { code: 'Pacific/Chuuk', name: 'Pacific/Chuuk GMT+10:00' },
  { code: 'Pacific/Easter', name: 'Pacific/Easter GMT-6:00' },
  { code: 'Pacific/Efate', name: 'Pacific/Efate GMT+11:00' },
  { code: 'Pacific/Enderbury', name: 'Pacific/Enderbury GMT+13:00' },
  { code: 'Pacific/Fakaofo', name: 'Pacific/Fakaofo GMT+13:00' },
  { code: 'Pacific/Fiji', name: 'Pacific/Fiji GMT+12:00' },
  { code: 'Pacific/Funafuti', name: 'Pacific/Funafuti GMT+12:00' },
  { code: 'Pacific/Galapagos', name: 'Pacific/Galapagos GMT-6:00' },
  { code: 'Pacific/Gambier', name: 'Pacific/Gambier GMT-9:00' },
  { code: 'Pacific/Guadalcanal', name: 'Pacific/Guadalcanal GMT+11:00' },
  { code: 'Pacific/Guam', name: 'Pacific/Guam GMT+10:00' },
  { code: 'Pacific/Honolulu', name: 'Pacific/Honolulu GMT-10:00' },
  { code: 'Pacific/Johnston', name: 'Pacific/Johnston GMT-10:00' },
  { code: 'Pacific/Kiritimati', name: 'Pacific/Kiritimati GMT+14:00' },
  { code: 'Pacific/Kosrae', name: 'Pacific/Kosrae GMT+11:00' },
  { code: 'Pacific/Kwajalein', name: 'Pacific/Kwajalein GMT+12:00' },
  { code: 'Pacific/Majuro', name: 'Pacific/Majuro GMT+12:00' },
  { code: 'Pacific/Marquesas', name: 'Pacific/Marquesas GMT-10:30' },
  { code: 'Pacific/Midway', name: 'Pacific/Midway GMT-11:00' },
  { code: 'Pacific/Nauru', name: 'Pacific/Nauru GMT+12:00' },
  { code: 'Pacific/Niue', name: 'Pacific/Niue GMT-11:00' },
  { code: 'Pacific/Norfolk', name: 'Pacific/Norfolk GMT+11:00' },
  { code: 'Pacific/Noumea', name: 'Pacific/Noumea GMT+11:00' },
  { code: 'Pacific/Pago_Pago', name: 'Pacific/Pago_Pago GMT-11:00' },
  { code: 'Pacific/Palau', name: 'Pacific/Palau GMT+9:00' },
  { code: 'Pacific/Pitcairn', name: 'Pacific/Pitcairn GMT-8:00' },
  { code: 'Pacific/Pohnpei', name: 'Pacific/Pohnpei GMT+11:00' },
  { code: 'Pacific/Ponape', name: 'Pacific/Ponape GMT+11:00' },
  { code: 'Pacific/Port_Moresby', name: 'Pacific/Port_Moresby GMT+10:00' },
  { code: 'Pacific/Rarotonga', name: 'Pacific/Rarotonga GMT-10:00' },
  { code: 'Pacific/Saipan', name: 'Pacific/Saipan GMT+10:00' },
  { code: 'Pacific/Samoa', name: 'Pacific/Samoa GMT-11:00' },
  { code: 'Pacific/Tahiti', name: 'Pacific/Tahiti GMT-10:00' },
  { code: 'Pacific/Tarawa', name: 'Pacific/Tarawa GMT+12:00' },
  { code: 'Pacific/Tongatapu', name: 'Pacific/Tongatapu GMT+13:00' },
  { code: 'Pacific/Truk', name: 'Pacific/Truk GMT+10:00' },
  { code: 'Pacific/Wake', name: 'Pacific/Wake GMT+12:00' },
  { code: 'Pacific/Wallis', name: 'Pacific/Wallis GMT+12:00' },
  { code: 'Pacific/Yap', name: 'Pacific/Yap GMT+10:00' },
  { code: 'Poland', name: 'Poland GMT+1:00' },
  { code: 'Portugal', name: 'Portugal GMT+0:00' },
  { code: 'ROK', name: 'ROK GMT+9:00' },
  { code: 'Singapore', name: 'Singapore GMT+8:00' },
  { code: 'SystemV/AST4', name: 'SystemV/AST4 GMT-4:00' },
  { code: 'SystemV/AST4ADT', name: 'SystemV/AST4ADT GMT-4:00' },
  { code: 'SystemV/CST6', name: 'SystemV/CST6 GMT-6:00' },
  { code: 'SystemV/CST6CDT', name: 'SystemV/CST6CDT GMT-6:00' },
  { code: 'SystemV/EST5', name: 'SystemV/EST5 GMT-5:00' },
  { code: 'SystemV/EST5EDT', name: 'SystemV/EST5EDT GMT-5:00' },
  { code: 'SystemV/HST10', name: 'SystemV/HST10 GMT-10:00' },
  { code: 'SystemV/MST7', name: 'SystemV/MST7 GMT-7:00' },
  { code: 'SystemV/MST7MDT', name: 'SystemV/MST7MDT GMT-7:00' },
  { code: 'SystemV/PST8', name: 'SystemV/PST8 GMT-8:00' },
  { code: 'SystemV/PST8PDT', name: 'SystemV/PST8PDT GMT-8:00' },
  { code: 'SystemV/YST9', name: 'SystemV/YST9 GMT-9:00' },
  { code: 'SystemV/YST9YDT', name: 'SystemV/YST9YDT GMT-9:00' },
  { code: 'Turkey', name: 'Turkey GMT+3:00' },
  { code: 'UCT', name: 'UCT GMT+0:00' },
  { code: 'US/Alaska', name: 'US/Alaska GMT-9:00' },
  { code: 'US/Aleutian', name: 'US/Aleutian GMT-10:00' },
  { code: 'US/Arizona', name: 'US/Arizona GMT-7:00' },
  { code: 'US/Central', name: 'US/Central GMT-6:00' },
  { code: 'US/East-Indiana', name: 'US/East-Indiana GMT-5:00' },
  { code: 'US/Eastern', name: 'US/Eastern GMT-5:00' },
  { code: 'US/Hawaii', name: 'US/Hawaii GMT-10:00' },
  { code: 'US/Indiana-Starke', name: 'US/Indiana-Starke GMT-6:00' },
  { code: 'US/Michigan', name: 'US/Michigan GMT-5:00' },
  { code: 'US/Mountain', name: 'US/Mountain GMT-7:00' },
  { code: 'US/Pacific', name: 'US/Pacific GMT-8:00' },
  { code: 'US/Pacific-New', name: 'US/Pacific-New GMT-8:00' },
  { code: 'US/Samoa', name: 'US/Samoa GMT-11:00' },
  { code: 'UTC', name: 'UTC GMT+0:00' },
  { code: 'Universal', name: 'Universal GMT+0:00' },
  { code: 'W-SU', name: 'W-SU GMT+3:00' },
  { code: 'WET', name: 'WET GMT+0:00' },
  { code: 'Zulu', name: 'Zulu GMT+0:00' },
  { code: 'EST', name: 'EST GMT-5:00' },
  { code: 'HST', name: 'HST GMT-10:00' },
  { code: 'MST', name: 'MST GMT-7:00' },
  { code: 'ACT', name: 'ACT GMT+9:30' },
  { code: 'AET', name: 'AET GMT+10:00' },
  { code: 'AGT', name: 'AGT GMT-3:00' },
  { code: 'ART', name: 'ART GMT+2:00' },
  { code: 'AST', name: 'AST GMT-9:00' },
  { code: 'BET', name: 'BET GMT-3:00' },
  { code: 'BST', name: 'BST GMT+6:00' },
  { code: 'CAT', name: 'CAT GMT+2:00' },
  { code: 'CNT', name: 'CNT GMT-4:30' },
  { code: 'CST', name: 'CST GMT-6:00' },
  { code: 'CTT', name: 'CTT GMT+8:00' },
  { code: 'EAT', name: 'EAT GMT+3:00' },
  { code: 'ECT', name: 'ECT GMT+1:00' },
  { code: 'IET', name: 'IET GMT-5:00' },
  { code: 'IST', name: 'IST GMT+5:30' },
  { code: 'JST', name: 'JST GMT+9:00' },
  { code: 'MIT', name: 'MIT GMT+13:00' },
  { code: 'NET', name: 'NET GMT+4:00' },
  { code: 'NST', name: 'NST GMT+12:00' },
  { code: 'PLT', name: 'PLT GMT+5:00' },
  { code: 'PNT', name: 'PNT GMT-7:00' },
  { code: 'PRT', name: 'PRT GMT-4:00' },
  { code: 'PST', name: 'PST GMT-8:00' },
  { code: 'SST', name: 'SST GMT+11:00' },
  { code: 'VST', name: 'VST GMT+7:00' },
]

export const isTimeZone = (value: unknown) => timeZones.some(({ code }) => code === value)

@ValidatorConstraint({ name: 'timeZone', async: false })
export class IsTimeZoneConstraint implements ValidatorConstraintInterface {
  validate = isTimeZone

  defaultMessage = () => 'Input is not a valid timeZone'
}

export function IsTimeZone<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsTimeZoneConstraint,
    })
  }
}
