import { gql, useQuery } from '@apollo/client'
import {
  type ProductUserFeedbackCompletedInput,
  type ProductUserFeedbackCompletedQuery,
  type ProductUserFeedbackCompletedQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query productUserFeedbackCompleted($input: ProductUserFeedbackCompletedInput!) {
    productUserFeedbackCompleted(input: $input) {
      id
    }
  }
`

export const useProductUserFeedbackCompleted = (
  type: ProductUserFeedbackCompletedInput['type'],
  extra: ProductUserFeedbackCompletedInput['extra'],
) => {
  const { data, error, loading, refetch } = useQuery<
    ProductUserFeedbackCompletedQuery,
    ProductUserFeedbackCompletedQueryVariables
  >(QUERY, {
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        type,
        extra,
      },
    },
  })

  return {
    completed: !!data?.productUserFeedbackCompleted?.id,
    error: !!error,
    loading,
    refetch,
  }
}
