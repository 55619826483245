import { createContext, type ReactNode, useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { type Step } from './types'

type Entry = {
  path: string
} & Step<string>['options']

type Context = {
  current: Entry
  currentIndex: number
  entries: Entry[]
  goNext: VoidFunction
  goPrevious: VoidFunction
  hasNext: boolean
  hasPrevious: boolean
  hideBackButton: () => void
  isFirst: boolean
  isLast: boolean
  isVisibleBackButton: boolean
  showBackButton: () => void
}

const StepNavigationContext = createContext<Context | null>(null)

export const useStepNavigation = () => {
  const state = useContext(StepNavigationContext)

  if (!state) {
    throw new Error('The `useStepNavigation` should be wrapped with `StepNavigationProvider`.')
  }

  return state
}

type Props = {
  children: ReactNode
  entries: Entry[]
  initialIndex: number
}

export const StepNavigationProvider = ({ children, entries, initialIndex }: Props) => {
  const history = useHistory()
  const [isVisibleBackButton, setIsVisibleBackButton] = useState(false)

  const [currentIndex, setCurrentIndex] = useState(initialIndex)

  const current = entries[currentIndex]
  const hasPrevious = currentIndex > 0
  const hasNext = currentIndex < entries.length - 1
  const isFirst = currentIndex === 0
  const isLast = currentIndex === entries.length - 1

  const goNext = () => {
    if (isLast) {
      return
    }

    history.push(entries[currentIndex + 1].path)
    setCurrentIndex((value) => value + 1)
  }

  const goPrevious = () => {
    if (isFirst) {
      return
    }

    history.goBack()
    setCurrentIndex((value) => value - 1)
  }

  const hideBackButton = useCallback(() => {
    setIsVisibleBackButton(false)
  }, [])

  const showBackButton = useCallback(() => {
    setIsVisibleBackButton(true)
  }, [])

  return (
    <StepNavigationContext.Provider
      value={{
        current,
        currentIndex,
        entries,
        goNext,
        goPrevious,
        hasNext,
        hasPrevious,
        hideBackButton,
        isFirst,
        isLast,
        isVisibleBackButton,
        showBackButton,
      }}
    >
      {children}
    </StepNavigationContext.Provider>
  )
}
