import { Flex, type FlexProps } from 'cdk'
import { BORDER_RADIUS_XS, SPACING_0, SPACING_3XS, SPACING_LG, SPACING_MD, SPACING_SM, SPACING_XS } from 'design-tokens'
import { Icon } from 'icons'
import { Avatar, Button, Chip, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { RangeIndicator } from '~/domains/therapist/TherapistOverviewCard/RangeIndicator'
import { useTherapist } from '~/hooks/useTherapist'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { shouldShowYearsOfExperience } from '~/utils/shouldShowYearsOfExperience'
import { useTherapyPathById } from '../../reservation/hooks/useTherapyPathById'
import { useValidTherapistProfessionTypeValue } from '../../reservation/pages/ThankYouPage/hooks/useValidTherapistProfessionTypeValue'
import { TherapistChipSexology } from '../components/TherapistChipSexology'
import { useTherapistYearOfExperience } from '../hooks/useTherapistYearOfExperience'
import { TherapistOverviewCardDetail } from './Detail'
import { TherapistOverviewCardWrapperCustomStyle } from './TherapistOverviewCardWrapperCustomStyle'

type HeaderProps = {
  fullName: string
  image: string | undefined
  sex: number
  therapyPath: TherapyTherapyPathType
} & FlexProps

const Header = ({ fullName, image, sex, therapyPath, ...flexProps }: HeaderProps) => (
  <Flex {...flexProps} $align="center" $gap={SPACING_XS}>
    <Avatar image={image} name={fullName} size="2xl" />
    <Flex $align="center" $gap={SPACING_3XS}>
      <Text kind="h2">{fullName}</Text>
      <Text kind="paragraph" style={{ lineHeight: '24px' }}>
        <Translation id={useValidTherapistProfessionTypeValue({ pathType: therapyPath, sex })} />
      </Text>
    </Flex>
  </Flex>
)

type BadgeProps = {
  isSexology: boolean
  registrationRegion: string
  registrationYear: number | null
} & FlexProps

const Badges = ({ isSexology, registrationRegion, registrationYear, ...flexProps }: BadgeProps) => {
  const yearsOfExperience = useTherapistYearOfExperience({ registrationYear })
  const showYearsOfExperience = shouldShowYearsOfExperience(yearsOfExperience)

  return (
    <Flex
      {...flexProps}
      $align="center"
      $direction="row"
      $gap={SPACING_3XS}
      $justify="center"
      $px={SPACING_MD}
      $wrap="wrap"
    >
      {showYearsOfExperience && (
        <Chip kind="info">
          <TranslationMarkdown
            colorName="blue-80"
            fontWeight="500"
            id="therapist.overviewCard.card.yearOfExperience"
            kind="caption"
            values={{ years: yearsOfExperience }}
          />
        </Chip>
      )}
      <Chip kind="grey">{registrationRegion}</Chip>
      {isSexology && <TherapistChipSexology />}
    </Flex>
  )
}

const Formality = ({ score, ...flexProps }: { score: number | undefined } & FlexProps) => (
  <Flex {...flexProps}>
    <RangeIndicator
      leftIndicator="therapist.overviewCard.style.formality.negative"
      rangeValue={score}
      rightIndicator="therapist.overviewCard.style.formality.positive"
    />
  </Flex>
)

type InformationsProps = {
  patients: number
  sex: number
  workplaces: { workplace: { id: string; name: string } }[] | null
} & FlexProps

const Informations = ({ patients, sex, workplaces, ...flexProps }: InformationsProps) => (
  <Flex {...flexProps} $align="flex-start" $gap={SPACING_SM} $minWidth="100%">
    {patients >= 10 && (
      <Flex $direction="row" $gap={SPACING_XS}>
        <Flex>
          <Icon name="check" size={16} />
        </Flex>
        <Flex $shrink={1}>
          <TranslationMarkdown
            id={
              sex === 1
                ? 'therapist.overviewCard.card.followedPeople.male'
                : 'therapist.overviewCard.card.followedPeople.female'
            }
            kind="paragraph"
            values={{ count: patients }}
          />
        </Flex>
      </Flex>
    )}

    <Flex $direction="row" $gap={SPACING_XS}>
      <Flex>
        <Icon name="check" size={16} />
      </Flex>
      <Flex $shrink={1}>
        <TranslationMarkdown
          id={sex === 1 ? 'therapist.overviewCard.online.title.male' : 'therapist.overviewCard.online.title.female'}
          kind="paragraph"
        />
      </Flex>
    </Flex>

    {!!workplaces?.length && (
      <Flex $direction="row" $gap={SPACING_XS}>
        <Flex>
          <Icon name="check" size={16} />
        </Flex>
        <Flex $shrink={1}>
          <TranslationMarkdown
            id="therapist.overviewCard.card.workPlaces"
            kind="paragraph"
            values={{ workplaces: workplaces.map(({ workplace }) => workplace.name).join(', ') }}
          />
        </Flex>
      </Flex>
    )}
  </Flex>
)

type OpenResuneProps = {
  sex: number
  therapyPath: TherapyTherapyPathType
} & FlexProps

const OpenResume = ({ sex, therapyPath, ...flexProps }: OpenResuneProps) => (
  <Flex {...flexProps}>
    <TherapistOverviewCardDetail therapyPath={therapyPath}>
      <Button isGhost kind="primary" size="md">
        <Translation
          id={sex === 1 ? 'therapist.overviewCard.cta.readCv.male' : 'therapist.overviewCard.cta.readCv.female'}
        />
      </Button>
    </TherapistOverviewCardDetail>
  </Flex>
)

export const TherapistOverviewCard = () => {
  const { fullName, therapist } = useTherapist()
  const { therapyPath } = useTherapyPathById()

  const { numberOfPatients, profileImage, sex, therapistWorkplaces } = therapist
  const { registerRegistrationRegion, registerRegistrationYearPsychologist } = therapist
  const score = therapist.therapistProfessionalStyles?.find((style) => style.type === 'FORMALITY')?.score

  return (
    <TherapistOverviewCardWrapperCustomStyle
      $align="center"
      $borderRadius={BORDER_RADIUS_XS}
      $elevationName="lg"
      $grow={1}
      $maxWidth={360}
      $p={SPACING_MD}
      dir="row"
    >
      <Header $pt={SPACING_0} fullName={fullName} image={profileImage?.m} sex={sex} therapyPath={therapyPath.type} />
      <Badges
        $pt={SPACING_SM}
        isSexology={therapyPath.type === 'PATH_SEXOLOGY'}
        registrationRegion={registerRegistrationRegion}
        registrationYear={registerRegistrationYearPsychologist}
      />
      <Formality $pt={SPACING_MD} score={score} />
      <Informations $pt={SPACING_MD} patients={numberOfPatients} sex={sex} workplaces={therapistWorkplaces} />
      <OpenResume $pt={SPACING_LG} sex={sex} therapyPath={therapyPath.type} />
    </TherapistOverviewCardWrapperCustomStyle>
  )
}
