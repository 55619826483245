import { Flex, media, MountOn, PositionAbsolute, PositionRelative, Pressable } from 'cdk'
import { Icon } from 'icons'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Avatar } from 'ui'
import { Opacity } from '~/components/Opacity'
import { Visibility } from '~/components/Visibility'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type UserTherapist } from '~/domains/therapist/types'
import { useReservationFlow } from '../../hooks/useReservationFlow'
import { type ReservationFormValues } from '../../types'

const PositionRelativeWithIndex = styled(PositionRelative)`
  z-index: 2;

  ${media.gtMd`
    z-index: 1
  `}
`

const ArrowWrapper = styled(Flex).attrs({
  $align: 'center',
  $justify: 'center',
})`
  width: 40px;
  height: 40px;
`

const LeftSwitcher = styled(Flex)`
  transform: translateX(-100%);
`

const RightSwitcher = styled(Flex)`
  transform: translateX(100%);
`

type TherapistSwitcherProps = {
  direction: 'prev' | 'next'
  onClick: () => void
  therapist: UserTherapist
}

const TherapistSwitcher = ({ direction, onClick, therapist }: TherapistSwitcherProps) => (
  <Pressable onClick={onClick}>
    <PositionRelative>
      <Opacity opacity={0.7}>
        {therapist && <Avatar image={therapist?.therapist.profileImage?.s} name={therapist.fullName} size="lg" />}
      </Opacity>
      <PositionAbsolute
        $left={direction === 'prev' ? '-24px' : undefined}
        $right={direction === 'next' ? '-24px' : undefined}
        $top={12}
      >
        <ArrowWrapper $backgroundColorName="white" $borderColorName="primary-30" $borderRadius={40} $borderSize={1}>
          {direction === 'prev' && <Icon colorName="primary" name="arrow-left" size={24} />}
          {direction === 'next' && <Icon colorName="primary" name="arrow-right" size={24} />}
        </ArrowWrapper>
      </PositionAbsolute>
    </PositionRelative>
  </Pressable>
)

const TherapistSwitcherSmall = ({ direction, onClick }: TherapistSwitcherProps) => (
  <Pressable onClick={onClick}>
    <PositionRelative>
      <PositionAbsolute
        $left={direction === 'prev' ? '-2px' : undefined}
        $right={direction === 'next' ? '-2px' : undefined}
        $top={80}
      >
        <ArrowWrapper $backgroundColorName="white" $borderColorName="primary-30" $borderRadius={40} $borderSize={1}>
          {direction === 'prev' && <Icon colorName="primary" name="arrow-left" size={24} />}
          {direction === 'next' && <Icon colorName="primary" name="arrow-right" size={24} />}
        </ArrowWrapper>
      </PositionAbsolute>
    </PositionRelative>
  </Pressable>
)

export const SuggestedTherapistsSwitcher = () => {
  const form = useReactHookFormContext<ReservationFormValues>()
  const { suggestedTherapistsProfiles: therapists, goNext, goBack, index } = useReservationFlow()

  const resetFormState = useCallback(() => {
    form.setValue('selectedTimeSlot', null)
    form.trigger('selectedTimeSlot')
  }, [form])

  const onGoPrev = useCallback(() => {
    resetFormState()
    goBack()
  }, [goBack, resetFormState])

  const onGoNext = useCallback(() => {
    resetFormState()
    goNext()
  }, [goNext, resetFormState])

  const previousIndex = useMemo(
    () => (therapists.length > 2 && index === 0 ? therapists.length - 1 : index - 1),
    [index, therapists],
  )

  const nextIndex = useMemo(
    () => (therapists.length > 2 && index === therapists.length - 1 ? 0 : index + 1),
    [index, therapists],
  )

  return (
    <PositionRelativeWithIndex>
      <PositionAbsolute $left={0} $right={0} $top={120}>
        <Flex $align="center" $direction="row" $justify="space-between">
          <LeftSwitcher $px={16}>
            <Visibility visibility={therapists[previousIndex] ? 'visible' : 'hidden'}>
              <MountOn mediaQuery="gt-md">
                <TherapistSwitcher direction="prev" onClick={onGoPrev} therapist={therapists[previousIndex]} />
              </MountOn>
              <MountOn mediaQuery="lt-lg">
                <TherapistSwitcherSmall direction="prev" onClick={onGoPrev} therapist={therapists[previousIndex]} />
              </MountOn>
            </Visibility>
          </LeftSwitcher>
          <RightSwitcher $px={16}>
            <Visibility visibility={therapists[nextIndex] ? 'visible' : 'hidden'}>
              <MountOn mediaQuery="gt-md">
                <TherapistSwitcher direction="next" onClick={onGoNext} therapist={therapists[nextIndex]} />
              </MountOn>
              <MountOn mediaQuery="lt-lg">
                <TherapistSwitcherSmall direction="next" onClick={onGoNext} therapist={therapists[nextIndex]} />
              </MountOn>
            </Visibility>
          </RightSwitcher>
        </Flex>
      </PositionAbsolute>
    </PositionRelativeWithIndex>
  )
}
