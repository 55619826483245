import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { TherapistTherapiesProvider } from '~/domains/therapies/TherapistTherapiesProvider'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyTherapySessionSchedule = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const TherapySessionSchedulePage: Page = {
  exact: true,
  id: 'therapy-sessions.schedule',
  paths: ['/therapy-session/:therapyId/schedule'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'therapy-session.schedule',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertTherapist>{children}</AssertTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: TherapistTherapiesProvider,
  PageView: () => <LazyTherapySessionSchedule />,
}
