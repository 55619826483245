import { type FindTherapistProfileByIdQuery } from '~/types/graphql'

type TherapistProfile = NonNullable<FindTherapistProfileByIdQuery['findTherapistProfileById']>

export type UserMaybeTherapist = Omit<TherapistProfile, 'pathologies'> & {
  pathologies: {
    pathology: Pick<TherapistProfile['pathologies'][number]['pathology'], 'id' | 'name'>
  }[]
}

export type UserTherapist = {
  firstName: UserMaybeTherapist['firstName']
  fullName: UserMaybeTherapist['fullName']
  id: UserMaybeTherapist['id']
  pathologies: UserMaybeTherapist['pathologies']
  therapist: NonNullable<UserMaybeTherapist['therapist']>
}

export const isUserTherapist = (user: UserMaybeTherapist): user is UserTherapist => !!user?.therapist
