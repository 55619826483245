import { addMinutes, addSeconds, differenceInSeconds, eachMinuteOfInterval, secondsToMinutes, toDate } from 'date-fns'
import { isIntervalWithinInterval } from 'dates'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapySessionAvailabilityDurationInSeconds } from '~/utils/getTherapySessionAvailabilityDurationInSeconds'

type Availability = {
  endAt: Date
  startAt: Date
}

export const getCalendarAvailableTimeSlotFromAvailabilities = (
  availabilities: Availability[],
  therapyPathType: TherapyTherapyPathType,
) => {
  const slotDuration = getTherapySessionAvailabilityDurationInSeconds(therapyPathType)
  const result: Date[] = []

  for (const availability of availabilities) {
    // NOTE: start and end in availability are timestamps, we need to convert them to date
    const start = toDate(availability.startAt)
    const end = toDate(availability.endAt)

    const availabilityDuration = differenceInSeconds(end, start)
    if (availabilityDuration < slotDuration) {
      continue
    }

    const slotContainsOnlyOneTherapySession = differenceInSeconds(end, start) < slotDuration * 2
    if (slotContainsOnlyOneTherapySession) {
      result.push(start)
      result.push(addMinutes(start, 15))
      result.push(addMinutes(start, 30))
      result.push(addMinutes(start, 45))
    } else {
      const options = { step: secondsToMinutes(slotDuration) }
      result.push(...eachMinuteOfInterval({ end, start }, options))
    }
  }

  // filter out slots that are not within the availabilities
  const filteredSlots = result.filter((slotStart) => {
    const slotInterval = { end: addSeconds(slotStart, slotDuration), start: slotStart }

    return availabilities.some(({ endAt: end, startAt: start }) =>
      isIntervalWithinInterval({ end, start })(slotInterval),
    )
  })

  const uniqueResult = Array.from(new Set(filteredSlots))

  uniqueResult.sort((a, b) => a.getTime() - b.getTime())

  return uniqueResult
}
