import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazySettingsBilling = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const SettingsBillingPage: Page = {
  exact: true,
  id: 'settings.billing',
  paths: ['/settings/billing'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'settings.billing',
    title: 'settings.billing.appbar.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PatientTherapiesProvider,
  PageView: () => <LazySettingsBilling />,
}
