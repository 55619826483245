import { gql, useMutation } from '@apollo/client'
import { type UpdateProductReviewScoreMutation, type UpdateProductReviewScoreMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation updateProductReviewScore($input: UpdateProductReviewScoreInput!) {
    updateProductReviewScore(input: $input) {
      id
    }
  }
`

export const useUpdateProductReviewScore = () =>
  useMutation<UpdateProductReviewScoreMutation, UpdateProductReviewScoreMutationVariables>(MUTATION)
