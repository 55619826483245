import { Icon } from 'icons'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type Route } from '~/utils/getRoute'
import { MenuLink } from '../common/MenuLink'

const to: Route = '/notifications'

export const MenuItemNotifications = () => {
  const { pathname } = useLocation()
  const active = !!matchPath(pathname, { path: to })

  return (
    <MenuLink to={to}>
      <Flex pr={12}>
        <Icon colorName="grey-60" name="multi-window" size={24} />
      </Flex>

      <Flex grow={1} pt={2} shrink={1}>
        <Text colorName={active ? 'primary' : undefined} fontWeight="400" kind="paragraph">
          <Translation id="menu.notifications" />
        </Text>
      </Flex>
    </MenuLink>
  )
}
