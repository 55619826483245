import { useCallback } from 'react'
import { type ModalId } from '~/domains/modals'
import { useTrackEvent } from './useTrackEvent'

type Action = 'dismiss' | 'open'

export const useTrackEventModal = (action: Action) => {
  const trackEvent = useTrackEvent('MODAL')

  return useCallback((id: ModalId) => trackEvent({ name: `modals.${action}`, payload: { id } }), [action, trackEvent])
}
