import { gql, useMutation } from '@apollo/client'
import { type SendVerificationCodeMutation, type SendVerificationCodeMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation sendVerificationCode($input: SendVerificationCodeInput!) {
    sendVerificationCode(input: $input) {
      ok
    }
  }
`

export const useSendVerificationCode = () =>
  useMutation<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>(MUTATION)
