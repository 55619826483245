import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyProfileEducationPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.ProfileEducationPageView,
})

export const ProfileEducationPage: Page = {
  exact: true,
  id: 'profile.education',
  paths: ['/profile/education'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'profile.education',
    title: 'profile.education.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertTherapist target="/">{children}</AssertTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => {
    const { id } = useCurrentUser()

    return <TherapistFetchProvider id={id}>{children}</TherapistFetchProvider>
  },
  PageView: () => <LazyProfileEducationPageView />,
}
