import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyShareJournalingRewardStickerPage = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const ShareJournalingRewardStickerPage: Page = {
  exact: true,
  id: 'journaling.log.share',
  paths: [
    '/share/reward/journaling/first_log',
    '/share/reward/journaling/weekly_achievement',
    '/share/reward/journaling/monthly_achievement',
  ],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'journaling.log.share',
    title: 'share.journaling.score.appBar.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyShareJournalingRewardStickerPage />,
}
