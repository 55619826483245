import { type ColorName } from 'design-tokens'
import { Icon } from 'icons'
import { useMemo } from 'react'
import { components, type DropdownIndicatorProps } from 'react-select'
import { type SelectOption } from '~/components/SelectNew/types'

export const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption>) => {
  const colorName: ColorName = useMemo(() => {
    if (props.isDisabled) {
      return 'grey-60'
    }

    if (props.isFocused) {
      return 'primary-80'
    }

    return 'primary'
  }, [props])

  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <Icon colorName={colorName} name="nav-arrow-up" size={24} />
      ) : (
        <Icon colorName={colorName} name="nav-arrow-down" size={24} />
      )}
    </components.DropdownIndicator>
  )
}
