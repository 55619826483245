import { Flex } from 'cdk'
import { SPACING_LG } from 'design-tokens'
import NextLink from 'next/link'
import { useCallback } from 'react'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useIsAppleDevice } from '~/hooks/useIsAppleDevice'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useTherapist } from '~/hooks/useTherapist'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'
import { getRoute, type Route } from '~/utils/getRoute'
import { useLazyGetTherapySessionIcs } from '../hooks/useGetTherapySessionIcs'
import { downloadCalendarEvent } from '../utils/downloadCalendarEvent'

const secondaryActionLinkByTherapyPathType: Record<TherapyTherapyPathType, Route> = {
  COUPLES_PSYCHOTHERAPY: '/onboarding/couples',
  MYSELF_PSYCHIATRY: '/',
  MYSELF_PSYCHOTHERAPY: '/',
  PATH_ASSERTIVE_COMMUNICATION: '/',
  PATH_COACHING: '/',
  PATH_NUTRITION_DCA: '/',
  PATH_NUTRITION_WEIGHT_LOSS: '/',
  PATH_SEXOLOGY: '/',
  PATH_SLEEP: '/',
  UNDERAGE_PSYCHOTHERAPY: '/onboarding/guardian',
}

type ThankYouPageActionsProps = {
  therapySessionId: string
  therapyPathType?: TherapyTherapyPathType | null
}

export const ThankYouPageActions = ({ therapyPathType, therapySessionId }: ThankYouPageActionsProps) => {
  const trackEventClick = useTrackEventClick()
  const { id: therapistId } = useTherapist()
  const lazyFetch = useLazyGetTherapySessionIcs()
  const { isVariant1, isVariant2, isVariant3 } = useFeatureFlagsByUserId()

  const rootHistory = useRootHistory()

  const storeUrl = useIsAppleDevice() ? getEnv('SERENIS_APP_STORE') : getEnv('SERENIS_PLAY_STORE')
  const addToCalendar = isVariant1('ff_thank_you_page')
  const openChat = isVariant2('ff_thank_you_page')
  const downloadApp = isVariant3('ff_thank_you_page')

  const handleDownloadCalendarEvent = useCallback(async () => {
    trackEventClick('thank-you-page.download-calendar-event', { therapySessionId })

    const { data } = await lazyFetch({ variables: { id: therapySessionId } })

    if (data?.therapySessionIcs) {
      downloadCalendarEvent({
        base64: data.therapySessionIcs.icsAsBase64,
        fileName: data.therapySessionIcs.fileName,
      })
    }

    rootHistory.push(getRoute('/'))
  }, [lazyFetch, rootHistory, therapySessionId, trackEventClick])

  const handleOpenChat = useCallback(() => {
    trackEventClick('thank-you-page.open-chat', { therapySessionId })
    rootHistory.push(getRoute(`/chat/${therapistId}`))
  }, [rootHistory, therapistId, therapySessionId, trackEventClick])

  const handleDownloadApp = useCallback(() => {
    trackEventClick('thank-you-page.download-app', { therapySessionId })
    rootHistory.push(getRoute('/'))
  }, [rootHistory, therapySessionId, trackEventClick])

  const handleSecondaryAction = useCallback(() => {
    trackEventClick('thank-you-page.go-to-homepage', { therapySessionId })
    const root = therapyPathType ? secondaryActionLinkByTherapyPathType[therapyPathType] : getRoute('/')
    rootHistory.push(root)
  }, [rootHistory, therapyPathType, therapySessionId, trackEventClick])

  return (
    <Flex $gap={SPACING_LG}>
      {addToCalendar && (
        <Button kind="primary" onClick={handleDownloadCalendarEvent}>
          <Translation id="thankYouPage.therapist-card.action.primary.variant1" />
        </Button>
      )}
      {openChat && (
        <Button kind="primary" onClick={handleOpenChat}>
          <Translation id="thankYouPage.therapist-card.action.primary.variant2" />
        </Button>
      )}
      {downloadApp && (
        <Flex as={NextLink} href={storeUrl} target="_blank">
          <Button kind="primary" onClick={handleDownloadApp}>
            <Translation id="thankYouPage.therapist-card.action.primary.variant3" />
          </Button>
        </Flex>
      )}

      <Button data-test-id="thx-cta-homepage" kind="tertiary" onClick={handleSecondaryAction}>
        <Translation id="thankYouPage.therapist-card.action.secondary" />
      </Button>
    </Flex>
  )
}
