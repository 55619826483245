import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { AssertWithoutLegalPartners } from '~/domains/onboardingGuardian/components/AssertWithoutLegalPartners'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { AssertTherapyPathUnderage } from '../../domains/onboardingGuardian/components/AssertTherapyPathUnderage'

const OnboardingGuardianPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const OnboardingGuardianPage: Page = {
  exact: true,
  id: 'onboarding.guardian',
  paths: ['/onboarding/guardian'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'onboarding.guardian',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">
        <PatientTherapiesProvider>
          <AssertTherapyPathUnderage>
            <AssertWithoutLegalPartners>{children}</AssertWithoutLegalPartners>
          </AssertTherapyPathUnderage>
        </PatientTherapiesProvider>
      </AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <OnboardingGuardianPageView />,
}
