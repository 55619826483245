import { gql, useMutation } from '@apollo/client'
import {
  type DeleteForTherapistEmergencyMutation,
  type DeleteForTherapistEmergencyMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation deleteForTherapistEmergency($input: DeleteForTherapistEmergencyInput!) {
    deleteForTherapistEmergency(input: $input) {
      id
      therapistEmergencyReason
    }
  }
`

export const useDeleteForTherapistEmergency = () =>
  useMutation<DeleteForTherapistEmergencyMutation, DeleteForTherapistEmergencyMutationVariables>(MUTATION)
