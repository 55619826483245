import { gql, useLazyQuery } from '@apollo/client'
import { type GetCourierTokenQuery, type GetCourierTokenQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getCourierToken {
    getCourierToken {
      token
    }
  }
`

export const useCourierToken = () => {
  const [getToken, { data, loading }] = useLazyQuery<GetCourierTokenQuery, GetCourierTokenQueryVariables>(QUERY)

  return {
    getToken,
    token: data?.getCourierToken?.token,
    loading,
  }
}
