import { gql, useQuery } from '@apollo/client'
import { type JournalingMoodsQuery, type JournalingMoodsQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query journalingMoods {
    journalingMoods {
      id
      name
      slug
    }
  }
`

export const useJournalingMoods = () => {
  const { data, error, loading } = useQuery<JournalingMoodsQuery, JournalingMoodsQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    loading,
    journalingMoods: data?.journalingMoods || [],
  }
}
