import { Flex, MaxWidth640px } from 'cdk'
import { type ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  children: ReactNode
}

const BoxShadowBottom = styled(Flex).attrs({ $backgroundColorName: 'lighter', $p: 16 })`
  box-shadow: 0 -5px 20px 0 rgb(80 57 216 / 10%);
`

export const StepBottomBarButton = ({ children }: Props) => (
  <BoxShadowBottom>
    <MaxWidth640px>{children}</MaxWidth640px>
  </BoxShadowBottom>
)
