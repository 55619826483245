import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { RangeIndicator } from '../RangeIndicator'

type TherapistStyleProps = {
  formalityScore: number | undefined
  rationalityScore: number | undefined
  sex: number
}

const TherapistStyle = ({ formalityScore, rationalityScore, sex }: TherapistStyleProps) => (
  <Flex $gap={SPACING_SM}>
    <Text kind="h3">
      <Translation
        id={sex === 1 ? 'therapist.overviewCard.style.title.male' : 'therapist.overviewCard.style.title.female'}
      />
    </Text>
    <RangeIndicator
      leftIndicator="therapist.overviewCard.style.formality.negative"
      rangeValue={formalityScore}
      rightIndicator="therapist.overviewCard.style.formality.positive"
    />
    <RangeIndicator
      leftIndicator="therapist.overviewCard.style.rationality.negative"
      rangeValue={rationalityScore}
      rightIndicator="therapist.overviewCard.style.rationality.positive"
    />
  </Flex>
)

type TherapistDuringSessionProps = {
  structuringScore: number | undefined
  directivityScore: number | undefined
}

const TherapistDuringSession = ({ structuringScore, directivityScore }: TherapistDuringSessionProps) => (
  <Flex $gap={SPACING_SM}>
    <Text kind="h3">
      <Translation id="therapist.overviewCard.duringSession.title" />
    </Text>
    <RangeIndicator
      leftIndicator="therapist.overviewCard.duringSession.structuring.negative"
      rangeValue={structuringScore}
      rightIndicator="therapist.overviewCard.duringSession.structuring.positive"
    />
    <RangeIndicator
      leftIndicator="therapist.overviewCard.duringSession.directivity.negative"
      rangeValue={directivityScore}
      rightIndicator="therapist.overviewCard.duringSession.directivity.positive"
    />
  </Flex>
)

export const TherapistOverviewCardDetailContentIndicators = ({
  directivityScore,
  formalityScore,
  rationalityScore,
  sex,
  structuringScore,
}: TherapistStyleProps & TherapistDuringSessionProps) => (
  <>
    <TherapistStyle formalityScore={formalityScore} rationalityScore={rationalityScore} sex={sex} />
    <TherapistDuringSession directivityScore={directivityScore} structuringScore={structuringScore} />
  </>
)
