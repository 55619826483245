import { gql, useMutation } from '@apollo/client'
import { type DeleteRecurrencyMutation, type DeleteRecurrencyMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation deleteRecurrency($input: DeleteRecurrencyInput!) {
    deleteRecurrency(input: $input) {
      id
    }
  }
`

export const useDeleteRecurrency = () =>
  useMutation<DeleteRecurrencyMutation, DeleteRecurrencyMutationVariables>(MUTATION)
