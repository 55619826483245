import { gql, useQuery } from '@apollo/client'
import { type FindTherapyPathByIdQuery, type FindTherapyPathByIdQueryVariables } from '~/types/graphql'
import { useTherapyPathIdLocationParam } from './useTherapyPathIdLocationParam'

const QUERY = gql`
  query findTherapyPathById($id: String) {
    findTherapyPathById(id: $id) {
      active
      cost
      costFirst
      duration
      id
      type
    }
  }
`

export const useTherapyPathByIdQuery = () => {
  const therapyPathId = useTherapyPathIdLocationParam()

  const { data, loading } = useQuery<FindTherapyPathByIdQuery, FindTherapyPathByIdQueryVariables>(QUERY, {
    variables: {
      id: therapyPathId,
    },
  })

  return {
    loading,
    therapyPath: data?.findTherapyPathById ?? null,
  }
}
