import { createContext, type ReactNode, useContext } from 'react'
import { type UserTherapist } from '~/domains/therapist/types'

type Props = {
  children: ReactNode
  therapist: UserTherapist
}

export const TherapistContext = createContext<UserTherapist | null>(null)

export const TherapistProvider = ({ children, therapist }: Props) => (
  <TherapistContext.Provider value={therapist}>{children}</TherapistContext.Provider>
)

export const useTherapist = () => {
  const therapist = useContext(TherapistContext)

  if (!therapist) {
    throw new Error('The `useTherapist` should be wrapped with `TherapistProvider`.')
  }

  return therapist
}
