import { noop } from 'functions'
import { useCallback, useMemo } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { type TranslationId } from '~/i18n/types'
import { type TherapySessionStatus, type TherapySessionTypeCost } from '~/types/graphql'
import { isBeforeCancelationPolicyLimit } from '~/utils/dates/isBeforeCancelationPolicyLimit'
import { isTherapySessionSuccessfullyPaid } from '~/utils/dates/isTherapySessionSuccessfullyPaid'

type Props = {
  onClose?: () => void
  id: string
  initialStartAt: Date
  patientFirstName?: string
  status: TherapySessionStatus
  typeCost: TherapySessionTypeCost
}

export const ModalRescheduleTherapySessionWarning = ({
  id,
  initialStartAt,
  onClose = noop,
  patientFirstName,
  status,
  typeCost,
}: Props) => {
  const modalId = `rescheduleTherapySessionWarning:${id}` as const

  const { open, close, dismiss } = useModals()

  const handleCancel = useCallback(() => {
    dismiss(modalId)

    onClose()
  }, [dismiss, onClose, modalId])

  const goNext = useCallback(() => {
    close(modalId)

    open(`rescheduleTherapySession:${id}`)
  }, [close, id, modalId, open])

  const titleTranslationId = useMemo((): TranslationId => {
    if (isTherapySessionSuccessfullyPaid({ status, typeCost }) && !isBeforeCancelationPolicyLimit(initialStartAt)) {
      return 'availabilities.calendar.rescheduleTherapySession.paid.underCancelationPolicyLimit.title'
    }

    if (isTherapySessionSuccessfullyPaid({ status, typeCost }) && isBeforeCancelationPolicyLimit(initialStartAt)) {
      return 'availabilities.calendar.rescheduleTherapySession.paid.beforeCancelationPolicyLimit.title'
    }

    return 'availabilities.calendar.rescheduleTherapySession.free.underCancelationPolicyLimit.title'
  }, [initialStartAt, status, typeCost])

  const paragraphTranslationId = useMemo((): TranslationId => {
    if (isTherapySessionSuccessfullyPaid({ status, typeCost }) && !isBeforeCancelationPolicyLimit(initialStartAt)) {
      return patientFirstName
        ? 'availabilities.calendar.rescheduleTherapySession.paid.underCancelationPolicyLimit.1.paragraph'
        : 'availabilities.calendar.rescheduleTherapySession.paid.underCancelationPolicyLimit.2.paragraph'
    }

    if (isTherapySessionSuccessfullyPaid({ status, typeCost }) && isBeforeCancelationPolicyLimit(initialStartAt)) {
      return patientFirstName
        ? 'availabilities.calendar.rescheduleTherapySession.paid.beforeCancelationPolicyLimit.1.paragraph'
        : 'availabilities.calendar.rescheduleTherapySession.paid.beforeCancelationPolicyLimit.2.paragraph'
    }

    return patientFirstName
      ? 'availabilities.calendar.rescheduleTherapySession.free.underCancelationPolicyLimit.1.paragraph'
      : 'availabilities.calendar.rescheduleTherapySession.free.underCancelationPolicyLimit.2.paragraph'
  }, [patientFirstName, initialStartAt, status, typeCost])

  return (
    <Modal id={modalId}>
      <ModalCloseButton id={modalId} onClose={handleCancel} />
      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation id={titleTranslationId} />
        </Text>

        <TranslationMarkdown
          colorName="black"
          id={paragraphTranslationId}
          kind="paragraph"
          pt={16}
          values={{
            patientFirstName: patientFirstName || '',
          }}
        />
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" onClick={goNext} type="button">
          <Translation id="therapySession.deleteForTherapistEmergency.step.warning.confirm" />
        </Button>
      </Flex>
    </Modal>
  )
}
