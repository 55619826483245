import { Flex, OverflowAuto } from 'cdk'
import { useHistory } from 'react-router-dom'
import { Button, Text, useModalContext } from 'ui'
import { ChangeProfessionalTranslation } from '~/components/ChangeProfessionalTranslation'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { mapTherapyAlreadyOngoingType } from '~/domains/reservation/utils/mapTherapyAlreadyOngoingType'
import { useChangeNutritionist } from '~/hooks/useChangeNutritionist'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { StartTherapyPathIcon } from './StartTherapyPathIcon'

type Props = {
  onChangeTherapist: () => void
  therapyPathType?: TherapyTherapyPathType
  therapistFullName?: string
  therapistId?: string
}

export const TherapyAlreadyOngoingModalContent = ({
  onChangeTherapist,
  therapyPathType,
  therapistFullName,
  therapistId,
}: Props) => {
  const history = useHistory()
  const { setIsOpen } = useModalContext()
  const changeNutritionist = useChangeNutritionist()

  const isNutritionTherapyPath =
    therapyPathType != null && ['PATH_NUTRITION_DCA', 'PATH_NUTRITION_WEIGHT_LOSS'].includes(therapyPathType)
  const therapyPathIsCoaching = therapyPathType === 'PATH_COACHING'
  const therapyPathIsPsichiatry = therapyPathType === 'MYSELF_PSYCHIATRY'

  const onGoToChat = () => {
    if (!therapistId) {
      return null
    }

    setIsOpen(false)

    history.push(getRoute(`/chat/${therapistId}`))
  }

  const handleChangeProfessional = () => {
    setIsOpen(false)

    if (isNutritionTherapyPath) {
      changeNutritionist()
      return
    }

    if (therapyPathIsCoaching || therapyPathIsPsichiatry) {
      return
    }

    onChangeTherapist()
  }

  if (!therapyPathType) {
    return null
  }

  return (
    <>
      <OverflowAuto data-test-id={`therapy-path-${therapyPathType.toLowerCase()}-already-ongoing-modal`}>
        <StartTherapyPathIcon
          size={80}
          therapyPathType={mapTherapyAlreadyOngoingType[therapyPathType].illustrationTherapyPath}
        />

        <Flex $pt={8}>
          <Text fontWeight="600" kind="h2">
            <Translation id={mapTherapyAlreadyOngoingType[therapyPathType].title} />
          </Text>
        </Flex>

        <Flex $pt={16}>
          {therapistFullName ? (
            <TranslationMarkdown
              id="reservation.therapyAlreadyOngoing.description.1"
              kind="paragraph"
              values={{ therapistFullName }}
            />
          ) : (
            <Text kind="paragraph" textAlign="center">
              <Translation id="reservation.therapyAlreadyOngoing.description.1.withoutTherapistFullName" />
            </Text>
          )}

          {!therapyPathIsCoaching && !therapyPathIsPsichiatry && (
            <Flex $pt={32}>
              <Text kind="paragraph">
                <Translation id="reservation.therapyAlreadyOngoing.description.2" />
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex $gap={8} $pt={24}>
          <Button kind="primary" onClick={onGoToChat} type="submit">
            <Translation id="actions.goToChat" />
          </Button>

          {!therapyPathIsCoaching && !therapyPathIsPsichiatry && (
            <Button isGhost kind="secondary" onClick={handleChangeProfessional} type="button">
              <ChangeProfessionalTranslation therapyPathType={therapyPathType} />
            </Button>
          )}
        </Flex>
      </OverflowAuto>
    </>
  )
}
