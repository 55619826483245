import { Flex, MountOn } from 'cdk'
import { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useLanguage } from '~/i18n/hooks/useLanguage'

const TrustpilotWrapperMobile = styled(Flex).attrs({
  $pb: 24,
})`
  align-items: flex-start;
  margin-left: -56px;
`

const scriptId = 'trustpilot-script'
const widgetClass = 'trustpilot-widget'

const mobileWidgetId = '5419b6ffb0d04a076446a9af'
const desktopWidgetId = '53aa8807dec7e10d38f59f32'

export const TrustpilotWidget = () => {
  const { language } = useLanguage()

  const appendTrustpilotScript = useCallback(() => {
    const script = document.createElement('script')

    script.id = scriptId
    script.type = 'text/javascript'
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true

    document.head.appendChild(script)

    script.onload = function () {
      const element = document.querySelector(`.${widgetClass}`)

      if (element && window.Trustpilot) {
        window.Trustpilot.loadFromElement(element)
      }
    }
  }, [])

  useEffect(() => {
    const trustPilotScript = document.getElementById(scriptId)

    if (trustPilotScript?.parentElement) {
      trustPilotScript.parentElement.removeChild(trustPilotScript)
    }

    appendTrustpilotScript()
  }, [appendTrustpilotScript])

  return (
    <>
      <MountOn mediaQuery="gt-md">
        <div
          className="trustpilot-widget"
          data-businessunit-id="612cc718df8a85001c7f3815"
          data-locale={language}
          data-style-height="100px"
          data-style-width="180px"
          data-template-id={desktopWidgetId}
          data-theme="dark"
          style={{ pointerEvents: 'none' }}
        />
      </MountOn>
      <MountOn mediaQuery="lt-lg">
        <TrustpilotWrapperMobile>
          <div
            className="trustpilot-widget"
            data-businessunit-id="612cc718df8a85001c7f3815"
            data-locale={language}
            data-style-height="20px"
            data-style-width="100%"
            data-template-id={mobileWidgetId}
            data-theme="dark"
            style={{ pointerEvents: 'none' }}
          />
        </TrustpilotWrapperMobile>
      </MountOn>
    </>
  )
}
