import { useStripe } from '@stripe/react-stripe-js'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useAddPaymentMethodByPaymentElement } from '~/domains/payments/hooks/useAddPaymentMethodByPaymentElement'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'

export const SettingsPaymentsConfirm = () => {
  const stripe = useStripe()
  const { addToast } = useToasts()
  const history = useHistory()
  const addPaymentMethodByPaymentElement = useAddPaymentMethodByPaymentElement()

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret')
    const returnRoute = new URLSearchParams(window.location.search).get('returnRoute')

    if (!clientSecret) {
      addToast({
        translationId: 'payments.confirmPaymentElement.error',
        type: 'alert',
      })

      history.replace(getRoute('/settings/payments'))

      return
    }

    stripe.retrieveSetupIntent(clientSecret).then(async ({ setupIntent }) => {
      switch (setupIntent?.status) {
        case 'succeeded': {
          const paymentMethodId = setupIntent.payment_method

          await addPaymentMethodByPaymentElement(String(paymentMethodId))

          break
        }
        case 'processing':
          addToast({
            translationId: 'payments.confirmPaymentElement.onHold',
            type: 'alert',
          })
          break
        case 'requires_payment_method':
          addToast({
            translationId: 'payments.confirmPaymentElement.error',
            type: 'alert',
          })
          break
        default:
          addToast({
            translationId: 'payments.confirmPaymentElement.error',
            type: 'alert',
          })
          break
      }

      history.replace(returnRoute || getRoute('/settings/payments'))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe])

  return <CenteredLoader />
}
