import { gql, useMutation } from '@apollo/client'
import {
  type SendClinicalTestToPatientMutation,
  type SendClinicalTestToPatientMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation sendClinicalTestToPatient($input: SendToPatientInput!) {
    sendClinicalTestToPatient(input: $input) {
      ok
    }
  }
`

export const useSendClinicalTestToPatient = () =>
  useMutation<SendClinicalTestToPatientMutation, SendClinicalTestToPatientMutationVariables>(MUTATION)
