import { useMemo } from 'react'
import styled from 'styled-components'
import { Flex } from 'ui-deprecated'
import { DropdownMenu } from '~/components/Dropdown/Menu'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { type TherapySessionStatus } from '~/types/graphql'
import { isEndedTherapySession } from '~/utils/dates/isEndedTherapySession'
import { isLiveTherapySession } from '~/utils/dates/isLiveTherapySession'
import { DeleteForTherapistEmergency } from './DeleteForTherapistEmergency'
import { DeleteRecurrency } from './DeleteRecurrency'
import { MarkAsNoShowTherapySession } from './MarkAsNoShowTherapySession'
import { RescheduleTherapySession } from './RescheduleTherapySession'

const DropdownItem = styled(Flex)`
  margin-left: -4px;
`

type Props = Pick<
  TherapistAgendaTherapySession,
  'endAt' | 'free' | 'id' | 'patient' | 'recurrency' | 'startAt' | 'status' | 'therapyPathType' | 'typeCost'
>

export const DropdownMenuTherapist = ({
  endAt,
  free,
  id,
  patient,
  recurrency,
  startAt,
  status,
  therapyPathType,
  typeCost,
}: Props) => {
  const showReschedule = useMemo(
    () => (['CONFIRMED', 'PAID', 'SCHEDULED', 'NO_PAYMENT_METHOD'] as TherapySessionStatus[]).includes(status),
    [status],
  )

  const therapySessionIsInThePast = isEndedTherapySession({ endAt })
  const therapySessionIsLive = isLiveTherapySession({ endAt, startAt })

  const showDeleteRecurrency = Boolean(recurrency) && !therapySessionIsInThePast && !therapySessionIsLive

  const showDeleteEmergency = !therapySessionIsInThePast && !therapySessionIsLive

  const hideDropdown = [showDeleteRecurrency, showDeleteEmergency, therapySessionIsLive, showReschedule].every(
    (condition) => !condition,
  )

  if (hideDropdown || status === 'NO_SHOW') {
    return null
  }

  return (
    <DropdownMenu>
      {showReschedule && (
        <DropdownItem align="flex-start">
          <RescheduleTherapySession
            id={id}
            patient={patient}
            recurrency={recurrency}
            startAt={startAt}
            status={status}
            therapyPathType={therapyPathType}
            typeCost={typeCost}
          />
        </DropdownItem>
      )}

      {showDeleteRecurrency && (
        <DropdownItem align="flex-start" pt={showReschedule ? 16 : 0}>
          <DeleteRecurrency id={id} patient={patient} />
        </DropdownItem>
      )}

      {showDeleteEmergency && (
        <DropdownItem align="flex-start" pt={showReschedule || showDeleteRecurrency ? 16 : 0}>
          <DeleteForTherapistEmergency
            free={free}
            id={id}
            patient={patient}
            startAt={startAt}
            status={status}
            typeCost={typeCost}
          />
        </DropdownItem>
      )}

      {therapySessionIsLive && (
        <DropdownItem align="flex-start" pt={showReschedule || showDeleteRecurrency || showDeleteEmergency ? 16 : 0}>
          <MarkAsNoShowTherapySession id={id} patient={patient} startAt={startAt} />
        </DropdownItem>
      )}
    </DropdownMenu>
  )
}
