import { useMemo } from 'react'
import { type JournalingLogScore } from '~/types/graphql'
import { JournalingLogScoreIllustration1 } from './JournalingLogScoreIllustration1'
import { JournalingLogScoreIllustration2 } from './JournalingLogScoreIllustration2'
import { JournalingLogScoreIllustration3 } from './JournalingLogScoreIllustration3'
import { JournalingLogScoreIllustration4 } from './JournalingLogScoreIllustration4'
import { JournalingLogScoreIllustration5 } from './JournalingLogScoreIllustration5'

type Props = {
  score: JournalingLogScore
  size: number
}

export const JournalingLogScoreIllustration = ({ score, size }: Props) => {
  const Illustration = useMemo(() => {
    switch (score) {
      case 'ONE':
        return JournalingLogScoreIllustration1
      case 'TWO':
        return JournalingLogScoreIllustration2
      case 'THREE':
        return JournalingLogScoreIllustration3
      case 'FOUR':
        return JournalingLogScoreIllustration4
      case 'FIVE':
        return JournalingLogScoreIllustration5
      default:
        return null
    }
  }, [score])

  if (!Illustration) {
    return null
  }

  return <Illustration size={size} />
}
