import { useCallback } from 'react'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'

export const useChangeNutritionist = () => {
  const { openWithCustomMessage, update } = useIntercom()

  return useCallback(() => {
    update({
      customAttributes: {
        srns_change_nutritionist: true,
      },
    })
    openWithCustomMessage('')
  }, [openWithCustomMessage, update])
}
