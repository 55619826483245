import { Translation } from '~/components/Translation'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type TranslationId } from '~/i18n/types'
import { type ClinicalTestCode } from '~/types/graphql'

const translationsByClinicalTestCode: Record<
  ClinicalTestCode,
  Extract<TranslationId, `clinicalTests.tests.${ClinicalTestCode}.name`>
> = {
  GAD_7: 'clinicalTests.tests.GAD_7.name',
  PHQ_9: 'clinicalTests.tests.PHQ_9.name',
}

type ClinicalTestNameTranslationProps = {
  code: ClinicalTestCode
}

export const useClinicalTestNameTranslation = (code: ClinicalTestNameTranslationProps['code']) =>
  useTranslation(translationsByClinicalTestCode[code])

export const ClinicalTestNameTranslation = ({ code }: ClinicalTestNameTranslationProps) => (
  <Translation id={translationsByClinicalTestCode[code]} />
)
