import { Flex } from 'cdk'
import { type PropsWithChildren } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type ClinicalTestCode } from '~/types/graphql'

type CellProps = PropsWithChildren<{
  isLast?: boolean
}>

const Cell = ({ children, isLast }: CellProps) => (
  <Flex $borderColorName="neutral-40" $borderSizeBottom={isLast ? 0 : 1} $pb={isLast ? 0 : 8} $pt={8}>
    <Text>{children}</Text>
  </Flex>
)

type ClinicalTestScoresScaleProps = {
  code: ClinicalTestCode
}

export const ClinicalTestScoresScale = ({ code }: ClinicalTestScoresScaleProps) => {
  const showItemFrom22 = code === 'PHQ_9'

  return (
    <Flex $direction="row" $pt={8}>
      <Flex $basis={100}>
        <Cell>
          <Translation id="clinicalTests.results.0.values" />
        </Cell>
        <Cell>
          <Translation id="clinicalTests.results.1.values" />
        </Cell>
        <Cell>
          <Translation id="clinicalTests.results.2.values" />
        </Cell>
        <Cell isLast={!showItemFrom22}>
          <Translation id="clinicalTests.results.3.values" />
        </Cell>
        {showItemFrom22 && (
          <Cell isLast>
            <Translation id="clinicalTests.results.4.values" />
          </Cell>
        )}
      </Flex>
      <Flex $grow={1} $shrink={1}>
        <Cell>
          <Translation id="clinicalTests.results.0.name" />
        </Cell>
        <Cell>
          <Translation id="clinicalTests.results.1.name" />
        </Cell>
        <Cell>
          <Translation id="clinicalTests.results.2.name" />
        </Cell>
        <Cell isLast={!showItemFrom22}>
          <Translation id="clinicalTests.results.3.name" />
        </Cell>
        {showItemFrom22 && (
          <Cell isLast>
            <Translation id="clinicalTests.results.4.name" />
          </Cell>
        )}
      </Flex>
    </Flex>
  )
}
