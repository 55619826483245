import { type ComponentProps } from 'react'
import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { Select } from 'ui-deprecated'
import { useLoadingState } from '~/hooks/useLoadingState'

type SelectProps = ComponentProps<typeof Select>

type Props = Omit<SelectProps, 'errorMessage' | 'value'> & {
  rules?: RegisterOptions
}

export const ReactHookFormSelect = ({ defaultValue = '', disabled, name, rules, ...props }: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error, invalid },
        formState: { isSubmitting, isSubmitted, isValid },
      }) => (
        <Select
          {...props}
          ref={ref}
          disabled={disabled || ((isSubmitting || loading) && isValid)}
          errorMessage={error?.message}
          invalid={invalid && isSubmitted}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      )}
      rules={rules}
    />
  )
}
