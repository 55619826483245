import loadable from '@loadable/component'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyResetPasswordPage = loadable(() => import('~/domains/auth/pages/ResetPasswordPage'), {
  resolveComponent: (components) => components.ResetPasswordPage,
})

export const ResetPasswordPage: Page = {
  exact: true,
  id: 'auth.reset-password',
  paths: ['/reset-password'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'resetPassword',
    title: null,
  },
  PageGuard: PageNoop,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyResetPasswordPage />,
}
