import { gql, useMutation } from '@apollo/client'
import { type UpdateProductReviewBodyMutation, type UpdateProductReviewBodyMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation updateProductReviewBody($input: UpdateProductReviewBodyInput!) {
    updateProductReviewBody(input: $input) {
      id
    }
  }
`

export const useUpdateProductReviewBody = () =>
  useMutation<UpdateProductReviewBodyMutation, UpdateProductReviewBodyMutationVariables>(MUTATION)
