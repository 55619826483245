import loadable from '@loadable/component'
import { Flex, MountOn, OverflowAuto } from 'cdk'
import { Text } from 'ui'
import { AppBarLogo } from '~/components/AppBar/components/AppBarLogo'
import { Translation } from '~/components/Translation'
import { NavigationHideAppBarEffect } from '~/domains/navigation/components/NavigationHideAppBarEffect'
import { NavigationShowAppBarEffect } from '~/domains/navigation/components/NavigationShowAppBarEffect'
import { SignupForm } from '../components/SignupForm'
import { SignupHeroContent } from '../components/SignupHeroContent'
import { useSignupIntent } from '../hooks/useSignupIntent'

const LazySignupHeroBackground = loadable(() => import('../components/SignupHeroBackground.lazy'), {
  resolveComponent: (components) => components.SignupHeroBackground,
})

export const SignupPage = () => {
  const intent = useSignupIntent()

  return (
    <OverflowAuto $pb={0}>
      <LazySignupHeroBackground>
        <MountOn mediaQuery="lt-lg">
          <NavigationShowAppBarEffect />
        </MountOn>

        <MountOn mediaQuery="gt-md">
          <NavigationHideAppBarEffect />
        </MountOn>

        <SignupHeroContent />

        <Flex $backgroundColorName="lighter" $grow={1} $shrink={1}>
          <MountOn mediaQuery="gt-md">
            <Flex $align="flex-start" $pl={64} $pt={32}>
              <AppBarLogo />
            </Flex>
          </MountOn>

          <Flex $gap={16} $grow={1} $lgPx={64} $lgPy={64} $px={16} $py={24} $shrink={1} $xlPx={128}>
            <Text fontWeight="600" kind="h2">
              <Translation id={`generic.signup.${intent}.title`} />
            </Text>
            <SignupForm />
          </Flex>
        </Flex>
      </LazySignupHeroBackground>
    </OverflowAuto>
  )
}
