import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { Modal } from '~/domains/modals'
import { ReactHookFormProvider, useReactHookFormContext } from '~/domains/react-hook-form'
import { StepMessage } from './components/StepMessage'
import { StepReason } from './components/StepReason'
import { StepWarning } from './components/StepWarning'
import { type FormValues } from './types/formValues'
import { getDeleteBatchRecurrencyRoute } from './utils/getDeleteBatchRecurrencyRoute'

type Props = Pick<TherapistAgendaTherapySession, 'id' | 'patient'>

const initialEntries = [getDeleteBatchRecurrencyRoute('/warning')]

export const DeleteBatchRecurrencyModal = ({ id, patient }: Props) => {
  const form = useReactHookFormContext<FormValues>()

  const modalId = `deleteBatchRecurrency:${id}` as const

  return (
    <ReactHookFormProvider {...form}>
      <Modal id={modalId}>
        <MemoryRouter initialEntries={initialEntries}>
          <Switch>
            <Route path={getDeleteBatchRecurrencyRoute('/warning')}>
              <StepWarning id={id} patient={patient} />
            </Route>
            <Route path={getDeleteBatchRecurrencyRoute('/message')}>
              <StepMessage id={id} patient={patient} />
            </Route>
            <Route path={getDeleteBatchRecurrencyRoute('/reason')}>
              <StepReason id={id} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ReactHookFormProvider>
  )
}
