import { pipe } from 'fp-ts/function'
import { toCurrency } from 'numbers'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { getEnv } from '~/utils/getEnv'
import { type Intent } from '../types/intent'

export const useTranslationVariablesTherapySessionCosts = (intent: Intent): Record<string, string> => {
  const { isVariant1 } = useFeatureFlagsByFingerprint()

  switch (intent) {
    case 'call':
    case 'home':
    case 'journaling':
    case 'sexology':
    case 'therapyMyself':
      return {
        therapySessionCost: toCurrency(
          isVariant1('ff_disable_bundle') ? 4900 : Number(getEnv('SERENIS_THERAPY_SESSION_COST_ONLINE_MYSELF')),
        ),
        bundleTherapySessionCost: pipe(
          'SERENIS_THERAPY_SESSION_COST_ONLINE_MYSELF_BUNDLE_SESSION',
          getEnv,
          Number,
          toCurrency,
        ),
      }
    case 'coaching':
      return {
        therapySessionCost: toCurrency(Number(getEnv('SERENIS_THERAPY_SESSION_COST_ONLINE_COACHING'))),
        bundleTherapySessionCost: pipe(
          'SERENIS_THERAPY_SESSION_COST_ONLINE_MYSELF_BUNDLE_SESSION',
          getEnv,
          Number,
          toCurrency,
        ),
      }
    case 'dca':
      return {
        therapySessionCost: pipe('SERENIS_THERAPY_SESSION_COST_ONLINE_DCA', getEnv, Number, toCurrency),
        firstTherapySessionCost: pipe('SERENIS_THERAPY_SESSION_COST_ONLINE_DCA_FIRST', getEnv, Number, toCurrency),
      }
    case 'nutritionist':
      return {
        therapySessionCost: pipe('SERENIS_THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS', getEnv, Number, toCurrency),
        firstTherapySessionCost: pipe(
          'SERENIS_THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST',
          getEnv,
          Number,
          toCurrency,
        ),
      }
    case 'psychiatry':
      return {
        therapySessionCost: toCurrency(
          isVariant1('ff_disable_bundle') ? 7700 : Number(getEnv('SERENIS_THERAPY_SESSION_COST_ONLINE_PSYCHIATRY')),
        ),
        firstTherapySessionCost: toCurrency(
          isVariant1('ff_disable_bundle') ? 8900 : Number(getEnv('SERENIS_THERAPY_SESSION_COST_ONLINE_PSYCHIATRY')),
        ),
      }
    case 'therapyCouple':
      return {
        therapySessionCost: toCurrency(
          isVariant1('ff_disable_bundle') ? 5900 : Number(getEnv('SERENIS_THERAPY_SESSION_COST_ONLINE_COUPLE')),
        ),
        bundleTherapySessionCost: pipe(
          'SERENIS_THERAPY_SESSION_COST_ONLINE_COUPLE_BUNDLE_SESSION',
          getEnv,
          Number,
          toCurrency,
        ),
      }
  }
}
