import { matchPath, useParams } from 'react-router-dom'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute, type Route } from '~/utils/getRoute'

type Param = {
  therapyPathId?: string
}

const paths: Route[] = [
  getRoute('/booking/:therapyId'),
  getRoute('/change-therapist/:therapyId/booking/:therapyPathId'),
]

export const useTherapyPathIdLocationParam = (): string | null => {
  const params = useParams<Param>()

  const { location } = useRootHistory()

  const match = matchPath<Partial<Param>>(location.pathname, {
    path: paths,
  })

  return params.therapyPathId ?? match?.params?.therapyPathId ?? null
}
