import { useEffect } from 'react'
import { type ActionResponse } from '~/types/graphql'

type Param = {
  onOk: (code: string) => Promise<ActionResponse>
}

export const useOtpCodeRetriever = ({ onOk }: Param) => {
  useEffect(() => {
    const abortController = new AbortController()

    const handler = async () => {
      if (!('credentials' in navigator) || !('OTPCredential' in window)) {
        return
      }

      try {
        const otp = await navigator.credentials.get({
          otp: { transport: ['sms'] },
          signal: abortController.signal,
        })

        if (!otp) {
          return
        }

        await onOk(otp.code)
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    handler()

    return () => {
      abortController.abort()
    }
  }, [onOk])
}
