import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { JournalingProvider } from '../journaling/hooks/useJournalingContext'

const LazyJournalingLogEditPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.JournalingLogEditPageView,
})

export const JournalingLogEditPage: Page = {
  exact: true,
  id: 'journaling.log.edit',
  paths: ['/journaling/logs/:journalingLogId/edit'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'journaling.log.edit',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: JournalingProvider,
  PageView: () => <LazyJournalingLogEditPageView />,
}
