import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { StripeElementsProvider } from '~/domains/stripe'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazySettingsPayments = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const SettingsPaymentsPage: Page = {
  exact: true,
  id: 'settings.payments',
  paths: ['/settings/payments'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'settings.payments',
    title: 'settings.payments.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => (
    <PatientTherapiesProvider>
      <StripeElementsProvider>{children}</StripeElementsProvider>
    </PatientTherapiesProvider>
  ),
  PageView: () => <LazySettingsPayments />,
}
