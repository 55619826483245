import { gql, useQuery } from '@apollo/client'
import { type BundlesQuery, type BundlesQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query bundles {
    bundles {
      id
      cost
      therapyPathType
      therapySessionsAvailable
    }
  }
`

export const useBundles = () => {
  const { data, loading, error } = useQuery<BundlesQuery, BundlesQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    bundles: data?.bundles ?? [],
    loading,
    error: !!error,
  }
}
