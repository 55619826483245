import {
  registerDecorator,
  type ValidationOptions,
  ValidatorConstraint,
  type ValidatorConstraintInterface,
} from 'class-validator'

type Country = {
  code: string
  name: string
}

export const countries: Country[] = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Isole Åland', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'Samoa americane', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antartide', code: 'AQ' },
  { name: 'Antigua e Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaigian', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Bielorussia', code: 'BY' },
  { name: 'Belgio', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bonaire', code: 'BQ' },
  { name: 'Bosnia ed Erzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Isola Bouvet', code: 'BV' },
  { name: 'Brasile', code: 'BR' },
  { name: "Territorio britannico dell'oceano Indiano", code: 'IO' },
  { name: 'Brunei', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Capo Verde', code: 'CV' },
  { name: 'Cambogia', code: 'KH' },
  { name: 'Camerun', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Isole Cayman', code: 'KY' },
  { name: 'Repubblica Centrafricana', code: 'CF' },
  { name: 'Ciad', code: 'TD' },
  { name: 'Cile', code: 'CL' },
  { name: 'Cina', code: 'CN' },
  { name: 'Isola di Natale', code: 'CX' },
  { name: 'Isole Cocos', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comore', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Isole Cook', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Costa d'Ivory", code: 'CI' },
  { name: 'Croazia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Curaçao', code: 'CW' },
  { name: 'Cipro', code: 'CY' },
  { name: 'Cechia', code: 'CZ' },
  { name: 'Danimarca', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Repubblica Dominicana', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egitto', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Guinea Equatoriale', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Etiopia', code: 'ET' },
  { name: 'Falkland Islands', code: 'FK' },
  { name: 'Isole Faroe', code: 'FO' },
  { name: 'Figi', code: 'FJ' },
  { name: 'Finlandia', code: 'FI' },
  { name: 'Francia', code: 'FR' },
  { name: 'Guiana francese', code: 'GF' },
  { name: 'Polinesia francese', code: 'PF' },
  { name: 'Territori Francesi del Sud', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germania', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibilterra', code: 'GI' },
  { name: 'Grecia', code: 'GR' },
  { name: 'Groenlandia', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Isola di Heard e Isole McDonald', code: 'HM' },
  { name: 'Città del Vaticano', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Ungheria', code: 'HU' },
  { name: 'Islanda', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Irlanda', code: 'IE' },
  { name: 'Isola di Man', code: 'IM' },
  { name: 'Israele', code: 'IL' },
  { name: 'Italia', code: 'IT' },
  { name: 'Giamaica', code: 'JM' },
  { name: 'Giappone', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Giordania', code: 'JO' },
  { name: 'Kazakistan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Corea del Nord', code: 'KP' },
  { name: 'Corea del Sud', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kirghizistan', code: 'KG' },
  { name: 'Repubblica Democratica Popolare del Laos', code: 'LA' },
  { name: 'Lettonia', code: 'LV' },
  { name: 'Libano', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libia', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lituania', code: 'LT' },
  { name: 'Lussemburgo', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malesia', code: 'MY' },
  { name: 'Maldive', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Isole Marshall', code: 'MH' },
  { name: 'Martinica', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Messico', code: 'MX' },
  { name: 'Micronesia', code: 'FM' },
  { name: 'Moldavia', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Marocco', code: 'MA' },
  { name: 'Mozambico', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Paesi Bassi', code: 'NL' },
  { name: 'Nuova Caledonia', code: 'NC' },
  { name: 'Nuova Zelanda', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Isola Norfolk', code: 'NF' },
  { name: 'Macedonia del Nord', code: 'MK' },
  { name: 'Isole Marianne Settentrionali', code: 'MP' },
  { name: 'Norvegia', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestina', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua Nuova Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Perù', code: 'PE' },
  { name: 'Filippine', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Polonia', code: 'PL' },
  { name: 'Portogallo', code: 'PT' },
  { name: 'Porto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Réunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Federazione Russa', code: 'RU' },
  { name: 'Ruanda', code: 'RW' },
  { name: 'Saint Barthélemy', code: 'BL' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts e Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Martin', code: 'MF' },
  { name: 'Saint Pierre e Miquelon', code: 'PM' },
  { name: 'Saint Vincent e Grenadine', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'São Tomé e Príncipe', code: 'ST' },
  { name: 'Arabia Saudita', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Sint Maarten', code: 'SX' },
  { name: 'Slovacchia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Isole Salomone', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'Sudafrica', code: 'ZA' },
  { name: 'Georgia del Sud e Sandwich australi', code: 'GS' },
  { name: 'Sud Sudan', code: 'SS' },
  { name: 'Spagna', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard e Jan Mayen', code: 'SJ' },
  { name: 'Svezia', code: 'SE' },
  { name: 'Svizzera', code: 'CH' },
  { name: 'Repubblica Araba Siriana', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tagikistan', code: 'TJ' },
  { name: 'Tanzania', code: 'TZ' },
  { name: 'Thailandia', code: 'TH' },
  { name: 'Timor Est', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad e Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turchia', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Isole Turks e Caicos', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ucraina', code: 'UA' },
  { name: 'Emirati Arabi Uniti', code: 'AE' },
  { name: 'Regno Unito di Gran Bretagna e Irlanda del Nord', code: 'GB' },
  { name: "Stati Uniti d'America", code: 'US' },
  { name: 'Isole minori esterne degli Stati Uniti', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Isole Vergini Britanniche', code: 'VG' },
  { name: 'Isole Vergini Americane', code: 'VI' },
  { name: 'Wallis e Futuna', code: 'WF' },
  { name: 'Sahara Occidentale', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
]

export const isCountry = (value: unknown) => countries.some(({ code }) => code === value)

@ValidatorConstraint({ name: 'country', async: false })
export class IsCountryConstraint implements ValidatorConstraintInterface {
  validate = isCountry

  defaultMessage = () => 'Input is not a valid country'
}

export function IsCountry<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsCountryConstraint,
    })
  }
}
