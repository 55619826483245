import { type ReactNode } from 'react'
import { Flex, type FlexProps } from 'ui-deprecated'

type Props = {
  children: ReactNode
} & Omit<FlexProps, 'direction' | 'mdPx' | 'px'>

export const PatientDetailMaxWidth = ({ children, ...flexProps }: Props) => (
  <Flex {...flexProps} direction="row" mdPx={24} px={16}>
    <Flex basis="800px" shrink={1}>
      {children}
    </Flex>
  </Flex>
)
