import { gql, useMutation } from '@apollo/client'
import {
  type JournalingSettingUpdateGoalInDaysMutation,
  type JournalingSettingUpdateGoalInDaysMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation journalingSettingUpdateGoalInDays($input: JournalingSettingUpdateGoalInDaysInput!) {
    journalingSettingUpdateGoalInDays(input: $input) {
      ok
    }
  }
`

export const useJournalingSettingUpdateGoalInDays = () =>
  useMutation<JournalingSettingUpdateGoalInDaysMutation, JournalingSettingUpdateGoalInDaysMutationVariables>(MUTATION)
