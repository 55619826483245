import { useCallback } from 'react'
import { Flex, Form, PageLayout } from 'ui-deprecated'
import { PageRoute } from '~/components/PageRoute'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { Head } from '~/domains/reservation/components/Head'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { type ReservationFormValues } from '~/domains/reservation/types'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { SubmitBookingContent } from '../components/SubmitBookingContent'

export const SubmitBooking = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  const { onBookAndAssignTherapySession } = useReservationFlow()

  const { selectedTimeSlot } = form.getValues()

  const onSubmit = useCallback(
    async ({ selectedTimeSlot, therapistId }: ReservationFormValues) => {
      if (!selectedTimeSlot) {
        return
      }

      await onBookAndAssignTherapySession(selectedTimeSlot, therapistId)
    },
    [onBookAndAssignTherapySession],
  )

  if (!selectedTimeSlot) {
    return null
  }

  return (
    <>
      <PageScrollEffect />

      <PageRoute id="reservation.submit">
        <PageLayout maxWidth="640px" px={24}>
          <Head translationId="reservation.submit" />

          <Flex pb={32}>
            <Form grow={1} id="reservationFlow" onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
              <SubmitBookingContent />
            </Form>
          </Flex>
        </PageLayout>
      </PageRoute>
    </>
  )
}
