import { Avatar, type AvatarProps } from 'ui'
import { useTherapist } from '~/hooks/useTherapist'

type Props = {
  size: AvatarProps['size']
}

export const TherapistProfileImage = ({ size }: Props) => {
  const {
    fullName,
    therapist: { profileImage },
  } = useTherapist()

  return <Avatar image={profileImage?.s} name={fullName} size={size} />
}
