import { AssertUserWithPendingChangeTherapist } from '~/domains/agenda/components/AssertUserWithPendingChangeTherapist'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { ReservationPage as ReservationPageView } from '~/domains/reservation'
import { AssertAtLeastOneFormFlow } from '~/domains/reservation/components/AssertAtLeastOneFormFlow'
import { TherapyPathByIdProvider } from '~/domains/reservation/hooks/useTherapyPathById'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

export const ChangeTherapistReservationPage: Page = {
  exact: true,
  id: 'changeTherapist.booking',
  paths: ['/change-therapist/:therapyId/booking', '/change-therapist/:therapyId/booking/:therapyPathId'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'reservation.booking',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">
        <AssertAtLeastOneFormFlow>
          <PatientTherapiesProvider>
            <TherapyPathByIdProvider>
              <AssertUserWithPendingChangeTherapist target="/">{children}</AssertUserWithPendingChangeTherapist>
            </TherapyPathByIdProvider>
          </PatientTherapiesProvider>
        </AssertAtLeastOneFormFlow>
      </AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: ReservationPageView,
}
