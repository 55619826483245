import { Flex } from 'cdk'
import { memo } from 'react'
import { Text, type TextProps } from 'ui'
import { Translation } from '~/components/Translation'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { type TranslationId } from '~/i18n/types'
import { StartTherapyPathFaq } from './StartTherapyPathFaq'

type Props = {
  textAlign: TextProps['textAlign']
}

export const StartTherapyPathTitle = memo(({ textAlign }: Props) => {
  const { therapies } = usePatientTherapies()

  const translationId: TranslationId = therapies.length
    ? 'therapies.startTherapyPath.title'
    : 'therapies.startTherapyPath.emptyState.title'

  return (
    <Flex>
      <Text fontWeight="600" kind="h2" textAlign={textAlign}>
        <Translation id={translationId} />
      </Text>

      <StartTherapyPathFaq textAlign={textAlign} />
    </Flex>
  )
})
