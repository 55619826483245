import { type TherapyPathKey } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathCard'
import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'

type TherapyPathTypeTranslation = Record<
  TherapyTherapyPathType,
  {
    title: Extract<TranslationId, `reservation.therapyAlreadyOngoing.title.${string}`>
    illustrationTherapyPath: TherapyPathKey
  }
>

export const mapTherapyAlreadyOngoingType: TherapyPathTypeTranslation = {
  MYSELF_PSYCHOTHERAPY: {
    title: 'reservation.therapyAlreadyOngoing.title.individual',
    illustrationTherapyPath: 'MYSELF_PSYCHOTHERAPY',
  },
  COUPLES_PSYCHOTHERAPY: {
    title: 'reservation.therapyAlreadyOngoing.title.couples',
    illustrationTherapyPath: 'COUPLES_PSYCHOTHERAPY',
  },
  PATH_COACHING: {
    title: 'reservation.therapyAlreadyOngoing.title.coaching',
    illustrationTherapyPath: 'PATH_COACHING',
  },
  MYSELF_PSYCHIATRY: {
    title: 'reservation.therapyAlreadyOngoing.title.psychiatry',
    illustrationTherapyPath: 'MYSELF_PSYCHIATRY',
  },
  // FIXME: https://linear.app/serenis/issue/PRD-5756/excludetherapypathtype-path-sexology-app-web
  PATH_SEXOLOGY: {
    title: 'reservation.therapyAlreadyOngoing.title.individual',
    illustrationTherapyPath: 'MYSELF_PSYCHOTHERAPY',
  },
  PATH_SLEEP: {
    title: 'reservation.therapyAlreadyOngoing.title.sleep',
    illustrationTherapyPath: 'MYSELF_PSYCHOTHERAPY',
  },
  PATH_ASSERTIVE_COMMUNICATION: {
    title: 'reservation.therapyAlreadyOngoing.title.assertive',
    illustrationTherapyPath: 'MYSELF_PSYCHOTHERAPY',
  },
  UNDERAGE_PSYCHOTHERAPY: {
    title: 'reservation.therapyAlreadyOngoing.title.underage',
    illustrationTherapyPath: 'MYSELF_PSYCHOTHERAPY',
  },
  PATH_NUTRITION_WEIGHT_LOSS: {
    title: 'reservation.therapyAlreadyOngoing.title.nutritionWeightLoss',
    illustrationTherapyPath: 'PATH_NUTRITION_WEIGHT_LOSS',
  },
  PATH_NUTRITION_DCA: {
    title: 'reservation.therapyAlreadyOngoing.title.nutritionDca',
    illustrationTherapyPath: 'PATH_NUTRITION_DCA',
  },
}
