import loadable from '@loadable/component'
import { Flex, MaxWidth1260px, OverflowHidden, PositionAbsolute, PositionRelative } from 'cdk'
import {
  BORDER_RADIUS_0,
  BORDER_WIDTH_0,
  SPACING_4XL,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  TIME_500,
} from 'design-tokens'
import { useBreakpoints } from 'hooks'
import styled from 'styled-components'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { type TranslationId } from '~/i18n/types'

const LazyClinicalDirectorAvatar = loadable(() => import('./ClinicalDirectorAvatar.lazy'), {
  resolveComponent: (components) => components.ClinicalDirectorAvatar,
})

const LazyClinicalDirectorAvatarVariant = loadable(() => import('./ClinicalDirectorAvatarVariant.lazy'), {
  resolveComponent: (components) => components.ClinicalDirectorAvatarVariant,
})

const ToastCard = styled(Card)`
  @keyframes translate-in {
    from {
      transform: translate(0, 200px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  border: ${BORDER_WIDTH_0};
  border-radius: ${BORDER_RADIUS_0};
  transform: translate(0, 200px);
  animation: 700ms ${TIME_500} translate-in forwards;
`

const ToastCardWrapper = styled(Flex)`
  margin: 0 calc(${SPACING_4XL} * -1);
`

type ClinicalDirectorQuoteProps = {
  title:
    | Extract<TranslationId, `clinicalTests.clinicalDirector.${string}.title`>
    | Extract<TranslationId, `formFlow.clinicalDirector.quote${string}.title`>
  description?:
    | Extract<TranslationId, `formFlow.clinicalDirector.quote${string}.description`>
    | Extract<TranslationId, `clinicalTests.clinicalDirector.${string}.description`>
}

export const ClinicalDirectorQuoteToast = ({ title, description }: ClinicalDirectorQuoteProps) => {
  const { isSmallOnly } = useBreakpoints()
  const { isVariant1 } = useFeatureFlagsByFingerprint()

  if (isVariant1('ff_restyle_survey')) {
    return (
      <ToastCardWrapper
        $backgroundColorName="neutral-100"
        $px={`calc(${SPACING_4XL} + ${SPACING_LG})`}
        $py={SPACING_SM}
      >
        <Flex $direction="row" $maxWidth={464}>
          <PositionRelative $basis={96}>
            <PositionAbsolute $bottom={-12} $left={-64}>
              <LazyClinicalDirectorAvatarVariant />
            </PositionAbsolute>
          </PositionRelative>
          <Flex $direction="row" $justify="center" $shrink={1}>
            <Flex $basis={320} $gap={8} $shrink={1}>
              <Text colorName="lighter" fontWeight="400" kind="paragraph">
                <Translation id={title} />
              </Text>
              <Flex>
                <Text colorName="neutral-50" fontWeight="600" kind="caption">
                  Domenico De Donatis
                </Text>
                <Text colorName="neutral-50" kind="footnote">
                  <Translation id="generic.clinicalDirector" />
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ToastCardWrapper>
    )
  }

  return (
    <OverflowHidden>
      <ToastCard backgroundColorName="darker" size="md">
        <MaxWidth1260px $direction="row" $gap={SPACING_MD} $justify="center">
          <Flex>
            <LazyClinicalDirectorAvatar size="md" />
          </Flex>
          <Flex $gap={4} $justify="center" $shrink={1}>
            <Text colorName="lighter" fontWeight="600" kind={isSmallOnly ? 'paragraph' : 'h2'}>
              <Translation id={title} />
            </Text>
            {description && (
              <Text colorName="lighter" fontWeight="400" kind={isSmallOnly ? 'caption' : 'h3'}>
                <Translation id={description} />
              </Text>
            )}
          </Flex>
        </MaxWidth1260px>
      </ToastCard>
    </OverflowHidden>
  )
}
