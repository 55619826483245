import { Suggestion, Text } from 'ui'
import { Flex, LinkButton } from 'ui-deprecated'
import { RouterLinkButton } from '~/components/RouterLinkButton'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { getGoogleMeetUrlWithEmail } from '~/utils/getGoogleMeetUrlWithEmail'
import { type Route } from '~/utils/getRoute'
import { type PatientAgendaTherapySession } from '../types'

type Props = Pick<PatientAgendaTherapySession, 'provider' | 'url'>

export const LiveTherapySessionSuggestion = ({ provider, url }: Props) => {
  const { email } = useCurrentUser()
  const { firstName } = useCurrentUser()

  return (
    <Suggestion kind="info">
      <Flex align="center" direction="row" justify="space-between">
        <Flex grow={1} pr={8} shrink={1}>
          <TranslationMarkdown
            id="therapySession.generic.ongoing.goToTherapySession.title"
            kind="paragraph"
            values={{
              firstName,
            }}
          />

          <Flex pt={8}>
            <Text fontWeight="400" kind="paragraph">
              <Translation id="therapySession.generic.ongoing.goToTherapySession.description" />
            </Text>
          </Flex>
        </Flex>

        {url && (
          <Flex mdBasis="180px" mdPl={16} mdPt={0} pt={8}>
            {provider === 'HOUSE' ? (
              <RouterLinkButton
                id="therapy-session-link"
                kind="primary"
                liveAnimation
                to={url as Route}
                translationId="therapySession.generic.ongoing.goToTherapySession"
              />
            ) : (
              <LinkButton
                href={getGoogleMeetUrlWithEmail(url, email)}
                id="therapy-session-link"
                kind="primary"
                target="_blank"
              >
                <Translation id="therapySession.generic.ongoing.goToTherapySession" />
              </LinkButton>
            )}
          </Flex>
        )}
      </Flex>
    </Suggestion>
  )
}
