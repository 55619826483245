import { gql, useQuery } from '@apollo/client'
import { type JournalingSettingQuery, type JournalingSettingQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query journalingSetting {
    journalingSetting {
      id
      goalInDays
      journalingActivities {
        id
        journalingLogCount
        name
        slug
      }
    }
  }
`

export const useJournalingSetting = () => {
  const { data, error, loading, refetch } = useQuery<JournalingSettingQuery, JournalingSettingQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    loading,
    journalingSetting: data?.journalingSetting || null,
    refetch,
  }
}
