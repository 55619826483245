import { gql, useQuery } from '@apollo/client'
import { type ClinicalTestsQuery, type ClinicalTestsQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query clinicalTests {
    clinicalTests {
      id
      code
      questions {
        id
      }
    }
  }
`

export const useClinicalTests = () => {
  const { data, error, loading } = useQuery<ClinicalTestsQuery, ClinicalTestsQueryVariables>(QUERY)

  return {
    clinicalTests: data?.clinicalTests ?? [],
    error: !!error,
    loading,
  }
}
