import {
  length,
  registerDecorator,
  type ValidationOptions,
  ValidatorConstraint,
  type ValidatorConstraintInterface,
} from 'class-validator'

export const isUID = (value: unknown) => {
  if (typeof value !== 'string') {
    return false
  }

  return length(value.trim(), 16, 20)
}

@ValidatorConstraint({ name: 'uid', async: false })
export class IsUIDConstraint implements ValidatorConstraintInterface {
  validate = isUID

  defaultMessage = () => 'Input is not a valid UID: length between 16 or 20 expected'
}

export function IsUID<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsUIDConstraint,
    })
  }
}
