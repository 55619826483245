import NextHead from 'next/head'
import { type PageKey, useSeoMeta } from '~/hooks/useSeoMeta'

type Props = {
  translationId: Extract<PageKey, `reservation.${string}`>
}

export const Head = ({ translationId }: Props) => {
  const { title } = useSeoMeta(translationId)

  return (
    <NextHead>
      <title key="title">{title}</title>
      <meta key="og:title" content={title} property="og:title" />
      <meta key="twitter:title" content={title} property="twitter:title" />
    </NextHead>
  )
}
