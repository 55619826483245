import { pipe } from 'fp-ts/function'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'

export const getTherapySessionCancellationPolicyInSeconds = (therapyPathType: TherapyTherapyPathType | null) => {
  if (therapyPathType === 'MYSELF_PSYCHIATRY') {
    return pipe('SERENIS_THERAPY_SESSION_CANCELLATION_POLICY_PSYCHIATRY', getEnv, Number)
  }

  return pipe('SERENIS_THERAPY_SESSION_CANCELLATION_POLICY', getEnv, Number)
}
