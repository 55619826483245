import { type CSSProperties } from 'react'
import styled from 'styled-components'
import { Flex } from 'ui-deprecated'

type Props = {
  visibility: CSSProperties['visibility']
}

export const Visibility = styled(Flex)<Props>`
  visibility: ${({ visibility }) => visibility};
`
