import { Flex } from 'cdk'
import { nowInMilliseconds } from 'dates'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Modal, ModalContent, Text } from 'ui'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { getRoute } from '~/utils/getRoute'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { Translation } from '../Translation'
import { BulletPoint } from '.'

const FlexWithBackgroundSmall = styled(Flex).attrs({
  $basis: '110px',
  $grow: 1,
  $justify: 'center',
  $backgroundColorName: 'black',
})`
  margin: -24px -24px 0;
  background-image: url('https://assets.serenis.it/bundles/bundles_sm.webp');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`

export const BundleIntroModal = () => {
  const { isVariant1 } = useFeatureFlagsByUserId()
  const [isOpen, setIsOpen] = useState(!getLocalStorage('bundle-intro-viewed-at'))

  const history = useHistory()

  const handleIsOpenChanged = useCallback(() => {
    setLocalStorage('bundle-intro-viewed-at', nowInMilliseconds())
    setIsOpen(false)
  }, [])

  const handleOnClick = useCallback(() => {
    history.push(getRoute('/bundles'))
    setLocalStorage('bundle-intro-viewed-at', nowInMilliseconds())
    setIsOpen(false)
  }, [history])

  if (isVariant1('ff_disable_bundle')) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onIsOpenChange={handleIsOpenChanged}>
      <ModalContent className="srns-serenis-dark">
        <FlexWithBackgroundSmall />
        <Flex $gap={16} $grow={1} $py={24}>
          <Text colorName="white" kind="h2">
            <Translation id="bundle.banner.title" />
          </Text>
          <BulletPoint id="bundle.banner.bullet.1" />
          <BulletPoint id="bundle.banner.bullet.2" />
        </Flex>
        <Flex $align="flex-start" className="srns-serenis-light">
          <Button kind="tertiary" onClick={handleOnClick}>
            <Translation id="bundle.banner.start" />
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
