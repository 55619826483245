import { PositionAbsolute, PositionRelative } from 'cdk'
import { BORDER_RADIUS_MD, type ColorName, TIME_150 } from 'design-tokens'
import styled from 'styled-components'

const Track = styled(PositionAbsolute)`
  transition: min-width ${TIME_150} ease;
`

type Props = {
  total: number
  value: number
  backgroundColorName?: ColorName
  trackColorName?: ColorName
}

export const ProgressBar = ({
  total,
  value,
  backgroundColorName = 'primary-40',
  trackColorName = 'primary-50',
}: Props) => (
  <PositionRelative $backgroundColorName={backgroundColorName} $borderRadius={BORDER_RADIUS_MD} $minHeight={4}>
    <Track
      $backgroundColorName={trackColorName}
      $borderRadius={BORDER_RADIUS_MD}
      $left={0}
      $minHeight={4}
      $minWidth={`${(value / total) * 100}%`}
      $top={0}
      $zIndex={1}
    />
  </PositionRelative>
)
