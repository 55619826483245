import { memo } from 'react'
import { Modal } from '~/domains/modals'
import { TherapistPresentationCard } from '~/domains/therapist/TherapistPresentationCard'
import { useTherapist } from '~/hooks/useTherapist'
import { type TherapyTherapyPathType } from '~/types/graphql'

type Props = {
  therapyPathType: TherapyTherapyPathType | null
}

export const TherapistProfileModal = memo(({ therapyPathType }: Props) => {
  const { id } = useTherapist()

  return (
    <Modal id={`therapistProfile:${id}`} size="xl">
      <TherapistPresentationCard therapyPathType={therapyPathType ?? 'MYSELF_PSYCHOTHERAPY'} />
    </Modal>
  )
})
