import { gql, useMutation } from '@apollo/client'
import { type DeleteUserDeviceMutation, type DeleteUserDeviceMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation deleteUserDevice($input: DeleteUserDeviceInput!) {
    deleteUserDevice(input: $input) {
      id
    }
  }
`

export const useDeleteUserDevice = () =>
  useMutation<DeleteUserDeviceMutation, DeleteUserDeviceMutationVariables>(MUTATION)
