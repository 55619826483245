import { matchPath, useLocation } from 'react-router-dom'
import { Pill } from 'ui'
import { Flex } from 'ui-deprecated'
import { ChipBonusPsicologo } from '~/components/ChipBonusPsicologo'
import { RecurrencyTime } from '~/components/RecurrencyTime'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { type TranslationId } from '~/i18n/types'
import { getRoute } from '~/utils/getRoute'
import { TherapySessionCardType } from './TherapySessionCardType'
import { TherapySessionDateInfo } from './TherapySessionDateInfo'
import { TherapySessionPatientProfile } from './TherapySessionPatientProfile'
import { TherapySessionPatientProfileLinks } from './TherapySessionPatientProfileLinks'
import { TherapySessionStatus } from './TherapySessionStatus'

type Props = Pick<
  TherapistAgendaTherapySession,
  'bonus' | 'endAt' | 'id' | 'patient' | 'repeatEveryWeek' | 'startAt' | 'status' | 'therapyPathType'
> & {
  isInTherapySessionLive?: boolean
  therapySessionTypeCost?: string | null
}

export const TherapySessionCardPatientInfo = ({
  bonus,
  endAt,
  id,
  isInTherapySessionLive = false,
  patient,
  repeatEveryWeek,
  startAt,
  status,
  therapyPathType,
  therapySessionTypeCost,
}: Props) => {
  const { pathname } = useLocation()
  const matchChatPath = !!matchPath(pathname, { path: [getRoute('/chat')] })

  const typeTranslationId: Extract<TranslationId, `therapySession.card.label.type.${string}`> =
    therapySessionTypeCost === 'PAID' ? 'therapySession.card.label.type.paid' : 'therapySession.card.label.type.free'

  return (
    <Flex grow={1} shrink={1}>
      <Flex align="center" direction="row">
        <Flex pr={8}>
          <TherapySessionStatus status={status} />
        </Flex>

        {!!bonus && (
          <Flex pr={8}>
            <ChipBonusPsicologo />
          </Flex>
        )}

        {!!therapySessionTypeCost && (
          <Pill>
            <Translation id={typeTranslationId} />
          </Pill>
        )}
      </Flex>

      <Flex pt={16}>
        <TherapySessionPatientProfile patient={patient} />

        <TherapySessionDateInfo endAt={endAt} startAt={startAt} />

        {repeatEveryWeek && (
          <Flex pt={8}>
            <RecurrencyTime repeatEveryWeek={repeatEveryWeek} startAt={startAt} />
          </Flex>
        )}

        <Flex pt={8}>
          <TherapySessionCardType therapyPathType={therapyPathType} />
        </Flex>

        {!matchChatPath && !isInTherapySessionLive && (
          <Flex pt={24}>
            <TherapySessionPatientProfileLinks id={id} patient={patient} />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
