import { getHours } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { type mapAvailabilitiesToSlotsByDayAndTherapist } from './mapAvailabilitiesToSlotsByDayAndTherapist'

type Items = ReturnType<typeof mapAvailabilitiesToSlotsByDayAndTherapist>

export const filterTimeSlotsByIntervals = (items: Items, intervals: string[]): Items => {
  const itemsFilteredSlots: Items = items.map(([day, slotsByTherapist]) => [
    day,
    slotsByTherapist.map(([therapistId, slots]) => [
      therapistId,
      slots.filter((slot) => {
        const hour = pipe(slot, getHours)

        switch (true) {
          case intervals.includes('MORNING') && hour >= 8 && hour < 13:
          case intervals.includes('AFTERNOON') && hour >= 13 && hour < 18:
          case intervals.includes('EVENING') && hour >= 18 && hour <= 23:
            return true
          case !intervals.includes('MORNING') && !intervals.includes('AFTERNOON') && !intervals.includes('EVENING'):
            return true
          default:
            return false
        }
      }),
    ]),
  ])

  return itemsFilteredSlots.filter(
    ([, slotsByTherapist]) => slotsByTherapist.length && slotsByTherapist.some(([, slots]) => slots.length),
  )
}
