import { Chip } from 'ui'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { type TranslationId } from '~/i18n/types'
import { getCurrentStatusTranslationId } from '../utils/translations/getCurrentStatusTranslationId'

type Props = Pick<TherapistAgendaTherapySession, 'status'>

const greenStatusLabels: TranslationId[] = [
  'therapySession.patient.confirmed.statusValues',
  'therapySession.patient.ended.statusValues',
  'therapySession.patient.ongoing.statusValues',
  'therapySession.patient.paymentFailed.statusValue',
  'therapySession.patient.scheduled.statusValue',
]

export const TherapySessionStatus = ({ status }: Props) => {
  const isStatusLabelGreen = greenStatusLabels.includes(getCurrentStatusTranslationId(status))

  return (
    <Chip data-test-id="diagnosis-mandatory-field-badge" kind={isStatusLabelGreen ? 'success' : 'error'}>
      <Translation id={getCurrentStatusTranslationId(status)} />
    </Chip>
  )
}
