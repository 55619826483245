import { useCallback } from 'react'
import { RouterLinkButton } from '~/components/RouterLinkButton'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { type ModalId, useModals } from '~/domains/modals'

type Props = {
  modalId: Extract<ModalId, `editTherapySession:${string}`>
}

export const EditTherapySessionContactButton = ({ modalId }: Props) => {
  const { close } = useModals()
  const trackClick = useTrackEvent('CLICK')

  const onClick = useCallback(() => {
    trackClick({ name: 'clicks.patientToTherapist.i-want-to-edit-therapy-session' })

    close(modalId)
  }, [close, modalId, trackClick])

  return <RouterLinkButton kind="primary" onClick={onClick} to="/chat" translationId="actions.proceed" />
}
