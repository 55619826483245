import { differenceInMinutes, secondsToMinutes, set } from 'date-fns'
import { isNeitherNullNorUndefined } from 'functions'
import {
  type CalendarAvailabilityEndAtFormValues,
  type CalendarAvailabilityStartAtFormValues,
} from '~/domains/availabilities/calendar/components/CalendarAvailabilityFormSelect'
import { THERAPIST_AVAILABILITY_MINIMUM_SLOT_DURATION } from '~/domains/availabilities/constants/constants'
import { type TranslationId } from '~/i18n/types'

type Param = Partial<CalendarAvailabilityStartAtFormValues> & Partial<CalendarAvailabilityEndAtFormValues>

export const isAvailabilityRangeAllowed = ({
  endAtHours,
  endAtMinutes,
  startAtHours,
  startAtMinutes,
}: Param):
  | Extract<
      TranslationId,
      | 'forms.errors.availability.required'
      | 'forms.errors.availability.lessThanMininumDuration'
      | 'forms.errors.availability.isNotModuloOfDuration'
    >
  | boolean => {
  if (!isNeitherNullNorUndefined(endAtHours) || !isNeitherNullNorUndefined(endAtMinutes)) {
    return true
  }

  if (!isNeitherNullNorUndefined(startAtHours) || !isNeitherNullNorUndefined(startAtMinutes)) {
    return 'forms.errors.availability.required'
  }

  const endAt = set(new Date(), { hours: endAtHours, minutes: endAtMinutes })
  const startAt = set(new Date(), { hours: startAtHours, minutes: startAtMinutes })

  const slotDuration = differenceInMinutes(endAt, startAt)
  if (slotDuration < secondsToMinutes(THERAPIST_AVAILABILITY_MINIMUM_SLOT_DURATION)) {
    return 'forms.errors.availability.lessThanMininumDuration'
  }

  if (slotDuration % secondsToMinutes(THERAPIST_AVAILABILITY_MINIMUM_SLOT_DURATION) > 0) {
    return 'forms.errors.availability.isNotModuloOfDuration'
  }

  return true
}
