import { gql, useMutation } from '@apollo/client'
import { type JournalingLogDeleteMutation, type JournalingLogDeleteMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation journalingLogDelete($input: JournalingLogDeleteInput!) {
    journalingLogDelete(input: $input) {
      ok
    }
  }
`

export const useJournalingLogDelete = () =>
  useMutation<JournalingLogDeleteMutation, JournalingLogDeleteMutationVariables>(MUTATION)
