import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { type FormValues } from '~/components/TherapistChurnReasonFields'
import { Modal, type ModalId } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { TherapistChurnReasonModalIntro } from './pages/Intro'
import { TherapistChurnReasonModalProceed } from './pages/Proceed'
import { TherapistChurnReasonModalStop } from './pages/Stop'
import { getTherapistChurnReasonRoute } from './utils/getTherapistChurnReasonRoute'

type Props = {
  id: Extract<ModalId, `setChurnReason:${string}` | `stopTherapySession:${string}`>
  loading: boolean
  onSubmit: (values: FormValues) => Promise<void>
  patientFullName: string
}

export const TherapistChurnReasonModal = ({ id, loading = false, onSubmit, patientFullName }: Props) => {
  const form = useReactHookForm<FormValues>()

  return (
    <ReactHookFormProvider {...form}>
      <Modal id={id} size="lg">
        <ModalCloseButton id={id} />

        <MemoryRouter>
          <Switch>
            <Route exact path={getTherapistChurnReasonRoute('/')}>
              <TherapistChurnReasonModalIntro loading={loading} patientFullName={patientFullName} />
            </Route>
            <Route exact path={getTherapistChurnReasonRoute('/proceed')}>
              <TherapistChurnReasonModalProceed
                loading={loading}
                onSubmit={onSubmit}
                patientFullName={patientFullName}
              />
            </Route>
            <Route exact path={getTherapistChurnReasonRoute('/stop')}>
              <TherapistChurnReasonModalStop loading={loading} onSubmit={onSubmit} patientFullName={patientFullName} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ReactHookFormProvider>
  )
}
