import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal } from '~/domains/modals'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { StepBookedFirst } from './components/StepBookedFirst'
import { StepBookedSecond } from './components/StepBookedSecond'
import { StepChooseTherapists } from './components/StepChooseTherapists'
import { StepNotBooked } from './components/StepNotBooked'
import { type FormValues } from './types/formValues'
import { getJournalingLogShareWithMultipleTherapistsRoute } from './utils/getJournalingLogShareWithMultipleTherapistsRoute'

type Props = {
  journalingLogId: string
  sharedWithTherapistUserIds?: string[] | null
}

export const JournalingLogShareWithMultipleTherapistsModal = ({
  journalingLogId,
  sharedWithTherapistUserIds,
}: Props) => {
  const form = useReactHookForm<FormValues>({
    defaultValues: {},
  })

  const initialEntries = [getJournalingLogShareWithMultipleTherapistsRoute('/choose-therapists')]

  return (
    <ReactHookFormProvider {...form}>
      <Modal id={`journalingLogShareWithMultipleTherapists:${journalingLogId}`}>
        <MemoryRouter initialEntries={initialEntries}>
          <Switch>
            <Route path={getJournalingLogShareWithMultipleTherapistsRoute('/choose-therapists')}>
              <StepChooseTherapists
                journalingLogId={journalingLogId}
                sharedWithTherapistUserIds={sharedWithTherapistUserIds}
              />
            </Route>
            <Route path={getJournalingLogShareWithMultipleTherapistsRoute('/booked-second')}>
              <StepBookedSecond
                journalingLogId={journalingLogId}
                sharedWithTherapistUserIds={sharedWithTherapistUserIds}
              />
            </Route>
            <Route path={getJournalingLogShareWithMultipleTherapistsRoute('/booked-first')}>
              <StepBookedFirst
                journalingLogId={journalingLogId}
                sharedWithTherapistUserIds={sharedWithTherapistUserIds}
              />
            </Route>
            <Route path={getJournalingLogShareWithMultipleTherapistsRoute('/not-booked')}>
              <StepNotBooked
                journalingLogId={journalingLogId}
                sharedWithTherapistUserIds={sharedWithTherapistUserIds}
              />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ReactHookFormProvider>
  )
}
