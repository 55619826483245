import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'

type Props = Pick<TherapistAgendaTherapySession, 'patient'>

export const TherapySessionPatientProfile = ({ patient }: Props) => (
  <Flex direction="row" grow={1} shrink={1}>
    <Text ellipsis fontWeight="600" kind="paragraph">
      {patient.fullName}
    </Text>
  </Flex>
)
