import { COLOR_GREY_60, COLOR_RED_80 } from 'design-tokens'
import { type TextAreaStyleProps } from '../types'

export const getPlaceholderColorFromProps = ({ invalid }: Pick<TextAreaStyleProps, 'invalid'>) => {
  if (invalid) {
    return COLOR_RED_80
  }

  return COLOR_GREY_60
}
