import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { type PatientAgendaTherapySession } from '~/domains/agenda/types'
import { Modal } from '~/domains/modals'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { StepMessage } from './components/StepMessage'
import { StepOptions } from './components/StepOptions'
import { StepReason } from './components/StepReason'
import { type FormValues } from './types/formValues'
import { getDeleteTherapySessionRoute } from './utils/getDeleteTherapySessionRoute'

type Props = Pick<PatientAgendaTherapySession, 'endAt' | 'id' | 'recurrency' | 'startAt' | 'therapistId'>

export const DeleteTherapySessionModal = ({ endAt, id, recurrency, startAt, therapistId }: Props) => {
  const form = useReactHookForm<FormValues>({
    defaultValues: {
      churnReason: undefined,
      deletedReason: undefined,
      deletedType: 'DELETE_SINGLE_THERAPY_SESSION',
      message: '',
    },
  })

  const initialEntries = recurrency
    ? [getDeleteTherapySessionRoute('/options')]
    : [getDeleteTherapySessionRoute('/reason')]

  return (
    <ReactHookFormProvider {...form}>
      <Modal id={`deleteTherapySession:${id}`}>
        <MemoryRouter initialEntries={initialEntries}>
          <Switch>
            <Route path={getDeleteTherapySessionRoute('/options')}>
              <StepOptions id={id} />
            </Route>
            <Route path={getDeleteTherapySessionRoute('/reason')}>
              <StepReason endAt={endAt} id={id} startAt={startAt} />
            </Route>
            <Route path={getDeleteTherapySessionRoute('/message')}>
              <StepMessage id={id} therapistId={therapistId} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ReactHookFormProvider>
  )
}
