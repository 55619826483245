import loadable from '@loadable/component'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { FormFlowsProvider } from '~/domains/formFlow/hooks/useFormFlows'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { getRoute } from '~/utils/getRoute'

const LazyFormFlow = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const ChangeTherapistPreferencesFormFlowPage: Page = {
  exact: true,
  id: 'changeTherapist.preferences.formFlow',
  paths: ['/change-therapist/:therapyId/preferences'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'changeTherapist',
    title: null,
  },
  PageGuard: ({ children }) => <AssertPatient target="/">{children}</AssertPatient>,
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => {
    const history = useHistory()

    const onError = useCallback(() => {
      history.replace(getRoute('/'))
    }, [history])
    return (
      <FormFlowsProvider onError={onError} type="PREFERENCES_CHANGE_THERAPIST">
        {children}
      </FormFlowsProvider>
    )
  },
  PageView: () => <LazyFormFlow />,
}
