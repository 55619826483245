import { gql, useMutation } from '@apollo/client'
import { type ChangeTherapistMutation, type ChangeTherapistMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation changeTherapist($input: ChangeTherapistInput!) {
    changeTherapist(input: $input) {
      ok
    }
  }
`

export const useChangeTherapist = () => useMutation<ChangeTherapistMutation, ChangeTherapistMutationVariables>(MUTATION)
