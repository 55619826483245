import { useCallback } from 'react'
import { Button } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type ModalId, useModals } from '~/domains/modals'
import { StopTherapySessionModal } from './StopTherapySessionModal'

type Props = {
  id: string
  patient: {
    fullName: string
    id: string
  }
}

export const StopTherapySessionButton = ({ id, patient }: Props) => {
  const { open, isOpen } = useModals()

  const modalId: ModalId = `stopTherapySession:${id}`

  const onClick = useCallback(() => {
    open(modalId)
  }, [modalId, open])

  return (
    <>
      <Button kind="secondary" onClick={onClick} size="small">
        <Translation id="actions.stopTherapySession" />
      </Button>

      {isOpen(modalId) && <StopTherapySessionModal id={id} patient={patient} />}
    </>
  )
}
