import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyJournalingLogPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.JournalingLogPageView,
})

export const JournalingLogSharedPage: Page = {
  exact: true,
  id: 'journaling.log',
  paths: ['/journaling/logs/:journalingLogId/shared'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'journaling.log.shared',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertTherapist target="/">{children}</AssertTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyJournalingLogPageView />,
}
