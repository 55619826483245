import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { ReferralPageView } from '~/domains/referral'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

export const ReferralPage: Page = {
  exact: true,
  id: 'referral',
  paths: ['/referral'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'referral',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PatientTherapiesProvider,
  PageView: ReferralPageView,
}
