import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'

type LabelTranslationId = Extract<TranslationId, `therapies.startTherapyPath.banner.title.${string}`>

export function getTherapyBannerLabelById(therapyPath: TherapyTherapyPathType | null): LabelTranslationId {
  switch (therapyPath) {
    case 'COUPLES_PSYCHOTHERAPY':
      return 'therapies.startTherapyPath.banner.title.couples'
    case 'PATH_COACHING':
      return 'therapies.startTherapyPath.banner.title.coaching'
    case 'PATH_SEXOLOGY':
      return 'therapies.startTherapyPath.banner.title.sexology'
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return 'therapies.startTherapyPath.banner.title.nutritionWeightLoss'
    case 'PATH_NUTRITION_DCA':
      return 'therapies.startTherapyPath.banner.title.nutritionDca'
    case 'MYSELF_PSYCHOTHERAPY':
    default:
      return 'therapies.startTherapyPath.banner.title.individual'
  }
}
