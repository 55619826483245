import { Flex } from 'cdk'
import { SPACING_LG, SPACING_MD } from 'design-tokens'
import { GestoLogoHorizontal } from 'illustrations'
import { type PropsWithChildren, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { getRoute } from '~/utils/getRoute'

const FlexWithBackground = styled(Flex).attrs({
  $align: 'flex-start',
  $backgroundColorName: 'white',
  $gap: SPACING_MD,
  $grow: 1,
  $justify: 'center',
  $p: SPACING_LG,
  $wrap: 'wrap',
})`
  background-image: url('gesto-join-banner.webp');
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
`

const SaasActivateGestoCard = ({ children }: PropsWithChildren) => (
  <Flex $borderRadius={16} $elevationName="md" $overflow="hidden">
    <FlexWithBackground $hide $mdHide={false}>
      {children}
    </FlexWithBackground>

    <Flex $align="center" $direction="row" $gap={24} $mdHide $p={24} $wrap="wrap">
      {children}
    </Flex>
  </Flex>
)

export const SaasActivateGestoVariant = () => {
  const history = useHistory()

  const handleOnClick = useCallback(() => {
    history.push(getRoute('/gesto/onboarding/internal'))
  }, [history])

  return (
    <SaasActivateGestoCard>
      <GestoLogoHorizontal />
      <Flex $grow={1} $shrink={1}>
        <Text fontWeight="600" kind="h2">
          <Translation id="saas.onboarding.internal.suggestion.title.variant" />
        </Text>
        <TranslationMarkdown
          colorName="grey-80"
          fontWeight="500"
          id="saas.onboarding.internal.suggestion.description.variant"
        />
      </Flex>
      <Link to={getRoute('/gesto/onboarding/internal')}>
        <Button kind="primary" onClick={handleOnClick}>
          <Translation id="saas.onboarding.internal.suggestion.complete.variant" />
        </Button>
      </Link>
    </SaasActivateGestoCard>
  )
}
