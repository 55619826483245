import { authenticatedUserGroups } from '../constants'
import { type AuthenticatedUserGroups } from '../types'

export const toAuthenticatedUserGroups = (groups: string[]): AuthenticatedUserGroups => {
  const parsed = authenticatedUserGroups.filter((g) => groups.includes(g))

  if (!parsed.length) {
    return ['patient']
  }

  return parsed
}
