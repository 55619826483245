import { Flex } from 'cdk'
import Zoom from 'react-medium-image-zoom'
import { Avatar, Text } from 'ui'
import { getTherapistJobTitleTranslationId } from '~/components/TherapistDetailCard/utils/getTherapistJobTitleTranslationId'
import { Translation } from '~/components/Translation'
import { useTherapist } from '~/hooks/useTherapist'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { TherapistChipSexology } from '../components/TherapistChipSexology'
import { TherapistOrientation } from '../components/TherapistOrientation'

type TherapistSummaryProps = {
  isOneColumnLayout?: boolean
  therapyPathType: TherapyTherapyPathType
}

export const TherapistSummary = ({ isOneColumnLayout = false, therapyPathType }: TherapistSummaryProps) => {
  const {
    fullName,
    therapist: {
      profileImage,
      sex,
      therapySchool: { orientation },
    },
  } = useTherapist()

  const jobTitleTranslationId = getTherapistJobTitleTranslationId({
    sex,
    therapyPathType,
  })

  const { isCoach, isNutritionist, isPsychotherapist } = useTherapistProfessionTypeValue()

  const showOrientation = orientation != null && isPsychotherapist && !isCoach && !isNutritionist

  return (
    <Flex $align="center">
      <Zoom zoomImg={{ src: profileImage?.l }}>
        <Avatar image={profileImage?.s} name={fullName} size="2xl" />
      </Zoom>
      <Flex $pt={8}>
        <Text fontWeight="600" kind={isOneColumnLayout ? 'paragraph' : 'h3'} textAlign="center">
          {fullName}
        </Text>
      </Flex>
      <Flex $pt={4}>
        <Text colorName="grey-80" kind="paragraph" textAlign="center">
          <Translation id={jobTitleTranslationId} />
        </Text>
        {showOrientation && <TherapistOrientation orientation={orientation} textAlign="center" />}
      </Flex>
      {therapyPathType === 'PATH_SEXOLOGY' && (
        <Flex $pt={4}>
          <TherapistChipSexology />
        </Flex>
      )}
    </Flex>
  )
}
